export const BoltResource = {
  title: "Bolts",
  urlPath: "/monitorator/bolts",
  apiPath: "/monitorator/bolts",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
  },
  properties: {
    pk: {
      id: "pk",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        list: true,
        new: false,
        filter: true,
      },
    },
  },
};
