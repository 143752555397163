import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  /* height: calc(100vh - 130px); */
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  height: 75%;
  overflow: auto;

  .table-responsive {
    width: 100%;
    overflow-x: unset;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
  //FIREFOX
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 24px;
    max-width: 84%;
  }

  /* -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > .table-responsive {
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  } */
`;

export const DateContainer = styled.div`
  width: 200px;
`;

export const ChartFilters = styled.div`
  display: flex;
  gap: 8px;
`;