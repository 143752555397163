import { RecordProps } from "../../../../types/default";
import { ListContainer } from "./styles";

export const ListFacility = ({ id, record }: RecordProps) => {
  const { companyName, facilityName, companyId, facilityId } = record;

  if (!companyId) {
    return (
      <ListContainer>
        <span>Bolt nao cadastrado</span>
      </ListContainer>
    );
  }

  return (
    <ListContainer
      href={`${process.env.REACT_APP_RETINA_WEB_URL}/companies/${companyId}/facilities/${facilityId}/assets?tab=bolt`}
      target="_blank"
    >
      <div>
        <span>Empresa: </span>
        <span title={companyName}>{companyName}</span>
      </div>
      <div>
        <span>Unidade: </span>
        <span title={facilityName}>{facilityName}</span>
      </div>
    </ListContainer>
  );
};
