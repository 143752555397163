import { Header } from "../Header";
import { Sidebar } from "../Sidebar";

import { Container, ContentContainer } from "./styles";

import { PageTemplateProps } from "./types";

export const PageTemplate = ({ children }: PageTemplateProps) => {
  return (
    <Container>
      <Header />
      <ContentContainer>
        <Sidebar />
        {children}
      </ContentContainer>
      {/* <Footer /> */}
    </Container>
  );
};
