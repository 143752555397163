import { BuildRoutes } from "../../routes/BuildRoutes";

import { BoardSignalResource } from "./resources/BoardSignals";
import { GatewayScheduleResource } from "./resources/GatewaySchedules";
import { GatewayResource } from "./resources/Gateways";
import { GatewayFirmwares } from "./resources/GatewayFirmwares";

const resourcesRoutes = [
  BoardSignalResource,
  GatewayScheduleResource,
  GatewayResource,
  GatewayFirmwares,
]
  .map((resource) => BuildRoutes(resource))
  .reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);

export const CONFIGURATOR_ROUTES = [...resourcesRoutes];
