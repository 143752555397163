import { ListLogoUrl } from "../../../components/List/LogoUrl/List";
import { RecordProps } from "../../../types/default";
import { ListLastCollect } from "../components/LastCollect";
import { ListPosition } from "../components/Position";
import { ListSchedule } from "../components/Schedule";
import { ModalExportDisconnectedPositions } from "../modals/ExportDisconnectedPositions";

export const DisconnectedPositions = {
  title: "Pontos desconectados",
  urlPath: "/monitorator/disconnected-positions",
  apiPath: "/monitorator/disconnected-positions",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
    exportCSV: {
      name: "exportCSV",
      type: "resource",
      label: "Exportar CSV",
      modalComponent: () => <ModalExportDisconnectedPositions />,
    },
  },
  properties: {
    logoUrl: {
      id: "logoUrl",
      type: "IMAGE",
      label: null,
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListLogoUrl record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    position: {
      id: "position",
      type: "TEXT",
      label: "PONTO",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListPosition record={record} id={id} />
        ),
      },
    },
    activatorId: {
      id: "activatorId",
      label: "UUID",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    lastCollect: {
      id: "lastCollect",
      label: "ULTIMA COLETA",
      type: "TEXT",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => (
          <ListLastCollect record={record} id={id} />
        ),
      },
    },
    schedule: {
      id: "schedule",
      label: "ULTIMO AGENDAMENTO",
      type: "TEXT",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => <ListSchedule record={record} id={id} />,
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
      },
    },
    facilityId: {
      id: "facilityId",
      label: "UNIDADE",
      type: "SELECT_API",
      endpoint: "/facilities-options",
      isVisible: {
        filter: true,
      },
    },
    motive: {
      id: "motive",
      label: "MOTIVO",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
  },
};
