import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";
import useApi from "../../hooks/useApi";
import { error, success } from "../Toast";
import { CommentsModal } from "../../modals/CommentsModal";

interface IAddComment {
  gatewayId: string;
  value?: string;
  refresh: (values?: any) => void;
}

export const AddComment = ({ gatewayId, value, refresh }: IAddComment) => {
  const [editionMode, setEditionMode] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value || "");
  const [showCommentsModal, setShowCommentsModal] = useState<boolean>(false);

  const handleChange = (value: string) => {
    setInputValue(value);
  };

  const handleOpenModal = () => {
    setShowCommentsModal(true);
  };

  const handleCloseModal = () => {
    setShowCommentsModal(false);
  };

  return (
    <Container>
      <input
        disabled={!editionMode}
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
      />
      <ButtonsContainer>
        <button onClick={handleOpenModal}>
          {editionMode ? <FaCheck size={12} /> : <MdEdit size={12} />}
        </button>
      </ButtonsContainer>
      {showCommentsModal && (
        <CommentsModal
          onClose={handleCloseModal}
          isOpen={showCommentsModal}
          gatewayId={gatewayId}
          refresh={refresh}
        />
      )}
    </Container>
  );
};

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 5px;

  input {
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${({ theme }) => theme.fonts.primary.H4Regular};
    border-radius: 4px;
    padding: 8px;
    height: 34px;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ButtonsContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;

    svg {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }

    :hover {
      background: ${(props) => props.theme.colors.iconsSecondary};

      svg {
        color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      }
    }
  }
`;
