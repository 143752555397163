import { ListBoolean } from "../../../components/List/ListBoolean";
import { ListLogoUrl } from "../../../components/List/LogoUrl/List";
import { PageProps, RecordProps } from "../../../types/default";
import { del } from "../../../utils/api";
import { ListPosition } from "../../monitorator/components/Position";
import { ListSchedule } from "../components/ListSchedule";
import { ListSensorConnected } from "../components/ListSensorConnected";
import { GatewayScheduleDeleteAll } from "../pages/GatewayScheduleDeleteAll";

export const GatewayScheduleResource = {
  title: "Horário dos sensores",
  urlPath: "/configurator/gateway-schedules",
  apiPath: "/configurator/gateway-schedules",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: {
      isVisible: true,
      handler: async (values: any) => {
        await del({
          path: `/configurator/gateway-schedules/${values.gatewayId}`,
          queryStringParameters: {
            schedulePosition: values.schedulePosition,
          },
        });
      },
    },
    clearGateway: {
      type: "resource",
      name: "clearGateway",
      label: "Limpar gateway",
      component: ({ resource }: PageProps) => (
        <GatewayScheduleDeleteAll resource={resource} />
      ),
    },
  },
  properties: {
    logoUrl: {
      id: "logoUrl",
      type: "IMAGE",
      label: null,
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListLogoUrl record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    boardId: {
      id: "boardId",
      type: "TEXT",
      label: "id sensor",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    position: {
      id: "position",
      type: "TEXT",
      label: "PONTO",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListPosition record={record} id={id} />
        ),
      },
    },
    schedule: {
      id: "schedule",
      label: "AGENDAMENTO",
      type: "TEXT",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => <ListSchedule record={record} id={id} />,
      },
    },
    synchronized: {
      id: "synchronized",
      type: "SELECT",
      label: "Sincronizado?",
      options: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
      },
    },
    isConnected: {
      id: "isConnected",
      type: "SELECT",
      label: "Conectado?",
      options: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record, id }: any) => (
          <ListSensorConnected record={record} id={id} />
        ),
      },
    },
  },
};
