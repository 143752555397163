import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  width: 33%;
  height: 100%;
  padding: 10px;

  & > span {
    margin-left: 10px;
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const CountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  & span {
    font-size: 60px;
  }
`;

export const Company = styled.div`
  display: flex;
  align-items: center;
  padding: 0 !important;
  margin-left: 10px;

  & button {
    margin-left: 10px;
    padding: 0;
    background-color: transparent;
    color: ${(props) => props.theme.colors.iconsSecondary};
    width: 100%;
  }
`;

export const CompanyNameInput = styled.input`
  box-sizing: border-box;
  width: 80%;
  max-width: 327px;
  height: 48px;
  background: #121921;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: ${(props) => props.theme.colors.iconsSecondary};
  padding-left: 10px;
`;
