import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  /* height: calc(100vh - 130px); */
`;

export const ContentContainer = styled.div`
  padding: 20px;
`;

export const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const PropertyLabel = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H4Bold};
  margin-bottom: 5px;
`;

export const PropertyValue = styled.span`
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H3SemiBold};
`;

export const Form = styled.form``;

export const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
`;
