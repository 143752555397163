import { useLocation, useNavigate } from "react-router-dom";
import { createActionButton } from "./ActionButton";
import { ReactTable } from "./ReactTable";

import useApi from "../../hooks/useApi";

import { ColumnHeader, TableValue } from "./styles";

import { TableProps } from "./types";
import { MdOpenInNew } from "react-icons/md";

export const Table = ({ data, resource, refresh }: TableProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { request } = useApi({ path: resource.apiPath });

  const columns = [
    ...Object.values(resource.properties)
      .filter(
        (property: any) => !property.isVisible || property.isVisible?.list
      )
      .map((property: any) => {
        const renderFunction = property.component?.list;
        const formatedValue = property.formatedValue;
        const openLink = property.openLink;

        function handleClick({ record, id }: any) {
          if (openLink) {
            const link = openLink({ record, id });
            window.open(link, "_blank");
          }
        }

        return {
          id: property.id,
          accessor: property.id,
          Header: () => (
            <ColumnHeader>
              <span>{property.label}</span>
            </ColumnHeader>
          ),
          windth: `${property?.width ?? "112px"}`,
          Cell: ({ value, row }: any) => {
            if (renderFunction) {
              return renderFunction({
                record: row.original,
                id: property.id,
                label: property.label,
              });
            }
            let text = value;

            if (formatedValue) {
              text = formatedValue({
                record: row.original,
                id: property.id,
                label: property.label,
              });
            }

            return (
              <TableValue>
                {text}{" "}
                {openLink && (
                  <MdOpenInNew
                    onClick={() =>
                      handleClick({ record: row.original, id: property.id })
                    }
                  />
                )}
              </TableValue>
            );
          },
        };
      }),
    createActionButton(resource, location.pathname, navigate, request, refresh),
  ];

  return <ReactTable responsive data={data || []} columns={columns} />;
};
