import { Spinner } from "react-bootstrap";
import styled from "styled-components";

export const FullPageLoading = () => {
  return (
    <FullContainer>
      <Spinner animation={"border"} />
    </FullContainer>
  );
};

const FullContainer = styled.div`
  background: ${(props) => props.theme.backgroundColors.background1};
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0.7;
`;
