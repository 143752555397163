import { ContainerStatus } from "../styles";
import { ListProps } from "../types";

export const ListActive = ({ record, id }: ListProps) => {
  const isActive =
    record[id as string] === "ACTIVE" || record[id as string] === true;
  const value = isActive ? "Ativo" : "Inativo";

  return (
    <ContainerStatus active={isActive}>
      <span>{value}</span>
    </ContainerStatus>
  );
};
