import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  Loading,
  PageHeader,
  PageTemplate,
} from "../../../../components";
import useApi from "../../../../hooks/useApi";

import { PageProps } from "../../../../types/default";

import { Actions, Container, ContentContainer, Title } from "./styles";

export const ReactivateAsset = ({ resource }: PageProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { request, processing } = useApi({ path: "/web/assets" });

  const [details, setDetails] = useState<any>();
  const [reactivatePositions, setReactivatePositions] = useState<any>();

  const handlePost = () => {
    request({
      method: "put",
      pathParameters: `${id}/reactivate`,
      body: {
        reactivatePositions,
      },
    }).then(() => {
      navigate(`${resource.urlPath}`);
    });
  };

  useEffect(() => {
    if (!processing) {
      request({
        method: "get",
        pathParameters: `${id}/deleted-details`,
      }).then((response) => setDetails(response));
    }
  }, []);

  return (
    <PageTemplate>
      <>
        <Container>
          <PageHeader resource={resource} />
          <ContentContainer>
            <Title>Reativar ativo</Title>
            {!details || processing ? (
              <Loading />
            ) : (
              <>
                <span>Tem certeza que deseja reativar o ativo </span>
                <strong>{details.asset.assetName}</strong>,
                <span> deletado na data </span>
                <strong>
                  {new Intl.DateTimeFormat("pt-BR", {
                    dateStyle: "short",
                    timeStyle: "medium",
                  }).format(new Date(details.asset.deletedAt))}
                </strong>
                <span> por </span>
                <strong>{details.asset.name}</strong>
                <br />
                {details.positions.length > 0 && (
                  <>
                    <Input
                      type="CHECK"
                      id="force"
                      label="Deseja restaurar os pontos que foram deletados?"
                      value={reactivatePositions}
                      onChange={(e) => {
                        setReactivatePositions(e.target.value);
                      }}
                    />
                    <span>Pontos:</span>
                    <ul>
                      {details.positions.map((item: any) => (
                        <li key={item.positionId}>{item.positionName}</li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            )}

            <Actions>
              <Button onClick={() => navigate(`${resource.urlPath}`)}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handlePost}>
                Salvar
              </Button>
            </Actions>
          </ContentContainer>
        </Container>
      </>
    </PageTemplate>
  );
};
