import styled from "styled-components";

export const ContainerStatus = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 200px;

  background-color: ${(props) => (props.active ? "green" : "red")};
  padding: 10px;
  height: 100%;

  & > span {
    color: ${(props) => props.theme.colors.white};
    ${(props) => props.theme.fonts.primary.H5Regular};
  }
`;
