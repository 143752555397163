import { ContainerStatus } from "../styles";

export type ListBooleanProps = {
  record: any;
  id: string;
  true?: string;
  false?: string;
};

export const ListBoolean = ({
  record,
  id,
  true: trueValue = "Sim",
  false: falseValue = "Não",
}: ListBooleanProps) => {
  const value = record[id as string] ? trueValue : falseValue;

  return (
    <ContainerStatus active={record[id as string]}>
      <span>{value}</span>
    </ContainerStatus>
  );
};
