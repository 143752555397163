import styled from "styled-components";
import { Modal } from "../../components/Modal";
import { PROBLEMS_LABELS } from "../../pages/GatewayHealthCheck";
import useApi from "../../hooks/useApi";
import { error, success } from "../../components/Toast";
import { useEffect, useState } from "react";
import { dateFormat } from "../../modules/summary/resources/Gateways";
import { Spinner } from "react-bootstrap";
import { CiCloudOff } from "react-icons/ci";

interface IInstructionModal {
  onClose: () => void | any;
  isOpen: boolean;
  refresh: (values?: any) => void;
  data: { date: string; serie: string };
}

export const DifferenceModal = ({
  onClose,
  isOpen,
  refresh,
  data,
}: IInstructionModal) => {
  const [newComment, setNewComment] = useState<string>("");

  const { request, processing } = useApi({
    path: `/monitorator/difference-comment`,
  });

  const handleSave = () => {
    //Devido a data vir formatada como string, é preciso transformar em epoch para que seja encontrado o dia (pk do dynamo) no histórico.

    const [day, month, year] = data.date.split("/");

    const measureDate = `${year}-${month}-${day}`;

    let epoch = new Date(measureDate).setDate(+day);
    epoch = new Date(epoch).setHours(0, 0, 0, 0);

    request({
      method: "put",
      pathParameters: epoch,
      body: { comments: newComment, serie: data.serie },
    })
      .then(() => {
        success("Observação atualizada com sucesso!");

        refresh();
        onClose();
        setNewComment("");
      })
      .catch(() => {
        error("Erro ao atualizar observação");
        setNewComment("");
        onClose();
      });
  };

  const handleConfirm = () => {
    if (!newComment) return;
    handleSave();
  };

  return (
    <Modal
      handleClose={onClose}
      show={isOpen}
      size="lg"
      handleConfirm={handleConfirm}
    >
      <PrincipalContainer>
        <Header>
          <h4>Observações</h4>
          {/* <ButtonAdd>
            <IoMdAdd />
          </ButtonAdd> */}
        </Header>

        <Container>
          <InputContainer>
            <label>Data</label>
            <Input value={data.date} disabled />
          </InputContainer>
          <InputContainer>
            <label>Serie</label>
            <Input value={data.serie} disabled />
          </InputContainer>
        </Container>
        <InputContainer>
          <label>Observação</label>
          <TextArea
            placeholder="Adicionar comentário..."
            autoFocus
            value={newComment}
            onChange={(e) => setNewComment(e?.target?.value)}
          />
        </InputContainer>
      </PrincipalContainer>
    </Modal>
  );
};

const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  ${({ theme }) => theme.fonts.primary.H4Regular};
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;

  label {
    font-size: 12px !important;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;

  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsPrimary};
  ${({ theme }) => theme.fonts.primary.H4Regular};
  border-radius: 4px;
  padding: 8px;
  height: 3rem;
  border-radius: 8px;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Input = styled.input`
  width: 100%;

  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsPrimary};
  ${({ theme }) => theme.fonts.primary.H4Regular};
  border-radius: 4px;
  padding: 8px;
  border-radius: 8px;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }
`;

export const PrincipalContainer = styled.div`
  padding: 8px 8px 12px 8px;
  border-radius: 6px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
