import { useMemo, useReducer } from "react";

interface IOrderBy {
  list: any[];
}

export const useOrderBy = ({ list }: IOrderBy) => {
  const initialState = { order: "", field: "" };

  function reducer(
    state: { order: string; field: string },
    action: { type: "ASC_ACTION" | "DESC_ACTION"; field: string }
  ) {
    switch (action.type) {
      case "ASC_ACTION": {
        const IsSameFieldAlreadyAscending =
          state.order === "ASCENDING" && state.field === action.field;

        if (IsSameFieldAlreadyAscending) {
          return { order: "DESCENDING", field: action.field };
        }

        return { order: "ASCENDING", field: action.field };
      }

      default: {
        return state;
      }
    }
  }

  const [orderState, dispatch] = useReducer(reducer, initialState);

  const handleOrder = (field: string) => {
    dispatch({ type: "ASC_ACTION", field });
  };

  const listDataMemo = useMemo(() => {
    if (!orderState.field) {
      return list;
    }

    if (orderState.order === "ASCENDING") {
      return list.sort((a: any, b: any) => {
        return a[orderState.field].localeCompare(b[orderState.field]);
      });
    }

    if (orderState.order === "DESCENDING") {
      return list.sort((a: any, b: any) => {
        return b[orderState.field].localeCompare(a[orderState.field]);
      });
    }

    return [];
  }, [list, orderState]);

  return { orderedList: listDataMemo, handleOrder, orderState };
};
