import styled from "styled-components";

export const LoginInputContainer = styled.div`
  /* margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    padding: 16px 16px 0 16px; */

  background: #121921;
  box-sizing: border-box;
  width: 100%;
  max-width: 327px;
  height: 48px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* margin: 1.5rem 0.5rem 0 3.7rem; */
`;

export const LoginInputText = styled.input`
  background: transparent;
  color: #ffffff;
  width: 85%;
  height: 40px;
  max-width: 320px;
  outline: 0;
  padding: 0.5rem;
  border: none;
  font-size: 16px;
  opacity: 0.8;
  margin: 0 5px;
`;

export const LoginMainNew = styled.div`
  box-sizing: border-box;
  position: absolute;
  background: linear-gradient(
    157.76deg,
    rgba(255, 255, 255, 0.1) -22.35%,
    rgba(255, 255, 255, 0.073) 34.08%
  );
  color: #ffffff;
  width: 100%;
  max-width: 466px;
  height: 528px;
  backdrop-filter: blur(300px);
  border-radius: 16px;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  flex-direction: column;
  z-index: 1;

  @media (max-width: 1408px) {
    width: 80%;
  }
`;

export const LoginTitle = styled.div`
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
  margin: 48px 62px;
  font-style: normal;
  align-items: left !important;
`;

export const Main = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #151820;
`;

export const NewLoginSubmitButton = styled.button`
  background-color: #f37035;
  color: white;
  cursor: pointer;
  margin: 48px 0;
  width: 100%;
  max-width: 327px;
  padding: 1rem;
  border-radius: 6px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid transparent;
  /* border: none; */
  line-height: 16.94px;
  &:hover {
    border: 1px solid lightgray;
    box-shadow: -3px 0px 11px 1px rgba(0, 0, 0, 0.5);
  }
  &:active {
    transform: translate(1px, 1.5px);
  }
`;

export const SecondIbbxLogo = styled.img`
  width: 20px;
  position: absolute;
  bottom: 20px;
`;

export const Pass = styled.div`
  width: 100%;
  max-width: 527px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
`;

type elipseTypes = {
  width: string;
  height: string;
  opacity: string;
  left: string;
  top: string;
};

export const Elipse = styled.div<elipseTypes>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  background: #f37035;
  opacity: ${(props) => props.opacity};
  filter: blur(164px);
  transform: rotate(25.53deg);
  position: absolute;
  z-index: 0;
`;

export const LoginMessage = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 19.36px;
  font-size: 14px;
  font-weight: 600;
  color: red;
  height: 10px;
  width: 100%;
  max-width: 348px;
`;

export const EyeSecondTextInput = styled.img`
  right: 3.12%;
  opacity: 0.8;
`;

export const ContainerBody = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
