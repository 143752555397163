import { Form } from "react-bootstrap";

import { InputContainer } from "../styles";

import { SelectApiProps } from "./types";

export const Select = ({
  label,
  options,
  onChange,
  value,
  isFilter,
  error,
  disabled,
}: SelectApiProps) => {
  return (
    <InputContainer>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        onChange={(e: any) => {
          if (e.target.value === "Todos") {
            e.target.value = null;
          }

          if (onChange) {
            onChange(e);
          }
        }}
        value={value}
        disabled={disabled}
      >
        {isFilter && (
          <option value={undefined} selected>
            Todos
          </option>
        )}

        {options.map(({ label, value }: any) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Form.Select>
      {error ? <div className="error">{error}</div> : null}
    </InputContainer>
  );
};
