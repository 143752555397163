import CountUp from "react-countup";

import { Container, CountContainer } from "./styles";
import { CardSensorProps } from "./types";

export const CardSensor = ({
  countSensor,
  countSensorOnline,
}: CardSensorProps) => {
  return (
    <Container>
      <span>Sensores transmitindo nas ultimas 24 horas</span>
      <div>
        <CountContainer>
          <CountUp start={0} end={countSensorOnline || 0} duration={1}>
            {({ countUpRef }) => (
              <div>
                <span ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </CountContainer>
        <span>de</span>
        <CountContainer>
          <CountUp start={0} end={countSensor || 0} duration={1}>
            {({ countUpRef }) => (
              <div>
                <span ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </CountContainer>
      </div>
    </Container>
  );
};
