export const SensorResource = {
  title: "Sensores",
  urlPath: "/monitorator/sensors",
  apiPath: "/monitorator/sensors",
  actions: {},
  properties: {
    pk: {
      id: "pk",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        list: true,
        new: false,
        filter: true,
      },
    },
  },
};
