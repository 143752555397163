import styled from "styled-components";
import { Modal } from "../../components/Modal";
import useApi from "../../hooks/useApi";
import { useEffect, useState } from "react";
import { error, success } from "../../components/Toast";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import {
  AddUserButton,
  ButtonDelete,
  InputsContainer,
  PrincipalContainer,
  TitleContainer,
} from "./styles";

interface INotificationsModal {
  onClose: () => void | any;
  show: boolean;
}

interface IUser {
  id: number;
  name: string;
  email: string;
  phone: string;
}

export const NotificationsModal = ({ onClose, show }: INotificationsModal) => {
  const [users, setUsers] = useState<any[]>([]);

  const { processing, request } = useApi({ path: `/users-to-notificate` });

  function fetchUsers() {
    request({ method: "get" }).then((response) => {
      setUsers(response);
    });
  }

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function saveUsers() {
    request({ method: "put", body: { userList: users } })
      .then((response) => {
        success("Alterações feita com sucesso");
        onClose();
      })
      .catch(() => error("Erro ao salvar alterações"));
  }

  function handleAddUser() {
    setUsers([
      ...users,
      { id: new Date().getTime(), name: "", email: "", phone: "" },
    ]);
  }

  function handleChange({
    user,
    field,
    value,
  }: {
    user: IUser;
    value: string;
    field: string;
  }) {
    let foundUser = users.find((item: IUser) => item.id === user.id);

    foundUser = { ...foundUser, [field]: value };

    const filtered = users.filter((item: IUser) => item.id !== user.id);

    const changedUsers = [...filtered, foundUser].sort(
      (a: IUser, b: IUser) => a.id - b.id
    );

    setUsers(changedUsers);
  }

  function handleRemove(id: number) {
    const filtered = users.filter((item: IUser) => item.id !== id);
    setUsers(filtered);
  }

  return (
    <Modal
      handleClose={onClose}
      show={show}
      size="lg"
      handleConfirm={saveUsers}
    >
      <div>
        <TitleContainer>
          <h5>Usuários cadastrados para receber notificações sobre o Bolt</h5>
          <AddUserButton onClick={handleAddUser}>
            <IoMdAdd />
          </AddUserButton>
        </TitleContainer>

        <PrincipalContainer>
          {processing ? (
            <label>Carregando..</label>
          ) : (
            users.map((user: IUser) => (
              <InputsContainer>
                <input
                  placeholder="Nome"
                  value={user.name}
                  onChange={(e) =>
                    handleChange({ user, field: "name", value: e.target.value })
                  }
                />
                <input
                  placeholder="Email"
                  value={user.email}
                  onChange={(e) =>
                    handleChange({
                      user,
                      field: "email",
                      value: e.target.value,
                    })
                  }
                />
                <input
                  placeholder="Telefone"
                  value={user.phone}
                  onChange={(e) =>
                    handleChange({
                      user,
                      field: "phone",
                      value: e.target.value,
                    })
                  }
                />
                <ButtonDelete onClick={() => handleRemove(user.id)}>
                  <MdDelete />
                </ButtonDelete>
              </InputsContainer>
            ))
          )}
        </PrincipalContainer>
      </div>
    </Modal>
  );
};
