import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Store
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  appSelect,
  appSetProcessing,
  appSetCurrentDarkMode,
} from "./store/slices/appSlice";
import { userSession } from "./store/slices/userSlice";

// Styled
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/global";
import light from "./styles/themes/light";
import dark from "./styles/themes/dark";

// Pages
import { AppLoading } from "./components/Loading";
import { Toast } from "./components/Toast";
import { Routes } from "./routes/Routes";

function App() {
  // Hooks
  const dispatch = useAppDispatch();
  const app = useAppSelector(appSelect);

  // Antes de carregar as rotas, verifica se o usuário está logado e se aceitou os termos de uso
  React.useEffect(() => {
    dispatch(userSession())
      .unwrap() // verifica se tem usuário ativo no localStorage
      .then((user: any) => {
        dispatch(appSetProcessing(false));
      })
      .catch((err: any) => {
        dispatch(appSetProcessing(false));
      });

    dispatch(appSetCurrentDarkMode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={app.darkMode ? dark : light}>
        <GlobalStyle />
        {app.processing ? <AppLoading /> : <Routes />}
        <Toast />
      </ThemeProvider>
    </>
  );
}

export default App;
