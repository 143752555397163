import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { BurninTable, TableEmpty, Tr } from "./styles";
import { FaExchangeAlt, FaFile } from "react-icons/fa";
import {
  IBurninDetails,
  IDevices,
  InspectionStepsEnum,
  InspectionStatusEnum,
} from "./types";
import { Button } from "../../components";
import { Spinner } from "react-bootstrap";
import { DeviceContainer } from "./modals/DeviceContainer";
interface IBurninInspectionProps {
  inspectionId: string;
  searchString: string;
  refresh: () => void;
}

type IBurnin = IBurninDetails & IDevices;

export default function BurninInspection({
  inspectionId,
  searchString,
  refresh,
}: IBurninInspectionProps) {
  const [burninDetails, setBurninDetails] = useState<IBurnin[]>();
  const { request: requestBurnin, processing: processingBurnin } = useApi({
    path: `/infra/quality-inspections/${inspectionId}/devices/burnin-details`,
  });

  const getBurninDetails = () => {
    requestBurnin({
      method: "get",
    }).then((response: IBurnin[]) => {
      setBurninDetails(response);
    });
  };

  useEffect(() => {
    getBurninDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDevices = () => {
    if (!burninDetails || !searchString) return burninDetails;

    return burninDetails?.filter((burnin) => {
      const uuid = String(burnin.activatorId).toLowerCase();
      const position = burnin.positionName?.toLowerCase();
      return (
        uuid.includes(searchString.toLowerCase()) ||
        position?.includes(searchString.toLowerCase())
      );
    });
  };

  return processingBurnin ? (
    <Spinner style={{ margin: "10% auto 0 auto" }} animation="border" />
  ) : (
    <BurninTable>
      <thead>
        <th>UUID</th>
        <th>Posição</th>
        <th>Conexão</th>
        <th>RSSI</th>
        <th>Performance</th>
        <th>Tensão</th>
        <th>Tensão Inicial</th>
        <th>Temperatura</th>
        <th>Consumo</th>
        <th></th>
      </thead>
      <tbody>
        {filteredDevices()?.map((item: IBurnin, index: number) => (
          <Tr
            key={index}
            status={item.burninStatus || InspectionStatusEnum.PENDING}
            positionId={item.positionId}
          >
            <td>{item.activatorId.slice(-6)}</td>
            <td>{item.positionName}</td>
            <td>{item.isConnected ? "Conectado" : "Desconectado"}</td>
            <td>
              {item.lastCollectRSSI ||
                item?.burninDetails?.lastCollectRSSI?.value}
            </td>
            <td>
              {item.performance || item?.burninDetails?.performance?.value}
            </td>
            <td>
              {item.batteryConsumption ||
                item?.burninDetails?.batteryConsumption?.value}
            </td>
            <td>
              {item.initialBatteryVoltage ||
                item?.burninDetails?.initialBatteryVoltage?.value}
            </td>
            <td>
              {item.temperature || item?.burninDetails?.temperature?.value}
            </td>
            <td>
              {item.batteryConsumption ||
                item?.burninDetails?.batteryConsumption?.value}
            </td>

            <td style={{ width: "165px" }}>
              {!item.burninStatus && (
                <DeviceContainer
                  refresh={refresh}
                  inspectionId={inspectionId || ""}
                  burninDetails={item as IBurninDetails}
                  type={InspectionStepsEnum.BURNIN_STATUS}
                  handleNext={() => {}}
                  currentPosition={0}
                >
                  <Button>
                    <FaExchangeAlt />
                    <span> Alterar Status</span>
                  </Button>
                </DeviceContainer>
              )}
            </td>
          </Tr>
        ))}
        {(!burninDetails || burninDetails?.length < 1) && (
          <TableEmpty>
            <FaFile style={{ fontSize: "24px" }} />
            <p style={{ margin: "0 0 0 8px" }}>Sem Dados</p>
          </TableEmpty>
        )}
      </tbody>
    </BurninTable>
  );
}
