import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Amplify } from "aws-amplify";

import { RootState } from "../store";

import { getItem, setItem } from "../../utils/localStorage";
import { awsConfig } from "../awsConfig";

//Configuração para acesso ao Cognito e ao API Gateway
Amplify.configure(awsConfig);

interface SliceState {
  darkMode: boolean; // alterna dark/light
  processing: boolean; // estado de processamento
  breadcrumb: { title: string; link: string }[]; // breadcrumb em telas grandes
  currentBreadcrumb: string; // breadcrumb em telas pequenas
  sidebarOpen: boolean; // sidebar aberto ou fechado em telas pequenas
  currentPage: string; // página atual
}

const initialState: SliceState = {
  darkMode: true,
  processing: true,
  breadcrumb: [],
  currentBreadcrumb: "",
  sidebarOpen: false,
  currentPage: "",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appToogleDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = !action.payload;
      setItem("darkMode", JSON.stringify(state.darkMode));
      //[A FAZER] Persistir a preferência do usuário para o darkMode
    },
    appReset: (state) => {
      state.darkMode = initialState.darkMode;
      state.processing = initialState.processing;
      state.breadcrumb = initialState.breadcrumb;
      state.currentBreadcrumb = initialState.currentBreadcrumb;
      state.sidebarOpen = initialState.sidebarOpen;
      state.currentPage = initialState.currentPage;
    },
    appSetProcessing: (state, action: PayloadAction<boolean>) => {
      state.processing = action.payload;
    },
    appSetBreadcrumb: (state, action: PayloadAction<any[]>) => {
      state.breadcrumb = action.payload;
    },
    appSetCurrentBreadcrumb: (state, action: PayloadAction<string>) => {
      state.currentBreadcrumb = action.payload;
    },
    appSetSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
    appSetCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    appSetCurrentDarkMode: (state) => {
      const storageDarkMode = getItem("darkMode");
      state.darkMode =
        storageDarkMode !== null ? Boolean(storageDarkMode) : true;
    },
  },
});

export const appSelect = (state: RootState) => state.app;

export const {
  appReset,
  appToogleDarkMode,
  appSetProcessing,
  appSetBreadcrumb,
  appSetCurrentBreadcrumb,
  appSetSidebarOpen,
  appSetCurrentPage,
  appSetCurrentDarkMode,
} = appSlice.actions;

export default appSlice.reducer;
