import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: 0.3rem;
    flex-direction: column;

`

export const Title = styled.div`
    display: flex;
    justify-content: center;   
    margin-top: 0.5rem;
    align-items: center;
    ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
`;


export const Button = styled.button`
    border: none;
    background: none;   
`

export const CloseButton = styled.button`
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0.2rem;
    cursor: pointer;

    svg {
        color: ${(props) => props.theme.colors.iconsPrimary};
        
        &:hover {
            color: ${(props) => props.theme.colors.statusRisk};
        }
    }
`