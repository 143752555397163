import { Dropdown, FloatingLabel } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  height: calc(100vh - 80px);
  width: calc(100vw - 300px);
`;

export const TableContainer = styled.div`
  height: 75%;
  overflow: auto;

  .table-responsive {
    width: 100%;
    overflow-x: unset;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
  //FIREFOX
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 24px;
    max-width: 84%;
  }

  /* -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > .table-responsive {
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  } */
`;

export const ColumnHeader = styled.div`
  margin: 8px 0 4px 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H4Bold};

  & > span {
    display: flex;
    align-items: center;
  }

  & > div {
    display: flex;
    gap: 10px;
  }
`;

export const TableValue = styled.span`
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};
  svg {
    margin-left: 6px;
  }
`;

export const CollectContainer = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  & div {
    & > span:first-child {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;

export const CompanyLogo = styled.img`
  width: 130px;
`;

export const TextInput = styled(FloatingLabel)`
  color: black;
`;

export const DropdownContainer = styled(Dropdown)``;

export const PageTitle = styled.h2`
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H2Bold};
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const PaginateContainer = styled.div`
  position: absolute;
  bottom: 10px;
  width: inherit;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
