import CountUp from "react-countup";

import { Container, CountContainer } from "./styles";
import { CardBoltProps } from "./types";

export const CardBolt = ({ countBoltOnline, countBolt }: CardBoltProps) => {
  return (
    <Container>
      <span>Bolts transmitindo nas ultimas 24 horas</span>
      <div>
        <CountContainer>
          <CountUp start={0} end={countBoltOnline || 0} duration={1}>
            {({ countUpRef }) => (
              <div>
                <span ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </CountContainer>
        <span>de</span>
        <CountContainer>
          <CountUp start={0} end={countBolt || 0} duration={1}>
            {({ countUpRef }) => (
              <div>
                <span ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </CountContainer>
      </div>
    </Container>
  );
};
