import { useEffect, useState } from "react";
import Chart from "react-google-charts";

import { ChartContainer, Container } from "./styles";
import { Loading, PageTemplate } from "../../components";
import useApi from "../../hooks/useApi";

export default function ResultsAI() {
  const { request: requestChartData, processing } = useApi({
    path: "/ai/results",
  });

  const [result, setResult] = useState<any>({ data: [] });
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    requestChartData({
      method: "get",
      queryStringParameters: {
        minDate: new Date().getTime() - 7 * 86400000,
        maxDate: new Date().getTime(),
      },
    }).then((response: any) => {
      const formated: { data: any[], series: string[]} = {
        ...response,
        data: response.data.map(([date, ...rest]: any[]) => [new Date(date), ...rest]),
      }

      setResult(formated);
      setData([formated.series, ...formated.data])
    });
    // eslint-disable-next-line
  }, []);

  const columnChartOptions = {
    chartArea: {
      width: "100%",
      height: "100%",
      top: 10,
      bottom: 20,
      right: 20,
      left: 30,
    },
    backgroundColor: "transparent",
    aggregationTarget: "none",
    legend: "none",
    animation: {
      startup: true,
      duration: 700,
      easing: "out",
    },
    hAxis: {
      minorGridlines: { color: "transparent" },
      direction: 1,
      format: "dd/MM",
      textStyle: {
        fontSize: 10,
      },
      gridlines: {
        color: "transparent",
      },
    },
    vAxis: {
      minorGridlines: { color: "transparent" },
      textStyle: {
        fontSize: 10,
      },
      format: "short",
      viewWindowMode: "explicit",
      gridlines: {
        color: "transparent",
      },
    },
    tooltip: { isHtml: true },
    visibleInLegend: false,
  };

  function renderWithoutChart() {
    if (processing) {
      return <Loading />;
    } else {
      return <p> Sem dados no período</p>;
    }
  }

  return (
    <PageTemplate>
      <Container>
        <h2>Resultados AI</h2>
        <ChartContainer width={900} height={350}>
          {result?.data?.length ? (
            <Chart
              chartType="ColumnChart"
              options={columnChartOptions}
              chartLanguage={"pt-Br"}
              style={{ width: "inherit", height: "100%" }}
              data={data}
              width="100%"
            />
          ) : (
            renderWithoutChart()
          )}
        </ChartContainer>
      </Container>
    </PageTemplate>
  );
}
