import styled from "styled-components";

export const Container = styled.div``;

export const Link = styled.button<{ selected?: boolean }>`
  padding: 5px 10px;
  margin-right: 5px;

  background-color: ${(props) => props.theme.colors.cardBackground};
  opacity: ${(props) => (props.selected ? 1 : 0.4)};

  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;
`;
