import {
  ContainerStatus,
  PropertyContainer,
  PropertyLabel,
  PropertyValue,
} from "../styles";
import { ShowProps } from "../types";

export const ShowActive = ({ record, label, id }: ShowProps) => {
  const value = record[id as string] ? "Ativo" : "Inativo";

  return (
    <PropertyContainer key={id}>
      <PropertyLabel>{label}</PropertyLabel>
      <ContainerStatus active={record[id as string]}>
        <PropertyValue>{value}</PropertyValue>
      </ContainerStatus>
    </PropertyContainer>
  );
};
