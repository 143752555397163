import styled from "styled-components";

export const ListContainer = styled.div`
  width: 250px;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  & div {
    & > span:first-child {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;

export const ConnectionStatus = styled.span<{ status: boolean }>`
  color: ${(props) => (props.status ? "green" : "red")};
  ${(props) => props.theme.fonts.primary.H6Bold};
`;

export const SynchronizedStatus = styled.span<{ status: boolean }>`
  color: ${(props) => (props.status ? "green" : "red")};
  ${(props) => props.theme.fonts.primary.H5Bold};
`;
