import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Loading, PageHeader, PageTemplate } from "../../components";

import {
  Container,
  ContentContainer,
  PropertyContainer,
  PropertyLabel,
  PropertyValue,
} from "./styles";

import useApi from "../../hooks/useApi";
import { ShowProps } from "./types";

export const Show = ({ resource }: ShowProps) => {
  const location = useLocation();
  const params = useParams();

  const { request, processing } = useApi({ path: resource?.apiPath });

  // Resource state
  const [record, setRecord] = useState<any>(null);

  function getRecord() {
    request({
      method: "get",
      pathParameters: `${params.id}`,
    }).then((response: any) => {
      setRecord(response);
    });
  }

  useEffect(() => {
    getRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, location.key]);

  const showProperties = [
    ...Object.values(resource.properties).filter(
      (propertie: any) => !propertie.isVisible || propertie.isVisible?.show
    ),
  ];

  function renderShow() {
    if (!record) {
      return null;
    }

    return (
      <>
        {showProperties.map(({ label, id, component }: any) => {
          const { show } = component || {};

          if (show) {
            return show({ record, label, id });
          }

          return (
            <PropertyContainer key={id}>
              <PropertyLabel>{label}</PropertyLabel>
              <PropertyValue>{record[id]}</PropertyValue>
            </PropertyContainer>
          );
        })}
      </>
    );
  }

  return (
    <>
      <PageTemplate>
        <>
          <Container>
            <PageHeader resource={resource} showRecordActions record={record} />
            <ContentContainer>
              {processing ? <Loading /> : renderShow()}
            </ContentContainer>
          </Container>
        </>
      </PageTemplate>
    </>
  );
};
