import styled from 'styled-components'
import * as Tab from '@radix-ui/react-tabs'

export const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.divBackground};
  width: 100%;
  border: 1px solid ${props => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  /* height: calc(100vh - 130px); */
`

export const ContentContainer = styled.div`
  padding: 20px;
`

export const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const PropertyLabel = styled.span`
  text-transform: uppercase;
  color: ${props => props.theme.colors.textPrimary};
  ${props => props.theme.fonts.primary.H4Bold};
  margin-bottom: 5px;
`

export const PropertyValue = styled.span`
  color: ${props => props.theme.colors.iconsSecondary};
  ${props => props.theme.fonts.primary.H3SemiBold};
`

export const Form = styled.form`
  overflow: auto;
  max-height: 80vh;
  padding-right: 5px;

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.colors.iconsSecondary};
      cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:active {
      background: ${props => props.theme.colors.iconsSecondary};
  }

  & > button {
    margin: 8px 0;
  }

  & > span {
    color: ${props => props.theme.colors.iconsSecondary};
    ${props => props.theme.fonts.primary.H4Regular};
  }

  & > ul {
    max-height: 200px;
    overflow: auto;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
      & > button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0 6px;
        height: 20px;
        border-radius: 6px;
        color: ${props => props.theme.colors.iconsSecondary};
        background-color: ${props => props.theme.colors.cardHighlight};
        ${props => props.theme.fonts.primary.H5Regular};
      }
    }

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px;
      @media screen and (max-width: 767px) {
        width: 3px !important;
      }
    }

    &::-webkit-scrollbar-track {
      background: ${props => props.theme.colors.headerBackground};
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${props => props.theme.colors.iconsSecondary};
    }
    //FIREFOX
    scrollbar-width: thin;
    scrollbar-color: ${props => props.theme.colors.iconsSecondaryAlpha};
  }
`

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 32px;
`

//Custom Tabs
export const TabsRoot = styled(Tab.Root)`
  display: flex;
  flex-direction: column;
`

export const TabsList = styled(Tab.List)`
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.iconsSecondary};
`

export const TabsTrigger = styled(Tab.Trigger)`
  all: unset;
  font-family: inherit;
  background-color: transparent;
  padding: 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  line-height: 1;
  user-select: none;
  color: ${(props) => props.theme.colors.iconsSecondary};

  &:first-child {
    border-top-left-radius: 6px;
  }

  &:last-child {
    border-top-left-radius: 6px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.textPrimary};
  }
  
  &[data-state="active"] {
    color: ${(props) => props.theme.colors.textPrimary};
    box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
  }
`

export const TabsContent = styled(Tab.Content)`
  flex-grow: 1;
  background-color: transparent;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  margin-top: 1rem;
  margin-bottom: 1rem;

  input {
    margin-bottom: 1rem;
  }

  & > ul {
    max-height: 120px;
    overflow: auto;
    margin-top: 0.5rem;
    padding-left: 1rem !important;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;

      input {
        background-color: transparent;
        color: ${props => props.theme.colors.iconsSecondary};
        border: none;
        border-bottom: 1px solid ${props => props.theme.colors.iconsSecondary};
        outline: none;
        width: 45%;
      }

      & > button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0 6px;
        height: 20px;
        border-radius: 6px;
        color: ${props => props.theme.colors.iconsSecondary};
        background-color: ${props => props.theme.colors.cardHighlight};
        ${props => props.theme.fonts.primary.H5Regular};
      }
    }

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px;
      @media screen and (max-width: 767px) {
        width: 3px !important;
      }
    }

    &::-webkit-scrollbar-track {
      background: ${props => props.theme.colors.headerBackground};
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${props => props.theme.colors.iconsSecondary};
    }
    //FIREFOX
    scrollbar-width: thin;
    scrollbar-color: ${props => props.theme.colors.iconsSecondaryAlpha};
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    font-size: ${(props) => props.theme.fonts.primary.H2Bold['font-size']};
    font-weight: ${(props) => props.theme.fonts.primary.H2Bold['font-weight']};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
`