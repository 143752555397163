import styled from "styled-components";

export const InputContainer = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  width: 100%;

  margin-bottom: 10px;

  & > input,
  select {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 10px;
  }

  & > label {
    text-transform: uppercase;
    ${({ theme }) => theme.fonts.primary.H4Regular};
  }

  & > div.error {
    color: red;
  }
`;

export const RangeInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  // Select de tipo de range
  & > div:first-child {
    max-width: 33%;
  }

  & > div {
    width: 100%;
  }
`;