import { Edit } from "../pages/Edit";
import { List } from "../pages/List";
import { New } from "../pages/New";
import { Show } from "../pages/Show";

export function BuildRoutes(resource: any) {
  const CustomNew = resource.actions?.new?.page;
  const CustomEdit = resource.actions?.edit?.page;

  const resourceComponentsPage = Object.values(resource.actions).filter(
    (action: any) => !!action.component
  );

  return [
    {
      path: `${resource.urlPath}`,
      private: true,
      component: <List resource={resource} />,
    },
    {
      path: `${resource.urlPath}/new`,
      private: true,
      component: CustomNew ? (
        <CustomNew resource={resource} />
      ) : (
        <New resource={resource} />
      ),
    },
    {
      path: `${resource.urlPath}/show/:id`,
      private: true,
      component: <Show resource={resource} />,
    },
    {
      path: `${resource.urlPath}/edit/:id`,
      private: true,
      component: CustomEdit ? (
        <CustomEdit resource={resource} />
      ) : (
        <Edit resource={resource} />
      ),
    },
    ...resourceComponentsPage.map(
      ({ component: Component, name, type }: any) => ({
        path:
          type === "record"
            ? `${resource.urlPath}/:id/${name}`
            : `${resource.urlPath}/${name}`,
        private: true,
        component: <Component resource={resource} />,
      })
    ),
  ];
}
