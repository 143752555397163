import { Form } from "react-bootstrap";
import {
  FormLabelDynamicStatus,
  HealthCheckDynamicStatusContainer,
} from "./styles";

interface IHealthCheckDynamicStatus {
  ignored: any[];
  setIgnored: any;
  items: any[];
}
export default function HealthCheckDynamicStatus({
  ignored,
  setIgnored,
  items,
}: IHealthCheckDynamicStatus) {
  return (
    <HealthCheckDynamicStatusContainer>
      <h2>Status Dinâmico</h2>
      <div>
        {items.map((item) => (
          <FormLabelDynamicStatus>
            <Form.Check
              checked={!ignored.includes(item.value)}
              onChange={(e: any) => {
                if (e.target.checked) {
                  setIgnored((cur: any) =>
                    cur.filter((val: any) => val !== item.value)
                  );
                } else {
                  setIgnored((cur: any) => [...cur, item.value]);
                }
              }}
            />
            {item.label}
          </FormLabelDynamicStatus>
        ))}
      </div>
    </HealthCheckDynamicStatusContainer>
  );
}
