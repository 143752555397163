import styled from "styled-components";

export const LoginMain = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 466px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
`;

export const PrincipalBox = styled.form`
  box-sizing: border-box;
  position: absolute;
  height: 562px;
  max-height: 562px;
  width: 32%;
  max-width: 466px;
  background: linear-gradient(
    157.76deg,
    rgba(255, 255, 255, 0.1) -22.35%,
    rgba(255, 255, 255, 0.073) 34.08%
  );
  backdrop-filter: blur(300px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1408px) {
    width: 80%;
  }
`;

export const Main = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #151820;
`;

export const FigureDiv = styled.div`
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 86px;
`;

export const FirstIbbxLogo = styled.img`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FirstTextInputContainer = styled.div`
  box-sizing: border-box;
  width: 80%;
  max-width: 327px;
  height: 48px;
  background: #121921;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-top: 80px;
`;

export const FirstTextInput = styled.input`
  width: 95%;
  height: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  background: #121921;
  border: 0;
  outline: 0;
  opacity: 0.8;
  margin-left: 2%;
  line-height: 19.36px;
`;

export const SecondTextInputContainer = styled.div`
  box-sizing: border-box;
  width: 80%;
  max-width: 327px;
  height: 48px;
  background: #121921;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
`;

export const SecondTextInput = styled.input`
  width: 85%;
  height: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  background: #121921;
  border: 0;
  outline: 0;
  opacity: 0.8;
  margin-left: 2%;
`;

export const EyeSecondTextInput = styled.img`
  right: 3.12%;
  opacity: 0.8;
`;

export const SubmitButton = styled.button`
  width: 80%;
  max-width: 327px;
  height: 48px;
  background: #f37035;
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.5px;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
  border: none;

  &:hover {
    border: 1px solid lightgray;
    box-shadow: -3px 0px 11px 1px rgba(0, 0, 0, 0.5);
  }
  &:active {
    transform: translate(1px, 1.5px);
  }
`;

type elipseTypes = {
  width: string;
  height: string;
  opacity: string;
  left: string;
  top: string;
};

export const Elipse = styled.div<elipseTypes>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  background: #f37035;
  opacity: ${(props) => props.opacity};
  filter: blur(164px);
  transform: rotate(25.53deg);
  position: absolute;
  z-index: 0;
`;

export const SecondIbbxLogo = styled.img`
  width: 20px;
  position: absolute;
  bottom: 20px;
`;

export const LoginPageMessage = styled.div`
  font-size: 16px;
  font-weight: 600;
  height: 10px;
  color: red;
`;
