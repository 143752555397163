import styled from "styled-components";

export const SearchScannerContainer = styled.div`
  display: flex;
  border-radius: 8px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.colors.iconsSecondary};
  
  input {
    padding-left: 4px;
    background-color: transparent;
    ${({ theme }) => theme.fonts.primary.H4Regular};
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.iconsPrimary};
  }

  svg {
    height: 100%;
    width: 30px;
  }
`;

export const QrCodeButton = styled.div`
  border-radius: 4px;
  background-color: transparent;
`;  
