import { useState } from "react";
import { InputText } from "./Text";
import { SelectApi } from "./SelectApi";

import { InputProps } from "./types";
import { Select } from "./Select";
import { Image } from "./Image";
import { File } from "./FIle";
import { Check } from "./Check";
import { DateInput } from "../DateInput";
import { RangeInputContainer } from "./styles";
import SelectHiearchy from "./SelectHiearchy";
const separator = "_";

export const Input = ({
  type,
  id,
  label,
  value,
  onChange,
  endpoint,
  isFilter,
  error,
  options,
  disabled,
  parent,
  resetOnChange,
  values,
  setValues = () => null,
}: InputProps) => {
  const [minDate, setMinDate] = useState<any>(() => {
    if (type !== "DATE_PERIOD") {
      return null;
    }
    const [min] = (value || "")?.split("-");
    const date = min.replace("GT").length > 1 ? +min.replace("GT", "") : null;
    return date ? new Date(date) : null;
  });

  const [date, setDate] = useState<any>((newValue: any) => {
    if (type !== "DATE" && type !== "DATE_TIME") {
      return null;
    }
    const selectedDate = newValue || value || "";

    return new Date(selectedDate).getTime();
  });

  const [maxDate, setMaxDate] = useState<any>(() => {
    if (type !== "DATE_PERIOD") {
      return null;
    }
    const [, max] = (value || "")?.split("-");
    const date =
      (max || "").replace("LT", "").length > 1 ? +max.replace("LT", "") : null;
    return date ? new Date(date) : null;
  });

  const [rangeType, setRangeType] = useState<"" | "GT" | "LT" | "GTLT">(() => {
    if (type !== "RANGE") {
      return "";
    }

    if ((value || "").includes(separator)) {
      return "GTLT";
    }
    if ((value || "").includes("GT")) {
      return "GT";
    }
    return (value || "").includes("LT") ? "LT" : "";
  });

  const [rangeGt, setRangeGt] = useState<any>(() => {
    if (type !== "RANGE") {
      return null;
    }
    if ((value || "").includes(separator)) {
      const [GT] = value.split(separator);
      return (GT || "").replace("GT", "").replace(separator + "LT", "");
    }
    return (value || "").replace("GT", "").replace("LT", "");
  });

  const [rangeLt, setRangeLt] = useState<any>(() => {
    if (type !== "RANGE") {
      return null;
    }

    if ((value || "").includes(separator)) {
      const [, LT] = value.split(separator);
      return (LT || "").replace("LT", "");
    }
    return "";
  });

  if (type === "TEXT") {
    return (
      <InputText
        label={label}
        name={id}
        placeholder={label}
        value={value}
        onChange={onChange}
        error={error}
      />
    );
  }

  if (type === "SELECT_API") {
    return (
      <SelectApi
        label={label}
        name={id}
        placeholder={label}
        value={value}
        onChange={onChange}
        endpoint={endpoint}
        isFilter={isFilter}
        error={error}
        disabled={disabled}
      />
    );
  }

  if (type === "SELECT_HIEARCHY") {
    return (
      <SelectHiearchy
        key={id}
        path={endpoint ?? ""}
        label={label}
        parent={parent}
        values={values}
        setValues={setValues}
        resetOnChange={resetOnChange ?? []}
        name={id}
      />
    );
  }

  if (type === "SELECT") {
    return (
      <Select
        label={label}
        name={id}
        placeholder={label}
        value={value}
        onChange={onChange}
        options={options}
        isFilter={isFilter}
        error={error}
        disabled={disabled}
      />
    );
  }

  if (type === "IMAGE") {
    return (
      <Image
        label={label}
        name={id}
        placeholder={label}
        value={value}
        onChange={onChange}
        isFilter={isFilter}
        error={error}
        disabled={disabled}
      />
    );
  }

  if (type === "FILE") {
    return (
      <File
        label={label}
        name={id}
        placeholder={label}
        value={value}
        onChange={onChange}
        isFilter={isFilter}
        error={error}
        disabled={disabled}
      />
    );
  }

  if (type === "CHECK") {
    return (
      <Check
        label={label}
        name={id}
        placeholder={label}
        value={value}
        onChange={onChange}
        isFilter={isFilter}
        error={error}
        disabled={disabled}
      />
    );
  }

  function handleRageType(e: any) {
    if (rangeType === "GTLT" && e.target.value !== "GTLT") {
      setRangeLt("");
    }

    if (e.target.value === "GTLT") {
      const part1 = rangeGt ? `GT${rangeGt}` : "";
      const part2 = rangeLt ? `LT${rangeLt}` : "";
      setValues((prevState: any) => ({
        ...prevState,
        [id]: `${part1}${separator}${part2}}`,
      }));
    } else {
      const part1 = rangeGt ? `${e.target.value}${rangeGt}` : "";
      setValues((prevState: any) => ({
        ...prevState,
        [id]: `${part1}`,
      }));
    }

    setRangeType(e.target.value);
  }

  function handleInputGT(e: any) {
    let prefix = rangeType === "GTLT" ? "GT" : rangeType;
    setRangeGt(`${e.target.value}`);
    const sufix = rangeLt ? `${separator}LT${rangeLt}` : "";
    const part1 = e.target.value ? `${prefix}${e.target.value}` : "";
    setValues((prevState: any) => ({
      ...prevState,
      [id]: `${part1}${sufix}`,
    }));
  }

  function handleInputLT(e: any) {
    setRangeLt(`${e.target.value}`);
    const part2 = e.target.value ? `LT${e.target.value}` : "";
    setValues((prevState: any) => ({
      ...prevState,
      [id]: `GT${rangeGt}${separator}${part2}`,
    }));
  }

  if (type === "RANGE") {
    return (
      <RangeInputContainer>
        <Select
          label={label}
          name={id}
          placeholder={label}
          value={rangeType}
          onChange={handleRageType}
          options={[
            { value: "", label: "Igual" },
            { value: "GT", label: "Maior" },
            { value: "LT", label: "Menor" },
            { value: "GTLT", label: "Entre" },
          ]}
          error={error}
          disabled={disabled}
        />
        <InputText
          label={""}
          name={id}
          placeholder={
            rangeType === ""
              ? "Valor"
              : rangeType === "GT"
                ? "Maior que"
                : "Menor que"
          }
          value={rangeGt}
          onChange={handleInputGT}
          error={error}
        />
        {rangeType === "GTLT" && (
          <InputText
            label={""}
            name={id}
            placeholder={"Menor que"}
            value={rangeLt}
            onChange={handleInputLT}
            error={error}
          />
        )}
      </RangeInputContainer>
    );
  }

  function handleDateChange(date: Date | number | null) {
    const formated = date ? new Date(date).getTime() : "";
    const event = {
      target: { value: formated },
    };
    setDate(formated);
    onChange(event);
    setValues((prevState: any) => ({
      ...prevState,
      [id]: formated,
    }));
  }

  if (type === "DATE_TIME") {
    return (
      <DateInput
        label={label}
        name={id}
        placeholder="Informe uma data"
        handleChange={handleDateChange}
        value={date}
        showTimeSelect
        format="dd/MM/yyyy HH:mm"
        timeFormat="HH:mm"
        timeIntervals={15}
      />
    );
  }

  if (type === "DATE") {
    return (
      <DateInput
        label={label}
        name={id}
        placeholder="Informe uma data"
        handleChange={handleDateChange}
        value={date}
      />
    );
  }

  function handleMinDateChange(date: Date | number | null) {
    const [, max] = (value || "")?.split("-");
    const minDate = date ? new Date(date).getTime() : "";
    const formated = minDate ? `GT${minDate}` : "";

    setMinDate(minDate);
    setValues((prevState: any) => ({
      ...prevState,
      [id]: `${formated}-${max || ""}`,
    }));
  }

  function handleMaxDateChange(date: Date | number | null) {
    const [min] = (value || "")?.split("-");
    const maxDate = date ? new Date(date).getTime() : "";
    const formated = maxDate ? `-LT${maxDate}` : "";

    setMaxDate(maxDate);
    setValues((prevState: any) => ({
      ...prevState,
      [id]: `${min}${formated}`,
    }));
  }

  if (type === "DATE_PERIOD") {
    return (
      <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
        <DateInput
          name=""
          label={label}
          placeholder="Data Mínima"
          handleChange={handleMinDateChange}
          value={minDate}
        />
        <DateInput
          name=""
          placeholder="Data Máxima"
          handleChange={handleMaxDateChange}
          value={maxDate}
        />
      </div>
    );
  }

  return <span>Não implementado</span>;
};
