import { PropertyContainer, PropertyLabel, PropertyValue } from "../styles";
import { ShowProps } from "../types";

export const ShowDate = ({ record, label, id }: ShowProps) => {
  let date;
  try {
    date = new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "medium",
    }).format(new Date(record[id]));
  } catch (error) {}

  return (
    <PropertyContainer key={id}>
      <PropertyLabel>{label}</PropertyLabel>
      <PropertyValue>{date}</PropertyValue>
    </PropertyContainer>
  );
};
