export function getProfile(profileBits: string) {
  switch (profileBits) {
    case "111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111":
      return "Master";
    case "000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000":
      return "Executivo Master";
    case "000000000000000000000000000100000000000000000100100000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000000":
      return "Mesa Master";
    case "000000000000000000000000000000000000000001000100000000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000001":
      return "Representante";
    case "000000000000000000000000000000000000000001000100000000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000000":
      return "Mesa Analista";
    case "000000000000000000000000000000001000000000000100000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000":
      return "Cliente Master";
    case "000000000000000000000000000000000010000000000100000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000":
      return "Cliente Comum";
    default:
      break;
  }
}
