import { ListBoolean } from "../../../components/List/ListBoolean";
import { ListLogoUrl } from "../../../components/List/LogoUrl/List";
import { success } from "../../../components/Toast";
import { RecordProps } from "../../../types/default";
import { del, put } from "../../../utils/api";
import { ListCollect } from "../components/Collect";

import { ListPosition } from "../components/Position";
import { ModalCollect } from "../modals/ModalCollect";

export const CollectResource = {
  title: "Coletas",
  urlPath: "/monitorator/collects",
  apiPath: "/monitorator/collects",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
    // delete: {
    //   isVisible: true,
    //   handler: async (values: any) => {
    //     await del({
    //       path: `/monitorator/collects/${values.pk}`,
    //       queryStringParameters: {
    //         sk: values.sk,
    //       },
    //     });
    //   },
    // },
    viewCollect: {
      name: "viewCollect",
      type: "record",
      label: "Ver coleta",
      modalComponent: ({ record, id }: RecordProps) => (
        <ModalCollect id={id} record={record} />
      ),
    },
    resendCollect: {
      name: "resend",
      type: "record",
      label: "Reenviar Coleta",
      component: (record: any) => <></>,
      disableNavigate: true,
      handler: async (values: any) => {
        await put({
          path: `/monitorator/collects/${values.pk}/resend`,
          body: { sk: values.sk },
        });
        success("Coleta Reenviada com sucesso!");
      },
    },
  },
  properties: {
    logoUrl: {
      id: "logoUrl",
      type: "IMAGE",
      label: null,
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListLogoUrl record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    pk: {
      id: "pk",
      type: "TEXT",
      label: "UUID",
      isPrimaryKey: true,
      isVisible: {},
    },
    date: {
      id: "date",
      type: "DATE_PERIOD",
      label: "Data",
      isVisible: {
        list: false,
        filter: true,
      },
    },
    activatorId: {
      id: "activatorId",
      type: "TEXT",
      label: "UUID",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    gatewayId: {
      id: "gatewayId",
      type: "TEXT",
      label: "BOLT ID",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    collect: {
      id: "collect",
      type: "TEXT",
      label: "COLETA",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListCollect record={record} id={id} />
        ),
      },
    },
    position: {
      id: "position",
      type: "TEXT",
      label: "PONTO",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListPosition record={record} id={id} />
        ),
      },
    },
    classificated: {
      id: "classificated",
      type: "SELECT",
      label: "CLASSIFICADA?",
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
      },
      options: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
    },
    calculated: {
      id: "calculated",
      type: "SELECT",
      label: "CALCULADA?",
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
      },
      options: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
    },
    analisated: {
      id: "analisated",
      type: "SELECT",
      label: "ANALISADA?",
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
      },
      options: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
    },
    hourMeter: {
      id: "hourMeter",
      type: "SELECT",
      label: "HORIMETRO?",
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
      },
      options: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
    },
  },
};
