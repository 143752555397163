import { isEmpty } from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  PageHeader,
  PageTemplate,
} from "../../../../components";
import useApi from "../../../../hooks/useApi";

import { PageProps } from "../../../../types/default";

import { Actions, Container, ContentContainer, Title } from "./styles";

export const BoltChangeUpdatingMode = ({ resource }: PageProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { request } = useApi({ path: "/configurator/gateways" });

  const [version, setVersion] = useState("");
  const [force, setForce] = useState(false);

  const [errors, setErrors] = useState<any>({});

  const handlePost = () => {
    if (isEmpty(version)) {
      setErrors({ version: "Campo obrigatório" });
      return;
    }

    request({
      method: "put",
      pathParameters: `${id}/updating-mode`,
      body: {
        version,
        force,
      },
    }).then(() => {
      navigate(`${resource.urlPath}`);
    });
  };

  return (
    <PageTemplate>
      <>
        <Container>
          <PageHeader resource={resource} />
          <ContentContainer>
            <Title>Atualizar versão do bolt</Title>
            <Input
              type="SELECT_API"
              id="version"
              label="Versão do Firmware"
              value={version}
              onChange={(e) => {
                setErrors({});
                setVersion(e.target.value);
              }}
              endpoint={"/gateway-firmwares-options"}
              error={errors.version}
            />
            <Input
              type="CHECK"
              id="force"
              label="Forçar atualização"
              value={force}
              onChange={(e) => {
                setForce(e.target.value);
              }}
            />
            <Actions>
              <Button onClick={() => navigate(`${resource.urlPath}`)}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handlePost}>
                Salvar
              </Button>
            </Actions>
          </ContentContainer>
        </Container>
      </>
    </PageTemplate>
  );
};
