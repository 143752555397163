import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import queryString from "query-string";
import { Button, PageTemplate, Paginate } from "../../components";

import { ReactTable } from "../../components/Table/ReactTable";
import { TableValue } from "../../components/Table/styles";

import { CSVLink } from "react-csv";

import useApi from "../../hooks/useApi";
import {
  ButtonFilter,
  ClickableTableValue,
  ColumnHeader,
  Container,
  DetailsCard,
  DetailsContainer,
  FilterContainer,
  Header,
  HeadersContainer,
  PaginateContaienr,
  StatusContainer,
  TableContainer,
} from "./styles";
import { PageTitle } from "../../components/PageHeader/styles";
import HealthCheckDynamicStatus from "../GatewayHealthCheck/HeathCheckDynamicStatus";
import DeviceHealthSelect from "../GatewayHealthCheck/DeviceHealthSelect";
import { OrderButton } from "../../components/MultiSelect/OrderButton";
import { MdFilterAlt, MdFilterAltOff, MdOpenInNew } from "react-icons/md";
import { InstructionModal } from "../../modals/InstructionModal";
import { NotificationsModal } from "../../modals/NotificationsModal";
import { useLocation } from "react-router-dom";
import { FullPageLoading } from "../../components/FullPageLoading";
import { Tooltip } from "../../modules/monitorator/components/Tooltip";
import { ModalExportCSV } from "../../components/ModalExportCSV";
import { formatValueToCsv } from "../../utils/csvUtils";

const STATUS_OPTIONS = [
  { label: "Todos", value: null, isDefault: true },
  { label: "Normal", value: 0 },
  { label: "Alerta", value: 1 },
  { label: "Risco", value: 2 },
];

const SPECTRA_VERSIONS_OPTIONS = [
  { label: "Spectra 1.2", value: "Spectra 1.2" },
  { label: "Spectra 1.0", value: "Spectra 1.0" },
  { label: "Todos", value: null, isDefault: true },
];

const HOMOLOGATION_OPTIONS = [
  { label: "Todos", value: null, isDefault: true },
  { label: "Homologação", value: "true" },
];

export const PROBLEMS_LABELS: any = {
  BOLT_OFF: "Bolt Off",
  RSSI: "RSSI",
  EXCESSIVE_CONSUMPTION: "Bateria Baixa/Consumo Excessivo",
  NEGATIVE_TEMPERATURE: "Temperatura Negativa",
  LOW_PERFORMANCE: "Baixa Performance",
  DISCONNECTED: "Desconectado",
  NO_BOLT: "Sem Bolt",
  LOW_SIGNAL_QUALITY: "Baixa Qualidade de Sinal"
};

const IGNORED_DEFAULT: string[] = [];

export const SENSORS_DYNAMIC_STATUS = [
  { value: "connectivity", label: "Conexão" },
  { value: "performance", label: "Performance" },
  { value: "batteryVoltage", label: "Bateria" },
  { value: "firmware", label: "Versão" },
  { value: "temperature", label: "Temperatura" },
  { value: "signalQuality", label: "Qualidade do Sinal" },
  { value: "hitBoardAlive", label: "Hit Board Alive" },

  { value: "rssi", label: "RSSI" },
];

const DEFAULT_FILTERS = {
  endpointVersion: "Spectra 1.2",
  limit: 200,
};

interface IPagination {
  page: number;
  totalPages: number;
  totalData?: number;
  nextPage?: number | null | undefined;
}

const LIMIT_OPTIONS = [
  { value: 50, label: "50" },
  { value: 200, label: "200", isDefault: true },
  { value: 500, label: "500" },
  { value: 1000, label: "1000" },
  { value: 2000, label: "2000" },
];

export const SensorsHealth = () => {
  const { request, processing } = useApi({
    path: "",
  });

  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    totalPages: 1,
  });

  const [details, setDetails] = useState<any>([]);
  const [statusPercentage, setStatusPercentage] = useState([]);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);

  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [showInstructions, setShowInstructions] = useState<{
    flag: string;
    show: boolean;
  }>({ flag: "", show: false });

  const [filters, setFilters] = useState<any>(DEFAULT_FILTERS);

  const [statusIgnored, setStatusIgnored] = useState<any>(IGNORED_DEFAULT);

  function formatDate(date: number) {
    if (!date) return "";

    const formatted = new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "medium",
    }).format(new Date(date));

    return formatted;
  }

  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  function handleCloseInstructionModal() {
    setShowInstructions({ flag: "", show: false });
  }

  function formatDataToCSV(data: any[]) {
    return (
      data
        ?.filter((item: any) => !item.isIgnored)
        .map((item: any) => {
          return {
            DATA: formatDate(item.date),
            STATUS: getLabelStatus(item.status),
            PROBLEMA: PROBLEMS_LABELS[item.flag] || "",
            EMPRESA: item.companyName,
            UNIDADE: item.facilityName,
            ATIVO: item.assetName,
            "NOME PONTO": item.positionName,
            PONTO: item.boardId,
            MONITORAMENTO: item.sysSensorTypeName,
            "VERSAO SENSOR": item.sensorVersion,
            "ULTIMA COLETA": formatDate(item.lastAcquisitionDate.label),
            "DATA ATIVACAO": formatDate(item.activatedAt.label),
            "GATEWAY CONECTADO": item?.isGatewayConnected?.label,
            "GATEWAY ATIVADO": item?.isGatewayActivated?.label,
            "GATEWAY ULTIMA COLETA": item?.lastCollectGatewayId,
            "GATEWAY ID": item?.gatewayId,
            CONECTADO: item.connectivity.label,
            SINCRONIZADO: item.scheduleSynchronized.label,
            "DIAS CONECTADOS": formatValueToCsv(item.daysConnected.label),
            PERFORMANCE: formatValueToCsv(item.performance.label),
            BATERIA: formatValueToCsv(item.batteryVoltage.label),
            CONSUMO_BATERIA: formatValueToCsv(item.batteryConsumption),
            BATERIA_INICIAL: formatValueToCsv(item.initialBatteryVoltage),
            "VERSAO FIRMWARE": item.firmware.label,
            TEMPERATURA: item.temperature.label,
            "QUALIDADE DO SINAL": formatValueToCsv(item.signalQuality.label),
            RSSI: formatValueToCsv(item.rssi.label),
          };
        }) || []
    );
  }

  async function loadData({ page = 1 }: { page?: any; limit?: number | null }) {
    request({
      method: "get",
      pathParameters: "monitorator/sensors-health",
      queryStringParameters: {
        ...(statusIgnored.length ? { ignore: statusIgnored.join(",") } : {}),
        ...filters,
        page,
      },
    }).then((response) => {
      setPagination(response?.pagination);

      const orderedList = response?.data?.sort(
        (a: any, b: any) => a?.isIgnored - b?.isIgnored
      );

      setList(orderedList);
    });

    request({
      method: "get",
      pathParameters: "monitorator/sensors-health-details",
      queryStringParameters: {
        ...(statusIgnored.length ? { ignore: statusIgnored.join(",") } : {}),
        ...filters,
      },
    }).then((response) => {
      setDetails(response?.details);
    });
  }

  function handleCloseNotifications() {
    setShowNotifications(false);
  }

  useEffect(() => {
    loadData({ page: 1 });
    // eslint-disable-next-line
  }, [filters, statusIgnored]);

  useEffect(() => {
    if (queryParams.page) {
      loadData({ page: queryParams.page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.page]);

  function getLabelStatus(value: number) {
    switch (value) {
      case 0:
        return "Normal";
      case 1:
        return "Alerta";
      case 2:
        return "Risco";
      default:
        return "N/A";
    }
  }

  function getStatusBadge(statusNumber: any) {
    switch (statusNumber) {
      case 0:
        return "success";
      case 1:
        return "warning";
      case 2:
        return "danger";
      default:
        return "";
    }
  }

  function handleFilterChange(name: string, value: any) {
    if (value === null) {
      delete filters[name];
      setFilters({ ...filters });
      return;
    }

    setFilters({ ...filters, [name]: value });
  }

  const [order, setOrder] = useState<{
    order: "asc" | "desc" | null;
    field: string | null;
    type: string | null;
  }>({ field: "", order: null, type: "" });

  const handleOrderV2 = useCallback(
    ({ field, variableType }: { field: string; variableType: string }) => {
      if (field !== order.field) {
        setOrder({ order: "asc", field, type: variableType });
        return;
      }

      switch (order.order) {
        case "desc": {
          setOrder({ order: null, field: null, type: null });
          break;
        }
        case "asc": {
          setOrder({ order: "desc", field, type: variableType });
          break;
        }

        default: {
          setOrder({ order: "desc", field, type: variableType });
        }
      }
    },
    [order]
  );

  const handleToggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  useEffect(() => {
    if (order.field) {
      setFilters({
        ...filters,
        orderParameters: `${order.field}:${order.type}:${order.order}`,
      });
    } else {
      setFilters({ ...filters, orderParameters: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <PageTemplate>
      <Container>
        <HeadersContainer>
          {processing && <FullPageLoading />}
          <Header>
            <PageTitle>
              Saúde dos Endpoints ({pagination.totalData || ""})
            </PageTitle>

            <div>
              <ModalExportCSV
                resource={{
                  apiPath: "/monitorator/sensors-health",
                  actions: { csv: { filename: "endpoints-health" } },
                  queryParams: {
                    ...(statusIgnored.length
                      ? { ignore: statusIgnored.join(",") }
                      : {}),
                    ...filters,
                  },
                  customFormatter: formatDataToCSV,
                }}
              />
            </div>
          </Header>

          <DetailsContainer>
            <DetailsCard>
              <span>NORMAL</span>
              <div>
                <StatusContainer bg="success">
                  {(details?.percentStatusNormal * 100).toFixed(2) || "-"}%
                </StatusContainer>
              </div>
            </DetailsCard>
            <DetailsCard>
              <span>ALERTA</span>
              <div>
                <StatusContainer bg="warning">
                  {(details?.percentStatusAlert * 100).toFixed(2) || "-"}%
                </StatusContainer>
              </div>
            </DetailsCard>
            <DetailsCard>
              <span>RISCO</span>
              <div>
                <StatusContainer bg="danger">
                  {(details?.percentStatusRisk * 100).toFixed(2) || "-"}%
                </StatusContainer>
              </div>
            </DetailsCard>
            <Tooltip
              tooltip={showFilters ? "Esconder filtros" : "Mostrar filtros"}
              position={"top"}
            >
              <ButtonFilter onClick={handleToggleFilters}>
                {showFilters ? (
                  <MdFilterAltOff size={18} />
                ) : (
                  <MdFilterAlt size={18} />
                )}
              </ButtonFilter>
            </Tooltip>
          </DetailsContainer>
          {showFilters && (
            <>
              <HealthCheckDynamicStatus
                ignored={statusIgnored}
                setIgnored={setStatusIgnored}
                items={SENSORS_DYNAMIC_STATUS}
              />
              <FilterContainer>
                <div>
                  <label>Filtro por Status</label>
                </div>
                <div>
                  <DeviceHealthSelect
                    label="Status"
                    value={filters?.status}
                    options={STATUS_OPTIONS}
                    handleChange={(e) => handleFilterChange("status", e.value)}
                  />
                  <DeviceHealthSelect
                    label="Conexão"
                    value={filters?.connectivity}
                    options={STATUS_OPTIONS}
                    handleChange={(e) =>
                      handleFilterChange("connectivity", e.value)
                    }
                  />
                  <DeviceHealthSelect
                    label="Performance"
                    value={filters?.performance}
                    options={STATUS_OPTIONS}
                    handleChange={(e) =>
                      handleFilterChange("performance", e.value)
                    }
                  />
                  <DeviceHealthSelect
                    label="Bateria"
                    value={filters?.batteryVoltage}
                    options={STATUS_OPTIONS}
                    handleChange={(e) =>
                      handleFilterChange("batteryVoltage", e.value)
                    }
                  />
                  <DeviceHealthSelect
                    label="Versão Firmware"
                    value={filters?.firmware}
                    options={STATUS_OPTIONS}
                    handleChange={(e) =>
                      handleFilterChange("firmware", e.value)
                    }
                  />
                  <DeviceHealthSelect
                    label="Temperatura"
                    value={filters?.temperature}
                    options={STATUS_OPTIONS}
                    handleChange={(e) =>
                      handleFilterChange("temperature", e.value)
                    }
                  />
                  <DeviceHealthSelect
                    label="Qualidade do Sinal"
                    value={filters?.signalQuality}
                    options={STATUS_OPTIONS}
                    handleChange={(e) =>
                      handleFilterChange("signalQuality", e.value)
                    }
                  />
                  <DeviceHealthSelect
                    label="RSSI"
                    value={filters?.rssi}
                    options={STATUS_OPTIONS}
                    handleChange={(e) => handleFilterChange("rssi", e.value)}
                  />

                  <Button
                    onClick={() => {
                      setFilters({});
                      setStatusIgnored(IGNORED_DEFAULT);
                    }}
                  >
                    Resetar
                  </Button>
                </div>
                <div>Filtros por Dados</div>
                <div>
                  <DeviceHealthSelect
                    label="Versão Endpoint"
                    value={filters?.endpointVersion}
                    options={SPECTRA_VERSIONS_OPTIONS}
                    handleChange={(e) =>
                      handleFilterChange("endpointVersion", e.value)
                    }
                  />
                  <DeviceHealthSelect
                    label="Ambiente"
                    value={filters?.homologation}
                    options={HOMOLOGATION_OPTIONS}
                    handleChange={(e) =>
                      handleFilterChange("homologation", e.value)
                    }
                  />
                  <DeviceHealthSelect
                    label="Qtd por Página"
                    value={filters?.limit}
                    options={LIMIT_OPTIONS}
                    handleChange={(e) => handleFilterChange("limit", e.value)}
                  />
                </div>
              </FilterContainer>
            </>
          )}
        </HeadersContainer>
        <TableContainer>
          {processing ? (
            <div>Carregando..</div>
          ) : (
            <ReactTable
              responsive
              data={list || []}
              columns={[
                {
                  id: "date",
                  accessor: "date",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Data</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "date",
                              variableType: "bigint",
                            })
                          }
                          order={order}
                          field={"date"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {formatDate(value)}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "status",
                  accessor: "status",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>STATUS </span>
                        {/* <OrderButton
                          handleClick={() =>
                            handleOrder({ field: "status", type: "STRING" })
                          }
                          order={order}
                          field={"status"}
                        /> */}
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    let status = "success";
                    let statusLabel = "NORMAL";
                    if (value === 1) {
                      status = "warning";
                      statusLabel = "ALERTA";
                    } else if (value === 2) {
                      status = "danger";
                      statusLabel = "RISCO";
                    }
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={status}>
                          {statusLabel}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "flag",
                  accessor: "flag",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>PROBLEMA</span>
                        {/* <OrderButton
                          handleClick={() =>
                            handleOrder({ field: "flag", type: "STRING" })
                          }
                          order={order}
                          field={"flag"}
                        /> */}
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    if (!value) {
                      return (
                        <TableValue disabled={original.isIgnored}>-</TableValue>
                      );
                    }

                    return (
                      <ClickableTableValue
                        onClick={() =>
                          setShowInstructions({
                            show: true,
                            flag: value,
                          })
                        }
                      >
                        <TableValue disabled={original.isIgnored}>
                          <StatusContainer bg={getStatusBadge(original.status)}>
                            {PROBLEMS_LABELS[value]}
                          </StatusContainer>
                        </TableValue>
                      </ClickableTableValue>
                    );
                  },
                },
                {
                  id: "activatorId",
                  accessor: "activatorId",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Ponto</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "activatorId",
                              variableType: "text",
                            })
                          }
                          order={order}
                          field={"activatorId"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                        <MdOpenInNew
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_RETINA_INFRA_URL}/summary/endpoints/${original.activatorId}/history`,
                              "_blank"
                            )
                          }
                        />
                      </TableValue>
                    );
                  },
                },
                {
                  id: "connectivity",
                  accessor: "connectivity",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>CONECTADO</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "isConnected",
                              variableType: "bool",
                            })
                          }
                          order={order}
                          field={"isConnected"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "scheduleSynchronized",
                  accessor: "scheduleSynchronized",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Sincronizado</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "scheduleSynchronized",
                              variableType: "bool",
                            })
                          }
                          order={order}
                          field={"scheduleSynchronized"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "isGatewayConnected",
                  accessor: "isGatewayConnected",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Gateway Conectado</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "isGatewayConnected",
                              variableType: "bool",
                            })
                          }
                          order={order}
                          field={"isGatewayConnected"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },

                {
                  id: "isGatewayActivated",
                  accessor: "isGatewayActivated",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Gateway Ativado</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "isGatewayActivated",
                              variableType: "bool",
                            })
                          }
                          order={order}
                          field={"isGatewayActivated"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },

                {
                  id: "sysSensorTypeName",
                  accessor: "sysSensorTypeName",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Monitoramento</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "sysSensorTypeName",
                              variableType: "text",
                            })
                          }
                          order={order}
                          field={"sysSensorTypeName"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "sensorVersion",
                  accessor: "sensorVersion",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Versão Sensor</span>
                        {/* <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "sensorVersion",
                              type: "STRING",
                            })
                          }
                          order={order}
                          field={"sensorVersion"}
                        /> */}
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "lastAcquisitionDate",
                  accessor: "lastAcquisitionDate",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Última Coleta</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "lastAcquisitionDate",
                              variableType: "bigint",
                            })
                          }
                          order={order}
                          field={"lastAcquisitionDate"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value: { label }, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {formatDate(label)}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "activatedAt",
                  accessor: "activatedAt",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Data de Ativação</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "activatedAt",
                              variableType: "bigint",
                            })
                          }
                          order={order}
                          field={"activatedAt"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value: { label }, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {formatDate(label)}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "totalGatewaysListen",
                  accessor: "totalGatewaysListen",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Qtd Gateways Enxergando</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "totalGatewaysListen",
                              variableType: "int",
                            })
                          }
                          order={order}
                          field={"totalGatewaysListen"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "lastCollectGatewayId",
                  accessor: "lastCollectGatewayId",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Gateway Ultima Coleta</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "lastCollectGatewayId",
                              variableType: "text",
                            })
                          }
                          order={order}
                          field={"lastCollectGatewayId"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                        {!!value && (
                          <MdOpenInNew
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_RETINA_INFRA_URL}/summary/gateways/${value}/history`,
                                "_blank"
                              )
                            }
                          />
                        )}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "gatewayId",
                  accessor: "gatewayId",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>GatewayId</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "gatewayId",
                              variableType: "text",
                            })
                          }
                          order={order}
                          field={"gatewayId"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                      </TableValue>
                    );
                  },
                },

                {
                  id: "daysConnected",
                  accessor: "daysConnected",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Dias Conectados</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "daysConnected",
                              variableType: "int",
                            })
                          }
                          order={order}
                          field={"daysConnected"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "performance",
                  accessor: "performance",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Performance</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "performance",
                              variableType: "int",
                            })
                          }
                          order={order}
                          field={"performance"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label ? label + " %" : ""}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "batteryVoltage",
                  accessor: "batteryVoltage",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Bateria</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "batteryVoltage",
                              variableType: "float",
                            })
                          }
                          order={order}
                          field={"batteryVoltage"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label ? label + " V" : null}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "batteryConsumption",
                  accessor: "batteryConsumption",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Consumo</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "batteryConsumption",
                              variableType: "float",
                            })
                          }
                          order={order}
                          field={"batteryConsumption"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value ? +value.toFixed(3) + " mAh" : ""}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "initialBatteryVoltage",
                  accessor: "initialBatteryVoltage",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Bateria Inicial</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "initialBatteryVoltage",
                              variableType: "float",
                            })
                          }
                          order={order}
                          field={"initialBatteryVoltage"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value ? +value.toFixed(2) + " V" : ""}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "firmware",
                  accessor: "firmware",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Versão Firmware</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "firmware",
                              variableType: "text",
                            })
                          }
                          order={order}
                          field={"firmware"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "temperature",
                  accessor: "temperature",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Temperatura</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "temperature",
                              variableType: "float",
                            })
                          }
                          order={order}
                          field={"temperature"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label ? label + " ºC" : null}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "signalQuality",
                  accessor: "signalQuality",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Qualidade do Sinal</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "signalQuality",
                              variableType: "float",
                            })
                          }
                          order={order}
                          field={"signalQuality"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label ? label + " %" : ""}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },

                {
                  id: "rssi",
                  accessor: "rssi",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>RSSI</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "lastCollectRSSI",
                              variableType: "float",
                            })
                          }
                          order={order}
                          field={"rssi"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },

                {
                  id: "companyName",
                  accessor: "companyName",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Empresa</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "companyName",
                              variableType: "text",
                            })
                          }
                          order={order}
                          field={"companyName"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "facilityName",
                  accessor: "facilityName",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>UNIDADE</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "facilityName",
                              variableType: "text",
                            })
                          }
                          order={order}
                          field={"facilityName"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}{" "}
                        <MdOpenInNew
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${original.companyId}/facilities/${original.facilityId}/assets?sidetab=bolt`,
                              "_blank"
                            )
                          }
                        />
                      </TableValue>
                    );
                  },
                },
                {
                  id: "assetName",
                  accessor: "assetName",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Ativo</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrderV2({
                              field: "assetName",
                              variableType: "text",
                            })
                          }
                          order={order}
                          field={"assetName"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                        <MdOpenInNew
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${original.companyId}/facilities/${original.facilityId}/assets/${original.assetId}`,
                              "_blank"
                            )
                          }
                        />
                      </TableValue>
                    );
                  },
                },
              ]}
            />
          )}
        </TableContainer>
        <PaginateContaienr>
          <Paginate
            pagination={{
              totalPages: pagination.totalPages,
              page: pagination.page,
            }}
          />
        </PaginateContaienr>
        {showInstructions.show && (
          <InstructionModal
            flag={showInstructions?.flag}
            onClose={handleCloseInstructionModal}
          />
        )}
        {showNotifications && (
          <NotificationsModal
            onClose={handleCloseNotifications}
            show={showNotifications}
          />
        )}
      </Container>
    </PageTemplate>
  );
};
