import styled, { keyframes } from "styled-components";
import { Modal as RBModal } from "react-bootstrap";

const loading = keyframes`
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  100% {
    content: "...";
  }
`;

export const RBModalBody = styled(RBModal.Body)`
  & > h1 {
    ${({ theme }) => theme.fonts.primary.H2Bold};
    margin-bottom: 16px;
  }
`;

export const ModalFrame = styled.div<{
  height?: string;
  backgroundColor?: string;
  borderColor?: string;
}>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: ${(props) => props.height};
  /* min-width: 200px;
  min-height: 100px; */
  /* background-color: transparent; */

  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.divBackground};

  border: 1px solid
    ${(props) =>
      props.borderColor ? props.borderColor : props.theme.colors.cardHighlight};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
`;

export const ButtonCancel = styled.button<{ isEsgPanel?: boolean }>`
  background: transparent;
  min-width: 126px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  border: none;
  border-radius: 6px;
  margin-right: 10px;

  ${(props) => props.theme.fonts.primary.H5SemiBold};
  color: ${(props) =>
    props.isEsgPanel ? "black" : props.theme.colors.iconsPrimary};
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsPrimary} !important;
    background: transparent !important;
    color: ${(props) =>
      props.isEsgPanel ? "black" : props.theme.colors.iconsPrimary} !important;
  }

  &:active {
    background: transparent !important;
    border: none !important;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.iconsPrimary};
  }
`;

export const ButtonSave = styled.button<{ disabled?: boolean }>`
  background: ${(props) => props.theme.colors.ibbxOrange};
  background: ${(props) => props.disabled && props.theme.colors.lightOrange};
  min-width: 126px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  border: none;
  border-radius: 6px;
  color: #fff !important;
  transition: all 0.2s;
  margin: 0 !important;
  ${(props) => props.theme.fonts.primary.H5SemiBold};

  &:hover {
    border: 1px solid
      ${(props) =>
        props.disabled
          ? "#BDC1DA"
          : props.theme.colors.iconsPrimary} !important;
    background: ${(props) =>
      props.disabled ? "#BDC1DA" : props.theme.colors.ibbxOrange};
  }

  &:active {
    border: none !important;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.iconsPrimary};
  }

  @media screen and (max-width: 767px) {
    min-width: 6rem;
  }
`;

export const SavePosition = styled(ButtonSave)`
  :disabled {
    opacity: 0.5;

    &:after {
      content: " ";
      animation: ${loading} 1.5s steps(1, end) infinite;
    }

    &:hover {
      border: none;
      background: ${(props) => props.theme.colors.ibbxOrange};
    }
  }
`;
