import { ListDate } from "../../../components/List/ListDate";
import { ListLogoUrl } from "../../../components/List/LogoUrl/List";
import { RecordProps } from "../../../types/default";
import { del } from "../../../utils/api";
import { ListPosition } from "../components/Position";

export const BoardAlarms = {
  title: "Alarme dos sensores",
  urlPath: "/monitorator/board-alarms",
  apiPath: "/monitorator/board-alarms",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: {
      isVisible: true,
      label: "Fechar",
      handler: async (values: any) => {
        await del({
          path: `/monitorator/board-alarms/${values.boardId}`,
          queryStringParameters: {
            date: values.date,
          },
        });
      },
    },
  },
  properties: {
    logoUrl: {
      id: "logoUrl",
      type: "IMAGE",
      label: null,
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListLogoUrl record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    position: {
      id: "position",
      type: "TEXT",
      label: "PONTO",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListPosition record={record} id={id} />
        ),
      },
    },
    activatorId: {
      id: "activatorId",
      type: "TEXT",
      label: "UUID",
      isVisible: {
        list: true,
      },
    },
    createdAt: {
      id: "date",
      label: "CRIADO EM",
      type: "TEXT",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
      },
    },
    type: {
      id: "type",
      label: "TIPO",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    value: {
      id: "value",
      label: "VALOR",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    status: {
      id: "status",
      label: "STATUS",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
      },
    },
    facilityId: {
      id: "facilityId",
      label: "UNIDADE",
      type: "SELECT_API",
      endpoint: "/facilities-options",
      isVisible: {
        filter: true,
      },
    },
  },
};
