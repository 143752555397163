import { MdQrCode, MdSearch } from "react-icons/md";
import { QrCodeButton, SearchScannerContainer } from "./styles";
import { DebounceInput } from "react-debounce-input";

type SearchScannerProps = {
  type: "ENDPOINTS" | "GATEWAYS";
  setShow: (value: boolean) => void;
  value: any;
  setValue: (value: any) => void;
  handleSearch: Function;
};

function SearchScanner({ type, setShow, setValue, value, handleSearch }: SearchScannerProps) {
  function handleShowScan() {
    setShow(true);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value)
    handleSearch(event.target.value)
  }

  return (
    <SearchScannerContainer>
      <QrCodeButton onClick={handleShowScan}>
        <MdQrCode />
      </QrCodeButton>
      <DebounceInput value={value} onChange={handleChange} debounceTimeout={300} placeholder="Pesquisar..."></DebounceInput>
      <MdSearch />
    </SearchScannerContainer>
  );
}

export default SearchScanner;
