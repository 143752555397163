import { BuildRoutes } from "../../routes/BuildRoutes";

import { BoltResource } from "./resources/Bolts";
import { SensorResource } from "./resources/Sensors";
import { CollectResource } from "./resources/Collects";
import { DisconnectedPositions } from "./resources/DisconnectedPositions";
import { OfflineBolts } from "./resources/OfflineBolts";
import { BoardAlarms } from "./resources/BoardAlarms";

const resourcesRoutes = [
  BoltResource,
  SensorResource,
  CollectResource,
  DisconnectedPositions,
  OfflineBolts,
  BoardAlarms,
]
  .map((resource) => BuildRoutes(resource))
  .reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);

export const MONITORATOR_ROUTES = [...resourcesRoutes];
