import { ListDate } from "../../../components/List/ListDate";
import { PageProps } from "../../../types/default";
import { ReactivateAsset } from "../pages/ReactivateAsset";

export const AssetsResource = {
  title: "Ativos",
  urlPath: "/web/assets",
  apiPath: "/web/assets",
  actions: {
    new: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
    show: { isVisible: false },
    reactivate: {
      name: "reactivate",
      type: "record",
      label: "Reativar",
      guard: "Tem certeza que deseja reativar o ativo?",
      condition: (record: any) => !record.isActive,
      component: ({ resource }: PageProps) => (
        <ReactivateAsset resource={resource} />
      ),
    },
  },
  properties: {
    id: {
      id: "assetId",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        list: true,
        filter: true,
      },
    },
    assetName: {
      id: "assetName",
      type: "TEXT",
      label: "ATIVO",
      isVisible: {
        list: true,
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
      },
    },
    companyName: {
      id: "companyName",
      type: "TEXT",
      label: "EMPRESA",
      isVisible: {
        list: true,
      },
    },
    facilityId: {
      id: "facilityId",
      label: "UNIDADE",
      type: "SELECT_API",
      endpoint: "/facilities-options",
      isVisible: {
        filter: true,
      },
    },
    facilityName: {
      id: "facilityName",
      type: "TEXT",
      label: "UNIDADE",
      isVisible: {
        list: true,
      },
    },
    deletedAt: {
      id: "deletedAt",
      type: "TEXT",
      label: "DELETADO EM",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
      },
    },
    isDeleted: {
      id: "isDeleted",
      type: "SELECT",
      label: "DELETADO?",
      isVisible: {
        list: false,
        filter: true,
      },
      options: [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
      ],
    },
  },
};
