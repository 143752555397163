import { Form } from "react-bootstrap";
import styled from "styled-components";

export const HealthCheckDynamicStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    ${({ theme }) => theme.fonts.primary.H4Regular};
    
  }
  & > div {
    display: flex;
    gap: 8px;
    font-size: 9px;
  }
`;

export const FormLabelDynamicStatus = styled(Form.Label)`
  display: flex;
  gap: 8px;
  cursor: pointer;
`;
