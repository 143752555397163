import { BuildRoutes } from "../../routes/BuildRoutes";
import { InpectionsResource } from "./resources/Inspections";

const resourcesRoutes = [InpectionsResource]

  .map((resource) => BuildRoutes(resource))
  .reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);

export const QUALITY_ROUTES = [...resourcesRoutes];
