import RSelect, { GroupBase, StylesConfig } from "react-select";
import { useTheme } from "styled-components";
import { SelectClearButton, SelectHiearchyContainer } from "./styles";
import React, { Ref, useEffect, useMemo, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { components } from "react-select";
import { MdClose } from "react-icons/md";
import Select from "react-select/dist/declarations/src/Select";

type SelectProps = {
  disabled?: boolean;
  path: string;
  name?: string;
  placeholder?: string;
  isClearable?: boolean;
  label?: string;
  resetOnChange?: string[];
  parent?: string;
  values: any;
  setValues: (value: any) => void;
};

export default function SelectHiearchy({
  disabled,
  name,
  placeholder,
  isClearable,
  label,
  path,
  resetOnChange,
  parent,
  values,
  setValues,
}: SelectProps) {
  const { colors, fonts } = useTheme();
  const { request, processing } = useApi({ path });
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<any>(values[name ?? ""]);

  const defaultStyles: StylesConfig<any, false, GroupBase<any>> = {
    control: (base, props) => ({
      ...base,
      backgroundColor: colors.background,
      borderColor: colors.cardHighlight,
      color: colors.iconsPrimary,
      height: "36px",
      marginTop: "4px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "transparent",
        outline: "none",
      },
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
      color: colors.iconsPrimary,
    }),
    option: (base, props) => ({
      ...base,
      color: colors.iconsPrimary,
      ":hover": { backgroundColor: colors.headerBackground },
      ":active": { backgroundColor: colors.headerBackground },
      backgroundColor: `${props.isSelected && colors.cardBackground}`,
    }),
    valueContainer: (base) => ({
      ...base,
      color: colors.iconsPrimary,
      height: "100%",
      display: "flex",
      ...fonts.primary.H4Regular,
      position: "relative",
      "& > div": {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      [`input[type="text"]:focus`]: {
        boxShadow: "none",
      },
    }),
    input: (base) => ({
      ...base,
      position: "absolute",
      left: 0,
      border: "none",
      outline: "none",
    }),
    placeholder: (base) => ({
      ...base,
      // color: customColors?.placeholder?.color || colors.textPrimary,
      fontSize: "13px",
    }),
    singleValue: (base) => ({
      ...base,
      color: colors.iconsPrimary,
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: colors.background,
      fontWeight: "600",
      fontSize: "12px",
      zIndex: 3,
    }),
    clearIndicator: (base: any) => ({
      ...base,
    }),
  };

  const parentId = useMemo(() => {
    const curValues = values ?? {};
    return curValues[parent ?? ""];
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    let queryStringParameters: any = {};
    if (parent) {
      queryStringParameters[parent ?? ""] = parentId;
    }

    if ((parentId && parent) || !parent) {
      request({ method: "get", queryStringParameters }).then(setOptions);
    }

    if (parent && !parentId) {
      setOptions([]);
    }
    // eslint-disable-next-line
  }, [parentId]);

  const selectValue = useMemo(() => {
    if (!value) {
      return null;
    }
    const label = options.find((opt) => opt.value === +value)?.label ?? "";
    return value ? { value, label } : null;
    // eslint-disable-next-line
  }, [value]);

  function clearChildren() {
    if (!(resetOnChange ?? []).length) {
      return;
    }

    setValues((prevState: any) => {
      for (let item of resetOnChange ?? []) {
        delete prevState[item];
      }
      return prevState;
    });
  }

  const selectRef: Ref<Select<any, false, GroupBase<any>>> = useRef(null);

  function handleChange(change: any) {
    if (value !== change.value) {
      clearChildren();

      setValue(change.value);
      if (change?.value) {
        setValues((prevState: any) => ({
          ...prevState,
          [name ?? ""]: change.value,
        }));
      } else {
        setValues((prevState: any) => {
          delete prevState[name ?? ""];
          return prevState;
        });
      }
    }
  }

  const clearSelect = () => {
    selectRef.current?.onMenuClose();
    handleChange({ value: null });
  };

  return (
    <SelectHiearchyContainer>
      {label && <label>{label}</label>}
      <RSelect<any>
        isSearchable={true}
        value={selectValue}
        onChange={handleChange}
        isClearable={true}
        options={options}
        isDisabled={disabled || processing}
        placeholder={placeholder}
        isLoading={processing}
        ref={selectRef}
        components={{
          ClearIndicator: (props: any) => (
            <components.ClearIndicator {...props}>
              <SelectClearButton
                type="button"
                onClick={clearSelect}
                onTouchEnd={clearSelect}
              >
                <MdClose />
              </SelectClearButton>
            </components.ClearIndicator>
          ),
        }}
        // loadingMessage={"Carregando..."}
        defaultValue={null}
        styles={defaultStyles}
      />
    </SelectHiearchyContainer>
  );
}
