import styled from "styled-components";

export const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const PropertyLabel = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H4Bold};
  margin-bottom: 5px;
`;

export const PropertyValue = styled.span`
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H3SemiBold};
`;

export const ContainerStatus = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 200px;

  background-color: ${(props) => (props.active ? "green" : "red")};
  padding: 10px;
  height: 100%;

  & > span {
    color: ${(props) => props.theme.colors.white};
    ${(props) => props.theme.fonts.primary.H5Regular};
  }
`;
