import { PageProps, RecordProps } from '../../../types/default'
import { ListLogoUrl } from '../../../components/List/LogoUrl/List'
import { ListPositionDetails } from '../../../components/List/ListPositionDetails'
import { ListBoolean } from '../../../components/List/ListBoolean'
import { ListPositionAcquisitionDetails } from '../../../components/List/ListPositionAcquisitionDetails'

import { NewAnnouncement } from '../pages/Announcements/New'

export const AnnouncementsResource = {
  title: 'Anúncios',
  urlPath: '/web/announcements',
  apiPath: '/web/announcement',
  actions: {
    new: {
      isVisible: true,
      page: ({ resource }: PageProps) => <NewAnnouncement resource={resource} />
    },
    edit: { isVisible: true },
    delete: { isVisible: true },
    show: { isVisible: false }
  },
  properties: {
    version: {
      id: 'version',
      label: 'VERSÃO',
      type: 'TEXT'
    },
    title: {
      id: 'title',
      label: 'TITULO',
      type: 'TEXT'
    },
    videoUrl: {
      id: 'videoUrl',
      label: 'LINK DO VIDEO',
      type: 'TEXT'
    }

    // logoUrl: {
    //   id: "logoUrl",
    //   type: "IMAGE",
    //   label: null,
    //   component: {
    //     list: ({ record, id }: RecordProps) => (
    //       <ListLogoUrl record={record} id={id} />
    //     ),
    //   },
    //   isVisible: {
    //     list: true,
    //   },
    // },
    // id: {
    //   id: "id",
    //   type: "TEXT",
    //   label: "ID",
    //   isPrimaryKey: true,
    //   isVisible: {
    //     list: true,
    //     filter: true,
    //   },
    // },
    // name: {
    //   id: "name",
    //   type: "TEXT",
    //   label: "NOME",
    //   isVisible: {
    //     list: true,
    //   },
    // },
    // sensorTypeName: {
    //   id: "sensorTypeName",
    //   type: "TEXT",
    //   label: "MONITORAMENTO",
    //   isVisible: {
    //     list: true,
    //   },
    // },
    // sysSensorTypeId: {
    //   id: "sysSensorTypeId",
    //   type: "SELECT_API",
    //   label: "TIPO MONITORAMENTO",
    //   endpoint: "/sensor-type-options",
    //   isVisible: {
    //     filter: true,
    //   },
    // },
    // companyDetails: {
    //   id: "companyDetails",
    //   type: "TEXT",
    //   label: "DETALHES",
    //   isVisible: {
    //     list: true,
    //   },
    //   component: {
    //     list: ({ record, id }: any) => (
    //       <ListPositionDetails record={record} id={id} />
    //     ),
    //   },
    // },
    // companyId: {
    //   id: "companyId",
    //   label: "EMPRESA",
    //   type: "SELECT_API",
    //   endpoint: "/companies-options",
    //   isVisible: {
    //     filter: true,
    //     edit: true,
    //   },
    // },
    // facilityId: {
    //   id: "facilityId",
    //   label: "UNIDADE",
    //   type: "SELECT_API",
    //   endpoint: "/facilities-options",
    //   isVisible: {
    //     filter: true,
    //     edit: true,
    //   },
    // },
    // sysStatusId: {
    //   id: "sysStatusId",
    //   type: "SELECT",
    //   label: "STATUS EQUIPAMENTO",
    //   isVisible: {
    //     filter: true,
    //   },
    //   options: [
    //     { value: 1, label: "Normal" },
    //     { value: 2, label: "Alerta analise" },
    //     { value: 3, label: "Alerta" },
    //     { value: 4, label: "Risco analise" },
    //     { value: 5, label: "Risco" },
    //   ],
    // },
    // acquisitionDetails: {
    //   id: "lastAcquisitionDate",
    //   type: "TEXT",
    //   label: "DETALHES TRANSMISSÔES",
    //   component: {
    //     list: ({ record, id }: any) => (
    //       <ListPositionAcquisitionDetails record={record} id={id} />
    //     ),
    //   },
    //   isVisible: {
    //     list: true,
    //   },
    // },
    // isActivated: {
    //   id: "isActivated",
    //   type: "SELECT",
    //   label: "ATIVADO?",
    //   component: {
    //     list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
    //   },
    //   isVisible: {
    //     list: true,
    //     filter: true,
    //   },
    //   options: [
    //     { value: true, label: "Sim" },
    //     { value: false, label: "Não" },
    //   ],
    // },
    // isConnected: {
    //   id: "isConnected",
    //   type: "SELECT",
    //   label: "CONECTADO?",
    //   component: {
    //     list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
    //   },
    //   isVisible: {
    //     list: true,
    //     filter: true,
    //   },
    //   options: [
    //     { value: true, label: "Sim" },
    //     { value: false, label: "Não" },
    //   ],
    // },
    // battery: {
    //   id: "battery",
    //   type: "SELECT",
    //   label: "BATERIA",
    //   isVisible: {
    //     filter: true,
    //   },
    //   options: [
    //     { value: "LOW", label: "Baixa" },
    //     { value: "HIGH", label: "Alta" },
    //   ],
    // },
    // connectivity: {
    //   id: "connectivity",
    //   type: "SELECT",
    //   label: "QUALIDADE DE SINAL",
    //   isVisible: {
    //     filter: true,
    //   },
    //   options: [
    //     { value: "LOW", label: "Baixo" },
    //     { value: "HIGH", label: "Alto" },
    //   ],
    // },
  }
}
