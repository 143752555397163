import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import queryString from "query-string";

import { Modal } from "../../../../components/Modal";

import { ButtonCancel, Container, Footer, Title } from "./styles";
import { Button } from "../../../../components";
import useApi from "../../../../hooks/useApi";
import { useLocation } from "react-router-dom";

export const ModalExportDisconnectedPositions = () => {
  const location = useLocation();

  const csvRef = useRef<any>(null);

  const { request } = useApi({
    path: "/monitorator/disconnected-positions",
  });

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<String>("");
  const [data, setData] = useState<any>([]);
  const [completed, setCompleted] = useState(false);

  async function fetchPages() {
    setMessage("Buscando dados 0%...");
    setCompleted(false);

    const params = queryString.parse(location.search);

    let response, pagination;
    let page = 1;
    let accData: any = [];

    do {
      response = await request({
        method: "get",
        queryStringParameters: {
          page,
          limit: 100,
          ...params,
        },
      });

      pagination = response.pagination;
      page++;
      accData = [...accData, ...response.data];

      setMessage(
        `Buscando dados ${Math.trunc(
          (pagination.page / pagination.totalPages) * 100
        )}% ...`
      );
    } while (pagination.page < pagination.totalPages);

    const formattedData = accData.map((item: any) => {
      return {
        MOTIVO: item.motive,
        EMPRESA: item.companyName,
        UNIDADE: item.facilityName,
        EQUIPAMENTO: item.assetName,
        PONTO: item.positionName,
        "ID PONTO": item.positionId,
        UUID: item.activatorId,
        "ULTIMA COLETA": item.lastAcquisitionDate,
        BATERIA: item.lastCollect?.batteryVoltage?.toFixed(2),
        RSSI: item.lastCollect?.rssi?.toFixed(2),
        LINK: `${process.env.REACT_APP_RETINA_WEB_URL}/positions/${item.positionId}`,
      };
    });

    setMessage("Relatório pronto!");
    setCompleted(true);
    setData(formattedData);
  }

  useEffect(() => {
    if (show) {
      fetchPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const date = new Date();

  return (
    <>
      <Button onClick={() => setShow(true)}>Exportar CSV</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        confirmLabel="Baixar"
        size="lg"
        disabledConfirm={false}
        changeFotter
      >
        <Container>
          <Title>
            <strong>{`Exportando dados`}</strong>
          </Title>
          <p>{message}</p>
          {completed && (
            <CSVLink
              separator=";"
              style={{ display: "none" }}
              ref={csvRef}
              data={data}
              filename={`pontos-desconectados-${date.getDay()}/${
                date.getMonth() + 1
              }.csv`}
              className=""
            ></CSVLink>
          )}
          <Footer>
            <div>
              <ButtonCancel onClick={() => setShow(false)}>
                Cancelar
              </ButtonCancel>
              <Button
                disabled={!completed}
                variant="primary"
                onClick={() => {
                  csvRef.current.link.click();
                }}
              >
                Baixar
              </Button>
            </div>
          </Footer>
        </Container>
      </Modal>
    </>
  );
};
