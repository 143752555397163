import styled from "styled-components";

export const Container = styled.span`
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};
`;

export const ContainerStatus = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 200px;

  background-color: ${(props) => (props.active ? "green" : "red")};
  padding: 10px;
  height: 100%;

  & > span {
    color: ${(props) => props.theme.colors.white};
    ${(props) => props.theme.fonts.primary.H5Regular};
  }
`;

export const ListContainer = styled.div`
  width: 200px;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  & div {
    & > span:first-child {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;
