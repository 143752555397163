import Resizer from "react-image-file-resizer";

import { Form } from "react-bootstrap";
import { InputContainer } from "../styles";
import { ImageProps } from "./types";
import { useState } from "react";

export const Image = ({
  label,
  name,
  placeholder,
  onChange,
  error,
  value,
}: ImageProps) => {
  const [preview, setPreview] = useState<any>(value);

  return (
    <>
      <InputContainer>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          name={name}
          type="file"
          placeholder={placeholder}
          onChange={(e: any) => {
            try {
              Resizer.imageFileResizer(
                e.target.files[0],
                300,
                300,
                "SVG",
                100,
                0,
                (uri) => {
                  setPreview(uri);
                  onChange({ target: { value: uri } });
                },
                "base64",
                200,
                200
              );
            } catch (err) {
              console.log(err);
            }
          }}
        />
        {error ? <div className="error">{error}</div> : null}
      </InputContainer>
      <div>{preview && <img src={preview} alt="preview" />}</div>
    </>
  );
};
