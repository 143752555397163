import { useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import { AiOutlineDownload } from "react-icons/ai";

import { Input, PageTemplate } from "../../components";

import useApi from "../../hooks/useApi";

import { Container } from "./styles";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";

export const Growth = () => {
  const { darkMode } = useAppSelector(appSelect);

  const { request } = useApi({ path: "/companies-options" });
  const { request: requestChartData, processing } = useApi({
    path: "/monitorator/growth",
  });

  const [options, setOptions] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    request({ method: "get" }).then((response) => {
      setOptions(response);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    requestChartData({
      method: "get",
      queryStringParameters: {
        companyId: companyId || "IBBX",
      },
    }).then((response) => setData(response));
    // eslint-disable-next-line
  }, [companyId, darkMode]);

  const echartsOptions = useMemo(() => {
    if (!data || !data.length) {
      return null;
    }

    const color = darkMode ? "#FFF" : "#000";

    return {
      grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
      legend: {
        data: ["Cadastrados", "Ativados", "Conectados"],
        textStyle: { color },
      },
      xAxis: {
        type: "time",
        name: "Data",
        axisLabel: {
          color,
        },
        nameTextStyle: {
          color,
        },
      },
      yAxis: {
        name: "Pontos",
        axisLabel: {
          color,
        },
        nameTextStyle: {
          color,
        },
      },
      darkMode: true,
      series: [
        {
          name: "Cadastrados",
          data: data.map((i: any) => ({
            name: i.formattedDate,
            value: [i.formattedDate, i.countPositions],
          })),
          type: "line",
          smooth: true,
          showSymbol: false,
          endLabel: {
            show: true,
            formatter: function (params: any) {
              return `${params.seriesName}: ${params.value[1]}`;
            },
            color,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          lineStyle: {
            width: 3,
          },
        },
        {
          name: "Ativados",
          data: data.map((i: any) => ({
            name: i.formattedDate,
            value: [i.formattedDate, i.countActivatedPositions],
          })),
          type: "line",
          smooth: true,
          showSymbol: false,
          endLabel: {
            show: true,
            formatter: function (params: any) {
              return `${params.seriesName}: ${params.value[1]}`;
            },
            color,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          lineStyle: {
            width: 3,
          },
        },
        {
          name: "Conectados",
          data: data.map((i: any) => ({
            name: i.formattedDate,
            value: [i.formattedDate, i.countConnectedPositions],
          })),
          type: "line",
          smooth: true,
          showSymbol: false,
          endLabel: {
            show: true,
            formatter: function (params: any) {
              return `${params.seriesName}: ${params.value[1]}`;
            },
            color,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          lineStyle: {
            width: 3,
          },
        },
      ],
      tooltip: {
        trigger: "axis",
      },
      animationDuration: 3000,
      toolbox: {
        show: true,
        itemSize: 15,
        iconStyle: {
          color,
          borderColor: color,
        },
        feature: {
          dataZoom: {
            filterMode: "filter",
          },
          magicType: {
            type: ["line", "bar"],
          },
          saveAsImage: {
            name: `crescimento`,
            title: "Baixar imagem",
            backgroundColor: darkMode ? "#000" : "#FFF",
          },
        },
      },
    };
  }, [data, darkMode]);

  function render() {
    if (processing || !echartsOptions) {
      return <span>Loading...</span>;
    }

    return <ReactECharts option={echartsOptions} />;
  }

  return (
    <PageTemplate>
      <Container>
        <h2>Crescimento</h2>
        <Input
          id="companyId"
          label="IBBX"
          type="SELECT"
          key="companyId"
          value={companyId}
          onChange={(e: any) => {
            setCompanyId(e.target.value);
          }}
          options={[{ value: "IBBX", label: "Todas" }, ...options]}
        />
        <br />
        <br />
        {render()}
      </Container>
    </PageTemplate>
  );
};
