import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const Button = styled(Dropdown.Toggle)`
  padding: 10px;
  margin-right: 5px;

  background-color: ${(props) => props.theme.colors.cardBackground};
  /* opacity: ${(props) => (props.selected ? 1 : 0.4)}; */

  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;

  background-color: ${(props) =>
    props.variant === "primary" && props.theme.colors.lightOrange};
  color: ${(props) => props.theme.colors.textPrimary};

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 10px;
  }
`;
