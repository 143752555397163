import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import useApi from "../../../hooks/useApi";
import { Loading } from "../../Loading";

import { InputContainer } from "../styles";

import { SelectApiProps } from "./types";

export const SelectApi = ({
  label,
  endpoint,
  onChange,
  value,
  isFilter,
  error,
  disabled,
}: SelectApiProps) => {
  const { request, processing } = useApi({ path: endpoint as string });
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    request({
      method: "get",
    }).then((response: any) => {
      setOptions(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputContainer>
      {processing ? (
        <Loading />
      ) : (
        <>
          <Form.Label>{label}</Form.Label>
          <Form.Select
            onChange={(e: any) => {
              if (e.target.value === "Todos") {
                e.target.value = null;
              }

              if (onChange) {
                onChange(e);
              }
            }}
            value={value}
            disabled={disabled}
          >
            <option value={undefined} selected>
              {isFilter ? "Todos" : label}
            </option>
            {options.map(({ label, value }: any) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </>
      )}
      {error ? <div className="error">{error}</div> : null}
    </InputContainer>
  );
};
