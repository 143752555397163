export const PROBLEMS_CONFIG: any = {
  CONNECTION_ALERT: {
    value: "CONNECTION_ALERT",
    type: "CONNECTION",
    color: "#FF5850",
    label: "Conexão Instável",
    status: 2,
  },
  CONNECTION_RISK: {
    value: "CONNECTION_RISK",
    type: "CONNECTION",
    color: "#FC170D",
    label: "Conexão Fraca",
    status: 3,
  },
  CONNECTION_RSSI_RISK: {
    value: "CONNECTION_RSSI_RISK",
    type: "CONNECTION",
    color: "#C70039",
    label: "Conexão com RSSI Fraca",
    status: 3,
  },
  // Cartão SD
  SAN_DISK_SD_CARD: {
    value: "SAN_DISK_SD_CARD",
    type: "SD_CARD",
    color: "#86AFFF",
    status: 2,
    label: "Cartão SanDisk",
  },
  NOT_RECOMMENDED_SD_CARD: {
    value: "NOT_RECOMMENDED_SD_CARD",
    type: "SD_CARD",
    color: "#076AFF",
    status: 3,
    label: "Cartão IBBX Antigo",
  },
  // Alimentação
  USING_BATTERY: {
    value: "USING_BATTERY",
    type: "POWER_SUPPLY",
    color: "#6FFF6E",
    status: 2,
    label: "Fora da Tomada",
  },
  LOW_BATTERY: {
    value: "LOW_BATTERY",
    type: "POWER_SUPPLY",
    color: "#1BFF06",
    status: 3,
    label: "Bateria Baixa",
  },
  // Versão
  NOT_RECOMMENDED_VERSION: {
    value: "NOT_RECOMMENDED_VERSION",
    type: "VERSION",
    color: "#FF9E61",
    status: 2,
    label: "Versão Suportada",
  },
  DISCONTINUED_VERSION: {
    value: "DISCONTINUED_VERSION",
    type: "VERSION",
    color: "#FF700B",
    status: 3,
    label: "Versão Descontinuada",
  },
  // PSRAM
  FREE_PSRAM_ALERT: {
    value: "FREE_PSRAM_ALERT",
    type: "FREE_PSRAM",
    color: "#FFEB58",
    status: 2,
    label: "PSRAM Livre Baixo",
  },
  FREE_PSRAM_RISK: {
    value: "FREE_PSRAM_RISK",
    type: "FREE_PSRAM",
    color: "#FFE30B",
    status: 3,
    label: "PSRAM Livre Crítico",
  },
  // HEAP MEMORY
  FREE_HEAP_MEMORY_ALERT: {
    value: "FREE_HEAP_MEMORY_ALERT",
    type: "FREE_HEAP",
    color: "#FFB76A",
    status: 2,
    label: "Mem. Heap Livre Baixo",
  },
  FREE_HEAP_MEMORY_RISK: {
    value: "FREE_HEAP_MEMORY_RISK",
    type: "FREE_HEAP",
    color: "#FF8300",
    status: 2,
    label: "Mem. Heap Livre Crítico",
  },
  // GSM RRSI
  GSM_RSSI_ALERT: {
    value: "GSM_RSSI_ALERT",
    type: "GSM_RRSI",
    color: "#FF48F2",
    status: 2,
    label: "RSSI GSM Baixo",
  },
  GSM_RSSI_RISK: {
    value: "GSM_RSSI_RISK",
    type: "GSM_RRSI",
    color: "#FF0EDE",
    status: 3,
    label: "RSSI GSM Crítico",
  },
  // WIFI RRSI
  WIFI_RSSI_ALERT: {
    value: "WIFI_RSSI_ALERT",
    type: "WIFI_RSSI",
    color: "#CD61FF",
    status: 2,
    label: "RSSI WIFI Baixo",
  },
  WIFI_RSSI_RISK: {
    value: "WIFI_RSSI_RISK",
    type: "WIFI_RSSI",
    color: "#BE05FF",
    status: 3,
    label: "RSSI WIFI Crítico",
  },
};

export const PROBLEMS_TYPE: any = {
  CONNECTION: {
    value: "CONNECTION",
    label: "Conexão",
    color: "#FC170D",
  },
  SD_CARD: {
    value: "SD_CARD",
    label: "Cartão SD",
    color: "#1BFF06",
  },
  POWER_SUPPLY: {
    value: "POWER_SUPPLY",
    label: "Alimentação",
    color: "#076AFF",
  },
  VERSION: {
    value: "VERSION",
    label: "Versão",
    color: "#FF700B",
  },
  FREE_PSRAM: {
    value: "FREE_PSRAM",
    label: "PSRAM Livre",
    color: "#FFE30B",
  },
  FREE_HEAP: {
    value: "FREE_HEAP",
    label: "Mem. Heap Livre",
    color: "#FF8300",
  },
  GSM_RSSI: {
    value: "GSM_RSSI",
    label: "Rssi GSM",
    color: "#FF0EDE",
  },
  WIFI_RSSI: {
    value: "WIFI_RSSI",
    label: "Rssi WIFI",
    color: "#BE05FF",
  },
};
