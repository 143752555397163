import styled from "styled-components";

export const Container = styled.div`
 width: 100%;
 display: flex;
 flex-direction: column;
 gap: 0.5rem;
`

export const FooterContainer = styled.footer`
 width: 100%;
 display: flex;
 justify-content: end;
 gap: 0.5rem;
`