import styled from "styled-components";

export const DeviceHealthSelectContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 2px;

  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    font-size: 11px;
    color: ${(props) => props.color || props.theme.colors.iconsPrimary};
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular};
    color: red !important;
  }

  div {
    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${({ theme }) => theme.colors.iconsSecondary};
    }
    //FIREFOX
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  }
`;