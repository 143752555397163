import styled from "styled-components";
import { Modal } from "../../components/Modal";
import { PROBLEMS_LABELS } from "../../pages/GatewayHealthCheck";

interface IInstructionModal {
  onClose: () => void | any;
  flag: string;
}

const text: any = {
  NEW_GATEWAY: {
    rule: "Gateway foi instalado há menos de um dia atrás.",
    symptoms: [],
    remoteActions: [],
    physicalActions: [],
  },
  NO_ENERGY_RISK: {
    rule: "Gateway não enviou nenhum HealthCheck está usando a alimentação da Bateria, que possui voltagem menor igual a 3.6V",
    symptoms: [
      "Bolt esta sem rede elétrica e sem energia na bateria pois ficou mais de 8 horas desenergizado",
    ],
    remoteActions: [""],
    physicalActions: [
      "Verificar se o Bolt esta sendo alimentando pela rede elétrica",
    ],
  },
  NO_ENERGY_ALERT: {
    rule: "Gateway está sendo alimentado pela Bateria, que possui voltagem acima de 3.6V",
    symptons: [],
    remoteActions: [],
    physicalActions: [
      "Verificar se o Bolt esta sendo alimentando pela rede elétrica",
    ],
  },
  DISCONNECTED: {
    rule: "Gateway bão enviou nenhum Health Check e possui porcentagem de conexão igual a 0",
    symptons: [],
    remoteActions: [],
    physicalActions: [
      "Verificar se o bolt possui alimentação e conexão com internet",
      "Verificar se o Bolt esta sendo alimentando pela rede elétrica",
    ],
  },
  INSTABLE_CONNECTION: {
    rule: "Conexão menor do que 50%",
    symptons: [],
    remoteActions: [],
    physicalActions: [],
  },
  WEAK_RSSI_RISK: {
    rule: "Gateway possui RSSI < 16 e Conectividade < 75%",
    symptons: [],
    remoteActions: [],
    physicalActions: [],
  },
  WEAK_RSSI_ALERT: {
    rule: "Gateway possui RSSI < 16 e Conectividade < 90%",
    symptons: [],
    remoteActions: [],
    physicalActions: [],
  },
};

interface IInstructions {
  rule: string;
  symptons: string[];
  remoteActions: string[];
  physicalActions: string[];
}

export const InstructionModal = ({ onClose, flag }: IInstructionModal) => {
  const currentProblem: IInstructions = text[flag];

  return (
    <Modal handleClose={onClose} show={true} size="lg" hideSaveButton>
      <div>
        <h5>Detalhes</h5>
        <PrincipalContainer>
          <div>
            <label>Problema:</label>
            <label htmlFor="">{PROBLEMS_LABELS[flag]}</label>
          </div>
          <div>
            <label>Regra:</label>
            <label>{currentProblem?.rule}</label>
          </div>
          <div>
            <label>Sintomas:</label>
            <div>
              {currentProblem?.symptons?.map((item: string) => (
                <label> - {item}</label>
              ))}
            </div>
          </div>
          <div>
            <label>Ações Remotas:</label>
            <div>
              {currentProblem?.remoteActions?.map((item: string) => (
                <label> - {item}</label>
              ))}
            </div>
          </div>
          <div>
            <label>Ações Físicas:</label>
            <div>
              {currentProblem?.physicalActions?.map((item: string) => (
                <label> - {item}</label>
              ))}
            </div>
          </div>
        </PrincipalContainer>
      </div>
    </Modal>
  );
};

export const PrincipalContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  padding: 8px 8px 12px 8px;
  border-radius: 6px;
  flex-direction: column;
  display: flex;
  justify-content: center;

  & > div {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.iconsSecondaryAlpha};
    display: flex;
    gap: 1rem;
    padding: 10px;
    align-items: flex-start;

    & > label:first-child {
      ${(props) => props.theme.fonts.primary.H5Bold};
      text-align: start;
    }
    & > label:last-child {
      ${(props) => props.theme.fonts.primary.H5Regular};
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > label {
        ${(props) => props.theme.fonts.primary.H5Regular};
      }
    }
  }
`;
