import styled from "styled-components";

export const SelectHiearchyContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 4px;

  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${(props) => props.color || props.theme.colors.iconsPrimary};
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular};
    color: red !important;
  }

  div {
    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${({ theme }) => theme.colors.iconsSecondary};
    }
    //FIREFOX
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  }
`;

export const DropdownIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const SelectClearButton = styled.button`
  width: 20px;
  z-index: 2;
  height: 20px;
  border-radius: 100%;
  background-color: inherit;
  border: none;
  color: ${(props) => props.theme.colors.iconsPrimary};
  background-color: ${(props) => props.theme.colors.background};

  @keyframes button-on {
    0% {
      transform: rotateY(70deg);
      transform-origin: right;
      opacity: 0;
    }
    100% {
      transform: rotateY(0);
      transform-origin: right;
      opacity: 1;
    }
  }

  svg {
    font-size: 17px;
  }
`;