import { useEffect, useMemo, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";

import { CSVLink } from "react-csv";

import { Button, PageTemplate } from "../../components";

import useApi from "../../hooks/useApi";

import { ChartContainer, Container, Header } from "./styles";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import GatewaysProblemsTable from "./GatewaysProblemsTable";
import GatewaysProblemsFilter from "./GatewaysProblemsFilter";
import { useLocation } from "react-router-dom";
import queryString from "../../utils/queryString";
import { PageTitle } from "../List/styles";
import { PROBLEMS_CONFIG } from "./types";

export const GatewaysProblems = () => {
  const { darkMode } = useAppSelector(appSelect);

  const { request: requestChartData, processing } = useApi({
    path: "/monitorator/health-status-problems",
  });

  const csvRef = useRef<any>(null);

  const [data, setData] = useState<any>(null);
  const [list, setList] = useState<any>([]);
  const [filters, setFilters] = useState<any>({});
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    if (!processing) {
      requestChartData({
        method: "get",
        queryStringParameters: { ...queryParams, ...filters },
      }).then((response) => {
        let csvData;

        csvData = response.csv.map((item: any) => {
          return {
            DIA: new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(item.date)),
            PROBLEMA: item.problem,
            GATEWAY: item.gatewayId,
            EMPRESA: item.companyName,
            UNIDADE: item.facilityName,
          };
        });

        setData({ chart: response.chart, csv: csvData, table: response.csv });
      });
    }

    // eslint-disable-next-line
  }, [darkMode, filters, location.search]);

  const onEvents = {
    click: (params: any) => {
      const key = params.data.name;
      const date = params.data.value[0];

      setList(
        data.table.filter(
          (item: any) => item.date === date && item.problem === key
        )
      );
    },
  };

  const echartsOptions = useMemo(() => {
    if (!data?.chart || !Object.keys(data.chart).length) {
      return null;
    }

    const color = darkMode ? "#FFF" : "#000";

    return {
      grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
      legend: {
        data: Object.keys(data?.chart || {}),
        textStyle: { color },
      },
      xAxis: {
        type: "time",
        nameTextStyle: {
          color,
        },
        axisLabel: {
          color,
          formatter: function (value: any) {
            const day = new Date(value).getDate();
            const monthNumber = new Date(value).getMonth() + 1;
            const month = monthNumber.toString().padStart(2, "0");
            return `${day}/${month}`;
          },
        },
      },
      yAxis: {
        axisLabel: {
          color,
        },
        nameTextStyle: {
          color,
        },
      },
      darkMode: true,
      series: data?.chart
        ? Object.keys(data?.chart || {}).map((item) => {
            return {
              name: PROBLEMS_CONFIG[item].label,
              color: PROBLEMS_CONFIG[item].color,
              data: (data.chart[item] || []).map((i: any) => ({
                name: item,
                value: i,
              })),
              type: "bar",
              stack: "Ad",
              smooth: true,
              showSymbol: false,
              endLabel: {
                show: true,
                formatter: function (params: any) {
                  return `${params.seriesName}: ${params.value[1]}`;
                },
              },
              labelLayout: {
                moveOverlap: "shiftY",
              },
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                width: 3,
              },
            };
          })
        : [],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
        },
      },
      animationDuration: 1000,
    };

    // eslint-disable-next-line
  }, [data, darkMode]);

  return (
    <PageTemplate>
      <Container>
        <Header>
          <PageTitle>Histórico de Análise de Falha</PageTitle>
          <div>
            <>
              <CSVLink
                separator=";"
                style={{ display: "none" }}
                ref={csvRef}
                data={data?.csv || []}
                filename={`data.csv`}
                className=""
              ></CSVLink>
              <Button
                variant="primary"
                onClick={() => {
                  csvRef.current.link.click();
                }}
              >
                Baixar CSV
              </Button>
            </>
          </div>
        </Header>
        <GatewaysProblemsFilter
          filters={filters}
          setFilters={setFilters}
          type="PROBLEMS"
        />
        <ChartContainer>
          {data?.chart && (
            <ReactECharts option={echartsOptions} onEvents={onEvents} />
          )}
        </ChartContainer>

        {!!list?.length && <GatewaysProblemsTable list={list} />}
      </Container>
    </PageTemplate>
  );
};
