import { useEffect, useMemo, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";

import { CSVLink } from "react-csv";

import { Button, Input, PageTemplate } from "../../components";

import useApi from "../../hooks/useApi";

import { Container, DateContainer, Header, TableContainer } from "./styles";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import { ReactTable } from "../../components/Table/ReactTable";
import { ColumnHeader, TableValue } from "../../components/Table/styles";
import { DateInput } from "../../components/DateInput";
import { MdOpenInNew } from "react-icons/md";

export const Occurrences = () => {
  const { darkMode } = useAppSelector(appSelect);

  const { request: requestChartData, processing } = useApi({
    path: "/monitorator/occurrence-history",
  });

  const { request: requestOccurrences, processing: processingOccurrences } =
    useApi({
      path: "/monitorator/occurrences",
    });

  const csvRef = useRef<any>(null);

  const [data, setData] = useState<any>(null);
  const [list, setList] = useState<any>();
  const [csv, setCSV] = useState<any>([]);
  const [type, setType] = useState<any>("OCCURRENCE");
  const [details, setDetails] = useState<any>(false);
  const [onlyDeleted, setOnlyDeleted] = useState<any>(false);
  const [onlyNOC, setOnlyNOC] = useState<any>(false);
  const [view, setView] = useState("HISTORY");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (view === "HISTORY" && !processing) {
      requestChartData({
        method: "get",
        queryStringParameters: { type, details, onlyDeleted, onlyNOC },
      }).then((response) => setData(response));
    }

    if (view === "LIST" && !processingOccurrences) {
      requestOccurrences({
        method: "get",
        queryStringParameters: { date: date.getTime(), type },
      }).then((response) => {
        setList(response);

        let csvData;

        if (type === "OCCURRENCE") {
          csvData = response.map((item: any) => {
            return {
              ID_EQUIPAMENTO: item.assetId,
              ID_OCORRENCIA: item.assetOccurrenceId,
              DATA_CRIACAO: new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(item.createdAt)),
              DATA_DELECAO: new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(item.deletedAt)),
              MOTIVO: item.exclusionReason,
              GRAFICOS: item.chartNames.join(", "),
              NAME: item.name,
              LINK_OCORRENCIA: `${process.env.REACT_APP_RETINA_WEB_URL}/assets/${item.assetId}/occurrences/${item.assetOccurrenceId}`,
            };
          });
        } else if (type === "EVIDENCE") {
          csvData = response.map((item: any) => {
            return {
              ID_EQUIPAMENTO: item.assetId,
              ID_OCORRENCIA: item.assetOccurrenceId,
              ID_PONTO: item.positionId,
              GRAFICO: item.chartName,
              DATA_CRIACAO: item.createdAt,
              DATA_DELECAO: item.deletedAt,
              MOTIVO: item.exclusionReason,
              USUARIO: item.name,
              VALOR_COLETA: item.collectValue,
              VALOR_ALARME: item.alarmValue,
              ABERTO_PELA_IA: item.openedByAI,
              LINK_OCORRENCIA: `${process.env.REACT_APP_RETINA_WEB_URL}/assets/${item.assetId}/occurrences/${item.assetOccurrenceId}`,
              LINK_PONTO: `${process.env.REACT_APP_RETINA_WEB_URL}/postions/${item.positionId}`,
            };
          });
        }

        setCSV(csvData);
      });
    }

    // eslint-disable-next-line
  }, [darkMode, type, details, view, date, onlyDeleted, onlyNOC]);

  const echartsOptions = useMemo(() => {
    if (!data || !Object.keys(data).length) {
      return null;
    }

    const color = darkMode ? "#FFF" : "#000";

    return {
      grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
      legend: {
        data: Object.keys(data),
        textStyle: { color },
      },
      xAxis: {
        type: "time",
        name: "Data",
        axisLabel: {
          color,
        },
        nameTextStyle: {
          color,
        },
      },
      yAxis: {
        name: type === "OCCURRENCE" ? "Ocorrências" : "Evidências",
        axisLabel: {
          color,
        },
        nameTextStyle: {
          color,
        },
      },
      darkMode: true,
      series: Object.keys(data).map((item) => {
        let color;

        if (item === "Deletada") {
          color = "red";
        } else if (item === "Valida") {
          color = "green";
        }

        return {
          name: item,
          data: data[item].map((i: any) => ({
            name: i.name,
            value: i.value,
          })),
          type: "bar",
          stack: "Ad",
          smooth: true,
          showSymbol: false,
          endLabel: {
            show: true,
            formatter: function (params: any) {
              return `${params.seriesName}: ${params.value[1]}`;
            },
            color,
          },
          labelLayout: {
            moveOverlap: "shiftY",
          },
          emphasis: {
            focus: "series",
          },
          lineStyle: {
            width: 3,
          },
          color,
        };
      }),
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
        },
      },
      animationDuration: 1000,
    };
  }, [data, darkMode, type]);

  function render() {
    if (processing || processingOccurrences) {
      return <span>Loading...</span>;
    }

    if (view === "HISTORY" && !!echartsOptions) {
      return (
        <>
          <div className="d-flex">
            <Input
              type="CHECK"
              id="onlyNOC"
              label="Somente NOC"
              value={onlyNOC}
              onChange={(e) => {
                setOnlyNOC(e.target.value);
              }}
            />
            &nbsp;&nbsp;
            <Input
              type="CHECK"
              id="onlyDeleted"
              label="Somente deletadas"
              value={onlyDeleted}
              onChange={(e) => {
                setOnlyDeleted(e.target.value);
              }}
            />
            &nbsp;&nbsp;
            <Input
              type="CHECK"
              id="details"
              label="Detalhes"
              value={details}
              onChange={(e) => {
                setDetails(e.target.value);
              }}
            />
          </div>
          <br />
          <ReactECharts option={echartsOptions} />
        </>
      );
    }

    if (view === "LIST" && type === "OCCURRENCE") {
      return (
        <TableContainer>
          <DateContainer>
            <DateInput
              name=""
              placeholder="Data"
              handleChange={(date) => {
                setDate(date);
              }}
              value={date}
            />
          </DateContainer>
          <ReactTable
            responsive
            data={list || []}
            columns={[
              {
                id: "assetOccurrenceId",
                accessor: "assetOccurrenceId",
                Header: () => (
                  <ColumnHeader>
                    <span>ID OCORRENCIA</span>
                  </ColumnHeader>
                ),
                Cell: ({ value, row }) => {
                  const { original }: any = row;
                  return (
                    <TableValue>
                      {value}{" "}
                      <MdOpenInNew
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_RETINA_WEB_URL}/assets/${original.assetId}/occurrences/${original.assetOccurrenceId}`,
                            "_blank"
                          );
                        }}
                      />
                    </TableValue>
                  );
                },
              },
              {
                id: "companyName",
                accessor: "companyName",
                Header: () => (
                  <ColumnHeader>
                    <span>EMPRESA</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value}</TableValue>;
                },
              },
              {
                id: "facilityName",
                accessor: "facilityName",
                Header: () => (
                  <ColumnHeader>
                    <span>UNIDADE</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value}</TableValue>;
                },
              },
              {
                id: "assetName",
                accessor: "assetName",
                Header: () => (
                  <ColumnHeader>
                    <span>ATIVO</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value}</TableValue>;
                },
              },
              {
                id: "assetId",
                accessor: "assetId",
                Header: () => (
                  <ColumnHeader>
                    <span>ID ATIVO</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value}</TableValue>;
                },
              },
              {
                id: "createdAt",
                accessor: "createdAt",
                Header: () => (
                  <ColumnHeader>
                    <span>ABERTURA</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return (
                    <TableValue>
                      {new Intl.DateTimeFormat("pt-BR", {
                        dateStyle: "short",
                        timeStyle: "medium",
                      }).format(new Date(value))}
                    </TableValue>
                  );
                },
              },
              {
                id: "chartNames",
                accessor: "chartNames",
                Header: () => (
                  <ColumnHeader>
                    <span>GRÁFICOS</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value?.join(", ")}</TableValue>;
                },
              },
              {
                id: "hasAIEvidence",
                accessor: "hasAIEvidence",
                Header: () => (
                  <ColumnHeader>
                    <span>EVIDENCIA I.A.</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value ? "True" : "False"}</TableValue>;
                },
              },
              {
                id: "deletedAt",
                accessor: "deletedAt",
                Header: () => (
                  <ColumnHeader>
                    <span>EXCLUSÃO</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return (
                    <TableValue>
                      {value &&
                        new Intl.DateTimeFormat("pt-BR", {
                          dateStyle: "short",
                          timeStyle: "medium",
                        }).format(new Date(value))}
                    </TableValue>
                  );
                },
              },
              {
                id: "userDeleted",
                accessor: "userDeleted",
                Header: () => (
                  <ColumnHeader>
                    <span>DELETADA POR</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value}</TableValue>;
                },
              },
              {
                id: "exclusionReason",
                accessor: "exclusionReason",
                Header: () => (
                  <ColumnHeader>
                    <span>MOTIVO</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value}</TableValue>;
                },
              },
              {
                id: "closedAt",
                accessor: "closedAt",
                Header: () => (
                  <ColumnHeader>
                    <span>FECHADA EM</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return (
                    <TableValue>
                      {value &&
                        new Intl.DateTimeFormat("pt-BR", {
                          dateStyle: "short",
                          timeStyle: "medium",
                        }).format(new Date(value))}
                    </TableValue>
                  );
                },
              },
              {
                id: "userClosed",
                accessor: "userClosed",
                Header: () => (
                  <ColumnHeader>
                    <span>FECHADA POR</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value}</TableValue>;
                },
              },
              {
                id: "hadIntervention",
                accessor: "hadIntervention",
                Header: () => (
                  <ColumnHeader>
                    <span>TEVE INTERVENÇÃO</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return <TableValue>{value}</TableValue>;
                },
              },
              {
                id: "validAnalysis",
                accessor: "validAnalysis",
                Header: () => (
                  <ColumnHeader>
                    <span>ANALISE VALIDA</span>
                  </ColumnHeader>
                ),
                Cell: ({ value }) => {
                  return (
                    <TableValue>
                      {value !== null ? (value ? "True" : "False") : null}
                    </TableValue>
                  );
                },
              },
            ]}
          />
        </TableContainer>
      );
    }

    return (
      <TableContainer>
        <DateContainer>
          <DateInput
            name=""
            placeholder="Data"
            handleChange={(date) => {
              setDate(date);
            }}
            value={date}
          />
        </DateContainer>
        <ReactTable
          responsive
          data={list || []}
          columns={[
            {
              id: "assetOccurrenceId",
              accessor: "assetOccurrenceId",
              Header: () => (
                <ColumnHeader>
                  <span>ID OCORRENCIA</span>
                </ColumnHeader>
              ),
              Cell: ({ value, row }) => {
                const { original }: any = row;
                return (
                  <TableValue>
                    {value}{" "}
                    <MdOpenInNew
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_RETINA_WEB_URL}/assets/${original.assetId}/occurrences/${original.assetOccurrenceId}`,
                          "_blank"
                        );
                      }}
                    />
                  </TableValue>
                );
              },
            },
            {
              id: "assetId",
              accessor: "assetId",
              Header: () => (
                <ColumnHeader>
                  <span>ID ATIVO</span>
                </ColumnHeader>
              ),
              Cell: ({ value }) => {
                return <TableValue>{value}</TableValue>;
              },
            },
            {
              id: "createdAt",
              accessor: "createdAt",
              Header: () => (
                <ColumnHeader>
                  <span>ABERTURA</span>
                </ColumnHeader>
              ),
              Cell: ({ value }) => {
                return (
                  <TableValue>
                    {new Intl.DateTimeFormat("pt-BR", {
                      dateStyle: "short",
                      timeStyle: "medium",
                    }).format(new Date(value))}
                  </TableValue>
                );
              },
            },
            {
              id: "deletedAt",
              accessor: "deletedAt",
              Header: () => (
                <ColumnHeader>
                  <span>EXCLUSÃO</span>
                </ColumnHeader>
              ),
              Cell: ({ value }) => {
                return (
                  <TableValue>
                    {new Intl.DateTimeFormat("pt-BR", {
                      dateStyle: "short",
                      timeStyle: "medium",
                    }).format(new Date(value))}
                  </TableValue>
                );
              },
            },
            {
              id: "name",
              accessor: "name",
              Header: () => (
                <ColumnHeader>
                  <span>USUÁRIO</span>
                </ColumnHeader>
              ),
              Cell: ({ value }) => {
                return <TableValue>{value}</TableValue>;
              },
            },
            {
              id: "chartName",
              accessor: "chartName",
              Header: () => (
                <ColumnHeader>
                  <span>GRÁFICO</span>
                </ColumnHeader>
              ),
              Cell: ({ value }) => {
                return <TableValue>{value}</TableValue>;
              },
            },
            {
              id: "exclusionReason",
              accessor: "exclusionReason",
              Header: () => (
                <ColumnHeader>
                  <span>MOTIVO</span>
                </ColumnHeader>
              ),
              Cell: ({ value }) => {
                return <TableValue>{value}</TableValue>;
              },
            },
          ]}
        />
      </TableContainer>
    );
  }

  return (
    <PageTemplate>
      <Container>
        <Header>
          <h2>{type === "OCCURRENCE" ? "Ocorrências" : "Evidências"}</h2>
          <div>
            {view === "LIST" && (
              <>
                <CSVLink
                  separator=";"
                  style={{ display: "none" }}
                  ref={csvRef}
                  data={csv}
                  filename={`data.csv`}
                  className=""
                ></CSVLink>
                <Button
                  variant="primary"
                  onClick={() => {
                    csvRef.current.link.click();
                  }}
                >
                  Baixar CSV
                </Button>
              </>
            )}
            <Button
              onClick={() => setView(view === "HISTORY" ? "LIST" : "HISTORY")}
            >
              {view === "HISTORY" ? "Lista" : "Histôrico"}
            </Button>
          </div>
        </Header>
        <Input
          id="type"
          label=""
          type="SELECT"
          key="type"
          value={type}
          onChange={(e: any) => {
            setType(e.target.value);
          }}
          options={[
            { value: "OCCURRENCE", label: "Ocorrências" },
            { value: "EVIDENCE", label: "Evidencias" },
          ]}
        />
        {render()}
      </Container>
    </PageTemplate>
  );
};
