import styled, { css } from "styled-components";

export const ContainerFilter = styled.div<{ show: boolean }>`
  opacity: 1;
  position: absolute;
  right: 0.63rem;
  width: 40vw;

  -webkit-box-shadow: -22px 2px 22px -14px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -22px 2px 22px -14px rgba(0, 0, 0, 0.3);
  box-shadow: -22px 2px 22px -14px rgba(0, 0, 0, 0.3);

  background-color: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;

  padding: 10px;
  height: calc(100vh - 135px);

  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ show }) => !show && css`
    width: 0;
    right: -40rem;
    opacity: 0;
  `}
  transition: all 0.6s ease-in-out;
`;

export const RoundedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 100%;
  border: none;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
    font-size: 1.5rem;
  }

  &:hover {
    background: ${(props) => props.theme.colors.headerBackground};
    transition: background 0.1s;
  }
`;

export const Title = styled.h2`
  margin-top: 5px;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H2Bold};
`;

export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const ContainerActions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
`;

export const ContainerInputs = styled.form`
  width: 100%;
`;
