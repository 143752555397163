export const setItem = (key: string, valor: string) => {
  localStorage.setItem(`retina:${key}`, valor);
};

export const getItem = (key: string) => {
  const storageValue = localStorage.getItem(`retina:${key}`);
    if (storageValue) {
      return JSON.parse(storageValue);
    }
    return null;
};
