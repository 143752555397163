export function formatValueToCsv(value: any) {
  if (typeof value === "number" && value.toString().includes(".")) {
    return value.toString().replace(".", ",");
  }

  if (value === "N/D") {
    return "";
  }

  return value ?? "";
}
