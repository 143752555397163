import {
  FaArrowsAltV,
  FaLongArrowAltDown,
  FaLongArrowAltUp,
} from "react-icons/fa";
import styled from "styled-components";

interface IOrderButton {
  handleClick: () => void;
  order: any;
  field: string;
}

export const OrderButton = ({ handleClick, order, field }: IOrderButton) => {
  const renderIcon = () => {
    switch (true) {
      case order.field === field && order.order === "asc": {
        return <FaLongArrowAltDown size={12} />;
      }

      case order.field === field && order.order === "desc": {
        return <FaLongArrowAltUp size={12} />;
      }

      default:
        return <FaArrowsAltV size={12} />;
    }
  };

  return (
    <OrderButtonStyle
      selected={order.order !== null && order.field === field}
      onClick={handleClick}
    >
      {renderIcon()}
    </OrderButtonStyle>
  );
};

export const OrderButtonStyle = styled.button<{ selected?: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 100%;
  min-width: 20px;
  min-height: 20px;
  color: ${(props) =>
    props.selected
      ? props.theme.colors.iconsSecondaryAlpha
      : props.theme.colors.iconsSecondary};
  background: ${(props) =>
    props.selected ? props.theme.colors.iconsSecondary : "transparent"};
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
`;
