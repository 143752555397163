import { ListContainer } from "../styles";
import { ListProps } from "../types";

export const ListUserCompany = ({ record }: ListProps) => {
  return (
    <ListContainer>
      <div>
        <span>Empresa: </span>
        <span>{record.companyName}</span>
      </div>
      <div>
        <span>Unidade: </span>
        <span>{record.facilityName}</span>
      </div>
    </ListContainer>
  );
};
