import { useEffect, useMemo, useRef, useState } from "react";
import { BiTrophy } from "react-icons/bi";
import { Button, PageTemplate } from "../../components";

import { ReactTable } from "../../components/Table/ReactTable";
import { TableValue } from "../../components/Table/styles";

import { CSVLink } from "react-csv";

import useApi from "../../hooks/useApi";

import {
  Container,
  TableContainer,
  StatusContainer,
  ColumnHeader,
  DetailsContainer,
  DetailsCard,
  FilterContainer,
  Header,
  ClickableTableValue,
  SummaryChartByProblemContainer,
  ChartBar,
  RankingContainer,
  StyledButton,
  ButtonContainer,
} from "./styles";

import { MdOpenInNew } from "react-icons/md";
import { PageTitle } from "../List/styles";
import HealthCheckDynamicStatus from "./HeathCheckDynamicStatus";
import DeviceHealthSelect from "./DeviceHealthSelect";
import DeviceHealthIgnoreButton from "./DeviceHealthIgnoreButton";
import { OrderButton } from "../../components/MultiSelect/OrderButton";
import { NotificationsModal } from "../../modals/NotificationsModal";
import { AddComment } from "../../components/AddComment";
import { IoMdClose } from "react-icons/io";
import { ProblemsHistoryModal } from "../../modals/ProblemsHistoryModal";
import { Tooltip } from "../../modules/monitorator/components/Tooltip";
import { formatValueToCsv } from "../../utils/csvUtils";

const STATUS_OPTIONS = [
  { label: "Todos", value: null, isDefault: true },
  { label: "Normal", value: 0 },
  { label: "Alerta", value: 1 },
  { label: "Risco", value: 2 },
];

const VERSION_OPTIONS = [
  { label: "Todos", value: null, isDefault: true },
  { label: "Recomendada", value: 0 },
  { label: "Suportada", value: 1 },
  { label: "Descontinuada", value: 2 },
];

export const PROBLEMS_LABELS: any = {
  NEW_GATEWAY: "Novo Bolt",
  NO_ENERGY_RISK: "Desenergizado",
  NO_ENERGY_ALERT: "Desenergizado Alerta",
  DISCONNECTED: "Desconectado",
  INSTABLE_CONNECTION: "Conexão Instável",
  WEAK_RSSI_RISK: "GSM RSSI Fraco",
  WEAK_RSSI_ALERT: "GSM RSSI Fraco Alerta",
  WEAK_RSSI_WIFI_RISK: "WIFI RSSI Fraco",
  WEAK_RSSI_WIFI_ALERT: "WIFI RSSI Fraco Alerta",
  WRONG_SD_CARD: "Cartão",
  DEAD: "Morto",
};

const IGNORED_DEFAULT: string[] = [
  "wifiRSSI",
  "sdCardType",
  "powerSupply",
  "version",
  "percentFreePSRAM",
  "percentFreeHeap",
];

const GATEWAY_DYNAMIC_STATUS = [
  { value: "connectivity", label: "Conexão" },
  { value: "sdCardType", label: "Cartão SD" },
  { value: "powerSupply", label: "Alimentação" },
  { value: "version", label: "Versão" },
  { value: "percentFreePSRAM", label: "Memória PSRAM" },
  { value: "percentFreeHeap", label: "Memória Heap" },
  { value: "gsmRSSI", label: "GSM RSSI" },
  { value: "wifiRSSI", label: "WIFI RSSI" },
];

interface IPagination {
  page: number;
  totalPages: number;
  totalData?: number;
  nextPage?: number | null | undefined;
}

const LIMIT_REQUEST_DATA = 500;

export const GatewayHealthCheck = () => {
  const { request, processing } = useApi({
    path: "/monitorator/gateway-health-check",
  });

  const csvRef = useRef<any>(null);

  const [list, setList] = useState<any[]>([]);
  const [details, setDetails] = useState<any>([]);
  const [csv, setCSV] = useState<any>([]);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    totalPages: 1,
  });

  const [showHistoryProblems, setShowHistoryProblems] = useState<{
    gatewayId: string;
  }>({ gatewayId: "" });

  const [filters, setFilters] = useState<any>({});
  const [summaryByProblem, setSummaryByProblem] = useState<any[]>([]);

  const [statusIgnored, setStatusIgnored] = useState<any>(IGNORED_DEFAULT);
  const [showSummary, setShowSummary] = useState<boolean>(false);

  function handleCloseHistoryProblems() {
    setShowHistoryProblems({ gatewayId: "" });
  }

  function formatDate(date: number) {
    if (!date) return "";

    const formatted = new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "medium",
    }).format(new Date(date));

    return formatted;
  }

  function formatFlag({ flag, date }: { flag: string; date: number }) {
    if (flag === "Desconectado" || flag === "Morto") {
      const formatedDate = formatDate(date);
      flag = flag + " " + formatedDate;
    }

    return flag;
  }

  function loadData({
    page = 1,
    limit = 1000,
    shouldAppendResults,
  }: {
    page?: number | null;
    limit?: number | null;
    shouldAppendResults?: boolean;
  }) {
    request({
      method: "get",
      queryStringParameters: {
        ...(statusIgnored.length ? { ignore: statusIgnored.join(",") } : {}),
        ...filters,
        page,
        limit,
      },
    }).then((response) => {
      setPagination(response?.pagination);

      const orderedList = response?.list?.sort(
        (a: any, b: any) => a?.isIgnored - b?.isIgnored
      );
      setDetails(response?.details);
      setSummaryByProblem(response?.arraySummaryByFlag);

      let csvData = response?.list
        ?.filter((item: any) => !item.isIgnored)
        .map((item: any) => {
          return {
            GATEWAY_ID: item.gatewayId,
            EMPRESA: item.companyName,
            UNIDADE: item.facilityName,
            STATUS: getLabelStatus(item.status),
            PROBLEMA: PROBLEMS_LABELS[item.flag] || "",
            OBSERVAÇÃO: item.customComment,
            "SENSORES SINCRONIZADOS": item.syncronizedBoards.label,
            "ÚLTIMA CONEXÃO": new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(item?.healthCheckDate - 60 * 60 * 3 * 1000)),
            CONECTADO: item.isConnected.label,
            CONEXÃO: item.connectivity.label,
            "CONEXÃO STATUS": getLabelStatus(item.connectivity.status),
            ALIMENTAÇÃO: item.powerSupply.label,
            "ALIMENTAÇÃO STATUS": getLabelStatus(item.powerSupply.status),
            VERSÃO: item.version.label,
            "VERSÃO STATUS": getLabelStatus(item.version.status),
            "CARTÃO SD": item.sdCardType.label,
            "CARTÃO SD STATUS": getLabelStatus(item.sdCardType.status),
            "MEMÓRIA HEAP LIVRE (%)": formatValueToCsv(
              item?.percentFreeHeap?.label
            ),
            "MEMÓRIA HEAP STATUS": getLabelStatus(item.percentFreeHeap.status),
            "MEMÓRIA PSRAM LIVRE (%)": formatValueToCsv(
              item?.percentFreePSRAM?.label
            ),
            "MEMÓRIA PSRAM STATUS": getLabelStatus(
              item.percentFreePSRAM.status
            ),
            "WIFI RSSI (dBm)": formatValueToCsv(item.wifiRSSI?.label),
            "WIFI RSSI STATUS": formatValueToCsv(item.wifiRSSI.status),
            "GSM RSSI (dBm)": formatValueToCsv(item.gsmRSSI?.label),
            "TEC. DE REDE": item?.networkType?.label,
            "TIPO TEC REDE": item?.networkType?.tooltip,
            OPERADORA: item.gsmType,
            "GSM RSSI STATUS": getLabelStatus(item.gsmRSSI.status),
          };
        });

      if (shouldAppendResults) {
        setList([...list, ...orderedList]);
        setCSV([...csv, ...csvData]);
      } else {
        setList(orderedList);
        setCSV(csvData);
      }
    });
  }

  function handleOpenNotifications() {
    setShowNotifications(true);
  }

  function handleCloseNotifications() {
    setShowNotifications(false);
  }

  useEffect(() => {
    loadData({ page: 1, limit: LIMIT_REQUEST_DATA });
    // eslint-disable-next-line
  }, [filters, statusIgnored]);

  function getNumericValue(value: any) {
    if (value === "N/D") {
      return "";
    }

    if (value && value.toString().includes(".")) {
      return value.toString().replace(".", ",");
    }

    return +value;
  }

  function getLabelStatus(value: number) {
    switch (value) {
      case 0:
        return "Normal";
      case 1:
        return "Alerta";
      case 2:
        return "Risco";
      default:
        return "N/A";
    }
  }

  function getStatusBadge(statusNumber: any) {
    switch (statusNumber) {
      case 0:
        return "success";
      case 1:
        return "warning";
      case 2:
        return "danger";
      default:
        return "";
    }
  }

  function handleFilterChange(name: string, value: any) {
    if (value === null) {
      delete filters[name];
      setFilters({ ...filters });
      return;
    }

    setFilters({ ...filters, [name]: value });
  }

  function sortArray({
    type,
    array,
    order,
    orderedField,
    assistantField,
  }: {
    type: "NUMBER" | "STRING" | "";
    array: any[];
    order: "UP" | "DOWN";
    orderedField: string;
    assistantField?: string;
  }) {
    if (type === "NUMBER") {
      switch (order) {
        case "UP": {
          return array?.sort((a, b) => {
            let aValue = +a[orderedField]?.[assistantField || "label"];

            let bValue = +b[orderedField]?.[assistantField || "label"];

            if (isNaN(aValue)) {
              aValue = 0;
            }

            if (isNaN(bValue)) {
              bValue = 0;
            }

            if (bValue > aValue) {
              return 1;
            }

            return -1;
          });
        }

        case "DOWN": {
          return array?.sort((a, b) => {
            let aValue = +a[orderedField]?.[assistantField || "label"];

            let bValue = +b[orderedField]?.[assistantField || "label"];

            if (isNaN(aValue)) {
              aValue = 0;
            }

            if (isNaN(bValue)) {
              bValue = 0;
            }

            if (bValue < aValue) {
              return 1;
            }

            return -1;
          });
        }
      }
    } else {
      switch (order) {
        case "DOWN": {
          return array?.sort((a, b) => {
            if (b[orderedField] < a[orderedField]) {
              return -1;
            }

            return 1;
          });
        }

        case "UP": {
          return array?.sort((a: any, b: any) => {
            if (a[orderedField] > b[orderedField]) {
              return 1;
            }

            return -1;
          });
        }
      }
    }
  }

  const [order, setOrder] = useState<{
    order: "UP" | "DOWN" | "NONE";
    field: string;
    type: "NUMBER" | "STRING" | "";
    assistantField?: string;
  }>({ field: "", order: "NONE", type: "" });

  const [orderedField, setOrderedField] = useState<string>("");

  const listMemo = useMemo(() => {
    if (order.order !== "NONE" && !!orderedField) {
      if (order.field === "isIgnored" || order.field === "ignoredReason") {
        return sortArray({
          type: order.type,
          array: list,
          order: order.order,
          orderedField: orderedField,
          assistantField: order?.assistantField,
        });
      }

      const ignored = list?.filter((item: any) => item.isIgnored);

      const notIgnored = list?.filter((item: any) => !item.isIgnored);

      const sortIgnored = sortArray({
        type: order.type,
        array: ignored,
        order: order.order,
        orderedField: orderedField,
        assistantField: order?.assistantField,
      });

      const sortNotIgnored = sortArray({
        type: order.type,
        array: notIgnored,
        order: order.order,
        orderedField: orderedField,
        assistantField: order?.assistantField,
      });

      return [...(sortNotIgnored || []), ...(sortIgnored || [])];
    } else {
      return list.sort((a, b) => a.isIgnored - b.isIgnored);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, order, orderedField]);

  function handleOrder({
    field,
    type,
    assistantField,
  }: {
    field: string;
    type: "NUMBER" | "STRING" | "";
    assistantField?: string;
  }) {
    if (field !== order.field) {
      setOrder({ order: "UP", field, type, assistantField });
      setOrderedField(field);
      return;
    }

    switch (order.order) {
      case "NONE": {
        setOrder({ order: "UP", field, type, assistantField });
        break;
      }
      case "UP": {
        setOrder({ order: "DOWN", field, type, assistantField });
        break;
      }
      case "DOWN": {
        setOrder({ order: "NONE", field: "", type, assistantField });
        break;
      }
    }

    setOrderedField(field);
  }

  const ref = useRef<any>(null);
  // Add event list to close dropdown when click outside
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        setShowSummary(false);
      }
    }

    // Add event list on create component
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event list on destroy component
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleSaveCommentWithoutReloading = ({
    gatewayId,
    addedComment,
  }: {
    gatewayId: string;
    addedComment: string;
  }) => {
    const selectedGateway = list.find(
      (gateway: any) => gateway.gatewayId === gatewayId
    );

    const updated = list.map((item: any) => {
      if (item.gatewayId === gatewayId) {
        return { ...selectedGateway, customComment: addedComment };
      }

      return item;
    });

    setList(updated);
  };

  return (
    <PageTemplate>
      <Container>
        <Header>
          <PageTitle>
            Saúde dos bolts ({list.filter((item) => !item.isIgnored).length} /{" "}
            {pagination?.totalData} )
          </PageTitle>
          <div>
            <>
              <Button
                disabled={
                  processing ||
                  list.filter((item) => !item.isIgnored).length ===
                    pagination.totalData
                }
                variant="secondary"
                onClick={() => {
                  pagination.nextPage &&
                    loadData({
                      page: +pagination.nextPage,
                      limit: LIMIT_REQUEST_DATA,
                      shouldAppendResults: true,
                    });
                }}
              >
                Carregar Mais
              </Button>
              <Button variant="secundary" onClick={handleOpenNotifications}>
                Notificações
              </Button>
              <CSVLink
                separator=";"
                style={{ display: "none" }}
                ref={csvRef}
                data={csv}
                filename={`data.csv`}
                className=""
              ></CSVLink>
              <Button
                variant="primary"
                onClick={() => {
                  csvRef.current.link.click();
                }}
              >
                Baixar CSV
              </Button>
            </>
          </div>
        </Header>

        <DetailsContainer>
          <div>
            <DetailsCard>
              <span>NORMAL</span>
              <div>
                <StatusContainer bg="success">
                  {(details?.percentStatusNormal * 100).toFixed(2)}%
                </StatusContainer>
              </div>
            </DetailsCard>
            <DetailsCard>
              <span>ALERTA</span>
              <div>
                <StatusContainer bg="warning">
                  {(details?.percentStatusAlert * 100).toFixed(2)}%
                </StatusContainer>
              </div>
            </DetailsCard>
            <DetailsCard>
              <span>RISCO</span>
              <div>
                <StatusContainer bg="danger">
                  {(details?.percentStatusRisk * 100).toFixed(2)}%
                </StatusContainer>
              </div>
            </DetailsCard>
            <StyledButton onClick={() => setShowSummary(true)}>
              <BiTrophy size={20} />
            </StyledButton>
          </div>

          {showSummary && (
            <RankingContainer ref={ref}>
              <ButtonContainer>
                <StyledButton onClick={() => setShowSummary(false)}>
                  <IoMdClose size={14} />
                </StyledButton>
              </ButtonContainer>
              {summaryByProblem.length ? (
                <SummaryChartByProblemContainer>
                  {summaryByProblem.map((item: any) => {
                    const widthPercentage =
                      String((item.value / summaryByProblem[0].value) * 100) +
                      "%";

                    return (
                      <div>
                        <label>{PROBLEMS_LABELS[item.label]} :</label>
                        <ChartBar width={widthPercentage}>
                          <div>{item.value}</div>
                        </ChartBar>
                      </div>
                    );
                  })}
                </SummaryChartByProblemContainer>
              ) : null}
            </RankingContainer>
          )}
        </DetailsContainer>

        <HealthCheckDynamicStatus
          ignored={statusIgnored}
          setIgnored={setStatusIgnored}
          items={GATEWAY_DYNAMIC_STATUS}
        />
        <FilterContainer>
          <DeviceHealthSelect
            label="Status"
            value={filters?.status}
            options={STATUS_OPTIONS}
            handleChange={(e) => handleFilterChange("status", e.value)}
          />
          <DeviceHealthSelect
            label="Conexão"
            value={filters?.connectivity}
            options={STATUS_OPTIONS}
            handleChange={(e) => handleFilterChange("connectivity", e.value)}
          />
          <DeviceHealthSelect
            label="Cartão SD"
            value={filters?.sdCardType}
            options={STATUS_OPTIONS}
            handleChange={(e) => handleFilterChange("sdCardType", e.value)}
          />
          <DeviceHealthSelect
            label="Alimentação"
            value={filters?.powerSupply}
            options={STATUS_OPTIONS}
            handleChange={(e) => handleFilterChange("powerSupply", e.value)}
          />
          <DeviceHealthSelect
            label="Versão"
            value={filters?.version}
            options={VERSION_OPTIONS}
            handleChange={(e) => handleFilterChange("version", e.value)}
          />
          <DeviceHealthSelect
            label="PSRAM livre"
            value={filters?.percentFreePSRAM}
            options={STATUS_OPTIONS}
            handleChange={(e) =>
              handleFilterChange("percentFreePSRAM", e.value)
            }
          />
          <DeviceHealthSelect
            label="Mem. Heap Livre"
            value={filters?.percentFreeHeap}
            options={STATUS_OPTIONS}
            handleChange={(e) => handleFilterChange("percentFreeHeap", e.value)}
          />
          <DeviceHealthSelect
            label="GSM Rssi"
            value={filters?.gsmRSSI}
            options={STATUS_OPTIONS}
            handleChange={(e) => handleFilterChange("gsmRSSI", e.value)}
          />
          <DeviceHealthSelect
            label="WIFI Rssi"
            value={filters?.wifiRSSI}
            options={STATUS_OPTIONS}
            handleChange={(e) => handleFilterChange("wifiRSSI", e.value)}
          />
          <Button
            onClick={() => {
              setFilters({});
              setStatusIgnored(IGNORED_DEFAULT);
            }}
          >
            Resetar
          </Button>
        </FilterContainer>
        <TableContainer>
          {processing ? (
            <div>Carregando..</div>
          ) : (
            <ReactTable
              responsive
              data={listMemo || []}
              columns={[
                {
                  id: "status",
                  accessor: "status",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>STATUS </span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({ field: "status", type: "STRING" })
                          }
                          order={order}
                          field={"status"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    let status = "success";
                    let statusLabel = "NORMAL";
                    if (value === 1) {
                      status = "warning";
                      statusLabel = "ALERTA";
                    } else if (value === 2) {
                      status = "danger";
                      statusLabel = "RISCO";
                    }
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={status}>
                          {statusLabel}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "flag",
                  accessor: "flag",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>PROBLEMA</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({ field: "flag", type: "STRING" })
                          }
                          order={order}
                          field={"flag"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    if (!value) {
                      return (
                        <TableValue disabled={original.isIgnored}>-</TableValue>
                      );
                    }

                    return (
                      <ClickableTableValue
                        onClick={() =>
                          setShowHistoryProblems({
                            gatewayId: original.gatewayId,
                          })
                        }
                      >
                        <TableValue disabled={original.isIgnored}>
                          <StatusContainer bg={getStatusBadge(original.status)}>
                            {formatFlag({
                              flag: PROBLEMS_LABELS[value],
                              date: original.healthCheckDate,
                            })}
                          </StatusContainer>
                        </TableValue>
                      </ClickableTableValue>
                    );
                  },
                },
                {
                  id: "customComment",
                  accessor: "customComment",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Observação</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "customComment",
                              type: "STRING",
                            })
                          }
                          order={order}
                          field={"customComment"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <div>
                        <AddComment
                          gatewayId={original.gatewayId}
                          value={value}
                          refresh={handleSaveCommentWithoutReloading}
                        />
                      </div>
                    );
                  },
                },
                {
                  id: "gatewayId",
                  accessor: "gatewayId",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>GATEWAY ID</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({ field: "gatewayId", type: "STRING" })
                          }
                          order={order}
                          field={"gatewayId"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}{" "}
                        <MdOpenInNew
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_RETINA_INFRA_URL}/summary/gateways/${value}/history`,
                              "_blank"
                            )
                          }
                        />
                      </TableValue>
                    );
                  },
                },
                {
                  id: "companyName",
                  accessor: "companyName",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>EMPRESA</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "companyName",
                              type: "STRING",
                            })
                          }
                          order={order}
                          field={"companyName"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                      </TableValue>
                    );
                  },
                },
                {
                  id: "facilityName",
                  accessor: "facilityName",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>UNIDADE</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "facilityName",
                              type: "STRING",
                            })
                          }
                          order={order}
                          field={"facilityName"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}{" "}
                        <MdOpenInNew
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${original.companyId}/facilities/${original.facilityId}/assets?sidetab=bolt`,
                              "_blank"
                            )
                          }
                        />
                      </TableValue>
                    );
                  },
                },
                {
                  id: "syncronizedBoards",
                  accessor: "syncronizedBoards",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>SENSORES SINCRONIZADOS</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "syncronizedBoards",
                              type: "NUMBER",
                              assistantField: "label",
                            })
                          }
                          order={order}
                          field={"syncronizedBoards"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer
                          bg={getStatusBadge(status)}
                          fontSize={"12px"}
                        >
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "isConnected",
                  accessor: "isConnected",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>CONECTADO</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "isConnected",
                              type: "NUMBER",
                              assistantField: "status",
                            })
                          }
                          order={order}
                          field={"isConnected"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "connectivity",
                  accessor: "connectivity",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>CONEXÃO (%)</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "connectivity",
                              type: "NUMBER",
                            })
                          }
                          order={order}
                          field={"connectivity"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    label = `${label}%`;
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "sdCardType",
                  accessor: "sdCardType",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>CARTÃO SD</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "sdCardType",
                              type: "NUMBER",
                              assistantField: "status",
                            })
                          }
                          order={order}
                          field={"sdCardType"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "powerSupply",
                  accessor: "powerSupply",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>ALIMENTAÇÃO</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "powerSupply",
                              type: "NUMBER",
                              assistantField: "status",
                            })
                          }
                          order={order}
                          field={"powerSupply"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "version",
                  accessor: "version",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>VERSÃO</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "version",
                              type: "NUMBER",
                              assistantField: "status",
                            })
                          }
                          order={order}
                          field={"version"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    if (status === 0) {
                      label = label + " Recomendada";
                    }
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "percentFreePSRAM",
                  accessor: "percentFreePSRAM",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>MEMÓRIA PSRAM</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "percentFreePSRAM",
                              type: "NUMBER",
                            })
                          }
                          order={order}
                          field={"percentFreePSRAM"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    if (!isNaN(status)) {
                      label += "%";
                    }
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "percentFreeHeap",
                  accessor: "percentFreeHeap",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>MEMÓRIA HEAP</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "percentFreeHeap",
                              type: "NUMBER",
                            })
                          }
                          order={order}
                          field={"percentFreeHeap"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    if (!isNaN(status)) {
                      label += "%";
                    }
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "wifiRSSI",
                  accessor: "wifiRSSI",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>WIFI RSSI</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "wifiRSSI",
                              type: "NUMBER",
                              assistantField: "label",
                            })
                          }
                          order={order}
                          field={"wifiRSSI"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    if (!isNaN(status) && status !== 999) {
                      label += "dBm";
                    }

                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "gsmRSSI",
                  accessor: "gsmRSSI",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>GSM RSSI</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "gsmRSSI",
                              type: "NUMBER",
                              assistantField: "label",
                            })
                          }
                          order={order}
                          field={"gsmRSSI"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    if (!isNaN(status) && status !== 999) {
                      label += "dBm";
                    }

                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer bg={getStatusBadge(status)}>
                          {label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "networkType",
                  accessor: "networkType",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Tec. Rede</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "networkType",
                              type: "NUMBER",
                              assistantField: "status",
                            })
                          }
                          order={order}
                          field={"networkType"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label, tooltip },
                    row: { original },
                  }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <Tooltip tooltip={tooltip} position={"top"}>
                          <StatusContainer bg={getStatusBadge(status)}>
                            {label || ""}
                          </StatusContainer>
                        </Tooltip>
                      </TableValue>
                    );
                  },
                },

                {
                  id: "gsmType",
                  accessor: "gsmType",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>OPERADORA</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "gsmType",
                              type: "STRING",
                            })
                          }
                          order={order}
                          field={"gsmType"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                      </TableValue>
                    );
                  },
                },

                {
                  id: "consumptionCurrentDay",
                  accessor: "consumptionCurrentDay",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Consumo Hoje</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "consumptionCurrentDay",
                              type: "NUMBER",
                              assistantField: "label",
                            })
                          }
                          order={order}
                          field={"consumptionCurrentDay"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer
                          bg={getStatusBadge(status)}
                          fontSize={"12px"}
                        >
                          {label ? label + " gB" : label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "consumption",
                  accessor: "consumption",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Consumo</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "consumption",
                              type: "NUMBER",
                              assistantField: "label",
                            })
                          }
                          order={order}
                          field={"consumption"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer
                          bg={getStatusBadge(status)}
                          fontSize={"12px"}
                        >
                          {label ? label + " gB" : label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "consumptionPercent",
                  accessor: "consumptionPercent",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Consumo (%)</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "consumptionPercent",
                              type: "NUMBER",
                              assistantField: "label",
                            })
                          }
                          order={order}
                          field={"consumptionPercent"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer
                          bg={getStatusBadge(status)}
                          fontSize={"12px"}
                        >
                          {label ? label + " %" : label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "consumptionValidated",
                  accessor: "consumptionValidated",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>Consumo Validado</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "consumptionValidated",
                              type: "NUMBER",
                              assistantField: "label",
                            })
                          }
                          order={order}
                          field={"consumptionValidated"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({
                    value: { status, label },
                    row: { original },
                  }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <StatusContainer
                          bg={getStatusBadge(status)}
                          fontSize={"12px"}
                        >
                          {label ? label + "gB" : label}
                        </StatusContainer>
                      </TableValue>
                    );
                  },
                },
                {
                  id: "isIgnored",
                  accessor: "isIgnored",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>VÁLIDO</span>
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        <DeviceHealthIgnoreButton
                          isIgnored={value}
                          gatewayId={original.gatewayId}
                          refresh={() =>
                            loadData({ page: 1, limit: LIMIT_REQUEST_DATA })
                          }
                        />
                      </TableValue>
                    );
                  },
                },

                {
                  id: "ignoredReason",
                  accessor: "ignoredReason",
                  Header: () => (
                    <ColumnHeader>
                      <div>
                        <span>MOTIVO</span>
                        <OrderButton
                          handleClick={() =>
                            handleOrder({
                              field: "ignoredReason",
                              type: "STRING",
                            })
                          }
                          order={order}
                          field={"ignoredReason"}
                        />
                      </div>
                    </ColumnHeader>
                  ),
                  Cell: ({ value, row: { original } }: any) => {
                    return (
                      <TableValue disabled={original.isIgnored}>
                        {value}
                      </TableValue>
                    );
                  },
                },
              ]}
            />
          )}
        </TableContainer>
        {!!showHistoryProblems.gatewayId && (
          <ProblemsHistoryModal
            gatewayId={showHistoryProblems.gatewayId}
            onClose={handleCloseHistoryProblems}
          />
        )}

        {showNotifications && (
          <NotificationsModal
            onClose={handleCloseNotifications}
            show={showNotifications}
          />
        )}
      </Container>
    </PageTemplate>
  );
};
