import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";

import { CSVLink } from "react-csv";

import { Button, PageTemplate } from "../../components";

import useApi from "../../hooks/useApi";

import { Container, Header } from "./styles";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import GatewaysProblemsFilter from "../GatewaysProblems/GatewaysProblemsFilter";
import { ChartContainer } from "../GatewaysProblems/styles";
import { PageTitle } from "../List/styles";
import {
  STATUS_CONFIG,
  ENDPOINT_STATUS_PROBLEMS_CONFIG,
  STATUS_LABEL,
  ENDPOINT_DEAD_ALIVE_SERIES_NAMES,
} from "./types";
import HealthCheckDynamicStatus from "../GatewayHealthCheck/HeathCheckDynamicStatus";
import SensorsStatusTable from "./SensorsStatusTable";
import { SENSORS_DYNAMIC_STATUS } from "../SensorsHealth";
import { Spinner } from "react-bootstrap";
import { DEAD_ALIVE_CONFIG } from "../GatewaysStatus/types";

const FIELD_TO_TYPE: any = {
  connectivity: "CONNECTION",
  sdCardType: "SD_CARD",
  powerSupply: "POWER_SUPPLY",
  version: "VERSION",
  percentFreePSRAM: "PSRAM",
  percentFreeHeap: "HEAP_MEMORY",
  gsmRSSI: "GSM_RSSI",
  wifiRSSI: "WIFI_RSSI",
};

const ENABLE_LABELS = true;

const IGNORED_DEFAULT: string[] = [];

const SERIES_NAME = [
  "Normal",
  "Alerta",
  "Risco",
  "Bolt Off",
  "RSSI",
  "Consumo Excessivo",
  "Temperatura Negativa",
  "Baixa Performance",
  "Desconectado",
];

export const SensorsStatus = () => {
  const { darkMode } = useAppSelector(appSelect);

  const { request, processing } = useApi({
    path: "",
  });

  const csvRef = useRef<any>(null);

  const [data, setData] = useState<any>(null);
  const [list, setList] = useState<any>([]);
  const [filters, setFilters] = useState<any>({});
  const [statusIgnored, setStatusIgnored] = useState<any>(IGNORED_DEFAULT);

  const [chartType, setChartType] = useState<
    "CHART-PROBLEMS" | "CHART" | "DEAD-ALIVE"
  >("CHART");

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  function handleChangeChartType(
    chart: "CHART-PROBLEMS" | "CHART" | "DEAD-ALIVE"
  ) {
    setChartType(chart);
  }

  const handleClickDeadAliveSerie = useCallback(
    ({ date, serie }: { date: string; serie: string }) => {
      const [day, month, year] = date.split("/");

      const formatedDate = `${year}-${month}-${day}`;

      let epoch = new Date(formatedDate).getTime();

      const hours = new Date(epoch).getHours();

      if (hours !== 0) {
        epoch = epoch + 60 * 60 * 1000 * 3;
      }

      const serieName = ENDPOINT_DEAD_ALIVE_SERIES_NAMES[serie];

      request({
        method: "get",
        pathParameters: `monitorator/endpoints-dead-alive-csv/${epoch}/series/${serieName}`,
      }).then((response) => {
        const sorted = response.sort((a: any, b: any) => {
          return a.companyName.localeCompare(b.companyName);
        });

        setList(sorted);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processing]
  );

  const statusValue: any = {
    Normal: 0,
    Alerta: 1,
    Risco: 2,
  };

  useEffect(() => {
    if (!processing) {
      if (queryParams.minDate && !queryParams.maxDate) return;

      request({
        method: "get",
        pathParameters: "monitorator/sensors-status",
        queryStringParameters: {
          ...queryParams,
          ...(statusIgnored.length
            ? { ignore: statusIgnored.join(",") }
            : { ignore: undefined }),
          ...filters,
        },
      }).then((response) => {
        // let csvData;
        // csvData = response.csv.map((item: any) => {
        //   return {
        //     DIA: new Intl.DateTimeFormat("pt-BR", {
        //       dateStyle: "short",
        //       timeStyle: "medium",
        //     }).format(new Date(item.date)),
        //     "BOARD ID": item.boardId,
        //     PONTO: item.positionName,
        //     STATUS: STATUS_LABEL[item.status],
        //     PROBLEMA: ENDPOINT_STATUS_PROBLEMS_CONFIG[item.flag]?.label || "",
        //     EMPRESA: item.companyName,
        //     UNIDADE: item.facilityName,
        //   };
        // });

        setData({
          chart: response.chartStatus,
          // csv: csvData,
          table: response.csv,
          chartProblems: response.chartProblems || [],
          deadAlive: response.chartDeadAlive,
        });
      });
    }

    // eslint-disable-next-line
  }, [darkMode, location.search, filters, statusIgnored]);

  const [selectedSeries, setSelectedSeries] = useState<string[]>(SERIES_NAME);

  const handleSaveSelectedSeries = useCallback(({ selected }: any) => {
    const filtered = Object.entries(selected)
      .filter(([key, value]) => value)
      .map(([keyMap, valueMap]) => {
        return keyMap;
      });
    setSelectedSeries(filtered);
  }, []);

  const onEvents = useMemo(
    () => {
      return {
        click: async (params: any) => {
          const key = params?.data?.name;
          const date = params?.data?.value?.[0];

          switch (chartType) {
            case "CHART": {
              setList([]);

              await request({
                method: "get",
                pathParameters: "monitorator/sensor-status",
                queryStringParameters: {
                  ignore: "",
                  date,
                  status: statusValue[key],
                  ...filters,
                },
              }).then((response) => {
                const sorted = response.sort((a: any, b: any) => {
                  return a.companyName?.localeCompare(b.companyName);
                });

                setList(sorted);
              });

              break;
            }

            case "CHART-PROBLEMS": {
              const keyProblemName = Object.entries(
                ENDPOINT_STATUS_PROBLEMS_CONFIG
              ).find(([chave, value]: any) => value.label === key)?.[0];

              await request({
                method: "get",
                pathParameters: "monitorator/sensor-status",
                queryStringParameters: {
                  ignore: "",
                  date,
                  problem: keyProblemName,
                  ...filters,
                },
              }).then((response) => {
                const sorted = response.sort((a: any, b: any) => {
                  return a.companyName?.localeCompare(b.companyName);
                });

                setList(sorted);
              });

              break;
            }

            case "DEAD-ALIVE": {
              const { seriesName: serie, name: date, value } = params;

              if (value) {
                handleClickDeadAliveSerie({ serie, date });
              }
              break;
            }
          }
        },
        legendselectchanged: handleSaveSelectedSeries,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, chartType]
  );

  const series: any[] = useMemo(() => {
    return data?.chart
      ? Object.keys(data?.chart || {}).map((item, index) => {
          return {
            name: STATUS_CONFIG[item].label,
            color: STATUS_CONFIG[item].color,
            data: (data.chart[item] || []).map((i: any) => ({
              name: STATUS_CONFIG[item].label,
              value: i,
            })),
            type: "bar",
            stack: "Ad",
            showSymbol: false,
            endLabel: {
              show: true,
              formatter: function (params: any) {
                return `${params.seriesName}: ${params.value[1]}`;
              },
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            lineStyle: {
              width: 3,
            },
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const seriesByProblems: any[] = useMemo(() => {
    return data?.chartProblems
      ? Object.keys(data?.chartProblems || {}).map((item, index) => {
          return {
            name: ENDPOINT_STATUS_PROBLEMS_CONFIG[item]?.label,
            color: ENDPOINT_STATUS_PROBLEMS_CONFIG[item]?.color,
            data: (data.chartProblems[item] || []).map((i: any) => ({
              name: ENDPOINT_STATUS_PROBLEMS_CONFIG[item]?.label,
              value: i,
            })),
            type: "bar",
            stack: "Ad",
            showSymbol: false,
            endLabel: {
              show: true,
              formatter: function (params: any) {
                return `${params.seriesName}: ${params.value[1]}`;
              },
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            lineStyle: {
              width: 3,
            },
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartType]);

  function genFormatter(series: any[]) {
    return (param: any) => {
      if (!ENABLE_LABELS) {
        return "";
      }
      let sum = 0;
      const onlySelectedSerie = series.filter((item: any) =>
        selectedSeries.includes(item.name)
      );

      onlySelectedSerie.forEach((item) => {
        sum += item.data[param.dataIndex].value[1];
      });
      return sum || "";
    };
  }

  function percentFormater(series: any[]) {
    return (param: any) => {
      if (!ENABLE_LABELS) {
        return "";
      }
      let sum = 0;
      series.forEach((item) => {
        sum += item.data[param.dataIndex].value[1];
      });
      if (!sum || !param.data.value[1]) {
        return "";
      }
      return `${+((param.data.value[1] / sum) * 100).toFixed(2)}%`;
    };
  }

  const echartsOptionsDeadAlive = useMemo(() => {
    const color = darkMode ? "#FFF" : "#000";

    return {
      grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["Forest", "Steppe", "Desert", "Wetland"],
        textStyle: { color },
        right: "right",
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: data?.deadAlive?.dates || [],
          nameTextStyle: {
            color,
          },
          axisLabel: {
            color,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            color,
          },
          nameTextStyle: {
            color,
          },
          max: data?.deadAlive?.series?.[0]?.maxValue + 10 || 30,
        },
      ],
      series: data?.deadAlive?.series?.map(
        (item: {
          name: string;
          data: any[];
          maxValue: number;
          markPoints: any;
        }) => {
          return {
            name: item.name,
            type: "bar",
            color: DEAD_ALIVE_CONFIG[item.name]?.color,
            barGap: 0,
            endLabel: {
              show: true,
              formatter: function (params: any) {
                return `${params.seriesName}: ${params.value[1]}`;
              },
            },
            label: {
              show: ENABLE_LABELS,
              fontSize: 15,
              color: "white",
              position: "inside",
            },
            emphasis: {
              focus: "series",
            },
            data: item.data,
            lineStyle: {
              width: 5,
            },
          };
        }
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartType]);

  const echartsOptions = useMemo(() => {
    if (!data?.chart || !Object.keys(data.chart).length) {
      return null;
    }

    if (chartType === "DEAD-ALIVE") {
      return echartsOptionsDeadAlive;
    }

    let currentSeries = series;
    if (chartType === "CHART-PROBLEMS") {
      currentSeries = seriesByProblems;
    }

    const color = darkMode ? "#FFF" : "#000";

    return {
      grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
      legend: {
        data: Object.keys(data?.chart || {}).map(
          (item) => STATUS_CONFIG[item].label
        ),
        textStyle: { color },
        right: "right",
      },
      xAxis: {
        type: "time",
        nameTextStyle: {
          color,
        },
        axisLabel: {
          color,
          formatter: function (value: any) {
            const day = new Date(value).getDate();
            const monthNumber = new Date(value).getMonth() + 1;
            const month = monthNumber.toString().padStart(2, "0");
            return `${day}/${month}`;
          },
        },
      },
      yAxis: {
        axisLabel: {
          color,
        },
        nameTextStyle: {
          color,
        },
      },
      darkMode: true,
      series: currentSeries
        .map((item, index) =>
          Object.assign(item, {
            type: "bar",
            stack: "Ad",
            label: {
              show: ENABLE_LABELS,
              formatter: percentFormater(currentSeries),
              fontSize: 15,
              color: "white",
              position: "inside",
            },
          })
        )
        .concat([
          {
            show: false,
            name: "Total",
            type: "bar",
            stack: "Ad",
            data: currentSeries[0]?.data?.map((item: any) => {
              return {
                name: "Total",
                value: [item.value[0], 0],
              };
            }),
            label: {
              show: ENABLE_LABELS,
              position: "top",
              formatter: genFormatter(currentSeries),
              fontSize: 20,
              color: "white",
            },
            tooltip: {
              show: false,
            },
          },
        ]),
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
        },
      },
      animationDuration: 1000,
    };
    // eslint-disable-next-line
  }, [data, darkMode, chartType, selectedSeries]);

  useEffect(() => {
    setList([]);
  }, [chartType]);

  return (
    <PageTemplate>
      <Container>
        <Header>
          <PageTitle>
            Histórico dos Sensores{" "}
            <Button
              variant={chartType === "CHART" ? "primary" : "secundary"}
              onClick={() => handleChangeChartType("CHART")}
            >
              Por Status
            </Button>
            <Button
              variant={chartType === "CHART-PROBLEMS" ? "primary" : "secundary"}
              onClick={() => handleChangeChartType("CHART-PROBLEMS")}
              disabled={processing}
            >
              Por Problema
            </Button>{" "}
            <Button
              variant={chartType === "DEAD-ALIVE" ? "primary" : "secundary"}
              onClick={() => handleChangeChartType("DEAD-ALIVE")}
              disabled={processing}
            >
              Mortos
            </Button>
            {processing && <Spinner animation={"border"} />}
          </PageTitle>
          <div>
            {/* <CSVLink
              separator=";"
              style={{ display: "none" }}
              ref={csvRef}
              data={data?.csv || []}
              filename={`data.csv`}
              className=""
            ></CSVLink> */}

            {/* <Button
              variant="primary"
              onClick={() => {
                csvRef.current.link.click();
              }}
            >
              Baixar CSV
            </Button> */}
          </div>
        </Header>
        {chartType !== "DEAD-ALIVE" && (
          <HealthCheckDynamicStatus
            ignored={statusIgnored}
            setIgnored={setStatusIgnored}
            items={SENSORS_DYNAMIC_STATUS}
          />
        )}
        <GatewaysProblemsFilter
          filters={filters}
          setFilters={setFilters}
          type="STATUS"
          hideGateweayId
          hideFilters={chartType === "DEAD-ALIVE"}
        />
        <ChartContainer>
          {data?.chart && (
            <ReactECharts option={echartsOptions} onEvents={onEvents} />
          )}
        </ChartContainer>
        {!!list?.length && (
          <SensorsStatusTable
            list={list}
            changeColumnLabel={chartType === "CHART" ? "" : "Problema"}
          />
        )}
      </Container>
    </PageTemplate>
  );
};
