import { isNumber } from "lodash";
import { RecordProps } from "../../../../types/default";
import { ListContainer } from "./styles";

export const ListLastCollect = ({ id, record }: RecordProps) => {
  const { lastCollect } = record;
  const { signalQuality, sk, batteryVoltage, rssi, gatewayId } =
    lastCollect || {};

  if (!lastCollect) {
    return (
      <ListContainer>
        <span>Sem coleta</span>
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      <div>
        <span>Data: </span>
        <span>
          {isNumber(sk) &&
            sk.toString().length > 11 &&
            sk.toString().length < 15 &&
            new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(+sk))}
        </span>
      </div>
      <div>
        <span>Qualidade do sinal: </span>
        <span>{(signalQuality * 100).toFixed(2)}%</span>
      </div>
      <div>
        <span>Bateria: </span>
        <span>{batteryVoltage?.toFixed(2)}V</span>
      </div>
      <div>
        <span>Bolt: </span>
        <span>{gatewayId}</span>
      </div>
      <div>
        <span>RSSI: </span>
        <span>{rssi?.toFixed(2)}</span>
      </div>
    </ListContainer>
  );
};
