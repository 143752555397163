import { Routes as RRDRoutes, Route as RRDRoute } from "react-router-dom";

import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { NewPassword } from "../pages/NewPassword";
import { NotFoundRoute } from "./NotFountRoute";
import { Growth } from "../pages/Growth";

import { Route } from "./Route";

import { CONFIGURATOR_ROUTES } from "../modules/configurator";
import { WEB_ROUTES } from "../modules/web";
import { INFRA_ROUTES } from "../modules/infra";
import { MONITORATOR_ROUTES } from "../modules/monitorator";
import { SUMMARY_ROUTES } from "../modules/summary";
import { SummaryHistory } from "../pages/SummaryHistory";
import ResultsAI from "../pages/ResultsAI";
import { Occurrences } from "../pages/Occurrences";
import { FacilityStatus } from "../pages/FacilityStatus";
import { GatewayHealthCheck } from "../pages/GatewayHealthCheck";
import { GatewaysProblems } from "../pages/GatewaysProblems";
import { GatewaysStatus } from "../pages/GatewaysStatus";
import { SensorsHealth } from "../pages/SensorsHealth";
import { SensorsStatus } from "../pages/SensorsStatus";
import QualityInspections from "../pages/QualityInspections";
import { QUALITY_ROUTES } from "../modules/quality";

const APP_ROUTES = [
  {
    path: "/login",
    guest: true,
    component: <Login />
  },
  {
    path: "/new-password",
    guest: true,
    newPassword: true,
    component: <NewPassword />
  },
  {
    path: "/",
    private: true,
    component: <Home />
  },
  {
    path: "/summary/endpoints/:boardId/history",
    private: true,
    component: <SummaryHistory type="ENDPOINTS" />
  },
  {
    path: "/summary/gateways/:gatewayId/history",
    private: true,
    component: <SummaryHistory type="GATEWAYS" />
  },
  {
    path: "/monitorator/growth",
    private: true,
    component: <Growth />
  },
  {
    path: "/ai/results",
    private: true,
    component: <ResultsAI />
  },
  {
    path: "/monitorator/occurrences",
    private: true,
    component: <Occurrences />
  },
  {
    path: "/monitorator/gateway-health-check",
    private: true,
    component: <GatewayHealthCheck />
  },
  {
    path: "/monitorator/health-status-problems",
    private: true,
    component: <GatewaysProblems />
  },
  {
    path: "/monitorator/gateways-status",
    private: true,
    component: <GatewaysStatus />
  },
  {
    path: "/monitorator/facility-status",
    private: true,
    component: <FacilityStatus />
  },
  {
    path: "/monitorator/sensors-health",
    private: true,
    component: <SensorsHealth />
  },
  {
    path: "/monitorator/sensors-status",
    private: true,
    component: <SensorsStatus />
  },
  {
    path: "/quality/inspection/:id",
    private: true,
    component: <QualityInspections />
  },
  ...CONFIGURATOR_ROUTES,
  ...WEB_ROUTES,
  ...INFRA_ROUTES,
  ...MONITORATOR_ROUTES,
  ...SUMMARY_ROUTES,
  ...QUALITY_ROUTES
];

export const Routes = () => {
  return (
    <RRDRoutes>
      {APP_ROUTES.map((route: any, index) => (
        <RRDRoute
          key={index}
          path={route.path}
          element={
            <Route isGuest={route.guest} isPrivate={route.private}>
              {route.component}
            </Route>
          }
        />
      ))}

      <RRDRoute path="*" element={<NotFoundRoute />} />
    </RRDRoutes>
  );
};
