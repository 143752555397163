export const GatewayFirmwares = {
  title: "Bolt Firmwares",
  urlPath: "/configurator/gateway-firmwares",
  apiPath: "/configurator/gateway-firmwares",
  actions: {
    edit: { isVisible: true },
    show: { isVisible: false },
  },
  properties: {
    version: {
      id: "version",
      label: "Versão",
      type: "TEXT",
      isPrimaryKey: true,
    },
    url: {
      id: "url",
      label: "Link no s3",
      type: "TEXT",
      visible: {
        edit: false,
      },
    },
    status: {
      id: "status",
      label: "Status",
      type: "SELECT",
      options: [
        { label: "Selecione", value: null },
        { label: "Recomendada", value: "recommended" },
        { label: "Suportada", value: "supported" },
        { label: "Descontinuada", value: "discontinued" },
      ],
    },
  },
};
