import styled from "styled-components";

export const ProfileButton = styled.div`
  color: ${(props) => props.theme.colors.ibbxOrange};
  background-color: ${(props) => props.theme.colors.cardBackground};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;

  border-radius: 100%;
  cursor: pointer;
`;

export const DarkmodeButton = styled.div`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 26px;
  border-radius: 20px;
  &:hover {
    background-color: ${(props) => props.theme.colors.cardBackground};
  }
`;

export const DarkmodeArea = styled.div`
  width: 100%;
  height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 0 !important;
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  &:hover {
    background-color: ${(props) => props.theme.colors.cardBackground};
  }
`;

export const MenuSep = styled.div`
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.cardHighlight};
  margin-bottom: 18px;
`;

export const Container = styled.div`
  width: 208px;

  background-color: ${(props) => props.theme.colors.headerBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.iconsPrimary};

  padding: 16px;

  p {
    margin: 0;
    margin-bottom: 16px;
  }

  z-index: 2;
`;

export const LogoutQuestion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.iconsPrimary};
`;
