import { useParams } from "react-router-dom";
import { List } from "../List";
import { Endpoints } from "../../modules/summary/resources/Endpoints";
import { Gateways } from "../../modules/summary/resources/Gateways";
import { RecordProps } from "../../types/default";

type SummaryHistoryProps = {
  type: "ENDPOINTS" | "GATEWAYS";
};

function dateFormat(date: any) {
  if (!isNaN(date)) {
    date = +date;
  }

  if (date) {
    return new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "short",
    }).format(new Date(date));
  }

  return "";
}

export const SummaryHistory = ({ type }: SummaryHistoryProps) => {
  const params = useParams();
  const { boardId, gatewayId } = params || {};
  const baseResource: any = type === "ENDPOINTS" ? Endpoints : Gateways;
  const path =
    type === "ENDPOINTS"
      ? `/summary/endpoint-history/${boardId}`
      : `/summary/gateway-history/${gatewayId}`;

  const title =
    type === "ENDPOINTS"
      ? `Endpoint ${boardId} Histórico`
      : `Gateway ${gatewayId} Histórico`;

  if (type === "ENDPOINTS") {
    baseResource.properties.isRecall.isVisible.list = true;
    baseResource.properties.isRecall.isVisible.filter = true;
    baseResource.properties.lastRecallAt.isVisible.list = false;
    baseResource.properties.lastRecallAt.isVisible.filter = false;
  }

  delete baseResource.properties.lastUpdate;

  const resource = {
    title: title,
    actions: {
      new: { isVisible: false },
      show: { isVisible: false },
      edit: { isVisible: false },
      delete: { isVisible: false },
      csv: { isVisible: true },
    },
    apiPath: path,
    properties: {
      date: {
        id: "date",
        label: "DATA",
        isVisible: {
          list: true,
        },
        component: {
          list: ({ record, id }: RecordProps) => (
            <span style={{ color: "#FFF" }}>{dateFormat(record[id])}</span>
          ),
        },
      },
      ...baseResource.properties,
    },
  };

  return <List resource={resource} />;
};
