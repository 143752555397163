import styled from "styled-components";
import { Modal } from "../../components/Modal";

interface IDeadAliveComment {
  onClose: () => void;
  isOpen: boolean;
  handleConfirm: () => void;
  comment: string;
  serie: string;
  date: string;
}

export const DeadAliveComment = ({
  handleConfirm,
  isOpen,
  onClose,
  comment,
  serie,
  date,
}: IDeadAliveComment) => {
  return (
    <Modal
      handleClose={onClose}
      show={isOpen}
      size="lg"
      handleConfirm={handleConfirm}
      hideSaveButton
    >
      <Container>
        <h4>
          Comentário - {serie} - {date}
        </h4>
        <label>{comment}</label>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  h4 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }

  & > label {
    width: 100%;
    min-height: 5rem;
    background-color: ${({ theme }) => theme.colors.background};
    padding: 4px;
    border-radius: 6px;
  }
`;
