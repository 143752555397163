import { ListContainer } from "../styles";
import { ListProps } from "../types";

export const ListPositionAcquisitionDetails = ({ record }: ListProps) => {
  return (
    <ListContainer>
      <div>
        <span>UUID: </span>
        <span>{record.activatorId}</span>
      </div>
      <div>
        <span>Ultimo envio: </span>
        <span>
          {record.lastAcquisitionDate &&
            new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(record.lastAcquisitionDate))}
        </span>
      </div>
      <div>
        <span>Quantidade de envios: </span>
        <span>{record.countAcquisitions}</span>
      </div>
      <div>
        <span>Bateria: </span>
        <span style={{ color: record.battery === "LOW" ? "red" : "green" }}>
          {record.battery === "LOW" ? "Baixa" : "Alta"}
        </span>
      </div>
      <div>
        <span>Sinal: </span>
        <span style={{ color: record.battery === "LOW" ? "red" : "green" }}>
          {record.connectivity === "LOW" ? "Baixo" : "Alto"}
        </span>
      </div>
    </ListContainer>
  );
};
