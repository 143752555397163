import { PropertyContainer, PropertyLabel, PropertyValue } from "../styles";
import { ShowProps } from "../types";

export const ShowUserStatus = ({ record, label, id }: ShowProps) => {
  let userStatus;

  switch (record[id]) {
    case "CONFIRMED":
      userStatus = "CONFIRMADO";
      break;

    default:
      break;
  }

  return (
    <PropertyContainer key={id}>
      <PropertyLabel>{label}</PropertyLabel>
      <PropertyValue>{userStatus}</PropertyValue>
    </PropertyContainer>
  );
};
