/**
 * VER MAIS SOBRE AMPLIFY E COGNITO EM
 * https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/
 */

import { Auth } from "aws-amplify";
import { store } from "./store";

export const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_ENDPOINT_NAME,
        endpoint: process.env.REACT_APP_ENDPOINT_URL,
        custom_header: async () => {
          // try get current session
          let currentSession;
          try {
            currentSession = await Auth.currentSession();
          } catch (error) {
            // Redirect to home
            window.location.reload();
            return
          }

          // If not have current session redirect to home
          if (!currentSession) {
            window.location.reload();
          }

          const { email } = currentSession.getIdToken().payload;
          const user = store.getState().user;

          if (email !== user.email) {
            window.location.reload();
          }

          const token = currentSession.getIdToken().getJwtToken();

          return { Authorization: `Bearer ${token}` }; // recupera o token ativo
        },
      },
    ],
  },
};
