import styled from "styled-components";

interface IMessage {
  children: JSX.Element;
}

export default function Message({ children }: IMessage) {
  return <MessageContainer>{children}</MessageContainer>;
}

const MessageContainer = styled.div`
  padding: 8px;
  padding-top: 12px;
  width: 100%;
  display: flex;
  justify-content: center;

  strong {
    text-align: center;
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
  }
`;
