import styled from "styled-components";

export const HomeFilterContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 2%;;
  min-height: 54px;
  align-items: flex-start;
`;

export const DebounceInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;;
  label {
    ${({ theme }) => theme.fonts.primary.H5Regular};
    font-size: 11px;
  }

  &> input {
    height: 28px;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.textSecondary};
    ${({ theme }) => theme.fonts.primary.H5Regular};
    font-size: 11px;
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
    border-radius: 4px;
    padding: 4px;
    :focus {
      outline: none;
    }
  }
`