import React, { useEffect, useRef, useState } from "react";
import { MdPersonOutline, MdLogout } from "react-icons/md";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userSelect, userLogout } from "../../store/slices/userSlice";
import { appToogleDarkMode, appSelect } from "../../store/slices/appSlice";

import { SwitchChange } from "../Switch";
import { Modal } from "../Modal";
import {
  Container,
  DarkmodeArea,
  DarkmodeButton,
  LogoutQuestion,
  MenuItem,
  MenuSep,
  ProfileButton,
} from "./styles";

const MenuDropProfile: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const app = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const [showModalLogout, setShowModalLogout] = useState(false);

  const handleCloseModalLogout = () => setShowModalLogout(false);

  // Desconectar
  const logOut = () => {
    dispatch(userLogout())
      .unwrap()
      .then(() => {
        if (!app.darkMode) dispatch(appToogleDarkMode(true));
      });
  };

  const darkMode = (value: boolean) => {
    dispatch(appToogleDarkMode(value));
  };

  // Add event list to close dropdown when click outside
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        setShow(false);
      }
    }

    // Add event list on create component
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event list on destroy component
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleConfirmModalLogout = () => {
    logOut();
  };

  return (
    <div style={{ position: "relative" }} ref={ref}>
      {/* ======= Modal Confirm Logout ======= */}
      <Modal
        handleClose={handleCloseModalLogout}
        show={showModalLogout}
        handleConfirm={handleConfirmModalLogout}
        confirmLabel="Sim"
      >
        <LogoutQuestion>
          <MdLogout size={50} />
          <span>Tem certeza que deseja sair?</span>
        </LogoutQuestion>
      </Modal>

      <ProfileButton onClick={() => setShow(!show)}>
        <MdPersonOutline size={30} />
      </ProfileButton>
      {show ? (
        <Container style={{ position: "absolute", right: "24px" }}>
          <p>Olá, {user.name}!</p>
          <MenuSep />
          <DarkmodeArea>
            <p>Darkmode</p>
            <DarkmodeButton>
              <SwitchChange handleChange={darkMode} darkMode={!app.darkMode} />
            </DarkmodeButton>
          </DarkmodeArea>
          <MenuItem onClick={() => setShowModalLogout(true)}>Logout</MenuItem>
        </Container>
      ) : null}
    </div>
  );
};

export default MenuDropProfile;
