import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { userSelect } from "../store/slices/userSlice";

export const NotFoundRoute = () => {
  const user = useAppSelector(userSelect);

  if (user.id === "") {
    return <Navigate to="/login" />;
  }

  return <Navigate to="/" />;
};
