import { isEmpty } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, PageTemplate } from "../../../../components";
import useApi from "../../../../hooks/useApi";

import { PageProps } from "../../../../types/default";

import { Actions, Container, ContentContainer, Title } from "./styles";

export const GatewayScheduleDeleteAll = ({ resource }: PageProps) => {
  const navigate = useNavigate();

  const { request } = useApi({ path: resource.apiPath });

  const [gatewayId, setGatewayId] = useState("");

  const [errors, setErrors] = useState<any>({});

  const handlePost = () => {
    if (isEmpty(gatewayId)) {
      setErrors({ gatewayId: "Campo obrigatório" });
      return;
    }

    request({
      method: "del",
      pathParameters: `${gatewayId}/delete-all`,
    }).then(() => {
      navigate(`${resource.urlPath}`);
    });
  };

  return (
    <PageTemplate>
      <>
        <Container>
          {/* <PageHeader resource={resource} /> */}
          <ContentContainer>
            <Title>Limpar janela do Bolt</Title>
            <Input
              type="SELECT_API"
              id="gatewayId"
              label="Bolt"
              value={gatewayId}
              onChange={(e) => {
                setErrors({});
                setGatewayId(e.target.value);
              }}
              endpoint={"/gateways-options"}
              error={errors.gatewayId}
            />
            <Actions>
              <Button onClick={() => navigate(`${resource.urlPath}`)}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handlePost}>
                Limpar
              </Button>
            </Actions>
          </ContentContainer>
        </Container>
      </>
    </PageTemplate>
  );
};
