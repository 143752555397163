import { ListActive } from "../../../components/List/ListActive";
import { ListProfile } from "../../../components/List/ListProfile";
import { ListDate } from "../../../components/List/ListDate";
import { ShowActive } from "../../../components/Show/ShowActive";
import { ShowDate } from "../../../components/Show/ShowDate";
import { ShowProfile } from "../../../components/Show/ShowProfile";
import { ShowUserStatus } from "../../../components/Show/ShowUserStatus";
import { ListUserStatus } from "../../../components/List/ListUserStatus";

import { put } from "../../../utils/api";

export const UserResource = {
  title: "Usuários infra",
  urlPath: "/infra/users",
  apiPath: "/infra/users",
  actions: {
    resetPassword: {
      condition: (record: any) => record.Enabled,
      type: "record",
      label: "Resetar senha",
      guard: "Tem certeza que deseja resetar a senha?",
      handler: async (record: any) =>
        put({
          path: `/infra/users/${record.cognitoId}/reset-password`,
        }),
    },
    activeInactive: {
      type: "record",
      label: "Ativar/desativar",
      guard: "Tem certeza que deseja ativar/desativar usuário?",
      handler: async (record: any) =>
        put({
          path: `/infra/users/${record.cognitoId}/active-inactive`,
        }),
    },
  },
  properties: {
    cognitoId: {
      id: "cognitoId",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        list: true,
        show: true,
        filter: true,
      },
    },
    email: {
      id: "email",
      type: "TEXT",
      label: "EMAIL",
    },
    name: {
      id: "name",
      type: "TEXT",
      label: "NOME",
    },
    status: {
      id: "status",
      label: "STATUS",
      type: "SELECT",
      options: [
        {
          label: "CONFIRMADO",
          value: "CONFIRMED",
        },
        {
          label: "TROCA DE SENHA",
          value: "FORCE_CHANGE_PASSWORD",
        },
      ],
      component: {
        list: ({ record, id }: any) => (
          <ListUserStatus record={record} id={id} />
        ),
        show: ({ record, label, id }: any) => (
          <ShowUserStatus record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
        filter: true,
      },
    },
    profile: {
      id: "profile",
      type: "SELECT",
      options: [
        {
          label: "Master",
          value:
            "111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        },
      ],
      label: "PERFIL",
      component: {
        list: ({ record, id }: any) => <ListProfile record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowProfile record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
      },
    },
    createDate: {
      id: "createDate",
      type: "TEXT",
      label: "CRIADO EM",
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
      },
    },
    modifiedDate: {
      id: "modifiedDate",
      type: "TEXT",
      label: "MODIFICADO EM",
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        show: true,
      },
    },
    Enabled: {
      id: "Enabled",
      type: "SELECT",
      label: "ATIVO?",
      options: [
        {
          label: "Ativo",
          value: true,
        },
        {
          label: "Inativo",
          value: false,
        },
      ],
      component: {
        list: ({ record, id }: any) => <ListActive record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowActive record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
        filter: true,
      },
    },
  },
};
