import { InputCompoundContainer, InputFieldCheckBox } from "./styles";

interface IInputField {
  label: string;
  type: "number" | "checkbox" | "text";
  value: any;
  handleChange: (val: any) => void;
  disabled?: boolean;
}

interface ICheckBox {
  label: string;
  value: any;
  handleChange: (val: any) => void;
}

function CheckBox({ label, value, handleChange }: ICheckBox) {
  return (
    <InputFieldCheckBox>
      <input type="checkbox" checked={value} onChange={handleChange} />
      {label}
    </InputFieldCheckBox>
  );
}

function InputField({ label, type, ...props }: IInputField) {
  function renderInput() {
    switch (type) {
      case "checkbox":
        return <CheckBox label={label} {...props}></CheckBox>;
      default:
        return (
          <>
            <label>{label}</label>
            <input
              type={type}
              onChange={props.handleChange}
              value={props.value}
              disabled={props.disabled}
            />
          </>
        );
    }
  }

  return <InputCompoundContainer>{renderInput()}</InputCompoundContainer>;
}

export default InputField;
