import { Form } from "react-bootstrap";

import { InputContainer } from "../styles";
import { FileProps } from "./types";

export const File = ({
  name,
  label,
  placeholder,
  onChange,
  error,
}: FileProps) => {
  const readFile = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });

  return (
    <InputContainer>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        name={name}
        type="file"
        placeholder={placeholder}
        onChange={(e: any) => {
          readFile(e.target.files[0]).then((data) => {
            onChange({ target: { value: data } });
          });
        }}
      />
      {error ? <div className="error">{error}</div> : null}
    </InputContainer>
  );
};
