import { isNumber } from "lodash";
import { RecordProps } from "../../../../types/default";
import { ListContainer } from "./styles";

export const ListCollect = ({ id, record }: RecordProps) => {
  const {
    signalQuality,
    sk,
    batteryVoltage,
    axis,
    packetType,
    uptime,
    cloudReceivedDate,
  } = record;

  return (
    <ListContainer>
      <div>
        <span>Data tx gateway: </span>
        <span>
          {isNumber(sk) &&
            sk.toString().length > 11 &&
            sk.toString().length < 15 &&
            new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(+sk))}
        </span>
      </div>
      <div>
        <span>Data recebimento nuvem: </span>
        <span>
          {cloudReceivedDate &&
            new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(+cloudReceivedDate))}
        </span>
      </div>
      <div>
        <span>Qualidade do sinal: </span>
        <span>{(signalQuality * 100).toFixed(2)}%</span>
      </div>
      <div>
        <span>Bateria: </span>
        <span>{batteryVoltage}</span>
      </div>
      <div>
        <span>Eixo: </span>
        <span>{axis}</span>
      </div>
      <div>
        <span>Tipo pacote: </span>
        <span>{packetType}</span>
      </div>
      <div>
        <span>Tempo Ligado: </span>
        <span>{uptime ? `${uptime}s` : ""}</span>
      </div>
    </ListContainer>
  );
};
