import styled from "styled-components";
import { Modal } from "../../components/Modal";
import { PROBLEMS_LABELS } from "../../pages/GatewayHealthCheck";
import useApi from "../../hooks/useApi";
import { useEffect, useState } from "react";
import { StatusContainer } from "../../pages/GatewayHealthCheck/styles";
import { Loading } from "../../components";

interface IInstructionModal {
  onClose: () => void | any;
  gatewayId: string;
}

export const ProblemsHistoryModal = ({
  onClose,
  gatewayId,
}: IInstructionModal) => {
  const [history, setHistory] = useState<any[]>([]);

  const { request, processing } = useApi({
    path: `/monitorator/gateway-problems-history/${gatewayId}`,
  });

  const fetchProblemHistory = () => {
    request({ method: "get" }).then((response: any) => {
      setHistory(response);
    });
  };

  function getStatusBadge(statusNumber: any) {
    console.log("statusNumber", statusNumber);
    switch (statusNumber) {
      case 1:
        return "success";
      case 2:
        return "warning";
      case 3:
        return "danger";
      default:
        return "";
    }
  }

  useEffect(() => {
    fetchProblemHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatDate(date: number) {
    if (!date) return "";

    const formatted = new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }).format(new Date(date));

    return formatted;
  }

  return (
    <Modal handleClose={onClose} show={true} size="lg" hideSaveButton>
      <>
        <Header>
          <h4>Histórico de Problemas</h4>
          {/* <ButtonAdd>
            <IoMdAdd />
          </ButtonAdd> */}
        </Header>
        <PrincipalContainer>
          {processing ? (
            <Loading />
          ) : (
            <div>
              {history.map((item: any, index: number) => (
                <Row key={index}>
                  <label>{formatDate(item.date)}</label>
                  <StatusContainer bg={getStatusBadge(+item.status)}>
                    {PROBLEMS_LABELS[item.flag]}
                  </StatusContainer>
                </Row>
              ))}
            </div>
          )}
        </PrincipalContainer>
      </>
    </Modal>
  );
};

export const PrincipalContainer = styled.div`
  border-radius: 6px;
  background: ${(props) => props.theme.backgroundColors.background1};
  padding: 9px;
  height: 16rem;

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    gap: 6px;
  }
`;

const Row = styled.div`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  ${(props) => props.theme.fonts.secondary.H4Bold};
  font-size: 13px;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }
`;
