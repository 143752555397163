import { RecordProps } from "../../../types/default";

import { Container } from "./styles";
import { PROFILES_BITS } from "../../../store/slices/userSlice";

import LogoWhite from "../../../images/ibbxLogo.png";

export const ListLogoUrl = ({ id, record }: RecordProps) => {
  const value = record[id];

  if (
    [
      PROFILES_BITS.EXECUTIVO_MASTER,
      PROFILES_BITS.MESA_ANALISTA,
      PROFILES_BITS.MESA_MASTER,
    ].includes(record["profile"])
  ) {
    return <Container src={LogoWhite} alt="logo" />;
  }

  if (!value) {
    return null;
  }

  return <Container src={value} alt="logo" />;
};
