import { useRef, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { Modal } from "../../../../components/Modal";
import { CSVDownload } from "react-csv";
import { Container, Title, CloseButton, Button } from "./styles";
import { MdClose } from "react-icons/md";

interface ExportQualityHistoricCSVProps {
  record: {
    id: number;
  };
}

interface QualityInspectionDevicesData {
  UUID: string;
  ULTIMA_ATUALIZAÇÃO: string;
  FIRWARE: string;
  LOTE: string;
  EMPRESA: string;
  UNIDADE: string;
  ATIVO: string;
  PONTO: string;
  PERFORMANCE: string;
  TEMPERATURA: string;
  BATERIA_INICIAL: string;
  BATERIA_ATUAL: string;
  CONSUMO: string;
  RSSI: string;
  CONECTADO: string;
  SINCRONIZADO: string;
  ACERELAÇÂO_RMS_X: string;
  ACERELAÇÃO_RMS_Y: string;
  ACERELAÇÃO_RMS_Z: string;
  VELOCIDADE_RMS_X: string;
  VELOCIDADE_RMS_Y: string;
  VELOCIDADE_RMS_Z: string;
}

export const ExportQualityHistoricCSV = ({
  record,
}: ExportQualityHistoricCSVProps) => {
  const csvRef = useRef<any>(null);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<String>("");
  const [completed, setCompleted] = useState(false);
  const [data, setData] = useState<QualityInspectionDevicesData[]>([]);

  const { request } = useApi({
    path: `/infra/quality-inspections/${record.id}/devices`,
  });

  async function loadData() {
    setMessage("Buscando sensores da inspeção...");
    setCompleted(false);

    const response = await request({
      method: "get",
    });

    const histories = [];

    for (let i = 0; i < response.length; i++) {
      const board = response[i];
      setMessage(
        `Buscando dados do sensor ${((i / response.length) * 100).toFixed(
          2
        )}%...`
      );

      const responseHistory = await request({
        method: "get",
        pathParameters: `/${board.id}/summary-history`,
      });

      histories.push(responseHistory);
    }

    setMessage(`Montando relatório...`);

    const formattedData = histories.flat().map((history: any) => {
      return {
        UUID: history.boardId,
        ULTIMA_ATUALIZAÇÃO: new Date(history.date).toISOString(),
        FIRWARE: history.firware,
        LOTE: history.batch,
        EMPRESA: history.companyName,
        UNIDADE: history.facilityName,
        ATIVO: history.assetName,
        PONTO: history.positionName,
        PERFORMANCE: history.performance,
        TEMPERATURA: String(history.temperature).replace(".", ","),
        BATERIA_INICIAL: String(history.initialBatteryVoltage).replace(
          ".",
          ","
        ),
        BATERIA_ATUAL: String(history.batteryVoltage).replace(".", ","),
        CONSUMO: String(history.batteryConsumption).replace(".", ","),
        RSSI: String(history.lastCollectRSSI).replace(".", ","),
        CONECTADO: history.isConnected ? "SIM" : "NÃO",
        SINCRONIZADO: history.scheduleSynchronized ? "SIM" : "NÃO",
        ACERELAÇÂO_RMS_X: String(history.accelerationRmsX).replace(".", ","),
        ACERELAÇÃO_RMS_Y: String(history.accelerationRmsY).replace(".", ","),
        ACERELAÇÃO_RMS_Z: String(history.accelerationRmsZ).replace(".", ","),
        VELOCIDADE_RMS_X: String(history.velocityRmsX).replace(".", ","),
        VELOCIDADE_RMS_Y: String(history.velocityRmsY).replace(".", ","),
        VELOCIDADE_RMS_Z: String(history.velocityRmsZ).replace(".", ","),
      };
    });

    setData(formattedData);
    setMessage("Relatório pronto!");
    setCompleted(true);
    setShow(false);
  }

  function handleLoadData() {
    loadData();
    setShow(true);
  }

  return (
    <>
      <Button onClick={handleLoadData}>Exportar Histórico</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        confirmLabel="Baixar"
        size="sm"
        disabledConfirm={false}
        changeFotter
      >
        <Container>
          <CloseButton onClick={() => setShow(false)}>
            <MdClose size={18} />
          </CloseButton>
          <Title>
            <strong>{message}</strong>
          </Title>
          {completed && (
            <CSVDownload
              separator=";"
              ref={csvRef}
              data={data.length > 0 ? data : []}
              asyncOnClick={true}
              filename={`test.csv`}
            ></CSVDownload>
          )}
        </Container>
      </Modal>
    </>
  );
};
