import { useEffect, useMemo, useState } from "react";
import PeriodInput from "../../../components/PeriodInput";
import { ChartFilters, DebounceInputContainer } from "./styles";
import useApi from "../../../hooks/useApi";
import DeviceHealthSelect from "../../GatewayHealthCheck/DeviceHealthSelect";
import { DebounceInput } from "react-debounce-input";
import { PROBLEMS_CONFIG, PROBLEMS_TYPE } from "../types";
import MultiSelect from "../../../components/MultiSelect";

interface IGatewaysProblemsFilter {
  filters: any;
  setFilters: (filters: any) => void;
  type: "PROBLEMS" | "STATUS";
  hideGateweayId?: boolean;
  hideFilters?: boolean;
}

export default function GatewaysProblemsFilter({
  filters,
  setFilters,
  type,
  hideGateweayId,
  hideFilters,
}: IGatewaysProblemsFilter) {
  // eslint-disable-next-line
  const { request: requestCompanies, processing: loadingOptions } = useApi({
    path: "/companies-options",
  });

  // eslint-disable-next-line
  const { request: requestFacilties, processing: loadingFacilities } = useApi({
    path: "/facilities-options",
  });

  const [companiesOptions, setCompaniesOptions] = useState<any[]>([]);
  const [facilitiesOptionsRaw, setFacilitiesOptionsRaw] = useState<any[]>([]);

  useEffect(() => {
    Promise.all([
      requestCompanies({ method: "get" }).then(setCompaniesOptions),
      requestFacilties({ method: "get" }).then(setFacilitiesOptionsRaw),
    ]);
    // eslint-disable-next-line
  }, []);

  const facilitiesOptions = useMemo(() => {
    if (filters.companyId) {
      return facilitiesOptionsRaw.filter(
        (item) => +item.companyId === +filters.companyId
      );
    }
    return [];
    // eslint-disable-next-line
  }, [filters.companyId]);

  const handleCompanyChange = (e: any) => {
    if (e?.value) {
      delete filters.facilityId;
      setFilters({ ...filters, companyId: e.value });
    } else {
      delete filters.companyId;
      delete filters.facilityId;
      setFilters({ ...filters });
    }
  };

  const handleFacilityChange = (e: any) => {
    if (e?.value) {
      setFilters({ ...filters, facilityId: e.value });
    } else {
      delete filters.facilityId;
      setFilters({ ...filters });
    }
  };

  const handleGatewayIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilters({ ...filters, gatewayId: event.target.value });
  };

  const handleProblemChange = (e: any) => {
    if (!e.length) {
      delete filters.problem;
      setFilters({ ...filters });
      return;
    }
    const problems = e.map(({ value }: any) => value).join(",");
    setFilters({ ...filters, problems });
  };

  const handleProblemTypeChange = (e: any) => {
    if (!e.length) {
      delete filters.problemType;
      setFilters({ ...filters });
      return;
    }
    const problemType = e.map(({ value }: any) => value).join(",");
    setFilters({ ...filters, problemType });
  };

  const SHOW_PROBLEMS_FILTER = false;

  return (
    <ChartFilters>
      <PeriodInput />
      {!hideFilters && (
        <>
          <DeviceHealthSelect
            label="Empresa"
            options={companiesOptions}
            handleChange={handleCompanyChange}
            isClearable={true}
          />
          <DeviceHealthSelect
            label="Unidade"
            options={facilitiesOptions}
            handleChange={handleFacilityChange}
            isClearable={true}
          />
        </>
      )}
      {!hideGateweayId && (
        <DebounceInputContainer>
          <label>Gateway ID</label>
          <DebounceInput
            value={filters.gatewayId}
            onChange={handleGatewayIdChange}
            debounceTimeout={400}
            placeholder="Gateway ID"
          />
        </DebounceInputContainer>
      )}
      {SHOW_PROBLEMS_FILTER && type === "PROBLEMS" && (
        <MultiSelect
          label="Problema"
          options={Object.values(PROBLEMS_CONFIG)}
          handleChange={handleProblemChange}
        />
      )}
      {SHOW_PROBLEMS_FILTER && type === "PROBLEMS" && (
        <MultiSelect
          label="Tipo de Problema"
          options={Object.values(PROBLEMS_TYPE)}
          handleChange={handleProblemTypeChange}
        />
      )}
    </ChartFilters>
  );
}
