import { formatterLoginErrorMessage } from "../../utils/loginMessages";
import ReactTooltip from "react-tooltip";
import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import logoIbbxOrange from "../../images/ibbxLogoOrange.svg";

// Store
import { useAppDispatch } from "../../store/hooks";
import { userLogin } from "../../store/slices/userSlice";

import {
  LoginMain,
  PrincipalBox,
  Main,
  FigureDiv,
  FirstIbbxLogo,
  FirstTextInputContainer,
  FirstTextInput,
  SecondTextInputContainer,
  SecondTextInput,
  EyeSecondTextInput,
  SubmitButton,
  Elipse,
  SecondIbbxLogo,
  LoginPageMessage,
} from "./styles";

import ibbxLogo from "../../images/ibbxLogo.png";
import visibility from "../../images/visibility.png";
import visibilityOff from "../../images/visibilityOff.png";

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [eyePass, setEyePass] = useState("password");
  const [toogleEyePass, setToogleEyePass] = useState(false);
  const [toogleToolTipEye, setToogleTooltipEye] = useState(false);
  const [EyePassFigure, setEyePassFigure] = useState<string>(visibilityOff);
  const [loginLocalMessage, setLoginLocalMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  //Função para alternar estado de visualização de senha
  const toggleSeePass = () => {
    setToogleEyePass(!toogleEyePass);
    if (toogleEyePass) {
      setEyePass("password");
      setEyePassFigure(visibilityOff);
    } else {
      setEyePass("text");
      setEyePassFigure(visibility);
    }
  };

  // Chamadas para o reducer
  const dispatch = useAppDispatch();

  //Opções de usuário para testes
  let loginData = ["", ""]; // Usuário em branco
  //loginData = ['mauricio@ibbx.tecf', 'c-nturb@967P']; // 29ebbad3-c416-455a-ac14-7f429f729b8e = Usuário para forçar a troca de senha, deixar assim, não trocar a senha
  //loginData = ['mauricio@ibbx.tecn', 'c-nturb@967P']; // 9d1a9db9-2aa6-4bf1-bcfd-f66314cc1571 = Usuário que não aceitou os termos de uso, deixar assim, não "aceitar"
  //loginData = ['mauricio@ibbx.tecc', 'Tecc@123']; // 7fb279bf-d535-4197-a8f4-539beb6931c5 = Usuário já que aceitou os termos de uso
  // loginData = ["henrique.anacretto@ibbx.tech", "#Abc1234"]; // Usuário para a api do .env.development

  //Atualizações de estado das variáveis usuário e senha
  const [emailData, setEmailData] = useState(loginData[0]);
  const [passwordData, setPasswordData] = useState(loginData[1]);

  //Função para coletar o campo do email
  const emailCollect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailData(event.target.value);
    setLoginLocalMessage("");
  };

  //Função para coletar input de senha
  const passwordCollect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordData(event.target.value);
    setLoginLocalMessage("");
  };

  //Função para executar a requisição de login e inserção de informações
  const postStart = async () => {
    if (!emailData || !passwordData) {
      setLoginLocalMessage("Campos email e senha obrigatórios.");
      return;
    }

    setProcessing(true);
    const params = {
      email: emailData,
      password: passwordData,
    };

    dispatch(userLogin(params))
      .unwrap()
      .then((user: any) => {
        const { from } = queryString.parse(location.search) || {};

        setLoginLocalMessage(user.loginMessage);
        if (user.loginNewPassword) {
          return navigate(`/new-password${location.search}`);
        }

        const redirectUrl = from ? from : "/";

        return navigate(redirectUrl as string);
      })
      .catch((error: any) => {
        const formatedErrorMessage = formatterLoginErrorMessage(error.message);

        setLoginLocalMessage(formatedErrorMessage);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  //Estrutura do Componente
  return (
    <Main>
      <Elipse width="20%" height="100%" left="28%" top="-50%" opacity="0.35" />
      <LoginMain>
        <PrincipalBox
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <FigureDiv>
            <FirstIbbxLogo src={logoIbbxOrange} />
          </FigureDiv>
          <FirstTextInputContainer>
            <FirstTextInput
              type={"text"}
              placeholder="E-mail"
              onChange={emailCollect}
              value={emailData}
            ></FirstTextInput>
          </FirstTextInputContainer>
          <SecondTextInputContainer>
            <SecondTextInput
              type={eyePass}
              onChange={passwordCollect}
              placeholder="Senha"
              value={passwordData}
            ></SecondTextInput>
            <EyeSecondTextInput
              data-tip
              data-for="eyeToolTip"
              src={EyePassFigure}
              onClick={toggleSeePass}
              onMouseOver={() => setToogleTooltipEye(true)}
              onMouseOut={() => setToogleTooltipEye(false)}
            ></EyeSecondTextInput>
          </SecondTextInputContainer>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              height: "10px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90%"
            }}
          >
            <LoginPageMessage>{loginLocalMessage}</LoginPageMessage>
          </div>
          <SubmitButton onClick={postStart}>
            {processing ? "Entrando ..." : "Entrar"}
          </SubmitButton>
        </PrincipalBox>
        {toogleToolTipEye && (
          <ReactTooltip id="eyeToolTip" type="dark">
            <span>Mudar a visibilidade de senha</span>
          </ReactTooltip>
        )}
        <SecondIbbxLogo src={ibbxLogo}></SecondIbbxLogo>
      </LoginMain>
      <Elipse width="20%" height="10%" left="51%" top="60%" opacity="0.65" />
      <Elipse width="20%" height="5%" left="38%" top="80%" opacity="0.9" />
    </Main>
  );
};
