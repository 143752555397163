import styled from "styled-components";

export const ModalGatewayWindowContainer = styled.div`
  h1 {
    margin-top: 16px;
    ${({ theme }) => theme.fonts.primary.H4Bold};
  }
`;

export const Button = styled.div``;

export const CollectContainer = styled.pre`
  background-color: #fff;
  color: #000;
  overflow-y: auto;
  max-height: 500px;
`;

export const WindowDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const WindowDetailsItem = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px;
  gap: 2px;
  margin: 0;
  cursor: pointer;

  border: 1px solid #fff;

  opacity: ${({ selected }) => (selected ? 1 : 0.8)};
  background-color: ${({ selected, theme }) =>
    selected && theme.colors.cardHighlight};

  &:hover {
    background-color: ${({ selected, theme }) => theme.colors.cardHighlight};
  }

  & p {
    padding: 0;
    margin: 0;
    height: 18px;
    ${({ theme }) => theme.fonts.primary.H5Regular};
  }
`;
