import { GroupBase, StylesConfig } from "react-select";
import { useTheme } from "styled-components";

export default function useDeviceHealthSelect() {
  const { colors, fonts } = useTheme();
  const styles: StylesConfig<any, false, GroupBase<any>> = {
    container: (base, props) => ({
      ...base,
      height: "28px",
      minWidth: "120px",
    }),
    control: (base, props) => ({
      ...base,
      backgroundColor: colors.background,
      borderColor: colors.cardHighlight,
      color: colors.iconsPrimary,
      boxShadow: "none",
      "&:hover": {
        borderColor: "transparent",
        outline: "none",
      },
      margin: 0,
      padding: 0,
      height: "28px",
      minHeight: "28px",
    }),
    valueContainer: (base) => ({
      ...base,
      color: colors.iconsPrimary,
      height: "24px",
      display: "flex",
      minWidth: "60px",
      ...fonts.primary.H5Regular,
      position: "relative",
      "& > div": {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      [`input[type="text"]:focus`]: {
        boxShadow: "none",
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "100%",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
      color: colors.iconsPrimary,
    }),
    option: (base, props) => ({
      ...base,
      color: colors.iconsPrimary,
      ":hover": { backgroundColor: colors.headerBackground },
      ":active": { backgroundColor: colors.headerBackground },
      backgroundColor: `${props.isSelected && colors.cardBackground}`,
    }),

    input: (base) => ({
      ...base,
      position: "absolute",
      left: 0,
      border: "none",
      outline: "none",
    }),
    placeholder: (base) => ({
      ...base,
      // color: customColors?.placeholder?.color || colors.textPrimary,
      fontSize: "12px",
    }),
    singleValue: (base) => ({
      ...base,
      color: colors.iconsPrimary,
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: colors.background,
      fontWeight: "600",
      fontSize: "11px",
      zIndex: 3,
    }),
    clearIndicator: (base: any) => ({
      ...base,
    }),
  };

  return { styles };
}
