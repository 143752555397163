import React, { useCallback, useEffect, useState } from "react";

import { Modal } from "../../../../components/Modal";
import { RecordProps } from "../../../../types/default";

import { Container, Button, Title, TableTimes } from "./styles";
import { useTheme } from "styled-components";

export const ModalGatewayTimes = ({ id, record }: RecordProps) => {
  const theme = useTheme();

  const [show, setShow] = useState(false);

  const [date, setDate] = useState(0);
  const [startCycle, setStartCycle] = useState(0);
  const [startWindow, setStartWindow] = useState(0);
  const [endWindow, setEndWindow] = useState(0);
  const [endCycle, setEndCycle] = useState(0);

  const [txGlobal, setTxGlobal] = useState<any>([]);
  const [txConfig, setTxConfig] = useState<any>([]);
  const [txDinamic, setTxDinamic] = useState<any>([]);

  const { parameters } = record;

  const globalTxWindow = (parameters?.globalTxWindow || 5) * 1000;
  const dynamicTxWindow = (parameters?.dynamicTxWindow || 180) * 1000;
  const configWindow = (parameters?.configWindow || 600) * 1000;
  const radioWindowInterval = (parameters?.radioWindowInterval || 3600) * 1000;
  const radioWindowNumBoardSlots = parameters?.radioWindowNumBoardSlots || 240;

  const getTime = () => {
    const date = new Date().getTime();

    const globalWindow = radioWindowNumBoardSlots * globalTxWindow;

    const numBoardsPerCycle =
      (radioWindowInterval - globalWindow - configWindow) / dynamicTxWindow;

    const numCycles = radioWindowNumBoardSlots / numBoardsPerCycle;

    const txTotalCycles = radioWindowInterval * numCycles;

    const startWindow = new Date(
      Math.floor(date / txTotalCycles) * txTotalCycles
    ).getTime();

    const endWindow = new Date(
      Math.ceil(date / txTotalCycles) * txTotalCycles
    ).getTime();

    const startCycle = new Date(
      Math.floor(date / radioWindowInterval) * radioWindowInterval
    ).getTime();

    const endCycle = new Date(
      Math.ceil(date / radioWindowInterval) * radioWindowInterval
    ).getTime();

    let txGlobal = [];
    for (let i = 0; i < radioWindowNumBoardSlots; i++) {
      txGlobal.push(startCycle + i * globalTxWindow);
    }

    let txConfig = [];
    for (let i = 0; i < radioWindowNumBoardSlots; i++) {
      const configCycle =
        Math.floor(i / numBoardsPerCycle) + 1 < numCycles
          ? Math.floor(i / numBoardsPerCycle) + 1
          : 0;
      txConfig.push(
        startWindow + configCycle * radioWindowInterval + globalWindow
      );
    }

    let txDinamic = [];
    for (let i = 0; i < radioWindowNumBoardSlots; i++) {
      const dynamicPos = Math.floor(i / numBoardsPerCycle);
      const dynamicPosMod = i % numBoardsPerCycle;

      txDinamic.push(
        startWindow +
          dynamicPos * radioWindowInterval +
          dynamicPosMod * dynamicTxWindow +
          globalWindow +
          configWindow
      );
    }

    setDate(date);
    setStartWindow(startWindow);
    setEndWindow(endWindow);
    setStartCycle(startCycle);
    setEndCycle(endCycle);
    setTxGlobal(txGlobal);
    setTxConfig(txConfig);
    setTxDinamic(txDinamic);
  };

  const getColor = useCallback(({ timeTx, durationTx }: any) => {
    const date = new Date().getTime();

    if (date > timeTx && date <= timeTx + durationTx) {
      return "green";
    }

    return theme.colors.textPrimary;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button onClick={() => setShow(true)}>Ver tempos</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        handleConfirm={() => {}}
        confirmLabel="Copiar"
        size="lg"
      >
        <Container>
          <Title>
            <strong>{`Horarios de transmissões (${id})`}</strong>
          </Title>
          <div>
            <strong>Hora atual: </strong>
            <span>
              {new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium"
              }).format(date)}
            </span>
          </div>
          <div>
            <strong>Inicio do ciclo: </strong>
            <span>
              {new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium"
              }).format(startWindow)}
            </span>
          </div>
          <div>
            <strong>Fim do ciclo: </strong>
            <span>
              {new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium"
              }).format(endWindow)}
            </span>
          </div>
          <div>
            <strong>Inicio da janela atual: </strong>
            <span>
              {new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium"
              }).format(startCycle)}
            </span>
          </div>
          <div>
            <strong>Fim da janela atual: </strong>
            <span>
              {new Intl.DateTimeFormat("pt-BR", {
                dateStyle: "short",
                timeStyle: "medium"
              }).format(endCycle)}
            </span>
          </div>
          <TableTimes>
            <tr>
              <th>Posição</th>
              <th>Global</th>
              <th>Configuração</th>
              <th>Dinamica</th>
            </tr>
            {txGlobal.map((item: any, index: any) => (
              <tr>
                <td>{index}</td>
                <td>
                  <span
                    style={{
                      color: getColor({
                        timeTx: item,
                        durationTx: globalTxWindow
                      })
                    }}
                  >
                    {new Intl.DateTimeFormat("pt-BR", {
                      timeStyle: "medium"
                    }).format(item)}
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      color: getColor({
                        timeTx: txConfig[index],
                        durationTx: configWindow
                      })
                    }}
                  >
                    {new Intl.DateTimeFormat("pt-BR", {
                      timeStyle: "medium"
                    }).format(txConfig[index])}
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      color: getColor({
                        timeTx: txDinamic[index],
                        durationTx: dynamicTxWindow
                      })
                    }}
                  >
                    {new Intl.DateTimeFormat("pt-BR", {
                      timeStyle: "medium"
                    }).format(txDinamic[index])}
                  </span>
                </td>
              </tr>
            ))}
          </TableTimes>
        </Container>
      </Modal>
    </>
  );
};
