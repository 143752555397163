import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import queryString from "query-string";

import { Container, Link } from "./styles";

import { PaginateProps } from "./types";

export const Paginate = ({ pagination }: PaginateProps) => {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const { totalPages, page } = pagination || {};

  const handleClickPage = (page: number) => {
    const params = queryString.parse(location.search);
    let newSearchParams = new URLSearchParams({
      ...params,
      page: String(page),
    });
    setSearchParams(newSearchParams);
  };

  if (totalPages === 1) {
    return null;
  }

  if (totalPages >= 2 && totalPages < 4) {
    const arr = Array.from(Array(totalPages).keys());
    return (
      <Container>
        {arr.map((i) => (
          <Link
            key={i}
            onClick={() => handleClickPage(i + 1)}
            selected={page === i + 1}
          >
            {i + 1}
          </Link>
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <Link onClick={() => handleClickPage(1)} disabled={page === 1}>
        {"<<"}
      </Link>
      <Link onClick={() => handleClickPage(page - 1)} disabled={page === 1}>
        {"<"}
      </Link>
      {page - 1 > 0 && (
        <>
          <span>...</span>
          <Link onClick={() => handleClickPage(page - 1)}>{page - 1}</Link>
        </>
      )}

      <Link selected>{page}</Link>
      {page + 1 <= totalPages && (
        <Link onClick={() => handleClickPage(page + 1)}>{page + 1}</Link>
      )}
      {page + 1 < totalPages && <span>...</span>}
      <Link
        onClick={() => handleClickPage(page + 1)}
        disabled={page === totalPages}
      >
        {">"}
      </Link>
      <Link
        onClick={() => handleClickPage(totalPages)}
        disabled={page === totalPages}
      >
        {">>"}
      </Link>
    </Container>
  );
};
