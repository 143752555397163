import styled from "styled-components";

export const ListContainer = styled.a`
  width: 200px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.textPrimary};
  }

  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    & > span:first-child {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;
