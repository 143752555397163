import styled from "styled-components";

export const InputCompoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${({ theme }) => theme.colors.iconsPrimary};
  }

  input[type="number"],
  input[type="text"] {
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${({ theme }) => theme.fonts.primary.H4Regular};
    border-radius: 4px;
    padding: 8px;
    height: 34px;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputFieldContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
`;

export const InputFieldCheckBox = styled.label`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  cursor: pointer;
  user-select: none;

  & > input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    border-radius: 3px;
    outline: none;
    background-color: ${(props) => props.theme.colors.lightBackground};
    cursor: pointer;
    position: relative;

    &:checked {
      background-color: ${(props) => props.theme.colors.ibbxOrange};
    }

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 6px;
      top: 25%;
      left: 20%;
      transform: translate(-50%, -50%);
      border: 2px solid white;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
      background: transparent;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:checked:before {
      opacity: 1;
    }

    @media screen and (max-width: 767px) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
