import Switch from "react-switch";
import { FaMoon, FaSun } from "react-icons/fa";
import { useState } from "react";

interface SwitchChangeProps {
  handleChange: (value: boolean) => void;
  darkMode: boolean;
}

export const SwitchChange = (props: SwitchChangeProps) => {
  const [checked, setChecked] = useState(props.darkMode);

  const handleOnChange = (value: boolean) => {
    setChecked(value);
    props.handleChange(value);
  };

  return (
    <>
      <label htmlFor="small-radius-switch">
        <Switch
          checked={checked}
          onChange={handleOnChange}
          handleDiameter={20}
          offColor="#252A3C"
          onColor="#E0E9F0"
          offHandleColor="#656A85"
          onHandleColor="#656A85"
          height={26}
          width={54}
          borderRadius={20}
          activeBoxShadow="0px 0px 1px 1px #3A4058"
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#656A85",
              }}
            >
              <FaSun />
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
              }}
            >
              <FaSun />
            </div>
          }
          uncheckedHandleIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#EBECF4",
              }}
            >
              <FaMoon />
            </div>
          }
          checkedHandleIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#EBECF4",
              }}
            >
              <FaMoon />
            </div>
          }
          className="react-switch"
          id="small-radius-switch"
        />
      </label>
    </>
  );
};
