export const occurrenceExclusionReasonsResource = {
  title: "Motivos de exclusão de ocorrências",
  urlPath: "/web/occurrence-exclusion-reasons",
  apiPath: "/web/occurrence-exclusion-reasons",
  actions: {
    edit: { isVisible: false },
    delete: { isVisible: true },
    show: { isVisible: false },
    filter: { isVisible: false },
  },
  properties: {
    id: {
      id: "id",
      label: "ID",
      isVisible: {
        list: false,
      },
    },
    reason: {
      id: "reason",
      label: "Motivo",
      type: "TEXT",
    },
  },
};
