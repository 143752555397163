import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.textPrimary};
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Inter';

    &::-webkit-scrollbar { 
      width: 0;
      background: transparent;
    }
  }
 
`;
