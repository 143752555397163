import { DefaultTheme as SCDefaultTheme } from "styled-components";

import DefaultTheme from "./default";

const darkTheme: SCDefaultTheme = {
  ...DefaultTheme,

  status: true,

  backgroundColors: {
    background0: "#0C0E13",
    background1: "#161A25",
    background2: "#1E222F",
    background3: "#252A3B",
    background4: "#3A4058"
  },

  colors3: {
    color0: "#FFFFFF",
    color1: "#ADB1CF",
    color2: "#252A3B"
  },

  colors: {
    alarmRisco: "#FF0000",
    alarmAlerta: "#FFD700",
    alarmRiscoAlpha: "#FF00005e",
    alarmAlertaAlpha: "#FFD70063",
    white: "#FFFFFF",
    normalStatus: "#01D5BF",
    statusText: "#FFFFFF",
    colorTools: "#9CA3C6",
    clock: "#ce3878",
    p30: "#01d5bf",
    IconsTools: "#BDC1DA",
    backgroundStatus: "#252a3c4d",
    background: "#0B0D12",
    StatusTools: "#CE3878",
    headerBackground: "#161A25",
    divBackground: "#1E222F",
    divBackgroundAlpha: "#1E222F64",
    cardBackground: "#252A3C",
    cardHighlight: "#3A4058",
    iconsSecondary: "#ADB2CF",
    iconsSecondaryAlpha: "#3A4058",
    iconsPrimary: "#DDDFED",
    textSecondary: "#EBECF4",
    textPrimary: "#FFFFFF",
    itemsHighlight: " #01D5BF",
    statusNormal: "#01D5BF",
    statusAlert: "#DEA40F",
    lightOrange: "#FF9B30",
    ibbxOrange: "#F37035",
    statusRisk: "#CE3878",
    failureAlert: "#DEA40F",
    lightBackground: "#F3F6F8"
  }
};

export default darkTheme;
