import * as React from "react";
import { useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { userSelect, userNewPassword } from "../../store/slices/userSlice";

import { formatterLoginErrorMessage } from "../../utils/loginMessages";

import ibbxLogo from "../../images/ibbxLogo.png";
import visibility from "../../images/visibility.png";
import visibilityOff from "../../images/visibilityOff.png";

import {
  LoginInputContainer,
  LoginInputText,
  LoginMainNew,
  LoginTitle,
  Main,
  NewLoginSubmitButton,
  SecondIbbxLogo,
  Pass,
  Elipse,
  LoginMessage,
  EyeSecondTextInput,
  ContainerBody,
} from "./styles";

export const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [eyePass, setEyePass] = useState("password");
  const [toogleEyePass, setToogleEyePass] = useState(false);
  const [EyePassFigure, setEyePassFigure] = useState<string>(visibilityOff);
  const [loginLocalMessage, setLoginLocalMessage] = React.useState("");
  const [processing, setProcessing] = React.useState(false);

  //Função para alternar estado de visualização de senha
  const toggleSeePass = () => {
    setToogleEyePass(!toogleEyePass);
    if (toogleEyePass) {
      setEyePass("password");
      setEyePassFigure(visibilityOff);
    } else {
      setEyePass("text");
      setEyePassFigure(visibility);
    }
  };

  // Estado do usuário
  const user = useAppSelector(userSelect); //Usuário atual
  const dispatch = useAppDispatch(); //Dispatch do reducer

  //Atualizações de estado das variáveis senha e confirmação
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  //Função para coletar input da senha 1
  const password1Collect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword1(event.target.value);
    setLoginLocalMessage("");
  };

  //Função para coletar input da senha 2
  const password2Collect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(event.target.value);
    setLoginLocalMessage("");
  };

  //Função para verificar e salvar a nova senha
  const postStart = async () => {
    if (!password1 || !password2) {
      setLoginLocalMessage("Preencha os campos.");
      return;
    }

    if (password1 !== password2) {
      setLoginLocalMessage("As senhas não conferem.");
      return;
    }

    setProcessing(true);
    const params = {
      email: user.email,
      loginTmpPassword: user.loginTmpPassword,
      newPassword: password1,
    };
    dispatch(userNewPassword(params))
      .unwrap()
      .then((user) => {
        const { from } = queryString.parse(location.search) || {};
        const redirectUrl = from ? from : "/";

        return navigate(redirectUrl as string);
      })
      .catch((err) => {
        const { code } = err;
        setLoginLocalMessage(formatterLoginErrorMessage(code));
      })
      .finally(() => setProcessing(false));
  };

  //Estrutura do Componente
  return (
    <Main>
      <Elipse width="20%" height="100%" left="28%" top="-50%" opacity="0.35" />
      <LoginMainNew>
        <LoginTitle>Criar senha</LoginTitle>
        <ContainerBody onSubmit={(e) => e.preventDefault()}>
          <LoginInputContainer>
            <LoginInputText
              type={eyePass}
              placeholder="Digite a nova senha"
              onChange={password1Collect}
            />
            <EyeSecondTextInput
              src={EyePassFigure}
              onClick={toggleSeePass}
            ></EyeSecondTextInput>
          </LoginInputContainer>
          <Pass>
            <PasswordStrengthBar
              password={password1}
              scoreWords={["", "Fraca", "Fraca", "Média", "Forte"]}
              shortScoreWord={""}
              scoreWordStyle={{ fontSize: "16px" }}
              style={{ width: "327px", height: "20px" }}
              barColors={["#ddd", "#ef4836", "#f6b44d", "#2b90ef", "#25c281"]}
            />
          </Pass>
          <LoginInputContainer>
            <LoginInputText
              type={eyePass}
              placeholder="Repita a nova senha"
              onChange={password2Collect}
            />
            <EyeSecondTextInput
              src={EyePassFigure}
              onClick={toggleSeePass}
            ></EyeSecondTextInput>
          </LoginInputContainer>

          <LoginMessage>{loginLocalMessage}</LoginMessage>

          <NewLoginSubmitButton onClick={postStart}>
            {processing ? "Salvando ..." : "Salvar"}
          </NewLoginSubmitButton>
        </ContainerBody>
      </LoginMainNew>
      <SecondIbbxLogo src={ibbxLogo}></SecondIbbxLogo>
      <Elipse width="20%" height="10%" left="51%" top="60%" opacity="0.65" />
      <Elipse width="20%" height="5%" left="38%" top="85%" opacity="0.9" />
    </Main>
  );
};
