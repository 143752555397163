import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { DateContainer, DatePickerContainer } from "./styles";
import queryString from "../../utils/queryString";

interface IPeriodInput {}

export default function PeriodInput({}: IPeriodInput) {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  registerLocale("ptBR", ptBR);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const ref = useRef(null);

  function handleDatePicker(value: any) {
    const params: any = queryString.parse(location.search);

    if (value[0] === null && value[1] === null) {
      params.minDate = null;
      params.maxDate = null;
    }

    if (value[0] && params.minDate) {
      params.minDate = value[0].getTime();
      params.maxDate = null;
    }

    if (value[1]) {
      params.minDate = value[0].getTime();
      params.maxDate = value[1].getTime();
    }

    setDateRange(value);

    if (!params.minDate) {
      delete params.minDate;
    }
    if (!params.maxDate) {
      delete params.maxDate;
    }

    setSearchParams(params);
  }

  return (
    <DateContainer>
      <label>Período</label>
      <DatePickerContainer>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selectsRange={true}
          locale={ptBR}
          startDate={startDate || new Date(new Date().getTime() - 7 * 86400000)}
          endDate={!startDate && !endDate ? new Date() : endDate}
          onChange={(value) => handleDatePicker(value)}
          isClearable
          maxDate={new Date()}
        />
      </DatePickerContainer>
    </DateContainer>
  );
}
