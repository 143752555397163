import { ListActive } from "../../../components/List/ListActive";
import { ListProfile } from "../../../components/List/ListProfile";
import { ListDate } from "../../../components/List/ListDate";
import { ShowActive } from "../../../components/Show/ShowActive";
import { ShowDate } from "../../../components/Show/ShowDate";
import { ShowProfile } from "../../../components/Show/ShowProfile";
import { ShowUserStatus } from "../../../components/Show/ShowUserStatus";
import { ListUserStatus } from "../../../components/List/ListUserStatus";

import { ListUserContact } from "../../../components/List/ListUserContact";
import { ListUserCompany } from "../../../components/List/ListUserCompany";
import { ListBoolean } from "../../../components/List/ListBoolean";

import { RecordProps } from "../../../types/default";
import { ListLogoUrl } from "../../../components/List/LogoUrl/List";

export const UsersFacilitiesResource = {
  title: "Usuários Unidades",
  urlPath: "/web/users-facilities",
  apiPath: "/web/users-facilities",
  actions: {},
  properties: {
    id: {
      id: "id",
      type: "TEXT",
      label: null,
      isPrimaryKey: true,
      isVisible: {},
    },
    logoUrl: {
      id: "logoUrl",
      type: "IMAGE",
      label: null,
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListLogoUrl record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    userId: {
      id: "userId",
      type: "SELECT_API",
      label: "USUÁRIO",
      endpoint: "/users-mesa-analista-options",
      isVisible: {
        show: true,
        filter: true,
        new: true,
      },
    },
    userInfo: {
      id: "userInfo",
      type: "TEXT",
      label: "CONTATO",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => (
          <ListUserContact record={record} id={id} />
        ),
      },
    },
    email: {
      id: "email",
      type: "TEXT",
      label: "EMAIL",
      isVisible: {
        show: true,
      },
    },
    name: {
      id: "name",
      type: "TEXT",
      label: "NOME",
      isVisible: {
        show: true,
      },
    },
    profile: {
      id: "profile",
      type: "SELECT",
      options: [
        {
          label: "Executivo Master",
          value:
            "000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000",
        },
        {
          label: "Mesa Master",
          value:
            "000000000000000000000000000100000000000000000100100000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000000",
        },
        {
          label: "Mesa Analista",
          value:
            "000000000000000000000000000000000000000001000100000000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000000",
        },
        {
          label: "Cliente Master",
          value:
            "000000000000000000000000000000001000000000000100000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000",
        },
        {
          label: "Cliente Comum",
          value:
            "000000000000000000000000000000000010000000000100000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000",
        },
      ],
      label: "PERFIL",
      component: {
        list: ({ record, id }: any) => <ListProfile record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowProfile record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    companyDetails: {
      id: "companyDetails",
      type: "TEXT",
      label: "EMPRESA/UNIDADE",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => (
          <ListUserCompany record={record} id={id} />
        ),
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
      },
    },
    companyName: {
      id: "companyName",
      label: "EMPRESA",
      type: "TEXT",
      isVisible: {
        show: true,
      },
    },
    facilityId: {
      id: "facilityId",
      label: "UNIDADE",
      type: "SELECT_API",
      endpoint: "/facilities-options",
      isVisible: {
        filter: true,
        edit: true,
        new: true,
      },
    },
    facilityName: {
      id: "facilityName",
      label: "UNIDADE",
      type: "TEXT",
      isVisible: {
        show: true,
      },
    },
    status: {
      id: "status",
      label: "STATUS",
      type: "SELECT",
      options: [
        {
          label: "CONFIRMADO",
          value: "CONFIRMED",
        },
        {
          label: "TROCA DE SENHA",
          value: "FORCE_CHANGE_PASSWORD",
        },
      ],
      component: {
        list: ({ record, id }: any) => (
          <ListUserStatus record={record} id={id} />
        ),
        show: ({ record, label, id }: any) => (
          <ShowUserStatus record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
      },
    },
    createDate: {
      id: "createDate",
      type: "TEXT",
      label: "CRIADO EM",
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
      },
    },
    modifiedDate: {
      id: "modifiedDate",
      type: "TEXT",
      label: "MODIFICADO EM",
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        show: true,
      },
    },
    Enabled: {
      id: "Enabled",
      type: "SELECT",
      label: "ATIVO?",
      options: [
        {
          label: "Ativo",
          value: true,
        },
        {
          label: "Inativo",
          value: false,
        },
      ],
      component: {
        list: ({ record, id }: any) => <ListActive record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowActive record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
      },
    },
    terms: {
      id: "terms",
      type: "SELECT",
      label: "TERMO?",
      options: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
      component: {
        list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowActive record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
      },
    },
  },
};
