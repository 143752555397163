import { RecordProps } from "../../../../types/default";
import { Container } from "./style";

export const ListConversion = ({ id, record }: RecordProps) => {
  const value = record;

  if (!value) {
    return null;
  }

  const { unit } = value;
  const inputValue = value[id];
  let hz, cpm;

  if (unit === "CPM") {
    hz = inputValue * 60;
    cpm = inputValue;
  } else {
    hz = inputValue;
    cpm = inputValue / 60;
  }

  return (
    <Container>
      <span>{(hz ?? 0).toFixed(5)} Hz</span>
      <span>{(cpm ?? 0).toFixed(5)} CPM</span>
    </Container>
  );
};
