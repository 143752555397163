import styled from "styled-components";
export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;

  & > div {
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    border-radius: 8px;
  }
  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  & button:hover {
    cursor: pointer;
    border-radius: 100%;

    svg {
      color: ${(props) => props.theme.colors.ibbxOrange};
    }
  }
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  height: 28px;
  input {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: none;
    background: ${(props) => props.theme.colors.cardBackground};
    color: ${(props) => props.theme.colors.iconsSecondary};
    font-size: 10px;
    font-weight: 800;
    padding: 10px;
    height: 28px;

    :focus {
      border: none !important;
      outline: none;
    }
    :focus-visible{
      outline: none;
    }
  }

  & > div {
    border-radius: 8px;
    border: none;

    & > div {
      & > button {
        ::after {
          background: ${(props) => props.theme.colors.iconsSecondary}!important;
          color: ${(props) => props.theme.colors.cardBackground}!important;
        }
      }
    }
  }

  .react-datepicker {
    border: none;

    & > div {
      & > div:first-child {
        div {
          color: ${(props) => props.theme.colors.iconsSecondary}!important;
        }
        background: ${(props) => props.theme.colors.cardBackground}!important;
      }
      & > div:last-child {
        margin: 0;
        padding: 0.4rem;
        background: ${(props) => props.theme.colors.cardBackground}!important;
      }
    }

    div:last-child {
      border: none;
      div:first-child {
      }
      div:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 5;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
`;
