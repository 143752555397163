import { Container, LogoContainer, Logo, MenuContainer } from "./styles";

import LogoWhite from "../../images/ibbxWhite.svg";
import MenuDropProfile from "../MenuDropProfile";

export const Header = () => {
  return (
    <Container>
      <LogoContainer to="/">
        <Logo>
          <img width="42" src={LogoWhite} alt="IBBx" />
        </Logo>
      </LogoContainer>
      <MenuContainer>
        <MenuDropProfile />
      </MenuContainer>
    </Container>
  );
};
