import { ListDate } from "../../../components/List/ListDate";
import { del } from "../../../utils/api";

export const BoardSignalResource = {
  title: "Sinais dos sensores",
  urlPath: "/configurator/board-signals",
  apiPath: "/configurator/board-signals",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: {
      isVisible: true,
      handler: async (values: any) => {
        await del({
          path: `/configurator/board-signals/${values.boardId}`,
          queryStringParameters: {
            gatewayId: values.gatewayId,
          },
        });
      },
    },
  },
  properties: {
    boardId: {
      id: "boardId",
      type: "TEXT",
      label: "ID sensor",
      isPrimaryKey: true,
      isVisible: {
        list: true,
        filter: true,
      },
    },
    gatewayId: {
      id: "gatewayId",
      type: "TEXT",
      label: "ID bolt",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    date: {
      id: "date",
      type: "TEXT",
      label: "Data",
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
      },
      isVisible: {
        list: true,
      },
    },
    rssi: {
      id: "rssi",
      type: "TEXT",
      label: "RSSI",
      isVisible: {
        list: true,
      },
    },
    hit: {
      id: "hit",
      type: "TEXT",
      label: "HIT",
      isVisible: {
        list: true,
      },
    },
  },
};
