import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Loading,
  PageHeader,
  PageTemplate,
  Input,
} from "../../components";

import { Container, ContentContainer, Form, FormActions } from "./styles";

import useApi from "../../hooks/useApi";
import { success } from "../../components/Toast";
import { EditProps } from "./types";

export const Edit = ({ resource }: EditProps) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const { request, processing } = useApi({ path: resource.apiPath });

  // // Resource state
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [dirty, setDirty] = useState<any>({});

  function getRecord() {
    request({
      method: "get",
      pathParameters: `${params.id}`,
    }).then((response: any) => {
      setValues(response);
    });
  }

  function putRecord() {
    const body = Object.keys(dirty).reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: values[curr],
      };
    }, {});

    request({
      method: "put",
      pathParameters: `${params.id}`,
      body,
    })
      .then(() => {
        success("Editado com sucesso!");
        navigate(`${resource.urlPath}`);
      })
      .catch((err) => {
        const { data } = err?.response || {};
        const formsErrors = data.reduce((acc: any, curr: any) => {
          return {
            ...acc,
            [curr.field]: curr.message,
          };
        }, {});

        setErrors(formsErrors);
      });
  }

  useEffect(() => {
    getRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, location.key]);

  const editProperties = [
    ...Object.values(resource.properties).filter(
      (propertie: any) => !propertie.isVisible || propertie.isVisible?.edit
    ),
  ];

  function renderEdit() {
    return (
      <Form>
        {editProperties.map((property: any) => (
          <Input
            {...property}
            value={values[property.id]}
            onChange={(e: any) => {
              setDirty((prevState: any) => ({
                ...prevState,
                [property.id]: true,
              }));

              setValues((prevState: any) => ({
                ...prevState,
                [property.id]: e.target.value,
              }));
            }}
            error={errors[property.id]}
          />
        ))}
        <FormActions>
          <Button onClick={() => navigate(`${resource.urlPath}`)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => putRecord()}>
            Salvar
          </Button>
        </FormActions>
      </Form>
    );
  }

  return (
    <>
      <PageTemplate>
        <>
          <Container>
            <PageHeader resource={resource} record={values} />
            <ContentContainer>
              {processing ? <Loading /> : renderEdit()}
            </ContentContainer>
          </Container>
        </>
      </PageTemplate>
    </>
  );
};
