import { Badge } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  width: 100%;
  width: calc(100vw - 300px);
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  /* height: calc(100vh - 130px); */
`;

export const ColumnHeader = styled.div`
  margin: 8px 0 4px 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H4Bold};

  /* width: 100px; */

  & > span {
    display: flex;
    align-items: center;
  }

  & > div:first-child {
    display: flex;
    align-items: flex-end;
    gap: 6px;
  }
`;

export const TableContainer = styled.div`
  /* height: 75%; */
  height: calc(100vh - 350px);
  overflow: auto;

  .table-responsive {
    width: 100%;
    overflow-x: unset;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
  //FIREFOX
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 24px;
    max-width: 84%;
  }
`;

export const StatusContainer = styled(Badge)<{ fontSize?: string }>`
  margin-left: 5px;
  padding: 5px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "11px")};
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  &:first-child {
    margin-left: 0;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 10px;

  & > div:first-child {
    width: 8%;
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
  }
`;

export const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px; */
  margin-right: 5px;

  span {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ClickableTableValue = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const SummaryChartByProblemContainer = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;

  & > div {
    padding: 3px;
    display: flex;
    label {
      width: 50%;
    }
  }
`;

export const ChartBar = styled.div<{ width?: string }>`
  flex: 1;

  div {
    padding: 2px 2px 2px 5px;
    border-radius: 6px;
    width: ${(props) => (props.width ? props.width : "10%")};
    background: ${(props) => props.theme.colors.ibbxOrange};
  }
`;

export const RankingContainer = styled.div`
  position: absolute;
  left: 200px;
  background-color: ${(props) => props.theme.backgroundColors.background3};
  z-index: 1;
  padding: 10px;
  border-radius: 10px;
  width: 30%;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
`;

export const StyledButton = styled.button`
  padding: 4px;
  border-radius: 100%;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  transition: 0.15s ease-in-out;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  :hover {
    background-color: ${(props) => props.theme.colors.iconsPrimary};

    svg {
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
