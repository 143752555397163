import { Form } from "react-bootstrap";
import { InputContainer } from "../styles";
import { CheckProps } from "./types";

export const Check = ({ label, name, onChange, error, value }: CheckProps) => {
  return (
    <InputContainer>
      <Form.Check
        checked={!!value}
        id={name}
        name={name}
        type="checkbox"
        label={label}
        onChange={(e) => {
          onChange({ target: { value: e.target.checked } });
        }}
      />
      {error ? <div className="error">{error}</div> : null}
    </InputContainer>
  );
};
