import { MdOpenInNew } from "react-icons/md";
import { ColumnHeader, TableValue } from "../../List/styles";
import { ReactTable } from "../../../components/Table/ReactTable";
import { SensorsStatusTableContainer } from "./styles";
import { ENDPOINT_STATUS_PROBLEMS_CONFIG, STATUS_LABEL } from "../types";
import { useMemo, useReducer } from "react";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { useOrderBy } from "../../../hooks/userOrderBy";
import { OrderButton } from "../../../components/OrderButton";

export default function SensorsStatusTable({ list = [] }: any) {
  const { handleOrder, orderedList, orderState } = useOrderBy({ list });

  return (
    <SensorsStatusTableContainer>
      <div>{list?.length} Registros</div>
      <ReactTable
        responsive
        data={orderedList || []}
        columns={[
          {
            id: "boardId",
            accessor: "boardId",
            Header: () => (
              <ColumnHeader>
                <span>BOARD ID</span>
              </ColumnHeader>
            ),
            Cell: ({ value, row: { original } }: any) => {
              return (
                <TableValue>
                  {value}
                  <MdOpenInNew
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RETINA_INFRA_URL}/summary/endpoints/${original.boardId}/history`,
                        "_blank"
                      )
                    }
                  />
                </TableValue>
              );
            },
          },
          {
            id: "positionName",
            accessor: "positionName",
            Header: () => (
              <ColumnHeader>
                <span>Ponto</span>
              </ColumnHeader>
            ),
            Cell: ({ value, row: { original } }: any) => {
              return (
                <TableValue>
                  {value}
                  <MdOpenInNew
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RETINA_WEB_URL}/positions/${original.positionId}`,
                        "_blank"
                      )
                    }
                  />
                </TableValue>
              );
            },
          },
          {
            id: "status",
            accessor: "status",
            Header: () => (
              <ColumnHeader>
                <span>Status</span>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return <TableValue>{STATUS_LABEL[value] || value}</TableValue>;
            },
          },
          {
            id: "flag",
            accessor: "flag",
            Header: () => (
              <ColumnHeader>
                <span>PROBLEMA</span>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return (
                <TableValue>
                  {ENDPOINT_STATUS_PROBLEMS_CONFIG[value]?.label || ""}
                </TableValue>
              );
            },
          },
          {
            id: "companyName",
            accessor: "companyName",
            Header: () => (
              <ColumnHeader>
                <div>
                  <span>EMPRESA</span>
                  <OrderButton
                    orderState={orderState}
                    handleOrder={handleOrder}
                    columnName="companyName"
                  />
                </div>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return <TableValue>{value}</TableValue>;
            },
          },

          {
            id: "facilityName",
            accessor: "facilityName",
            Header: () => (
              <ColumnHeader>
                <div>
                  <span>UNIDADE</span>
                  <OrderButton
                    orderState={orderState}
                    handleOrder={handleOrder}
                    columnName="facilityName"
                  />
                </div>
              </ColumnHeader>
            ),
            Cell: ({ value, row: { original } }: any) => {
              return (
                <TableValue>
                  {value}{" "}
                  <MdOpenInNew
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${original.companyId}/facilities/${original.facilityId}/assets?sidetab=bolt`,
                        "_blank"
                      )
                    }
                  />
                </TableValue>
              );
            },
          },
          {
            id: "assetName",
            accessor: "assetName",
            Header: () => (
              <ColumnHeader>
                <div>
                  <span>Ativo</span>
                </div>
              </ColumnHeader>
            ),
            Cell: ({ value, row: { original } }: any) => {
              return (
                <TableValue>
                  {value}
                  <MdOpenInNew
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${original.companyId}/facilities/${original.facilityId}/assets/${original.assetId}`,
                        "_blank"
                      )
                    }
                  />
                </TableValue>
              );
            },
          },
        ]}
      />
    </SensorsStatusTableContainer>
  );
}
