import styled from "styled-components";

export const Container = styled.div``;

export const Button = styled.div``;

export const CollectContainer = styled.pre`
  background-color: #fff;
  color: #000;
  overflow-y: auto;
  max-height: 500px;
`;
