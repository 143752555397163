import styled from "styled-components";

export const ButtonCancel = styled.button<{ isEsgPanel?: boolean }>`
  background: transparent;
  min-width: 126px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  border: none;
  border-radius: 6px;
  margin-right: 10px;
  /* z-index: 10; */
  ${(props) => ({ ...props.theme.fonts.primary.H5SemiBold })}
  color: ${(props) =>
    props.isEsgPanel ? "black" : props.theme.colors.iconsPrimary};
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsPrimary} !important;
    background: transparent !important;
    color: ${(props) =>
      props.isEsgPanel ? "black" : props.theme.colors.iconsPrimary} !important;
  }

  &:active {
    background: transparent !important;
    border: none !important;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.iconsPrimary};
  }
`;

export const ContainerConfirmModal = styled.div<{ isEsgPanel?: boolean }>`
  margin-top: 8px;
  word-break: normal;
  min-height: 32px;

  & > label {
    ${({ theme }) => theme.fonts.primary.H4Regular};
    color: ${(props) => (props.isEsgPanel ? "black" : "")};
    text-align: center;
    line-height: 24px;
  }
`;
