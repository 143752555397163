import styled from "styled-components";

export const SummaryCounterContainer = styled.div`
  border-radius: 8px;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.colors.iconsPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  height: 30px;
  ${({ theme }) => theme.fonts.primary.H4Bold};
`;

export const SummaryListHeaderContainer = styled.div`
  display: flex;
  gap: 16px;

  h1 {
    display: flex;
    align-items: center;
    height: 100%;
    ${({ theme }) => theme.fonts.primary.H3SemiBold};
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

export const ContainerScanner = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 20px;
  gap: 24px;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.2 : "")};

  h1 {
    ${(props) => props.theme.fonts.primary.H2Bold};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  span {
    ${(props) => props.theme.fonts.primary.H5Regular}
  }

  svg {
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    gap: 10px;

    & > div {
      display: flex;
      flex-direction: column;

      width: 100%;
      input {
        ${(props) => props.theme.fonts.primary.H4Regular};
        width: 100%;
        min-height: 36px;
        margin-top: 4px;
        color: ${(props) => props.theme.colors.iconsPrimary};
        background: ${(props) => props.theme.colors.background};
        border: ${(props) => props.theme.colors.cardHighlight};
        border-radius: 4px;
        padding: 8px;
        border: 1px solid ${(props) => props.theme.colors.cardHighlight};

        &:hover {
          border: 1px solid ${(props) => props.theme.colors.IconsTools};
        }
      }
    }

    button {
      height: 36px;
      min-width: 70px;
      ${(props) => (props.theme.fonts.primary.H4Regular)}
      background: ${(props) => props.theme.colors.background};
      border: ${(props) => props.theme.colors.cardHighlight};
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};
      border-radius: 5px;
      color: ${(props) => props.theme.colors.iconsPrimary};

      &:active {
        border: none;
      }
    }
  }

  @media screen and (max-width:767px) {
    input {
      font-size: 1rem;
    }
    padding: .3rem;
  };
`;
