import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Loading,
  PageHeader,
  PageTemplate,
  Input,
} from "../../components";

import { Container, ContentContainer, Form, FormActions } from "./styles";

import useApi from "../../hooks/useApi";
import { success } from "../../components/Toast";
import { NewProps } from "./types";

export const New = ({ resource }: NewProps) => {
  const navigate = useNavigate();

  const { request, processing } = useApi({ path: resource.apiPath });

  // // Resource state
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  function postRecord() {
    request({
      method: "post",
      body: values,
    })
      .then(() => {
        success("Criado com sucesso!");
        navigate(`${resource.urlPath}`);
      })
      .catch((err) => {
        const { data } = err?.response || {};
        const formsErrors = data.reduce((acc: any, curr: any) => {
          return {
            ...acc,
            [curr.field]: curr.message,
          };
        }, {});

        setErrors(formsErrors);
      });
  }

  const newProperties = [
    ...Object.values(resource.properties).filter(
      (propertie: any) => !propertie.isVisible || propertie.isVisible?.new
    ),
  ];

  function renderForm() {
    return (
      <Form>
        {newProperties.map((property: any) => (
          <Input
            {...property}
            key={property.id}
            value={values[property.id]}
            onChange={(e: any) => {
              setValues((prevState: any) => ({
                ...prevState,
                [property.id]: e.target.value,
              }));
            }}
            error={errors[property.id]}
          />
        ))}
        <FormActions>
          <Button onClick={() => navigate(`${resource.urlPath}`)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => postRecord()}>
            Salvar
          </Button>
        </FormActions>
      </Form>
    );
  }

  return (
    <>
      <PageTemplate>
        <>
          <Container>
            <PageHeader resource={resource} />
            <ContentContainer>
              {processing ? <Loading /> : renderForm()}
            </ContentContainer>
          </Container>
        </>
      </PageTemplate>
    </>
  );
};
