import { Form } from "react-bootstrap";

import { InputContainer } from "../styles";

import { InputTextProps } from "./types";

export const InputText = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  error,
}: InputTextProps) => {
  return (
    <InputContainer>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        name={name}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {error ? <div className="error">{error}</div> : null}
    </InputContainer>
  );
};
