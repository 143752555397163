import styled, { css } from "styled-components";

type statusCondition = {
  status?: "PENDING" | "APPROVED" | "REJECTED";
  positionId?: number;
  visualInspection?: boolean;
};

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  flex-direction: column;
  display: flex;
  gap: 1rem;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 4.5rem;
  border-radius: 6px 6px 0 0;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  padding: 0 16px;
  h2 {
    ${(props) => props.theme.fonts.primary.H2Bold}
    margin-bottom: 0;
  }
`;

const StandardStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResponsibleContainer = styled(StandardStyled)`
  position: relative;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.75rem 1rem;
  background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;
  color: ${(props) => props.theme.colors.iconsSecondary};
  span {
    ${(props) => props.theme.fonts.primary.H4Bold}
  }
`;

export const Lotdescription = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 1rem;

  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};

  label {
    ${(props) => props.theme.fonts.primary.H3SemiBold}
    margin-right: 0.25rem;
  }

  span {
    ${(props) => props.theme.fonts.primary.H4Regular}
  }
`;

export const BurninTable = styled.table`
  display: block;
  overflow-y: auto;

  thead {
    ${(props) => props.theme.fonts.primary.H5Bold}
    background-color: ${(props) => props.theme.colors.headerBackground};
    border-bottom: 2px solid
      ${(props) => props.theme.colors.iconsSecondaryAlpha};
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    display: table-header-group;
  }

  th,
  td {
    padding: 0.5rem 0.75rem;
    position: relative;
  }

  tbody {
    ${(props) => props.theme.fonts.primary.H4Regular}
    display: table-row-group;
    width: 100%;
  }

  tr {
    overflow: hidden;
    display: table-row;
    width: auto;

    select {
      border: 1px solid transparent;
      border-radius: 6px;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.textPrimary};
      margin-left: 0.7rem;
      margin-top: 0.5rem;

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      }
    }
  }

  tr:nth-child(even) {
    background-color: ${(props) => props.theme.colors.cardBackground};
  }

  td {
    width: 93px;
    word-wrap: break-word;

    @media screen and (min-width: 767px) and (max-width: 1536px) {
      width: 72px;
    }

    @media screen and (min-width: 1536px) and (max-width: 1700px) {
      width: 93px;
      
    }

    @media screen and (min-width: 1700px) {
      width: 200px;
    }
  }

  &::-webkit-scrollbar {
    width: 7px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background} !important;
    width: 20px !important;
    border-radius: 100vw !important;
    margin-block: -4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.iconsSecondaryAlpha} !important;
    height: 100% !important;
    max-height: 5rem !important;
    border-radius: 100vw !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.iconsSecondary} !important;
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.iconsSecondary} !important;
  }
`;

export const Tr = styled.tr<statusCondition>`
  > td:first-child {
    padding-left: 20px;
    position: relative;

    &::before {
      content: "";
      border-radius: 5px;
      background-color: ${(props) => props.theme.colors.lightBackground};
      background-color: ${({ status, theme }) =>
        status === "APPROVED"
          ? theme.colors.normalStatus
          : status === "REJECTED" && theme.colors.alarmRisco} !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      height: 90%;
      width: 5px;
      opacity: 0.8;
    }
  }

  color: ${({ status, theme }) =>
    status === "APPROVED"
      ? theme.colors.normalStatus
      : status === "REJECTED" && theme.colors.alarmRisco} !important;

  td {
    opacity: ${({ positionId }) => (positionId ? 1 : 0.4)};
  }

  td:nth-child(11) {
    opacity: 1;
  }
`;

export const Td = styled.td<statusCondition>`
  color: ${({ visualInspection, theme }) =>
    visualInspection === true
      ? theme.colors.normalStatus
      : visualInspection === false
      ? theme.colors.alarmRisco
      : theme.colors.textPrimary} !important;
`;

export const AddSensorContainer = styled(StandardStyled)`
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  div {
    display: flex;
    border-radius: 8px;
    height: 2.5rem;
    width: 40%;
    border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
    overflow: hidden;

    input {
      width: 100%;
      padding: 0.5rem 1rem;
      background-color: ${({ theme }) => theme.colors.background};
      ${({ theme }) => theme.fonts.primary.H4Regular};
      border: none;
      outline: none;
      color: ${({ theme }) => theme.colors.iconsPrimary};
    }
  }
`;

export const RefreshButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  color: ${({ theme }) => theme.colors.iconsPrimary};
  padding: 0.59rem 1rem;
  border-radius: 6px;
  ${({ theme }) => theme.fonts.primary.H4Bold};

  :hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  }
  transition: all 0.3s ease;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  button {
    border: 1px solid transparent;
    background-color: ${(props) => props.theme.colors.ibbxOrange};
    color: ${(props) => props.theme.colors.iconsPrimary};
    padding: 12px;
    border-radius: 6px;

    &:hover {
      border: 1px solid ${(props) => props.theme.colors.lightOrange};
    }
    transition: all 0.3s ease;
  }
`;

export const AddDeviceContainer = styled.div<{
  addDevice?: boolean;
  changeStatus?: boolean;
}>`
  width: 100%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  header,
  section,
  footer {
    display: flex;
    width: 100%;
  }

  header {
    ${({ theme }) => theme.fonts.primary.H2Bold};
    justify-content: space-between;
    flex-direction: ${({ addDevice }) => (addDevice ? "row" : "column")};
    gap: 0.5rem;

    & > span,
    p {
      width: 100%;
      text-align: center;
    }
    p {
      font-size: 0.85rem;
      font-weight: 400;
      margin-bottom: 0;
    }

    span {
      margin-bottom: ${({ changeStatus }) => (changeStatus ? "0.25em" : "0")};
    }

    div {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 0.35rem;
      ${({ theme }) => theme.fonts.primary.H2Bold};
      justify-content: center;

      select {
        border: 1px solid transparent;
        border-radius: 6px;
        background-color: transparent;
        color: ${({ theme }) => theme.colors.textPrimary};
        margin-top: -2px;

        :hover {
          border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
        }
      }
    }

    .select-slots {
      color: #3a4058;
    }

    .select-slots:disabled {
      cursor: not-allowed !important;
      color: #3a405840;
    }

    button {
      border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      background-color: transparent;
      padding: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      color: ${({ theme }) => theme.colors.iconsSecondary};

      svg {
        font-size: 1.25rem;
      }

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.iconsSecondary};
        color: ${({ theme }) => theme.colors.iconsSecondary};

        background-color: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      }
      transition: all 0.3s ease;
    }

    .sensorReproved {
      input[type="text"] {
        height: 100%;
        width: 100%;
        padding: 0.5rem;
        background-color: ${({ theme }) => theme.colors.background};
        ${({ theme }) => theme.fonts.primary.H4Regular};
        border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
        border-radius: 6px;
        outline: none;
        color: ${({ theme }) => theme.colors.iconsPrimary};
        text-align: center;
      }
    }
  }
  & > section {
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: ${({ changeStatus }) => (changeStatus ? "0.25em" : "1.25rem")};

    .addDeviceInputContainer {
      width: 100%;
      height: 2.5rem;
    }

    .rejectionContainer {
      border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      gap: 0.5rem;
      background-color: ${({ theme }) => theme.colors.headerBackground};
      width: 100%;
      flex: 1;
    }

    span {
      width: 100%;
      text-align: left;
      ${({ theme }) => theme.fonts.primary.H3SemiBold}
      font-weight: 400;

      ${({ changeStatus }) =>
        changeStatus &&
        css`
          ${({ theme }) => theme.fonts.primary.H4Regular}
        `};
    }

    label {
      ${({ theme }) => theme.fonts.primary.H3SemiBold};
      font-weight: 400;
    }

    input[type="text"] {
      height: 100%;
      width: 100%;
      padding: 0.5rem 0.75rem;
      background-color: ${({ theme }) => theme.colors.background};
      ${({ theme }) => theme.fonts.primary.H4Regular};
      border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      border-radius: 6px;
      outline: none;
      color: ${({ theme }) => theme.colors.iconsPrimary};
    }

    input[type="checkbox"] {
      border: 3px solid red;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
      border-radius: 6px;
      outline: none;
      background-color: ${(props) => props.theme.colors.lightBackground};
      cursor: pointer;
      position: relative;

      &:checked {
        background-color: ${(props) => props.theme.colors.statusRisk};
        border: 2px solid ${(props) => props.theme.colors.alarmRiscoAlpha};
      }

      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 6px;
        top: 25%;
        left: 20%;
        transform: translate(-50%, -50%);
        border: 2px solid white;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        background: transparent;
        opacity: 0;
        transition: opacity 0.2s;
      }

      &:checked:before {
        opacity: 1;
      }
    }
  }

  footer {
    justify-content: space-between;

    .rejectionButton {
      background-color: ${(props) => props.theme.colors.statusRisk};

      &:hover {
        background-color: ${(props) =>
          props.theme.colors.statusRisk} !important;
      }
    }

    .saveButton,
    .rejectionButton {
      :disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: 75vh;

    gap: 0.5rem;

    section {
      gap: 0.5rem;
    }
  }
`;

export const CloseButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  background-color: transparent;
  padding: 0.25rem;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.iconsSecondary};

  svg {
    font-size: 1.25rem;
  }

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.iconsSecondary};
    color: ${({ theme }) => theme.colors.iconsSecondary};

    background-color: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  }
  transition: all 0.3s ease;
`;

export const SelectRejectionItem = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;

  label {
    cursor: pointer;
    width: 100%;
  }
  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid ${(props) => props.theme.colors.statusRisk};
      background-color: #691a3c5c;
    `}

  &:hover {
    background-color: #691a3c5c;
  }
  transition: all 0.3s ease;
`;

export const AddDeviceButton = styled.button`
  ${({ theme }) => theme.fonts.primary.H4Bold};
  display: flex;
  gap: 0.5rem;
  border: 1px solid transparent;
  background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  color: ${(props) => props.theme.colors.iconsPrimary};
  padding: 0.5rem 1rem;
  border-radius: 6px;

  svg {
    font-size: 1.25rem;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  }
  transition: all 0.3s ease;
`;

export const TableEmpty = styled.div`
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

export const StepProgress = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin: 0 8px;
`;

export const ContainerScanner = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 55px) !important;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    section {
      width: 90%;
    }

    @media screen and (min-width: 767px) and (max-width: 1536px) {
      section {
        width: 60%;
      }
    }
  }
`;

export const ChangeStatusTable = styled.table`

  thead {
    ${(props) => props.theme.fonts.primary.H5Bold}
    background-color: ${(props) => props.theme.colors.headerBackground};
    border-bottom: 2px solid
      ${(props) => props.theme.colors.iconsSecondaryAlpha};
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    display: table-header-group;
  }

  th,
  td {
    padding: 0.5rem 0.75rem;
    position: relative;
  }

  tbody {
    ${(props) => props.theme.fonts.primary.H4Regular}
    display: table-row-group;
    width: 100%;
  }

  tr {
    overflow: hidden;
    display: table-row;
    width: 100%;

    select {
      border: 1px solid transparent;
      border-radius: 6px;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.textPrimary};

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
      }
    }
  }

  tr:nth-child(even) {
    background-color: ${(props) => props.theme.colors.cardBackground};
  }

  td {
    width: 93px;
    /* padding: 0.5rem 0.75rem; */
    word-wrap: break-word;
  }
`
