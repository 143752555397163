import React, { useState } from "react";
import { Modal } from "../Modal";
import { Container, Button } from "./styles";
import { ModalConfirmProps } from "./types";

export const ModalConfirm = ({
  message,
  handleConfirm,
  children,
}: ModalConfirmProps) => {
  const [show, setShow] = useState(false);

  const onConfirm = () => {
    const closeModal = handleConfirm();
    if (closeModal) {
      setShow(false);
    }
  };

  return (
    <>
      <Button onClick={() => setShow(true)}>{children}</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        handleConfirm={onConfirm}
        confirmLabel="Sim"
      >
        <Container>
          <span>{message}</span>
        </Container>
      </Modal>
    </>
  );
};
