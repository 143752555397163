import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.headerBackground};
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoContainer = styled(Link)``;

export const Logo = styled.div`
  background-color: ${(props) => props.theme.colors.ibbxOrange};
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
`;

export const MenuContainer = styled.div`
  background-color: ${(props) => props.theme.colors.headerBackground};
  color: ${(props) => props.theme.colors3.color0};
  text-align: center;
  width: 80px;
`;
