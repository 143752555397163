import styled from "styled-components";

export const OrderByContainers = styled.div<{ selected?: boolean }>`
  button {
    background: ${(props) =>
      props.selected ? props.theme.colors.iconsPrimary : "transparent"};
    border-radius: 100%;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: ${(props) =>
        props.selected
          ? props.theme.colors.cardBackground
          : props.theme.colors.iconsSecondary};
    }
  }
`;
