export const formatterLoginErrorMessage = (message: string): string => {
  if (message.includes("Incorrect username or password.")) {
    return "Email ou senha inválido.";
  }

  if (message.includes("User is disabled.")) {
    return "Usuário está inativo, entre em contato com um administrador para ter sua conta reativada!";
  }

  if (message.includes("InvalidPasswordException")) {
    return "A senha precisa ter no mínimo 8 caracteres, formada por números, letras maiúsculas, minúsculas e caracteres especiais.";
  }

  if (message.includes("NotAuthorizedException")) {
    return "Senha Atual Incorreta!";
  }

  if (message.includes("LimitExceededException")) {
    return "Limite de troca de senha excedido!";
  }

  return message;
};
