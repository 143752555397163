import axios from "axios";
import { useState } from "react";

interface useTranslationProps {
  maxTokens?: number;
}

interface useTranslationReturn {
  translate: ({ valuePT }: { valuePT: string }) => Promise<any>;
  response: any;
  processing: Boolean;
}

export function useTranslation({
  maxTokens = 300,
}: useTranslationProps): useTranslationReturn {
  const [response, setResponse] = useState<any>([]);
  const [processing, setProcessing] = useState(false);

  const apiUrl = `https://api.openai.com/v1/engines/gpt-3.5-turbo-instruct/completions`;

  const OPENAI_API_KEY = "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";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${OPENAI_API_KEY}`,
  };

  function parse({ answer }: { answer: string }) {
    if (answer && typeof answer === "string") {
      answer = answer.replace("pt:", `"pt":`);
      answer = answer.replace("en:", `"en":`);
      answer = answer.replace("es:", `"es":`);
      return JSON.parse(answer);
    }
  }

  const translate = async ({ valuePT }: { valuePT: string }) => {
    setProcessing(true);

    const promptChat = `Translate "${valuePT}" returning an object with the properties pt which will be the translation into Portuguese, en which will be the translation into English and es which will be the translation into Spanish, and dosen't change the portuguese text.`;

    const data = {
      prompt: promptChat,
      max_tokens: maxTokens,
      temperature: 0.5,
      n: 1,
    };

    let parsedAnswer = {};
    try {
      const res = await axios.post(apiUrl, data, { headers });
      let answer = res?.data?.choices[0]?.text || "";

      try {
        parsedAnswer = parse({ answer });
      } catch (err) {
        // console.log("Error parsing JSON:", err);
      }

      setResponse({ version: parsedAnswer });
    } catch (err) {
      console.log(err);
    } finally {
      setProcessing(false);
    }

    return parsedAnswer;
  };

  return { translate, response, processing };
}
