import { ChangeEvent, useEffect, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  PageHeader,
  PageTemplate,
} from "../../../../../components";
import { error, success } from "../../../../../components/Toast";
import useApi from "../../../../../hooks/useApi";
import { Modal } from "../../../../../components/Modal";
import { PROFILES_BITS } from "../../../../../store/slices/userSlice";

import {
  Container,
  ContentContainer,
  Form,
  FormActions,
  ModalContent,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from "./styles";

import { NewCustomProps, announcementValues } from "./types";
import { useTranslation } from "./hooks/useTranslation";
import { get, unset } from "lodash";

interface InputItem {
  id: number;
  value: string;
}

interface Item {
  id: number;
  name: {
    pt?: string;
    en?: string;
    es?: string;
  };
}

export const NewAnnouncement = ({ resource }: NewCustomProps) => {
  const navigate = useNavigate();

  const { request } = useApi({ path: resource.apiPath });
  const { request: requestFacilities } = useApi({
    path: "/facilities-options",
  });

  const initialAnnouncementValues: announcementValues = {
    version: {
      pt: "",
      en: "",
      es: "",
    },
    title: {
      pt: "",
      en: "",
      es: "",
    },
    videoUrl: "",
    detailsLink: "",
    companyId: undefined,
    detailsTitleButton: {
      pt: "",
      en: "",
      es: "",
    },
    closedTitleButton: {
      pt: "",
      en: "",
      es: "",
    },
  };

  // Resource state
  const [values, setValues] = useState<announcementValues>(
    initialAnnouncementValues
  );
  const [errors, setErrors] = useState<any>({});
  const [inputValue, setInputValue] = useState<any>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [facilities, setFacilities] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [postProcessing, setPostProcessing] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAddItem = (e: any, language: string) => {
    e.preventDefault();

    if (inputValue.trim() !== "") {
      const newItem: Item = {
        id: Date.now(),
        name: { [language]: inputValue },
      };

      setItems([...items, newItem]);
      setInputValue("");
    }
  };

  const handleRemoveItem = (itemId: number, e?: any) => {
    const updatedItems = items.filter((item) => item.id !== itemId);
    setItems(updatedItems);
  };

  const handleSubmit = () => {
    setShowModal(true);
  };

  function postRecord() {
    request({
      method: "post",
      body: { ...values, items },
      skipToast: true,
    })
      .then(() => {
        success("Criado com sucesso!");
        navigate(`${resource.urlPath}`);
      })
      .catch((err) => {
        error("Dados invalidos");
        console.log(err);
        const { data } = err?.response || {};
        const formsErrors = data.reduce((acc: any, curr: any) => {
          return {
            ...acc,
            [curr.field]: curr.message,
          };
        }, {});

        setErrors(formsErrors);
      });
    // console.log({ ...values, items });
  }

  useEffect(() => {
    if (values.companyId) {
      requestFacilities({
        method: "get",
        queryStringParameters: {
          companyId: values.companyId,
        },
      }).then((response: any) => {
        setFacilities(response);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.companyId]);

  const { translate, processing } = useTranslation({});

  const handleTranslation = async () => {
    const keys = [
      "version",
      "title",
      "detailsTitleButton",
      "closedTitleButton",
    ];

    keys.forEach((key) => {
      translate({ valuePT: get(values, key)?.pt })
        .then((translate) => {
          if (typeof translate === "object") {
            setValues((value) => ({
              ...value,
              [key]: {
                pt: get(values, key)?.pt,
                en: translate.en,
                es: translate.es,
              },
            }));
          } else {
            error("Alguns campos não puderam ser traduzidos.");
            setValues((value) => ({
              ...value,
              [key]: { pt: get(values, key)?.pt, en: "", es: "" },
            }));
          }
        })
        .catch(() => {
          error("Alguns campos não puderam ser traduzidos.");
        });
    });

    items.map((item) => {
      // eslint-disable-next-line array-callback-return
      if (!item.name?.pt) return;

      return translate({ valuePT: item.name?.pt })
        .then((translate) => {
          setItems((value) => {
            return value?.map((i) => {
              if (i.id === item.id) {
                if (typeof translate === "object") {
                  return {
                    ...i,
                    name: {
                      pt: item.name?.pt,
                      en: translate.en,
                      es: translate.es,
                    },
                  };
                } else {
                  error("Alguns campos não puderam ser traduzidos.");

                  return {
                    ...i,
                    name: { pt: item.name?.pt, en: "", es: "" },
                  };
                }
              }
              return i;
            });
          });
        })
        .catch(() => {
          error("Alguns campos não puderam ser traduzidos.");
        });
    });

    success("Tradução realizada com sucesso.");
  };

  function renderForm() {
    return (
      <Form>
        <TabsRoot aria-label="tab1" defaultValue="tab1">
          <TabsList aria-label="">
            <TabsTrigger value="tab1">Português</TabsTrigger>
            <TabsTrigger value="tab2">Inglês</TabsTrigger>
            <TabsTrigger value="tab3">Espanhol</TabsTrigger>
            <Button
              variant="primary"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleTranslation();
              }}
              disabled={!!processing}
            >
              Traduzir
            </Button>
          </TabsList>
          {Object.keys(values.version).map((language, index) => {
            return (
              <TabsContent value={`tab${index + 1}`} key={language}>
                <Input
                  id={""}
                  type={"TEXT"}
                  label={'VERSÃO - EX: "JUNHO 2023"'}
                  value={get(values.version, language)}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setValues((value) => ({
                      ...value,
                      version: { ...value.version, [language]: e.target.value },
                    }));
                  }}
                />

                <Input
                  id={""}
                  type={"TEXT"}
                  label={"TÍTULO"}
                  value={get(values.title, language)}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setValues((value) => ({
                      ...value,
                      title: { ...value.title, [language]: e.target.value },
                    }));
                  }}
                />

                <Input
                  id={""}
                  type={"TEXT"}
                  label={"TEXTO DO BOTÃO DE ACESSO AO CONTEÚDO DETALHADO"}
                  value={get(values.detailsTitleButton, language)}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setValues((value) => ({
                      ...value,
                      detailsTitleButton: {
                        ...value.detailsTitleButton,
                        [language]: e.target.value,
                      },
                    }));
                  }}
                />

                <Input
                  id={""}
                  type={"TEXT"}
                  label={"TEXTO DO BOTÃO DE FECHAR O MODAL DE DETALHES"}
                  value={get(values.closedTitleButton, language)}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setValues((value) => ({
                      ...value,
                      closedTitleButton: {
                        ...value.closedTitleButton,
                        [language]: e.target.value,
                      },
                    }));
                  }}
                />

                <span>
                  Adicione abaixo os principais tópicos da atualização:
                </span>

                <Input
                  id={""}
                  type={"TEXT"}
                  label={"ADICIONAR ITEM A LISTA"}
                  value={inputValue}
                  onChange={handleInputChange}
                />

                <Button onClick={(e) => handleAddItem(e, language)}>
                  Adicionar item
                </Button>

                <ul>
                  {items.map((item) => (
                    <div key={item.id}>
                      {/* <li key={item.id}>{get(item.name, language)}</li> */}
                      <input
                        value={get(item.name, language)}
                        onChange={(e) => {
                          const updatedItems = items.map((i) => {
                            if (i.id === item.id) {
                              return {
                                ...i,
                                name: { ...i.name, [language]: e.target.value },
                              };
                            }
                            return i;
                          });

                          setItems(updatedItems);
                        }}
                      />

                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemoveItem(item.id);
                        }}
                      >
                        Remover
                      </button>
                    </div>
                  ))}
                </ul>
              </TabsContent>
            );
          })}
        </TabsRoot>
        <Input
          id={""}
          type={"TEXT"}
          label={"LINK DO VÍDEO"}
          value={values.videoUrl}
          onChange={function (e: any): void {
            setValues({ ...values, videoUrl: e.target.value });
            // throw new Error("Function not implemented.");
          }}
        />

        <Input
          id={""}
          type={"TEXT"}
          label={"LINK DO CONTEÚDO DETALHADO"}
          value={values.detailsLink}
          onChange={function (e: any): void {
            setValues({ ...values, detailsLink: e.target.value });
            // throw new Error("Function not implemented.");
          }}
        />

        <FormActions>
          <Button onClick={() => navigate(`${resource.urlPath}`)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={(e: any) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            Salvar
          </Button>
        </FormActions>

        <Modal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleConfirm={postRecord}
          confirmLabel={"Confirmar"}
        >
          <ModalContent>
            <h2>Confirmação de Revisão de Dados</h2>
            <span>Todos os dados foram revisados?</span>
          </ModalContent>
        </Modal>
      </Form>
    );
  }

  return (
    <PageTemplate>
      <>
        <Container>
          <PageHeader resource={resource} />
          <ContentContainer>{renderForm()}</ContentContainer>
        </Container>
      </>
    </PageTemplate>
  );
};
