import { RecordProps } from "../../../types/default";
import { ListConversion } from "../components/ListConversion";

export const BearingResource = {
  title: "Rolamentos",
  urlPath: "/infra/bearings",
  apiPath: "/infra/bearings",
  actions: {},
  properties: {
    id: {
      id: "id",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        list: false,
        filter: true,
        show: true,
      },
    },
    bearingCode: {
      id: "bearingCode",
      type: "TEXT",
      label: "Código do Rolamento",
      isVisible: {
        list: true,
        new: true,
        show: true,
        filter: true,
        edit: true,
      },
    },
    BPFI: {
      id: "BPFI",
      type: "TEXT",
      label: "BPFI",
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListConversion id={id} record={record} />
        ),
      },
      isVisible: {
        list: true,
        new: true,
        show: true,
        edit: true,
      },
    },
    BPFO: {
      id: "BPFO",
      type: "TEXT",
      label: "BPFO",
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListConversion id={id} record={record} />
        ),
      },
      isVisible: {
        list: true,
        new: true,
        show: true,
        edit: true,
      },
    },
    BSF: {
      id: "BSF",
      type: "TEXT",
      label: "BSF",
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListConversion id={id} record={record} />
        ),
      },
      isVisible: {
        list: true,
        new: true,
        show: true,
        edit: true,
      },
    },
    cageInner: {
      id: "cageInner",
      type: "TEXT",
      label: "Gaiola / Interna",
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListConversion id={id} record={record} />
        ),
      },
      isVisible: {
        list: true,
        new: true,
        show: true,
        edit: true,
      },
    },
    unit: {
      id: "unit",
      type: "SELECT",
      label: "Unidade",
      isVisible: {
        new: true,
      },
      options: [
        { label: "Selecione", value: null },
        { label: "Hz", value: "Hz" },
        { label: "CPM", value: "CPM" },
      ],
    },
  },
};
