import { RecordProps } from "../../../types/default";
import { ListTranslations } from "../components/ListTranslations";

export const DiagnosticResource = {
  title: "Diagnósticos",
  urlPath: "/infra/diagnostics",
  apiPath: "/diagnostics",
  actions: {},
  properties: {
    id: {
      id: "id",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        list: false,
        filter: true,
        show: true,
      },
    },
    name: {
      id: "name",
      type: "TEXT",
      label: "NOME",
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListTranslations id={id} record={record} type="name" />
        ),
      },
      isVisible: {
        list: true,
        new: false,
      },
    },
    namePT: {
      id: "namePT",
      type: "TEXT",
      label: "NOME PT",
      isVisible: {
        edit: true,
        new: true,
      },
    },
    nameEN: {
      id: "nameEN",
      type: "TEXT",
      label: "NOME EN",
      isVisible: {
        new: true,
        edit: true,
      },
    },
    nameES: {
      id: "nameES",
      type: "TEXT",
      label: "NOME ES",
      isVisible: {
        new: true,
        edit: true,
      },
    },
    // caption: {
    //   id: "caption",
    //   type: "TEXT",
    //   label: "LABEL",
    // },
    sugestion: {
      id: "sugestion",
      type: "TEXT",
      label: "SUGESTÃO",
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListTranslations id={id} record={record} type="sugestion" />
        ),
      },
      isVisible: {
        list: true,
        show: true,
        edit: false,
        new: false,
      },
    },
    sugestionPT: {
      id: "sugestionPT",
      type: "TEXT",
      label: "SUGESTÃO PT",
      isVisible: {
        edit: true,
        new: true,
      },
    },
    sugestionEN: {
      id: "sugestionEN",
      type: "TEXT",
      label: "SUGESTÃO EN",
      isVisible: {
        edit: true,
        new: true,
      },
    },
    sugestionES: {
      id: "sugestionES",
      type: "TEXT",
      label: "SUGESTÃO ES",
      isVisible: {
        edit: true,
        new: true,
      },
    },
    sensorTypeName: {
      id: "sensorTypeName",
      type: "TEXT",
      label: "Tipo de Monitoramento",
      isVisible: {
        list: true,
        show: true,
      },
    },
    sysSensorTypeId: {
      id: "sysSensorTypeId",
      type: "SELECT_API",
      label: "Tipo de Monitoramento",
      endpoint: "/sensor-type-options",
      isVisible: {
        filter: true,
        edit: true,
        new: true,
      },
    },
  },
};
