import { useState, useMemo } from "react";
import { Modal } from "../../../../components/Modal";
import SearchScanner from "./SearchScanner";
import {
  ContainerScanner,
  SummaryCounterContainer,
  SummaryListHeaderContainer,
} from "./styles";
import QrReader from "react-web-qr-reader";

type SummaryHistoryHeaderProps = {
  type: "ENDPOINTS" | "GATEWAYS";
  filtered?: number;
  total?: number;
  title: string;
  handleSearch: Function;
};

function SummaryHistoryHeader({
  type,
  filtered = 0,
  total = 1,
  handleSearch,
  title,
}: SummaryHistoryHeaderProps) {
  const percent = +((filtered / total) * 100).toFixed(5);
  const [show, setShow] = useState(false);
  const [cameraDenied, setCameraDenied] = useState(false);
  const [value, setValue] = useState("");

  function handleClose() {
    setShow(false);
  }

  function handleScan(result: any) {
    if (result) {
      const formattedCode = result.data?.toUpperCase()?.replaceAll("-", "");
      if (result.data.includes("/main/?id=")) {
        setValue(formattedCode.split("=")[1]);
      } else {
        setValue(formattedCode);
      }

      handleSearch(formattedCode);
      handleClose();
    }
  }

  const handleError = (error: any) => {
    if (error.toString().includes("Permission denied")) {
      setCameraDenied(true);
    }
  };

  const camera = useMemo(() => {
    if (show) {
      return true;
    } else {
      return false;
    }
  }, [show]);

  return (
    <>
      <SummaryListHeaderContainer>
        <h1>{title}</h1>
        <SummaryCounterContainer>
          {filtered} / {percent}%
        </SummaryCounterContainer>
        <SearchScanner
          type={type}
          setShow={setShow}
          value={value}
          setValue={setValue}
          handleSearch={handleSearch}
        />
      </SummaryListHeaderContainer>
      <Modal
        show={show}
        handleClose={handleClose}
        handleConfirm={() => null}
        hideSaveButton
      >
        <>
          <ContainerScanner>
            <div>
              {cameraDenied && (
                <div>
                  <label> Sem Permissão para usar Câmera </label>
                </div>
              )}
              {!cameraDenied && camera && (
                <div>
                  <QrReader
                    delay={5}
                    //style={previewStyle}
                    onError={handleError}
                    onScan={handleScan}
                    // facing mode = "environment" for mobile
                    facingMode={"user"}
                  />
                </div>
              )}
            </div>
          </ContainerScanner>
        </>
      </Modal>
    </>
  );
}

export default SummaryHistoryHeader;
