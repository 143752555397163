import styled from "styled-components";

export const ListContainer = styled.a`
  width: 200px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.textPrimary};
  }

  & div {
    & > span:first-child {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;

export const GatewayId = styled.span`
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H6Bold};
  font-weight: normal !important;
  font-size: 9px !important;
`;
