import styled from "styled-components";

export const PrincipalContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  padding: 8px 8px 12px 8px;
  border-radius: 6px;
  flex-direction: column;
  display: flex;
  height: 20rem;
  overflow-y: auto;

  input {
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${({ theme }) => theme.fonts.primary.H4Regular};
    border-radius: 4px;
    padding: 8px;
    height: 34px;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 10px;
  width: 100%;
  justify-content: space-between;

  & > input:nth-child(1) {
    width: 22%;
  }
  & > input:nth-child(2) {
    width: 42%;
  }
  & > input:nth-child(1) {
    width: 22%;
  }
`;

export const ButtonDelete = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 100%;
  background: transparent;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  :hover {
    background: ${(props) => props.theme.colors.iconsPrimary};

    & > svg {
      color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    }
  }
`;

export const AddUserButton = styled.button`
  border-radius: 100%;
  background: ${(props) => props.theme.colors.ibbxOrange};
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    color: white;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
