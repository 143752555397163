import { RecordProps } from "../../../types/default";
import { AssetTypeImageList } from "../components/AssetTypeImage/List";
import { ListTranslations } from "../components/ListTranslations";

export const AssetTypesResource = {
  title: "Tipos de Equipamentos",
  urlPath: "/infra/asset-types",
  apiPath: "/infra/asset-types",
  actions: {},
  properties: {
    id: {
      id: "id",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        list: false,
        filter: true,
        show: true,
      },
    },
    name: {
      id: "name",
      type: "TEXT",
      label: "NOME",
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListTranslations id={id} record={record} type="name" />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    namePT: {
      id: "namePT",
      type: "TEXT",
      label: "NOME PT",
      isVisible: {
        new: true,
        edit: true,
      },
    },
    nameEN: {
      id: "nameEN",
      type: "TEXT",
      label: "NOME EN",
      isVisible: {
        new: true,
        edit: true,
      },
    },
    nameES: {
      id: "nameES",
      type: "TEXT",
      label: "NOME ES",
      isVisible: {
        new: true,
        edit: true,
      },
    },
    url: {
      id: "url",
      type: "TEXT",
      label: "URL",
      isVisible: {
        list: true,
        show: true,
      },
    },
    image: {
      id: "image",
      type: "IMAGE",
      label: "IMAGEM",
      component: {
        list: ({ record, id }: RecordProps) => (
          <AssetTypeImageList record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
        new: true,
        edit: true,
      },
    },
  },
};
