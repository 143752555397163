import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Input,
  PageHeader,
  PageTemplate,
} from "../../../../../components";
import { error, success } from "../../../../../components/Toast";
import useApi from "../../../../../hooks/useApi";
import { PROFILES_BITS } from "../../../../../store/slices/userSlice";

import { Container, ContentContainer, Form, FormActions } from "./styles";

import { NewCustomProps } from "./types";

export const NewCustom = ({ resource }: NewCustomProps) => {
  const navigate = useNavigate();

  const { request } = useApi({ path: resource.apiPath });
  const { request: requestFacilities } = useApi({
    path: "/facilities-options",
  });

  // // Resource state
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const [facilities, setFacilities] = useState<any>([]);

  function postRecord() {
    request({
      method: "post",
      body: values,
      skipToast: true,
    })
      .then(() => {
        success("Criado com sucesso!");
        navigate(`${resource.urlPath}`);
      })
      .catch((err) => {
        error("Dados invalidos");
        console.log(err);
        const { data } = err?.response || {};
        const formsErrors = data.reduce((acc: any, curr: any) => {
          return {
            ...acc,
            [curr.field]: curr.message,
          };
        }, {});

        setErrors(formsErrors);
      });
  }

  useEffect(() => {
    if (values.companyId) {
      requestFacilities({
        method: "get",
        queryStringParameters: {
          companyId: values.companyId,
        },
      }).then((response: any) => {
        setFacilities(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.companyId]);

  function renderForm() {
    return (
      <Form>
        <Input
          {...resource.properties.name}
          value={values[resource.properties.name.id]}
          onChange={(e: any) => {
            setValues((prevState: any) => ({
              ...prevState,
              [resource.properties.name.id]: e.target.value,
            }));
          }}
          error={errors[resource.properties.name.id]}
        />
        <Input
          {...resource.properties.email}
          value={values[resource.properties.email.id]}
          onChange={(e: any) => {
            setValues((prevState: any) => ({
              ...prevState,
              [resource.properties.email.id]: e.target.value,
            }));
          }}
          error={errors[resource.properties.email.id]}
        />
        <Input
          {...resource.properties.profile}
          value={values[resource.properties.profile.id]}
          onChange={(e: any) => {
            setValues((prevState: any) => ({
              ...prevState,
              [resource.properties.profile.id]: e.target.value,
            }));
          }}
          error={errors[resource.properties.profile.id]}
        />
        {[PROFILES_BITS.CLIENTE_MASTER, PROFILES_BITS.CLIENTE_COMUN].includes(
          values["profile"]
        ) && (
          <Input
            {...resource.properties.companyId}
            value={values[resource.properties.companyId.id]}
            onChange={(e: any) => {
              setValues((prevState: any) => ({
                ...prevState,
                [resource.properties.companyId.id]:
                  e.target.value === "EMPRESA" ? null : e.target.value,
              }));
              setFacilities([]);
            }}
            error={errors[resource.properties.companyId.id]}
          />
        )}
        {[PROFILES_BITS.CLIENTE_COMUN].includes(values["profile"]) && (
          <Input
            {...resource.properties.facilityId}
            type="SELECT"
            options={[
              {
                value: null,
                label: !values["companyId"] ? "Selecione a empresa" : "Unidade",
              },
              ...facilities,
            ]}
            disabled={!values["companyId"]}
            value={values[resource.properties.facilityId.id]}
            onChange={(e: any) => {
              setValues((prevState: any) => ({
                ...prevState,
                [resource.properties.facilityId.id]:
                  e.target.value === "Unidade" ? null : e.target.value,
              }));
            }}
            error={errors[resource.properties.facilityId.id]}
          />
        )}
        <FormActions>
          <Button onClick={() => navigate(`${resource.urlPath}`)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={(e: any) => {
              e.preventDefault();
              postRecord();
            }}
          >
            Salvar
          </Button>
        </FormActions>
      </Form>
    );
  }

  return (
    <PageTemplate>
      <>
        <Container>
          <PageHeader resource={resource} />
          <ContentContainer>{renderForm()}</ContentContainer>
        </Container>
      </>
    </PageTemplate>
  );
};
