import { useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { Modal } from "../../../components/Modal";
import { CSVDownload } from "react-csv";
import { Button, Container, Title, CloseButton } from "./styles";
import { MdClose } from "react-icons/md";

interface ExportQualityCSVProps {
  record: {
    id: number;
  };
}

interface QualityInspectionDevicesData {
  id: number;
  qualityInspectionId: number;
  created_at: string;
  created_by: string;
  updatedAt: string;
  updatedBy: string;
  positionId: number;
  positionName: string;
  activatorId: number;
  initialVisualInspectionStatus: "PENDING" | "APPROVED" | "REJECTED";
  initialVisualInspectionDetails: string;
  burninStatus: "PENDING" | "APPROVED" | "REJECTED";
  burninDetails: string;
  finalVisualInspectionDetails: string;
  packingInspectionStatus: "PENDING" | "APPROVED" | "REJECTED";
  packingInspectionDetails: string;
}

export const ExportQualityCSV = ({ record }: ExportQualityCSVProps) => {
  const csvRef = useRef<any>(null);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<String>("");
  const [completed, setCompleted] = useState(false);
  const [data, setData] = useState<QualityInspectionDevicesData[]>([]);

  const { request } = useApi({
    path: `/infra/quality-inspections/${record.id}/devices`,
  });

  const date = new Date();

  async function loadData() {
    setMessage("Buscando dados da inspeção...");
    setCompleted(false);

    const response = await request({
      method: "get",
    });

    const formattedData = response.map((item: any) => {
      return {
        ...item,
        initialVisualInspectionDetails: JSON.stringify(
          item.initialVisualInspectionDetails
        ),
        burninDetails: JSON.stringify(item.burninDetails),
        finalVisualInspectionDetails: JSON.stringify(
          item.finalVisualInspectionDetails
        ),
        packingInspectionDetails: JSON.stringify(item.packingInspectionDetails),
      };
    });

    setData(formattedData);
    setMessage("Dados da inspeção exportados!");
    setCompleted(true);
  }

  function handleLoadData() {
    loadData();
    setShow(true);
  }

  return (
    <>
      <Button onClick={handleLoadData}>Exportar CSV</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        confirmLabel="Baixar"
        size="sm"
        disabledConfirm={false}
        changeFotter
      >
        <Container>
          <CloseButton onClick={() => setShow(false)}>
            <MdClose size={18} />
          </CloseButton>
          <Title>
            <strong>{message}</strong>
          </Title>
          {completed && (
            <CSVDownload
              separator=";"
              ref={csvRef}
              data={data.length > 0 ? data : []}
              asyncOnClick={true}
              filename={`${date.getDay()}/${date.getMonth() + 1}-${
                record.id
              }.csv`}
            ></CSVDownload>
          )}
        </Container>
      </Modal>
    </>
  );
};
