import { RecordProps } from "../../../../types/default";
import { ListContainer } from "./styles";

export const ListSchedule = ({ id, record }: RecordProps) => {
  const { gatewayId, schedulePosition, healthCheckDate, rssi, createdAt } =
    record;

  let healthCheckDateFormatted;
  try {
    healthCheckDateFormatted = new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "medium",
    }).format(new Date(healthCheckDate));
  } catch (error) {}

  return (
    <ListContainer>
      <div>
        <span>Bolt: </span>
        <span>{`${gatewayId}`}</span>{" "}
      </div>
      <div>
        <span>Posição: </span>
        <span>{schedulePosition}</span>
      </div>
      <div>
        <span>RSSI: </span>
        <span>{rssi?.toFixed(2)}</span>
      </div>
      <div>
        <span>Criado em: </span>
        <span>
          {createdAt &&
            new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(createdAt))}
        </span>
      </div>
      <div>
        <span>Ultimo sincronizado: </span>
        <span>{healthCheckDateFormatted}</span>
      </div>
    </ListContainer>
  );
};
