import { ResponsiveContainer } from "recharts";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  /* height: calc(100vh - 130px); */
`;

export const ChartContainer = styled(ResponsiveContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
`;
