import { ListContainer } from "../styles";
import { ListProps } from "../types";

export const ListPositionDetails = ({ record }: ListProps) => {
  let color;
  switch (record.statusName) {
    case "risco análise":
    case "risco confirmado":
      color = "red";
      break;
    case "alerta análise":
    case "alerta confirmado":
      color = "yellow";
      break;
    default:
      color = "green";
      break;
  }

  return (
    <ListContainer>
      <div>
        <span>Empresa: </span>
        <span>{record.companyName}</span>
      </div>
      <div>
        <span>Unidade: </span>
        <span>{record.facilityName}</span>
      </div>
      <div>
        <span>Equipamento: </span>
        <span>{record.assetName}</span>
      </div>
      <div>
        <span>Status: </span>
        <span style={{ color }}>{record.statusName}</span>
      </div>
    </ListContainer>
  );
};
