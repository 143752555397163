import { useEffect, useRef, useState } from "react";

import { Button, Input, PageTemplate } from "../../components";

import useApi from "../../hooks/useApi";

import {
  Container,
  ContainerFacilitySummary,
  ContainerSummary,
  ContainerTable,
  Header,
  Summary,
  SummaryBadge,
} from "./styles";
import { ReactTable } from "../../components/Table/ReactTable";
import { ColumnHeader, TableValue } from "../../components/Table/styles";
import { MdOpenInNew } from "react-icons/md";

import { CSVLink } from "react-csv";

export const FacilityStatus = () => {
  const csvRef = useRef<any>(null);

  const { request: requestFacilityOptions } = useApi({
    path: "/facilities-options",
  });
  const { request: requestData, processing } = useApi({
    path: "/monitorator/facility-status",
  });

  const [options, setOptions] = useState([]);
  const [facilityId, setFacilityId] = useState(null);
  const [data, setData] = useState<any>(null);
  const [positions, setPositions] = useState<any>(null);
  const [csv, setCSV] = useState<any>(null);

  const [filterConnectivity, setFilterConnectivity] = useState<any>(null);
  const [filterConsumption, setFilterConsumption] = useState<any>(null);
  const [filterVoltage, setFilterVoltage] = useState<any>(null);

  useEffect(() => {
    requestFacilityOptions({ method: "get" }).then((response) => {
      setOptions(response);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!facilityId) {
      requestData({
        method: "get",
        queryStringParameters: {
          facilityId,
        },
      }).then((response) => {
        setData(response);

        setPositions(response.positions);

        const csvData = response.positions.map((item: any) => {
          return {
            ID_EQUIPAMENTO: item.assetId,
            EQUIPAMENTO: item.assetName,
            GATEWAYS_BONS: item.availableGateways,
            CONSUMO_BATERIA: item.batteryConsumption,
            VOLTAGEM_BATERIA: item.batteryVoltage,
            UUID: item.boardId,
            ID_EMPRESA: item.companyId,
            ID_UNIDADE: item.facilityId,
            ID_PONTO: item.positionId,
            PONTO: item.positionName,
            TOTAL_GATEWAYS: item.totalGateways,
            GATEWAYS_RUINS: item.unavailableGateways,
          };
        });

        setCSV(csvData);
      });
    }
    // eslint-disable-next-line
  }, [facilityId]);

  useEffect(() => {
    if (!positions) {
      return;
    }

    if (filterConnectivity) {
      setPositions(
        positions.filter((item: any) => item.availableGateways === 0)
      );
    } else {
      setPositions(data.positions);
    }

    // eslint-disable-next-line
  }, [filterConnectivity]);

  useEffect(() => {
    if (!positions) {
      return;
    }

    if (filterConsumption) {
      setPositions(
        positions.filter((item: any) => item.batteryConsumption >= 1.2)
      );
    } else {
      setPositions(data.positions);
    }

    // eslint-disable-next-line
  }, [filterConsumption]);

  useEffect(() => {
    if (!positions) {
      return;
    }

    if (filterVoltage) {
      setPositions(positions.filter((item: any) => item.batteryVoltage <= 3.2));
    } else {
      setPositions(data.positions);
    }

    // eslint-disable-next-line
  }, [filterVoltage]);

  function render() {
    if (processing) {
      return <span>Loading...</span>;
    }

    if (!data) {
      return;
    }

    if (data && positions?.length === 0) {
      return <span>Unidade sem pontos ativados</span>;
    }

    const { summary } = data;

    const {
      countBoardsGood,
      totalBoards,
      countBoardsAlert,
      countBoardsOffline,
      countBatteryConsumptionAlert,
      countBatteryConsumptionCritical,
      countBatteryConsumptionGood,
      countBatteryVoltageAlert,
      countBatteryVoltageCritical,
      countBatteryVoltageGood,
      totalGateways,
    } = summary;

    const percentGood = countBoardsGood / totalBoards;
    const percentAlert = countBoardsAlert / totalBoards;
    const percentOffline = countBoardsOffline / totalBoards;

    let status = "success";
    let statusLabel = "Ótimo";
    if (percentGood < 0.9 && percentGood > 0.5) {
      status = "warning";
      statusLabel = "Atenção";
    } else if (percentGood < 0.5) {
      status = "danger";
      statusLabel = "Crítico";
    }

    const percentConsumptionGood = countBatteryConsumptionGood / totalBoards;
    const percentConsumptionAlert = countBatteryConsumptionAlert / totalBoards;
    const percentConsumptionCritical =
      countBatteryConsumptionCritical / totalBoards;

    let statusConsumption = "success";
    let statusLabelConsumption = "Ótimo";
    if (percentConsumptionGood < 0.9 && percentConsumptionGood > 0.5) {
      statusConsumption = "warning";
      statusLabelConsumption = "Atenção";
    } else if (percentConsumptionGood < 0.5) {
      statusConsumption = "danger";
      statusLabelConsumption = "Crítico";
    }

    const percentVoltageGood = countBatteryVoltageGood / totalBoards;
    const percentVoltageAlert = countBatteryVoltageAlert / totalBoards;
    const percentVoltageCritical = countBatteryVoltageCritical / totalBoards;

    let statusVoltage = "success";
    let statusLabelVoltage = "Ótima";
    if (percentVoltageGood < 0.9 && percentVoltageGood > 0.5) {
      statusVoltage = "warning";
      statusLabelVoltage = "Atenção";
    } else if (percentVoltageGood < 0.5) {
      statusVoltage = "danger";
      statusLabelVoltage = "Crítica";
    }

    return (
      <>
        <ContainerFacilitySummary>
          <strong>{`Pontos ativados: `}</strong>
          <span>{`${totalBoards}`}</span>
          <strong>{`Bolts ativados: `}</strong>
          <span>{`${totalGateways}`}</span>
        </ContainerFacilitySummary>
        <ContainerSummary>
          <Summary>
            <div>
              <strong>CONECTIVIDADE</strong>
              <SummaryBadge bg={status}>{statusLabel}</SummaryBadge>
            </div>
            <div>
              <strong>{`Pontos otimos (2+ bolts): `}</strong>
              <br />
              <span>{`${countBoardsGood} (${(percentGood * 100).toFixed(
                2
              )}%)`}</span>
            </div>
            <div>
              <strong>{`Pontos em alerta (1 bolt): `}</strong>
              <br />
              <span>{`${countBoardsAlert} (${(percentAlert * 100).toFixed(
                2
              )}%)`}</span>
            </div>
            <div>
              <strong>{`Pontos criticos (0 bolt): `}</strong>
              <br />
              <span>{`${countBoardsOffline} (${(percentOffline * 100).toFixed(
                2
              )}%)`}</span>
            </div>
          </Summary>
          <Summary>
            <div>
              <strong>CONSUMO</strong>
              <SummaryBadge bg={statusConsumption}>
                {statusLabelConsumption}
              </SummaryBadge>
            </div>
            <div>
              <strong>{`Pontos otimos (consumo <= 0.9)`}</strong>
              <br />
              <span>{`${countBatteryConsumptionGood} (${(
                percentConsumptionGood * 100
              ).toFixed(2)}%)`}</span>
            </div>
            <div>
              <strong>{`Pontos em alerta (consumo  > 0.9 e consumo < 1.2): `}</strong>
              <br />
              <span>{`${countBatteryConsumptionAlert} (${(
                percentConsumptionAlert * 100
              ).toFixed(2)}%)`}</span>
            </div>
            <div>
              <strong>{`Pontos criticos (consumo >= 1.2): `}</strong>
              <br />
              <span>{`${countBatteryConsumptionCritical} (${(
                percentConsumptionCritical * 100
              ).toFixed(2)}%)`}</span>
            </div>
          </Summary>
          <Summary>
            <div>
              <strong>VOLTAGEM</strong>
              <SummaryBadge bg={statusVoltage}>
                {statusLabelVoltage}
              </SummaryBadge>
            </div>
            <div>
              <strong>{`Pontos otimos (V >= 3.45): `}</strong>
              <br />
              <span>{`${countBatteryVoltageGood} (${(
                percentVoltageGood * 100
              ).toFixed(2)}%)`}</span>
            </div>
            <div>
              <strong>{`Pontos em alerta (V < 3.45 e  V > 3.2): `}</strong>
              <br />
              <span>{`${countBatteryVoltageAlert} (${(
                percentVoltageAlert * 100
              ).toFixed(2)}%)`}</span>
            </div>
            <div>
              <strong>{`Pontos criticos (V <= 3.2): `}</strong>
              <br />
              <span>{`${countBatteryVoltageCritical} (${(
                percentVoltageCritical * 100
              ).toFixed(2)}%)`}</span>
            </div>
          </Summary>
        </ContainerSummary>
        <ContainerTable>
          <ReactTable
            responsive
            data={positions || []}
            columns={[
              {
                id: "assetName",
                accessor: "assetName",
                Header: () => (
                  <ColumnHeader>
                    <span>EQUIPAMENTO</span>
                  </ColumnHeader>
                ),
                Cell: ({ value, row: { original } }: any) => {
                  return (
                    <TableValue>
                      <span>{value}</span>
                      <MdOpenInNew
                        onClick={() => {
                          const link = `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${original.companyId}/facilities/${original.facilityId}/assets/${original.assetId}?tab=positions`;
                          window.open(link, "_blank");
                        }}
                      />
                    </TableValue>
                  );
                },
              },
              {
                id: "positionName",
                accessor: "positionName",
                Header: () => (
                  <ColumnHeader>
                    <span>PONTO</span>
                  </ColumnHeader>
                ),
                Cell: ({ value, row: { original } }: any) => {
                  return (
                    <TableValue>
                      <span>{value}</span>
                      <MdOpenInNew
                        onClick={() => {
                          const link = `${process.env.REACT_APP_RETINA_WEB_URL}/positions/${original.positionId}?tab=global`;
                          window.open(link, "_blank");
                        }}
                      />
                    </TableValue>
                  );
                },
              },
              {
                id: "conectivity",
                accessor: "conectivity",
                Header: () => (
                  <ColumnHeader>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!filterConnectivity) {
                          setFilterConnectivity(true);
                        } else {
                          setFilterConnectivity(false);
                        }
                      }}
                    >
                      Conectividade
                    </span>
                  </ColumnHeader>
                ),
                Cell: ({ value, row: { original } }: any) => {
                  const { availableGateways } = original;

                  let status = "success";
                  let statusLabel = "Ótimo";
                  if (availableGateways === 1) {
                    status = "warning";
                    statusLabel = "Atenção";
                  } else if (availableGateways === 0) {
                    status = "danger";
                    statusLabel = "Crítico";
                  }

                  return (
                    <TableValue>
                      <SummaryBadge bg={status}>
                        <span
                          style={{ fontSize: "14px" }}
                        >{`${statusLabel} (${availableGateways})`}</span>
                      </SummaryBadge>
                    </TableValue>
                  );
                },
              },
              {
                id: "consumption",
                accessor: "consumption",
                Header: () => (
                  <ColumnHeader>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!filterConsumption) {
                          setFilterConsumption(true);
                        } else {
                          setFilterConsumption(false);
                        }
                      }}
                    >
                      CONSUMO (mAh)
                    </span>
                  </ColumnHeader>
                ),
                Cell: ({ value, row: { original } }: any) => {
                  const { batteryConsumption } = original;

                  // < 0.90 - GOOD, < 1.2 - ALERT, >= 1.2 CRITICAL
                  let status = "success";
                  if (batteryConsumption >= 0.9 && batteryConsumption < 1.2) {
                    status = "warning";
                  } else if (batteryConsumption >= 1.2) {
                    status = "danger";
                  }

                  return (
                    <TableValue>
                      <SummaryBadge bg={status}>
                        <span style={{ fontSize: "16px" }}>{`${
                          batteryConsumption
                            ? batteryConsumption.toFixed(2)
                            : "-"
                        }`}</span>
                      </SummaryBadge>
                    </TableValue>
                  );
                },
              },
              {
                id: "voltage",
                accessor: "voltage",
                Header: () => (
                  <ColumnHeader>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!filterVoltage) {
                          setFilterVoltage(true);
                        } else {
                          setFilterVoltage(false);
                        }
                      }}
                    >
                      VOLTAGEM (V)
                    </span>
                  </ColumnHeader>
                ),
                Cell: ({ value, row: { original } }: any) => {
                  // > 3.45 - GOOD, < 3.20 - ALERT, <= 3.20 CRITICAL
                  const { batteryVoltage } = original;

                  let status = "success";
                  if (batteryVoltage < 3.45 && batteryVoltage > 3.2) {
                    status = "warning";
                  } else if (batteryVoltage <= 3.2) {
                    status = "danger";
                  }

                  return (
                    <TableValue>
                      <SummaryBadge bg={status}>
                        <span style={{ fontSize: "16px" }}>{`${
                          batteryVoltage ? batteryVoltage.toFixed(2) : "-"
                        }`}</span>
                      </SummaryBadge>
                    </TableValue>
                  );
                },
              },
            ]}
          />
        </ContainerTable>
      </>
    );
  }

  const date = new Date();

  return (
    <PageTemplate>
      <Container>
        <Header>
          <h2>Status da unidade</h2>
          {!processing &&
            data &&
            data.positions &&
            data.positions.length > 0 && (
              <>
                <CSVLink
                  separator=";"
                  style={{ display: "none" }}
                  ref={csvRef}
                  data={csv}
                  filename={`pontos-unidade-${facilityId}-${date.getDay()}/${
                    date.getMonth() + 1
                  }.csv`}
                  className=""
                ></CSVLink>
                <Button
                  variant="primary"
                  onClick={() => {
                    csvRef.current.link.click();
                  }}
                >
                  Baixar CSV
                </Button>
              </>
            )}
        </Header>
        <Input
          id="facilityId"
          label="Selecione a unidade"
          type="SELECT"
          key="facilityId"
          value={facilityId}
          onChange={(e: any) => {
            setFacilityId(e.target.value);
          }}
          options={[{ value: null, label: "Selecione a unidade" }, ...options]}
        />
        <br />
        {render()}
      </Container>
    </PageTemplate>
  );
};
