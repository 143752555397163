import { Container } from "../styles";
import { ListProps } from "../types";

export const ListUserStatus = ({ record, id }: ListProps) => {
  let userStatus;

  switch (record[id]) {
    case "CONFIRMED":
      userStatus = "CONFIRMADO";
      break;
    case "FORCE_CHANGE_PASSWORD":
      userStatus = "TROCA DE SENHA";
      break;

    default:
      userStatus = record[id];
      break;
  }

  return <Container>{userStatus}</Container>;
};
