import { BuildRoutes } from "../../routes/BuildRoutes";

import { DiagnosticResource } from "./resources/Diagnostics";
import { UserResource } from "./resources/Users";
import { AssetTypesResource } from "./resources/AssetTypes";
import { BearingResource } from "./resources/Bearing";

const resourcesRoutes = [DiagnosticResource, UserResource, AssetTypesResource, BearingResource]
  .map((resource) => BuildRoutes(resource))
  .reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);

export const INFRA_ROUTES = [...resourcesRoutes];
