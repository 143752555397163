export const STATUS_CONFIG: any = {
  NORMAL: {
    value: "NORMAL",
    color: "#008000",
    label: "Normal",
    status: 1,
  },
  ALERT: {
    value: "NORMAL",
    color: "#E6B400",
    label: "Alerta",
    status: 1,
  },
  RISK: {
    value: "NORMAL",
    color: "#FF0000",
    label: "Risco",
    status: 1,
  },
};

// {
//   NEW_GATEWAY: "Novo Bolt",
//   NO_ENERGY_RISK: "Desenergizado",
//   NO_ENERGY_ALERT: "Desenergizado Alerta",
//   DISCONNECTED: "Desconectado",
//   INSTABLE_CONNECTION: "Conexão Instável",
//   WEAK_RSSI_RISK: "RSSI Fraco",
//   WEAK_RSSI_ALERT: "RSSI Fraco Alerta",
//   WRONG_SD_CARD: "Cartão",
// };

export const STATUS_PROBLEMS_CONFIG: any = {
  NEW_GATEWAY: {
    value: "NORMAL",
    color: "#DAD6CA",
    label: "Novo Gateway",
    status: 1,
  },
  DISCONNECTED: {
    value: "NORMAL",
    color: "#1BB0CE",
    label: "Desconectado",
    status: 1,
  },
  NO_ENERGY_RISK: {
    value: "NORMAL",
    color: "#4F8699",
    label: "Desenergizado",
    status: 1,
  },
  NO_ENERGY_ALERT: {
    value: "NORMAL",
    color: "#6A5E72",
    label: "Desenergizado Alerta",
    status: 1,
  },
  INSTABLE_CONNECTION: {
    value: "NORMAL",
    color: "#563444",
    label: "Conexão Instável",
    status: 1,
  },
  WEAK_RSSI_RISK: {
    value: "NORMAL",
    color: "#008000",
    label: "RSSI Fraco",
    status: 1,
  },
  WEAK_RSSI_ALERT: {
    value: "NORMAL",
    color: "#BFD3DA",
    label: "RSSI Fraco Alerta",
    status: 1,
  },
};

export const ENDPOINT_STATUS_PROBLEMS_CONFIG: any = {
  BOLT_OFF: {
    value: "NORMAL",
    color: "#DAD6CA",
    label: "Bolt Off",
    status: 1,
  },

  RSSI: {
    value: "NORMAL",
    color: "#4F8699",
    label: "RSSI",
    status: 1,
  },
  EXCESSIVE_CONSUMPTION: {
    value: "NORMAL",
    color: "#6A5E72",
    label: "Consumo Excessivo",
    status: 1,
  },
  NEGATIVE_TEMPERATURE: {
    value: "NORMAL",
    color: "#563444",
    label: "Temperatura Negativa",
    status: 1,
  },
  LOW_PERFORMANCE: {
    value: "NORMAL",
    color: "#008000",
    label: "Baixa Performance",
    status: 1,
  },
  DISCONNECTED: {
    value: "NORMAL",
    color: "#1BB0CE",
    label: "Desconectado",
    status: 1,
  },
  NO_BOLT: {
    value: "NORMAL",
    color: "red",
    label: "Sem Bolt",
    status: 1,
  },
};

export const STATUS_LABEL: any = {
  0: "NORMAL",
  1: "ALERTA",
  2: "RISCO",
};

export const ENDPOINT_DEAD_ALIVE_SERIES_NAMES: any = {
  Mortos: "endpointsOfflineFromYesterday",
  "Total Mortos": "endpointsOffline",
  Reviveram: "endpointsRevived",
  Desativados: "deactivatedEndpoints",
};
