import styled from "styled-components";

export const ListContainer = styled.div`
  width: 200px;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  & div {
    & > span:first-child {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;
