import { useMemo } from "react";
import CountUp from "react-countup";

import {
  CardStatusIoTContainer,
  CardIoTConnectivityContainer,
  DoughnutChartStatusIot,
  DoughnutHoleStatusIot,
} from "./styles";

const ROUNT_TO_THOUSAND = false;

const CardCollectPercent = ({ expected, received }: any) => {
  return (
    <CardStatusIoTContainer>
      <span>Coletas recebidas nas ultimas 24 horas</span>
      <CardIoTConnectivityContainer>
        <div>
          <DoughnutChartStatusIot connectivity={received / expected}>
            <DoughnutHoleStatusIot>
              <span>
                <CountUp
                  start={Math.floor((received / expected) * 100) || 0}
                  end={Math.floor((received / expected) * 100)}
                  duration={0.8}
                  separator="."
                />
                %
              </span>
            </DoughnutHoleStatusIot>
          </DoughnutChartStatusIot>
        </div>
        <div>
          <div>
            <span>
              <CountUp
                start={expected || 0}
                end={expected}
                duration={0.8}
                separator={"."}
              />
             { ROUNT_TO_THOUSAND ? "K" : ""}
            </span>
            <label>Coletas Esperadas</label>
          </div>
          <div>
            <span>
              <CountUp
                start={received || 0}
                end={received}
                duration={0.8}
                separator={"."}
              />
              { ROUNT_TO_THOUSAND ? "K" : ""}
            </span>
            <label>Coletas Recebidas</label>
          </div>
        </div>
      </CardIoTConnectivityContainer>
    </CardStatusIoTContainer>
  );
};

export default CardCollectPercent;
