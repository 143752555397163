import React, { useEffect, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { success } from "../../../../components/Toast";
import useApi from "../../../../hooks/useApi";

import { Container, Button } from "./styles";
import { Select } from "../../../../components/Input/Select";
import { useLocation, useNavigate } from "react-router-dom";

type ModalOperationFrequencyProps = {
  gatewayId: string;
};

const options = [
  { value: null, label: "Não Definido" },
  { value: 902.0, label: "902.0" },
  { value: 902.5, label: "902.5" },
  { value: 903.0, label: "903.0" },
  { value: 903.5, label: "903.5" },
  { value: 904.0, label: "904.0" },
  { value: 904.5, label: "904.5" },
  { value: 905.0, label: "905.0" },
  { value: 905.5, label: "905.5" },
  { value: 906.0, label: "906.0" },
  { value: 906.5, label: "906.5" },
  { value: 907.0, label: "907.0" },
  { value: 907.5, label: "907.5" },
  { value: 915.0, label: "915.0" },
  { value: 915.5, label: "915.5" },
  { value: 916.0, label: "916.0" },
  { value: 916.5, label: "916.5" },
  { value: 917.0, label: "917.0" },
  { value: 917.5, label: "917.5" },
  { value: 918.0, label: "918.0" },
  { value: 918.5, label: "918.5" },
  { value: 919.0, label: "919.0" },
  { value: 919.5, label: "919.5" },
  { value: 920.0, label: "920.0" },
  { value: 920.5, label: "920.5" },
  { value: 921.0, label: "921.0" },
  { value: 921.5, label: "921.5" },
  { value: 922.0, label: "922.0" },
  { value: 922.5, label: "922.5" },
  { value: 923.0, label: "923.0" },
  { value: 923.5, label: "923.5" },
  { value: 924.0, label: "924.0" },
  { value: 924.5, label: "924.5" },
  { value: 925.0, label: "925.0" },
  { value: 925.5, label: "925.5" },
  { value: 926.0, label: "926.0" },
  { value: 926.5, label: "926.5" },
  { value: 927.0, label: "927.0" },
  { value: 927.5, label: "927.5" },
  { value: 928.0, label: "928.0" },
];

export const ModalOperationFrequency = ({
  gatewayId,
}: ModalOperationFrequencyProps) => {
  const { request } = useApi({
    path: `/configurator/gateways/${gatewayId}`,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [initialOpFreq, setInitialOpFreq] = useState<any>(null);
  const [opFreq, setOpFreq] = useState<any>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      loadOperationFrequency();
    }
    // eslint-disable-next-line
  }, [show]);

  function loadOperationFrequency() {
    request({
      method: "get",
    }).then((response) => {
      setInitialOpFreq(response.operationFrequency.toString());
      setOpFreq(response.operationFrequency.toString());
    });
  }

  function handleSave() {
    if (opFreq !== initialOpFreq) {
      request({
        method: "put",
        body: {
          operationFrequency: opFreq,
        },
      }).then(() => {
        success("Frequência de Operação Alterada com Sucesso");
        setShow(false);
        navigate(location.pathname);
      });
    }
  }

  function handleChange(e: any) {
    setOpFreq(e.target.value);
  }

  return (
    <>
      <Button onClick={() => setShow(true)}>Frequências</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        handleConfirm={handleSave}
        confirmLabel="Salvar"
        size="lg"
      >
        <Container>
          <Select
            label="Frequência de Operação"
            value={opFreq}
            options={options}
            onChange={handleChange}
          />
        </Container>
      </Modal>
    </>
  );
};
