import { ContainerStatus } from "./styles";
import { ListProps } from "./types";

export const ListSensorConnected = ({ record }: ListProps) => {
  const { healthCheckDate } = record;

  const isConnected =
    healthCheckDate > new Date().getTime() - 1000 * 60 * 60 * 2;

  return (
    <ContainerStatus active={isConnected}>
      <span>{isConnected ? "SIM" : "NÃo"}</span>
    </ContainerStatus>
  );
};
