import { BuildRoutes } from "../../routes/BuildRoutes";

import { Endpoints } from "./resources/Endpoints";
import { Gateways } from "./resources/Gateways";

const resourcesRoutes = [Endpoints, Gateways]
  .map((resource) => BuildRoutes(resource))
  .reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);

export const SUMMARY_ROUTES = [...resourcesRoutes];
