import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.backgroundColors.background2};
`;
