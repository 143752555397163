import { useEffect, useMemo, useState } from "react";
import { HomeFilterContainer } from "./styles";
import useApi from "../../../hooks/useApi";
import DeviceHealthSelect from "../../GatewayHealthCheck/DeviceHealthSelect";

interface IHomeFilterBar {
  filters: any;
  setFilters: (filters: any) => void;
}

export default function HomeFilterBar({ filters, setFilters }: IHomeFilterBar) {
  // eslint-disable-next-line
  const { request: requestCompanies, processing: loadingOptions } = useApi({
    path: "/companies-options",
  });

  // eslint-disable-next-line
  const { request: requestFacilties, processing: loadingFacilities } = useApi({
    path: "/facilities-options",
  });

  const [companiesOptions, setCompaniesOptions] = useState<any[]>([]);
  const [facilitiesOptionsRaw, setFacilitiesOptionsRaw] = useState<any[]>([]);

  useEffect(() => {
    Promise.all([
      requestCompanies({ method: "get" }).then(setCompaniesOptions),
      requestFacilties({ method: "get" }).then(setFacilitiesOptionsRaw),
    ]);
    // eslint-disable-next-line
  }, []);

  const facilitiesOptions = useMemo(() => {
    if (filters.companyId) {
      return facilitiesOptionsRaw.filter(
        (item) => +item.companyId === +filters.companyId
      );
    }
    return [];
    // eslint-disable-next-line
  }, [filters.companyId]);

  const handleCompanyChange = (e: any) => {
    if (e?.value) {
      delete filters.facilityId;
      setFilters({ ...filters, companyId: e.value });
    } else {
      delete filters.companyId;
      delete filters.facilityId;
      setFilters({ ...filters });
    }
  };

  const handleFacilityChange = (e: any) => {
    if (e?.value) {
      setFilters({ ...filters, facilityId: e.value });
    } else {
      delete filters.facilityId;
      setFilters({ ...filters });
    }
  };

  return (
    <HomeFilterContainer>
      <DeviceHealthSelect
        label="Empresa"
        options={companiesOptions}
        handleChange={handleCompanyChange}
        isClearable={true}
      />
      <DeviceHealthSelect
        label="Unidade"
        options={facilitiesOptions}
        handleChange={handleFacilityChange}
        isClearable={true}
      />
    </HomeFilterContainer>
  );
}
