import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Input,
  PageHeader,
  PageTemplate,
} from "../../../../../components";
import { error, success } from "../../../../../components/Toast";
import useApi from "../../../../../hooks/useApi";
import { PROFILES } from "../../../../../store/slices/userSlice";

import { Container, ContentContainer, Form, FormActions } from "./styles";

import { NewCustomProps } from "./types";

export const EditCustom = ({ resource }: NewCustomProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { request } = useApi({ path: resource.apiPath });
  const { request: requestFacilities } = useApi({
    path: "/facilities-options",
  });

  // // Resource state
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [dirty, setDirty] = useState<any>({});

  const [facilities, setFacilities] = useState<any>([]);

  function getRecord() {
    request({
      method: "get",
      pathParameters: `${params.id}`,
    }).then((response: any) => {
      setValues(response);
    });
  }

  function putRecord() {
    const body = Object.keys(dirty).reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: values[curr],
      };
    }, {});

    if (values.profile === PROFILES.CLIENTE_COMUN && !values.facilityId) {
      setErrors({ ...errors, facilityId: "Campo obrigatorio" });
      return;
    }

    request({
      method: "put",
      pathParameters: `${params.id}`,
      body,
      skipToast: true,
    })
      .then(() => {
        success("Editado com sucesso!");
        navigate(`${resource.urlPath}`);
      })
      .catch((err) => {
        error("Dados invalidos");
        const { data } = err?.response || {};
        const formsErrors = data.reduce((acc: any, curr: any) => {
          return {
            ...acc,
            [curr.field]: curr.message,
          };
        }, {});

        setErrors(formsErrors);
      });
  }

  useEffect(() => {
    getRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, location.key]);

  useEffect(() => {
    if (values.companyId) {
      requestFacilities({
        method: "get",
        queryStringParameters: {
          companyId: values.companyId,
        },
      }).then((response: any) => {
        setFacilities(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.companyId]);

  useEffect(() => {
    if (
      values.profile &&
      [
        PROFILES.EXECUTIVO_MASTER,
        PROFILES.MESA_MASTER,
        PROFILES.MESA_ANALISTA,
      ].includes(values.profile)
    ) {
      setValues((prevState: any) => {
        const newState = { ...prevState };
        delete newState.companyId;
        delete newState.facilityId;
        return newState;
      });
    } else if (
      values.profile &&
      [PROFILES.CLIENTE_MASTER].includes(values.profile)
    ) {
      setValues((prevState: any) => {
        const newState = { ...prevState };
        delete newState.facilityId;
        return newState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.profile]);

  function renderForm() {
    return (
      <Form>
        <Input
          {...resource.properties.name}
          value={values[resource.properties.name.id]}
          onChange={(e: any) => {
            setDirty((prevState: any) => ({
              ...prevState,
              [resource.properties.name.id]: true,
            }));
            setValues((prevState: any) => ({
              ...prevState,
              [resource.properties.name.id]: e.target.value,
            }));
          }}
          error={errors[resource.properties.name.id]}
        />
        <Input
          {...resource.properties.email}
          value={values[resource.properties.email.id]}
          onChange={(e: any) => {
            setDirty((prevState: any) => ({
              ...prevState,
              [resource.properties.email.id]: true,
            }));
            setValues((prevState: any) => ({
              ...prevState,
              [resource.properties.email.id]: e.target.value,
            }));
          }}
          error={errors[resource.properties.email.id]}
        />
        <Input
          {...resource.properties.profile}
          value={values[resource.properties.profile.id]}
          onChange={(e: any) => {
            setDirty((prevState: any) => ({
              ...prevState,
              [resource.properties.profile.id]: true,
            }));
            setValues((prevState: any) => ({
              ...prevState,
              [resource.properties.profile.id]: e.target.value,
            }));
          }}
          error={errors[resource.properties.profile.id]}
        />
        {[PROFILES.CLIENTE_MASTER, PROFILES.CLIENTE_COMUN].includes(
          values["profile"]
        ) && (
          <Input
            {...resource.properties.companyId}
            value={values[resource.properties.companyId.id]}
            onChange={(e: any) => {
              setDirty((prevState: any) => ({
                ...prevState,
                [resource.properties.companyId.id]: true,
              }));
              setValues((prevState: any) => ({
                ...prevState,
                [resource.properties.companyId.id]:
                  e.target.value === "EMPRESA" ? null : e.target.value,
                facilityId: null,
              }));
              setFacilities([]);
            }}
            error={errors[resource.properties.companyId.id]}
          />
        )}
        {[PROFILES.CLIENTE_COMUN].includes(values["profile"]) && (
          <Input
            {...resource.properties.facilityId}
            type="SELECT"
            options={[
              {
                value: null,
                label: !values["companyId"] ? "Selecione a empresa" : "Unidade",
              },
              ...facilities,
            ]}
            disabled={!values["companyId"]}
            value={values[resource.properties.facilityId.id]}
            onChange={(e: any) => {
              setDirty((prevState: any) => ({
                ...prevState,
                [resource.properties.facilityId.id]: true,
                [resource.properties.companyId.id]: true,
              }));
              setValues((prevState: any) => ({
                ...prevState,
                [resource.properties.facilityId.id]: e.target.value,
              }));
            }}
            error={errors[resource.properties.facilityId.id]}
          />
        )}
        <FormActions>
          <Button onClick={() => navigate(`${resource.urlPath}`)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={(e: any) => {
              e.preventDefault();
              putRecord();
            }}
          >
            Salvar
          </Button>
        </FormActions>
      </Form>
    );
  }

  return (
    <PageTemplate>
      <>
        <Container>
          <PageHeader resource={resource} />
          <ContentContainer>{renderForm()}</ContentContainer>
        </Container>
      </>
    </PageTemplate>
  );
};
