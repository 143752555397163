import { useEffect, useMemo, useRef, useState } from "react";

import {
  Container,
  ContentContainer,
  CardStatusContainer,
  List,
  ListItem,
  BoltStatus,
  ListContainer,
  CardList,
  FilterContainer,
  FilterButton,
  FilterText,
  SensorDetailsContainer,
  InfoButtonContainer,
  Triangle,
  InfoContainer,
} from "./styles";
import { CardBolt } from "./CardBolt";
import { CardSensor } from "./CardSensor";
import { Modal } from "../../components/Modal";
import { PageTemplate } from "../../components/PageTemplate";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BsWifi, BsWifiOff } from "react-icons/bs";
import CountUp from "react-countup";
import useApi from "../../hooks/useApi";
import CardCollectPercent from "./CardCollectPercent";
import HomeFilterBar from "./HomeFilterBar";

const OFFLINE_TIME = 24 * 60 * 60 * 1000; // 24 horas;

export const Home = () => {
  const { request } = useApi({ path: `/monitorator/collects-metrics` });

  const [selectedBolt, setSelectedBolt] = useState<any>(null);
  const [selectedSensor, setSelectedSensor] = useState<any>(null);

  const [showModalBolt, setShowModalBolt] = useState(false);
  const [showModalSensor, setShowModalSensor] = useState(false);

  const [boltFilterStatus, setBoltFilterStatus] = useState("all");
  const [boltFilterText, setBoltFilterText] = useState("");

  const [sensorFilterStatus, setSensorFilterStatus] = useState("all");
  const [sensorFilterText, setSensorFilterText] = useState("");
  const [filters, setFilters] = useState<any>({});

  const optionsRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showInfo, setShowInfo] = useState(false);

  const [transmissions, setTransmissions] = useState({
    expectedCollects: 0,
    receivedCollects: 0,
    expectedCollectsK: 0,
    receivedCollectsK: 0,
  });

  const [endpoints, setEndpoints] = useState({
    online: 0,
    offline: 0,
    total: 0,
    list: [],
    expectedTransmissions: 0,
    receivedTransmissions: 0,
    averagePerformance: 0,
  });

  const [gateways, setGateways] = useState({
    online: 0,
    offline: 0,
    list: [],
  });

  function loadData() {
    const queryStringParameters: any = filters;

    request({ method: "get", queryStringParameters }).then((response: any) => {
      const { endpoints, gateways } = response;
      const { expectedTransmissions = 0, receivedTransmissions = 0 } =
        endpoints;

      setTransmissions({
        expectedCollects: expectedTransmissions || 0,
        receivedCollects: receivedTransmissions || 0,
        expectedCollectsK: +(expectedTransmissions / 1000).toFixed(2),
        receivedCollectsK: +(receivedTransmissions / 1000).toFixed(2),
      });
      setEndpoints(endpoints);
      setGateways(gateways);
    });
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const gatewaysList = useMemo(() => {
    const { list } = gateways;
    let filteredResponse: any[] = list;

    if (boltFilterStatus === "online") {
      filteredResponse = list.filter((item: any) => item.dashboardConnected);
    }

    if (boltFilterStatus === "offline") {
      filteredResponse = list.filter((item: any) => !item.dashboardConnected);
    }

    if (boltFilterText) {
      filteredResponse = filteredResponse.filter(
        ({ companyName, pk }) =>
          String(pk).includes(boltFilterText) ||
          String(companyName).includes(boltFilterText)
      );
    }

    return filteredResponse;
  }, [gateways, boltFilterStatus, boltFilterText]);

  const sensorList = useMemo(() => {
    const { list } = endpoints;
    let filteredResponse: any[] = list;
    if (sensorFilterStatus === "online") {
      filteredResponse = list.filter((item: any) => item.dashboardConnected);
    }

    if (sensorFilterStatus === "offline") {
      filteredResponse = list.filter((item: any) => !item.dashboardConnected);
    }

    if (sensorFilterText) {
      filteredResponse = filteredResponse.filter(
        ({ companyName, pk, sk }) =>
          String(pk).includes(sensorFilterText) ||
          String(companyName).includes(sensorFilterText) ||
          String(sk).includes(sensorFilterText)
      );
    }

    return filteredResponse;
  }, [sensorFilterStatus, sensorFilterText, endpoints]);

  return (
    <PageTemplate>
      <Container>
        <Modal
          show={showModalSensor}
          handleClose={() => setShowModalSensor(false)}
          size="lg"
          hideSaveButton
        >
          {selectedSensor && (
            <SensorDetailsContainer>
              <h4>Detalhes do sensor ({selectedSensor.sk})</h4>
              <span>
                <strong>Bolt ID: </strong>
                {selectedSensor?.pk}
              </span>
              <span>
                <strong>Empresa: </strong>
                {selectedSensor?.companyName}
              </span>
              <span>
                <strong>Ultimo envio: </strong>
                {selectedSensor.lastAcquisitionTime &&
                  new Intl.DateTimeFormat("pt-BR", {
                    dateStyle: "short",
                    timeStyle: "medium",
                  }).format(new Date(selectedSensor.lastAcquisitionTime))}
              </span>
              <span>
                <strong>Status: </strong>
                <BoltStatus status={selectedSensor.status}>
                  {selectedSensor.status}
                </BoltStatus>
              </span>
              <br />
              <h5>Edge Network</h5>
              <span>
                <strong>rssi: </strong>
                {selectedSensor?.edgeNetwork?.rssi}
              </span>
              <br />
              <h5>Sistema</h5>
              <span>
                <strong>Tempo em execução: </strong>
                {selectedSensor?.system?.uptime}
              </span>
              <span>
                <strong>Firmware: </strong>
                {selectedSensor?.system?.firmwareVersion}
              </span>
              <span>
                <strong>Firmware configuration: </strong>
                {selectedSensor?.system?.configuration?.firmwareConfiguration}
              </span>
              <span>
                <strong>Hardware configuration: </strong>
                {selectedSensor?.system?.configuration?.hardwareConfiguration}
              </span>
              <span>
                <strong>Battery Voltage: </strong>
                {selectedSensor?.electrical?.batteryVoltage}
              </span>
            </SensorDetailsContainer>
          )}
        </Modal>
        <ContentContainer>
          <HomeFilterBar setFilters={setFilters} filters={filters} />
          <CardStatusContainer>
            <CardBolt
              countBoltOnline={gateways.online}
              countBolt={gateways.list.length}
            />
            <CardSensor
              countSensor={endpoints.total}
              countSensorOnline={endpoints.online}
            />
            <CardCollectPercent
              received={transmissions.receivedCollects}
              expected={transmissions.expectedCollects}
            />
          </CardStatusContainer>
          <ListContainer>
            <CardList>
              <FilterContainer>
                <div>
                  <span>
                    Registros de Bolts (
                    <CountUp end={gateways.list.length} duration={0.8} />)
                  </span>
                </div>

                <div>
                  <FilterButton
                    onClick={() =>
                      setBoltFilterStatus(
                        boltFilterStatus === "all"
                          ? "online"
                          : boltFilterStatus === "online"
                          ? "offline"
                          : "all"
                      )
                    }
                    active={boltFilterStatus === "all"}
                  >
                    {boltFilterStatus === "offline" ? (
                      <BsWifiOff color="red" />
                    ) : (
                      <BsWifi
                        color={boltFilterStatus === "online" ? "green" : ""}
                      />
                    )}
                  </FilterButton>

                  <FilterText
                    type="text"
                    placeholder="Empresa ou ID"
                    value={boltFilterText}
                    onChange={(e) => setBoltFilterText(e.target.value)}
                  />
                </div>
              </FilterContainer>
              <List>
                {gatewaysList &&
                  gatewaysList.map((bolt: any) => (
                    <ListItem
                      key={bolt.gatewayId}
                      onClick={() => {
                        setSelectedBolt(bolt);
                        setShowModalBolt(true);
                      }}
                    >
                      {bolt.companyName && (
                        <>
                          <span>
                            <strong>Empresa: </strong>
                            {bolt.companyName}
                          </span>
                        </>
                      )}
                      <span>
                        <strong>ID: </strong>
                        {bolt.gatewayId}
                      </span>
                      <span>
                        <strong>Ultimo envio: </strong>
                        {bolt.healthCheckDate &&
                          new Intl.DateTimeFormat("pt-BR", {
                            dateStyle: "short",
                            timeStyle: "medium",
                          }).format(new Date(bolt.healthCheckDate))}
                      </span>
                      <BoltStatus
                        status={bolt.dashboardConnected ? "online" : "offline"}
                      >
                        {bolt.dashboardConnected ? "online" : "offline"}
                      </BoltStatus>
                    </ListItem>
                  ))}
              </List>
            </CardList>
            <CardList>
              <FilterContainer>
                <div>
                  <span>
                    Registros de Sensores (
                    <CountUp end={endpoints.list.length} duration={0.8} />)
                  </span>
                  <InfoButtonContainer
                    ref={optionsRef}
                    onClick={() => setShowInfo(!showInfo)}
                  >
                    <IoMdInformationCircleOutline size={24} />
                  </InfoButtonContainer>
                </div>

                <div>
                  <FilterButton
                    onClick={() =>
                      setSensorFilterStatus(
                        sensorFilterStatus === "all"
                          ? "online"
                          : sensorFilterStatus === "online"
                          ? "offline"
                          : "all"
                      )
                    }
                  >
                    {sensorFilterStatus === "offline" ? (
                      <BsWifiOff color="red" />
                    ) : (
                      <BsWifi
                        color={sensorFilterStatus === "online" ? "green" : ""}
                      />
                    )}
                  </FilterButton>

                  <FilterText
                    type="text"
                    placeholder="Empresa ou ID ou Bolt ID"
                    value={sensorFilterText}
                    onChange={(e) => setSensorFilterText(e.target.value)}
                  />
                </div>
              </FilterContainer>
              {showInfo ? (
                <>
                  <Triangle left={optionsRef.current?.offsetLeft}></Triangle>
                  <InfoContainer
                    ref={menuRef}
                    left={optionsRef.current?.offsetLeft}
                  >
                    <span>
                      Registro de Sensores que já transmitiram, caso o mesmo
                      sensor transmita por múltiplos Bolts
                      <strong> haverá um registro para cada Bolt.</strong>
                    </span>
                  </InfoContainer>
                </>
              ) : null}
              <List>
                {sensorList.map((row: any) => (
                  <ListItem
                    onClick={() => {
                      setSelectedSensor(row);
                      setShowModalSensor(true);
                    }}
                  >
                    {row.companyName && (
                      <>
                        <span>
                          <strong>Empresa: </strong>
                          {row.companyName}
                        </span>
                      </>
                    )}
                    <span>
                      <strong>ID: </strong>
                      {row.id}
                    </span>
                    <span>
                      <strong>Bolt ID: </strong>
                      {row.gatewayId}
                    </span>
                    <span>
                      <strong>Firmware: </strong>
                      {row.firmware}
                    </span>
                    <span>
                      <strong>Ultimo envio: </strong>
                      {row.lastAcquisitionDate &&
                        new Intl.DateTimeFormat("pt-BR", {
                          dateStyle: "short",
                          timeStyle: "medium",
                        }).format(new Date(row.lastAcquisitionDate))}
                    </span>
                    <BoltStatus
                      status={row.dashboardConnected ? "online" : "offline"}
                    >
                      {row.dashboardConnected ? "online" : "offline"}
                    </BoltStatus>
                  </ListItem>
                ))}
              </List>
            </CardList>
          </ListContainer>
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};
