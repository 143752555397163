import { ListLogoUrl } from "../../../components/List/LogoUrl/List";
import { RecordProps } from "../../../types/default";
import { ListGateway } from "../../configurator/components/ListGateway";
import { ListGatewayConnectivity } from "../../configurator/components/ListGatewayConnectivity";
import { ListGatewayDetails } from "../../configurator/components/ListGatewayDetails";
import { ListFacility } from "../components/ListFacility";

export const OfflineBolts = {
  title: "Bolts offline",
  urlPath: "/monitorator/offline-bolts",
  apiPath: "/monitorator/offline-bolts",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
  },
  properties: {
    logoUrl: {
      id: "logoUrl",
      type: "IMAGE",
      label: null,
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListLogoUrl record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    gateway: {
      id: "gateway",
      label: "BOLT",
      type: "TEXT",
      isPrimaryKey: true,
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListGateway record={record} id={id} />
        ),
      },
    },
    facility: {
      id: "facility",
      type: "TEXT",
      label: "EMPRESA/UNIDADE",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListFacility record={record} id={id} />
        ),
      },
    },
    details: {
      id: "details",
      type: "TEXT",
      label: "DETALHES",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListGatewayDetails record={record} id={id} />
        ),
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
      },
    },
    facilityId: {
      id: "facilityId",
      label: "UNIDADE",
      type: "SELECT_API",
      endpoint: "/facilities-options",
      isVisible: {
        filter: true,
      },
    },
    connectivity: {
      id: "connectivity",
      type: "TEXT",
      label: "CONECTIVIDADE",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListGatewayConnectivity record={record} id={id} />
        ),
      },
    },
  },
};
