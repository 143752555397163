import { RecordProps } from "../../../../types/default";
import { Container } from "./style";

interface ListTranslationsProps extends RecordProps {
  type: "name" | "sugestion";
}

export const ListTranslations = ({
  id,
  record,
  type,
}: ListTranslationsProps) => {
  const value = record;

  if (!value) {
    return null;
  }

  const { name, nameTranslation, sugestion, sugestionTranslation } = value;

  return (
    <Container>
      <span>PT: {type === "name" ? name : sugestion}</span>
      <span>
        EN: {type === "name" ? nameTranslation?.en : sugestionTranslation?.en}
      </span>
      <span>
        ES: {type === "name" ? nameTranslation?.es : sugestionTranslation?.es}
      </span>
    </Container>
  );
};
