import styled from "styled-components";

export const Container = styled.div``;

export const Button = styled.button``;

export const CollectContainer = styled.pre`
  background-color: #fff;
  color: #000;
  overflow-y: auto;
  max-height: 500px;
`;

export const Title = styled.div`
  margin-bottom: 10px;
`;

export const Footer = styled.div`
  & > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const ButtonCancel = styled.button`
  background: transparent;
  min-width: 126px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  border: none;
  border-radius: 6px;
  margin-right: 10px;
  ${(props) => ({ ...props.theme.fonts.primary.H5SemiBold })};
  color: ${(props) => props.theme.colors.iconsPrimary};
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsPrimary} !important;
    background: transparent !important;
    color: ${(props) => props.theme.colors.iconsPrimary} !important;
  }

  &:active {
    background: transparent !important;
    border: none !important;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.iconsPrimary};
  }
`;
