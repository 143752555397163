import { useEffect, useState } from "react";
import { AddDeviceContainer, ContainerScanner, CloseButton } from "../styles";
import { MdClose } from "react-icons/md";
import { ButtonCancel } from "../../../modals/ConfirmModal/styles";
import { SavePosition } from "../../../components/ModalCompound/styles";
import { IDevices, InspectionStatusEnum } from "../types";
import QrReader from "react-web-qr-reader";

interface IavailableSlots {
  positionId: number;
  positionName: string;
  positioAvailable: boolean;
}

interface IAddDevices {
  inspectionId: string;
  availableSlots: IavailableSlots[];
  refreshAvailableSlots: () => void;
  closeModal: () => void;
  eventCreate: (deviceData: IDevices) => void;
  companyId: number;
}


export function AddDevices({
  inspectionId,
  closeModal,
  eventCreate,
  refreshAvailableSlots,
  availableSlots,
}: IAddDevices) {
  const [activatorId, setActivatorId] = useState<string>("");
  const [cameraDenied, setCameraDenied] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<{
    positionId: number;
    positionName: string;
  } | null>(null);

  const handleError = (error: any) => {
    if (error.toString().includes("Permission denied")) {
      setCameraDenied(true);
    }
  };

  function handleScan(result: any) {
    if (result) {
      const formattedCode = result.data?.toUpperCase()?.replaceAll("-", "");
      if (result.data.includes("/main/?id=")) {
        setActivatorId(formattedCode.split("=")[1]);
      } else {
        setActivatorId(formattedCode);
      }
    }
  }

  useEffect(() => {
    refreshAvailableSlots();
  })

  useEffect(() => {
    const firstAvailableSlot = availableSlots?.find(
      slot => slot.positioAvailable
    );
    setSelectedPosition(
      firstAvailableSlot
        ? {
            positionId: firstAvailableSlot?.positionId,
            positionName: firstAvailableSlot?.positionName
          }
        : null
    );

  }, [availableSlots])

  const createDevice = () => {
    eventCreate({
      qualityInspectionId: Number(inspectionId),
      deletedAt: "",
      deletedBy: "",
      createdAt: new Date(),
      createdBy: "",
      updatedAt: new Date(),
      updatedBy: "",
      positionId: selectedPosition?.positionId ?? 0,
      positionName: selectedPosition?.positionName ?? "",
      activatorId: activatorId,
      initialVisualInspectionStatus: InspectionStatusEnum.PENDING,
      initialVisualInspectionDetails: null,
      burninStatus: InspectionStatusEnum.PENDING,
      burninDetails: null,
      finalVisualInspectionStatus: InspectionStatusEnum.PENDING,
      finalVisualInspectionDetails: null,
      packingInspectionStatus: InspectionStatusEnum.PENDING,
      packingInspectionDetails: null
    });
  };

  return (
    <>
      <AddDeviceContainer addDevice>
        <>
          <header>
            <CloseButton onClick={closeModal}>
              <MdClose />
            </CloseButton>
            <div>
              <span>Dispositivo posição nº</span>
              <select
                value={selectedPosition?.positionId ?? ""}
                onChange={e =>
                  setSelectedPosition(
                    availableSlots?.find(
                      slot => slot.positionId === Number(e.target.value)
                    ) ?? null
                  )
                }
              >
                {availableSlots?.map(slot => (
                  <option
                    className="select-slots"
                    key={slot.positionId}
                    value={slot.positionId}
                    disabled={!slot.positioAvailable}
                  >
                    {slot.positionName}
                  </option>
                ))}
              </select>
            </div>
          </header>
          <section>
            <div className="addDeviceInputContainer">
              <input
                autoFocus
                type="text"
                placeholder="UUID"
                value={activatorId}
                onChange={event => setActivatorId(event?.target?.value)}
              />
            </div>
            <ContainerScanner>
              {cameraDenied && (
                <div>
                  <label> Sem Permissão para usar Câmera </label>
                </div>
              )}
              {!cameraDenied && (
                <div>
                  <QrReader
                    delay={5}
                    onError={handleError}
                    onScan={handleScan}
                    facingMode={"user"}
                  />
                </div>
              )}
            </ContainerScanner>
          </section>
        </>
        <footer>
          <ButtonCancel onClick={closeModal}>Cancelar</ButtonCancel>
          <SavePosition onClick={createDevice}>Salvar</SavePosition>
        </footer>
      </AddDeviceContainer>
    </>
  );
}
