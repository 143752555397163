import { Badge } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  /* height: calc(100vh - 130px); */
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerSummary = styled.div`
  display: flex;
`;

export const Summary = styled.div`
  border: 1px solid gray;
  width: 33%;
  padding: 5px;

  margin-right: 10px;

  & div:first-child {
    display: flex;
    align-items: center;

    margin-bottom: 10px;
  }
`;

export const ContainerFacilitySummary = styled.div`
  margin-bottom: 5px;
  & span {
    margin-right: 5px;
  }
`;

export const SummaryBadge = styled(Badge)`
  margin-left: 10px;
  padding: 5px;
`;

export const ContainerTable = styled.div`
  margin-top: 10px;
  height: calc(100vh - 545px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.iconsSecondary};
  }
  //FIREFOX
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
`;
