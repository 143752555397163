import { forwardRef } from 'react';
import { Container } from "./styles";
import { ButtonProps } from "./types";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  children,
  onClick,
  variant,
  type,
  disabled,
  width,
}, ref) => {
  return (
    <Container
      onClick={onClick}
      variant={variant}
      width={width}
      type={type}
      disabled={disabled}
      ref={ref}
    >
      {children}
    </Container>
  );
});