import ReactSelect from "react-select";
import { DeviceHealthSelectContainer } from "./styles";
import useDeviceHealthSelect from "./useDeviceHealthSelect";
import { useMemo } from "react";

interface IDeviceHealthSelect {
  label?: string;
  name?: string;
  options: any[];
  handleChange: (e: any) => void;
  value?: any;
  isSearchable?: boolean;
  isClearable?: boolean;
}

export default function DeviceHealthSelect({
  label,
  name,
  options,
  handleChange,
  value,
  isSearchable,
  isClearable,
}: IDeviceHealthSelect) {
  const { styles } = useDeviceHealthSelect();
  const defaltValue = useMemo(() => {
    return options.find(({ isDefault }) => !!isDefault);
  }, [options]);

  const currentValue = useMemo(() => {
    if (value || value === 0) {
      return options.find((option) => option.value === value);
    }
    return defaltValue || value;
    // eslint-disable-next-line
  }, [value]);

  return (
    <DeviceHealthSelectContainer>
      <label>{label}</label>
      <ReactSelect
        name={name}
        styles={styles}
        options={options}
        onChange={handleChange}
        defaultValue={defaltValue}
        isSearchable={false}
        value={currentValue}
        isClearable={isClearable}
      />
    </DeviceHealthSelectContainer>
  );
}
