import styled, { css } from "styled-components";

export const ColumnHeader = styled.div`
  margin: 8px 0 4px 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H4Bold};

  & > span {
    display: flex;
    align-items: center;
  }
`;

export const TableValue = styled.span<{ disabled?: boolean }>`
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  ${({ disabled }) => disabled && css`
    opacity: 0.7;
  `}
`;
