import { useEffect, useState, useMemo } from "react";
import queryString from "query-string";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  Table,
  PageHeader,
  PageTemplate,
  Filter,
  Paginate,
  Loading,
  Button,
} from "../../components";

import { Container, PaginateContainer, TableContainer } from "./styles";

import useApi from "../../hooks/useApi";
import { ListProps } from "./types";

export const List = ({ resource }: ListProps) => {
  const location = useLocation();

  const [showFilter, setShowFilter] = useState(false);
  const { request, processing } = useApi({ path: resource.apiPath });

  // // Resource state
  const [records, setRecords] = useState([]);
  const [pagination, setPagination] = useState<any>(null);
  const [lastKeyEvaluated, setLastKeyEvaluated] = useState<any>(null);
  const [paginationToken, setPaginationToken] = useState<any>(null);

  const [, setSearchParams] = useSearchParams();

  const handleSearch = (search: any) => {
    let newSearchParams = new URLSearchParams({
      ...filterValues,
      search,
    });
    setSearchParams(newSearchParams);
    setRecords([]);
  };

  function getRecords({ nextPaginationToken, nextLastKeyEvaluated }: any) {
    const params = queryString.parse(location.search);
    const { page = 1, ...filters } = params;

    const queryStringParameters = {
      limit: 10,
      ...filters,
    } as any;

    if (nextPaginationToken) {
      queryStringParameters.paginationToken = nextPaginationToken;
    } else if (nextLastKeyEvaluated) {
      queryStringParameters.lastKeyEvaluatedPk = nextLastKeyEvaluated.pk;
      queryStringParameters.lastKeyEvaluatedSk = nextLastKeyEvaluated.sk;
    } else {
      queryStringParameters.page = page;
    }

    request({
      method: "get",
      queryStringParameters,
    }).then((response: any) => {
      const { data, pagination, paginationToken } = response;

      if (!!nextPaginationToken) {
        setPaginationToken(null);
      }

      if (!!nextLastKeyEvaluated) {
        setLastKeyEvaluated(null);
      }

      // RDS Pagination
      if (!!pagination) {
        setPagination(pagination);
        setRecords(data);
      }
      // Dynamo Pagination
      else if (!!response.lastKeyEvaluated || nextLastKeyEvaluated) {
        setLastKeyEvaluated(response.lastKeyEvaluated);
        setRecords(nextLastKeyEvaluated ? [...records, ...data] : data);
      } else if (!!paginationToken) {
        setPaginationToken(paginationToken);
        setRecords(nextPaginationToken ? [...records, ...data] : data);
      } else if (data) {
        setRecords(nextPaginationToken ? [...records, ...data] : data);
      } else {
        setRecords(response);
      }
    });
  }

  const filterValues = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  useEffect(() => {
    setPagination(null);
    setLastKeyEvaluated(null);
    setPaginationToken(null);
    getRecords({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, location.pathname]);

  return (
    <>
      <PageTemplate>
        <>
          <Container>
            <PageHeader
              resultCount={pagination?.totalElements || records.length}
              resource={resource}
              handleClickFilter={() => setShowFilter(!showFilter)}
              showResourceActions
              handleSearch={handleSearch}
              queryTotal={pagination?.total}
            />
            {processing ? (
              <Loading />
            ) : (
              <>
                {!processing && records?.length === 0 ? (
                  <span>Sem dados</span>
                ) : (
                  <TableContainer>
                    <Table
                      resource={resource}
                      data={records}
                      refresh={getRecords}
                    />
                  </TableContainer>
                )}
                {pagination && (
                  <PaginateContainer>
                    <Paginate pagination={pagination} />
                  </PaginateContainer>
                )}
                {paginationToken && (
                  <PaginateContainer>
                    <Button
                      onClick={() => {
                        getRecords({ nextPaginationToken: paginationToken });
                      }}
                    >
                      Carregar Mais
                    </Button>
                  </PaginateContainer>
                )}
                {lastKeyEvaluated && (
                  <PaginateContainer>
                    <Button
                      onClick={() => {
                        getRecords({ nextLastKeyEvaluated: lastKeyEvaluated });
                      }}
                    >
                      Carregar Mais
                    </Button>
                  </PaginateContainer>
                )}
              </>
            )}
          </Container>
          <Filter
            key={`filter-${resource.title}`}
            show={showFilter}
            initialValues={filterValues}
            resource={resource}
            handleClose={() => setShowFilter(false)}
            handleFilter={() => setRecords([])}
          />
        </>
      </PageTemplate>
    </>
  );
};
