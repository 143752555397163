import styled from "styled-components";
import { Modal } from "../../components/Modal";
import { PROBLEMS_LABELS } from "../../pages/GatewayHealthCheck";
import useApi from "../../hooks/useApi";
import { error, success } from "../../components/Toast";
import { useEffect, useState } from "react";
import { dateFormat } from "../../modules/summary/resources/Gateways";
import { Spinner } from "react-bootstrap";
import { CiCloudOff } from "react-icons/ci";

interface IInstructionModal {
  onClose: () => void | any;
  isOpen: boolean;
  gatewayId: string;
  refresh: (values?: any) => void;
}

export const CommentsModal = ({
  onClose,
  isOpen,
  gatewayId,
  refresh,
}: IInstructionModal) => {
  const [comments, setComments] = useState<any[]>([]);
  const [newComment, setNewComment] = useState<string>("");

  const { request, processing } = useApi({
    path: `/monitorator/gateways/${gatewayId}/custom-field`,
  });

  const fetchAllComments = () => {
    request({ method: "get" })
      .then((response) => {
        setComments(response);
      })
      .catch(() => {});
  };

  const handleSave = () => {
    request({
      method: "put",
      body: { id: new Date().getTime(), comment: newComment },
    })
      .then(() => {
        success("Observação atualizada com sucesso!");
        fetchAllComments();
      })
      .catch(() => {
        error("Erro ao atualizar observação");
      });
  };

  const handleConfirm = () => {
    if (!newComment) return;
    setNewComment("");
    refresh({ gatewayId, addedComment: newComment });
    handleSave();
  };

  useEffect(() => {
    fetchAllComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      handleClose={onClose}
      show={isOpen}
      size="lg"
      handleConfirm={handleConfirm}
    >
      <PrincipalContainer>
        <Header>
          <h4>Observações</h4>
          {/* <ButtonAdd>
            <IoMdAdd />
          </ButtonAdd> */}
        </Header>
        <Input
          placeholder="Adicionar comentário..."
          autoFocus
          value={newComment}
          onChange={(e) => setNewComment(e?.target?.value)}
        />
        <Container>
          <div>
            <h4>Histórico</h4>
          </div>
          <div>
            {processing ? (
              <Loading>
                <Spinner animation={"border"} />
              </Loading>
            ) : !comments.length ? (
              <Loading>
                <CiCloudOff size={40} />
                <label>Ainda não há histórico</label>
              </Loading>
            ) : (
              comments.map(
                ({ comment, id }: { comment: string; id: number }) => (
                  <Historic>
                    <span>{dateFormat(id)}</span>
                    <label>{comment}</label>
                  </Historic>
                )
              )
            )}
          </div>
        </Container>
      </PrincipalContainer>
    </Modal>
  );
};

const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
`;

const Historic = styled.div`
  ${(props) => props.theme.fonts.primary.H5Regular};
  display: flex;
  gap: 1rem;
  padding: 12px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};

  & > span {
    width: 15%;
    font-weight: 300;
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  & > label {
    width: 85%;
    font-size: 14px;
  }
`;

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 10px;
  width: 100%;
  height: 14rem;
  padding: 1rem 0.45rem 1rem 1rem;

  h4 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }

  & > div:last-child {
    overflow-y: auto;
    height: 90%;

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px;
      @media screen and (max-width: 767px) {
        width: 3px !important;
      }
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.headerBackground};
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) =>
        props.theme.colors.iconsSecondaryAlpha} !important;
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }
    //FIREFOX
    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }
`;

const Input = styled.textarea`
  border: 1px solid red;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsPrimary};
  ${({ theme }) => theme.fonts.primary.H4Regular};
  border-radius: 4px;
  padding: 8px;
  height: 3rem;
  border-radius: 8px;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }
`;

const ButtonAdd = styled.button`
  background: ${(props) => props.theme.colors.ibbxOrange};
  color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border: none;
`;

export const PrincipalContainer = styled.div`
  padding: 8px 8px 12px 8px;
  border-radius: 6px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
