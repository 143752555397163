import styled from "styled-components";
import useApi from "../../../hooks/useApi";
import { MdOutlineToggleOff, MdToggleOn } from "react-icons/md";
import ModalCompound from "../../../components/ModalCompound";
import { useState } from "react";
import InputField from "../../../components/InputField";

interface IDeviceHealthIgnoreButton {
  gatewayId: string;
  isIgnored?: boolean;
  refresh: any;
}

export default function DeviceHealthIgnoreButton({
  isIgnored,
  gatewayId,
  refresh,
}: IDeviceHealthIgnoreButton) {
  const { request } = useApi({ path: "/monitorator/gateway-analysis-ignore" });

  const toggleIgnore = () => {
    request({
      method: "put",
      pathParameters: gatewayId,
      body: { reason },
    }).then(() => {
      refresh();
    });
  };

  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");

  const handleReasonChange = (e: any) => {
    setReason(e.target.value);
  };

  return (
    <>
      <ModalCompound show={show} handleClose={() => setShow(false)}>
        <ModalCompound.Body>
          <h1>Desabilitar</h1>
          <InputField
            label="Motivo"
            type="text"
            handleChange={handleReasonChange}
            value={reason}
          />
        </ModalCompound.Body>
        <ModalCompound.Footer>
          <ModalCompound.Save handleConfirm={toggleIgnore} />
          <ModalCompound.Cancel handleClose={() => setShow(false)} />
        </ModalCompound.Footer>
      </ModalCompound>
      <Container>
        {isIgnored ? (
          <MdOutlineToggleOff onClick={toggleIgnore} />
        ) : (
          <MdToggleOn onClick={() => setShow(true)} />
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  svg {
    cursor: pointer;
    font-size: 24px;
  }
`;

// const Button = styled.button`
//   ${({ theme }) => theme.fonts.primary.H5Regular};
//   background-color: ${({ theme }) => theme.colors.ibbxOrange};
//   font-size: 11px;
//   color: ${({ theme }) => theme.colors.white};
//   padding: 4px;
//   border-radius: 8px;
//   border: none;
// `;
