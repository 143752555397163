import { MdOpenInNew } from "react-icons/md";
import { ColumnHeader, TableValue } from "../../List/styles";
import { ReactTable } from "../../../components/Table/ReactTable";

import { GatewayStatusTableContainer } from "./styles";
import { useOrderBy } from "../../../hooks/userOrderBy";
import { OrderButton } from "../../../components/OrderButton";

export default function GatewaysStatusTable({ list, changeColumnLabel }: any) {
  const { handleOrder, orderedList, orderState } = useOrderBy({ list });

  return (
    <GatewayStatusTableContainer>
      <span>{list.length} registros</span>
      <ReactTable
        responsive
        data={orderedList || []}
        columns={[
          {
            id: "gatewayId",
            accessor: "gatewayId",
            Header: () => (
              <ColumnHeader>
                <span>GATEWAY ID</span>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return (
                <TableValue>
                  {value}{" "}
                  <MdOpenInNew
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RETINA_INFRA_URL}/summary/gateways/${value}/history`,
                        "_blank"
                      )
                    }
                  />
                </TableValue>
              );
            },
          },
          {
            id: "status",
            accessor: "status",
            Header: () => (
              <ColumnHeader>
                <span>{changeColumnLabel ? changeColumnLabel : "Status"}</span>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return <TableValue>{value}</TableValue>;
            },
          },
          {
            id: "companyName",
            accessor: "companyName",
            Header: () => (
              <ColumnHeader>
                <div>
                  <span>EMPRESA</span>
                  <OrderButton
                    orderState={orderState}
                    handleOrder={handleOrder}
                    columnName="companyName"
                  />
                </div>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return <TableValue>{value}</TableValue>;
            },
          },

          {
            id: "facilityName",
            accessor: "facilityName",
            Header: () => (
              <ColumnHeader>
                <div>
                  <span>UNIDADE</span>
                  <OrderButton
                    orderState={orderState}
                    handleOrder={handleOrder}
                    columnName="facilityName"
                  />
                </div>
              </ColumnHeader>
            ),
            Cell: ({ value, row: { original } }: any) => {
              return (
                <TableValue>
                  {value}{" "}
                  <MdOpenInNew
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${original.companyId}/facilities/${original.facilityId}/assets?sidetab=bolt`,
                        "_blank"
                      )
                    }
                  />
                </TableValue>
              );
            },
          },
        ]}
      />
    </GatewayStatusTableContainer>
  );
}
