import { DefaultTheme as SCDefaultTheme } from "styled-components";

import DefaultTheme from "./default";

const lightTheme: SCDefaultTheme = {
  ...DefaultTheme,

  status: false,

  backgroundColors: {
    background0: "#F2F2F2",
    background1: "#D9D9D9",
    background2: "#BFBFBF",
    background3: "#A6A6A6",
    background4: "#7F7F7F"
  },

  colors3: {
    color0: "#000000",
    color1: "#595959",
    color2: "#7F7F7F"
  },

  colors: {
    alarmRisco: "#FF0000",
    alarmAlerta: "#FFD700",
    alarmRiscoAlpha: "#FF00005e",
    alarmAlertaAlpha: "#FFD70063",
    white: "#FFFFFF",
    normalStatus: "#368B82",
    statusText: "#FFFFFF",
    colorTools: "#717A9B",
    clock: "#ce3878",
    p30: "#368B82",
    backgroundStatus: "#252a3ba3",
    StatusTools: "#CE3878",
    IconsTools: "#475271",
    background: "#E3E8EE",
    headerBackground: "#F6F6F7",
    divBackground: "#F7F8FB",
    divBackgroundAlpha: "#F7F8FB64",
    cardBackground: "#FFFFFF",
    cardHighlight: "#ECF3FA",
    iconsSecondary: "#3A4058",
    iconsSecondaryAlpha: "#3A405840",
    iconsPrimary: "#495781",
    textSecondary: "#282E40",
    textPrimary: "#0B0D12",
    itemsHighlight: "#01D5BF",
    statusNormal: "#368B82",
    statusAlert: "#B6933B",
    lightOrange: "#FF9B30",
    ibbxOrange: "#F37035",
    statusRisk: "#A9406C",
    failureAlert: "#DEA40F",
    lightBackground: "#F3F6F8"
  }
};

export default lightTheme;
