import styled from "styled-components";

export const Container = styled.div``;

export const Button = styled.div``;

export const CollectContainer = styled.pre`
  background-color: #fff;
  color: #000;
  overflow-y: auto;
  max-height: 500px;
`;

export const Title = styled.div`
  margin-bottom: 10px;
`;

export const TableTimes = styled.table`
  margin-top: 10px;

  & td,
  th {
    border: 1px solid ${({ theme }) => theme.colors.textPrimary};
    padding: 5px;
  }
`;
