import { ListBoolean } from "../../../components/List/ListBoolean";
import { ListLogoUrl } from "../../../components/List/LogoUrl/List";
import { PageProps, RecordProps } from "../../../types/default";
import { put } from "../../../utils/api";
import { ListFacility } from "../../monitorator/components/ListFacility";
import { ModalGatewayWindow } from "../../summary/components/ModaGatewayWindow";
import { ListGateway } from "../components/ListGateway";
import { ListGatewayConnectivity } from "../components/ListGatewayConnectivity";
import { ListGatewayDetails } from "../components/ListGatewayDetails";
import { ModalGatewayTimes } from "../modals/ModalGatewayTimes";
import { BoltChangeUpdatingMode } from "../pages/BoltChangeUpdatingMode";

export const GatewayResource = {
  title: "Bolts",
  urlPath: "/configurator/gateways",
  apiPath: "/configurator/gateways",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    delete: { isVisible: false },
    changeParameters: {
      type: "record",
      label: "Parâmetros",
      modalComponent: ({ record }: RecordProps) => (
        <ModalGatewayWindow gatewayId={record.gatewayId} />
      ),
    },
    changeOperationMode: {
      type: "record",
      label: "Trocar modo para operação",
      guard: "Tem certeza que deseja trocar o modo para operação?",
      condition: (record: any) => record.operationMode !== "OPERATION",
      handler: async (record: any) =>
        put({
          path: `/configurator/gateways/${record.gatewayId}/operation-mode`,
        }),
    },
    changeConfigurationMode: {
      type: "record",
      label: "Trocar modo para configuração",
      guard: "Tem certeza que deseja trocar o modo para configuração?",
      condition: (record: any) => record.operationMode !== "CONFIGURATION",
      handler: async (record: any) =>
        put({
          path: `/configurator/gateways/${record.gatewayId}/configuration-mode`,
        }),
    },
    changeUpdatingMode: {
      name: "changeUpdatingMode",
      type: "record",
      label: "Trocar modo para atualização",
      guard: "Tem certeza que deseja trocar o modo para atualização?",
      condition: (record: any) => record.operationMode !== "UPDATING",
      component: ({ resource }: PageProps) => (
        <BoltChangeUpdatingMode resource={resource} />
      ),
    },
    changeAutomaticMode: {
      type: "record",
      label: "Trocar modo para automatico",
      guard: "Tem certeza que deseja trocar o modo para automatico?",
      condition: (record: any) => record.operationMode !== "AUTOMATIC",
      handler: async (record: any) =>
        put({
          path: `/configurator/gateways/${record.gatewayId}/automatic-mode`,
        }),
    },
    changeFormatMode: {
      type: "record",
      label: "Trocar modo para format",
      guard: "Tem certeza que deseja trocar o modo para format?",
      condition: ({ version }: any) => {
        return (
          !!version &&
          !version.includes("-1.1") &&
          !version.includes("-1.2") &&
          !version.includes("-1.3") &&
          !version.includes("-1.4") &&
          !version.includes("-1.5")
        );
      },
      handler: async (record: any) =>
        put({
          path: `/configurator/gateways/${record.gatewayId}/format-mode`,
        }),
    },
    restart: {
      name: "restart",
      type: "record",
      label: "Resetar",
      guard: "Tem certeza que deseja resetar o bolt?",
      handler: async (record: any) =>
        put({
          path: `/configurator/gateways/${record.gatewayId}/restart-mode`,
        }),
    },
    forceAllSynchronize: {
      name: "forceAllSynchronize",
      type: "record",
      label: "Resincronizar todos os sensores",
      guard: "Tem certeza que deseja resincronizar todos os sensores?",
      handler: async (record: any) =>
        put({
          path: `/configurator/gateways/${record.gatewayId}/force-synchronize-sensors`,
        }),
    },
    viewTimes: {
      name: "viewTimes",
      type: "record",
      label: "Ver tempos",
      modalComponent: ({ record, id }: RecordProps) => (
        <ModalGatewayTimes id={id} record={record} />
      ),
    },
    changeConnectivity: {
      name: "changeConnectivity",
      type: "record",
      label: "Trocar connectividade Empresa/Unidade",
      guard: "Tem certeza que deseja trocar a connectividade do gateway?",
      handler: async (record: any) =>
        put({
          path: `/configurator/gateways/${record.gatewayId}/change-connectivity`,
        }),
    },
  },
  properties: {
    logoUrl: {
      id: "logoUrl",
      type: "IMAGE",
      label: null,
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListLogoUrl record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    gatewayId: {
      id: "gatewayId",
      label: "BOLT",
      type: "TEXT",
      isPrimaryKey: true,
      isVisible: {},
    },
    gateway: {
      id: "gateway",
      label: "BOLT",
      type: "TEXT",
      isPrimaryKey: true,
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListGateway record={record} id={id} />
        ),
      },
    },
    facility: {
      id: "facility",
      type: "TEXT",
      label: "EMPRESA/UNIDADE",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListFacility record={record} id={id} />
        ),
      },
    },
    details: {
      id: "details",
      type: "TEXT",
      label: "DETALHES",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListGatewayDetails record={record} id={id} />
        ),
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
      },
    },
    facilityId: {
      id: "facilityId",
      label: "UNIDADE",
      type: "SELECT_API",
      endpoint: "/facilities-options",
      isVisible: {
        filter: true,
      },
    },
    operationFrequency: {
      id: "operationFrequency",
      label: "Frequência",
      type: "SELECT",
      options: [
        { value: 902.5, label: "902.5" },
        { value: 903.0, label: "903.0" },
        { value: 903.5, label: "903.5" },
        { value: 904.0, label: "904.0" },
        { value: 904.5, label: "904.5" },
        { value: 905.0, label: "905.0" },
        { value: 905.5, label: "905.5" },
        { value: 906.0, label: "906.0" },
        { value: 906.5, label: "906.5" },
        { value: 907.0, label: "907.0" },
        { value: 907.5, label: "907.5" },

        { value: 915.0, label: "915.0" },
        { value: 915.5, label: "915.5" },
        { value: 916.0, label: "916.0" },
        { value: 916.5, label: "916.5" },
        { value: 917.0, label: "917.0" },
        { value: 917.5, label: "917.5" },
        { value: 918.0, label: "918.0" },
        { value: 918.5, label: "918.5" },
        { value: 919.0, label: "919.0" },
        { value: 919.5, label: "919.5" },
        { value: 920.0, label: "920.0" },
        { value: 920.5, label: "920.5" },
        { value: 921.0, label: "921.0" },
        { value: 921.5, label: "921.5" },
        { value: 922.0, label: "922.0" },
        { value: 922.5, label: "922.5" },
        { value: 923.0, label: "923.0" },
        { value: 923.5, label: "923.5" },
        { value: 924.0, label: "924.0" },
        { value: 924.5, label: "924.5" },
        { value: 925.0, label: "925.0" },
        { value: 925.5, label: "925.5" },
        { value: 926.0, label: "926.0" },
        { value: 926.5, label: "926.5" },
        { value: 927.0, label: "927.0" },
        { value: 927.5, label: "927.5" },
        { value: 928.0, label: "928.0" },

        // { value: 928.5, label: "928.50" },
        // { value: 929.0, label: "929.00" },
        // { value: 929.5, label: "929.50" },
        // { value: 930.0, label: "930.00" },
        // { value: 930.5, label: "930.50" },
        // { value: 931.0, label: "931.00" },
        // { value: 931.5, label: "931.50" },
        // { value: 932.0, label: "932.00" },
        // { value: 932.5, label: "932.50" },
        // { value: 933.0, label: "933.00" },
        // { value: 933.5, label: "933.50" },
        // { value: 934.0, label: "934.00" },
        // { value: 934.5, label: "934.50" },
        // { value: 935.0, label: "935.00" },
        // { value: 935.5, label: "935.50" },
        // { value: 936.0, label: "936.00" },
        // { value: 936.5, label: "936.50" },
        // { value: 937.0, label: "937.00" },
        // { value: 937.5, label: "937.50" },
        // { value: 938.0, label: "938.00" },
        // { value: 938.5, label: "938.50" },
        // { value: 939.0, label: "939.00" },
        // { value: 939.5, label: "939.50" },
        // { value: 940.0, label: "940.00" },
        // { value: 940.5, label: "940.50" },
        // { value: 941.0, label: "941.00" },
        // { value: 941.5, label: "941.50" },
        // { value: 942.0, label: "942.00" },
        // { value: 942.5, label: "942.50" },
        // { value: 943.0, label: "943.00" },
        // { value: 943.5, label: "943.50" },
        // { value: 944.0, label: "944.00" },
        // { value: 944.5, label: "944.50" },
        // { value: 945.0, label: "945.00" },
        // { value: 945.5, label: "945.50" },
        // { value: 946.0, label: "946.00" },
        // { value: 946.5, label: "946.50" },
        // { value: 947.0, label: "947.00" },
        // { value: 947.5, label: "947.50" },
        // { value: 948.0, label: "948.00" },
      ],
      isVisible: {
        edit: true,
      },
    },
    operationMode: {
      id: "operationMode",
      label: "MODO",
      type: "TEXT",
      isVisible: {},
    },
    connectivity: {
      id: "connectivity",
      type: "TEXT",
      label: "CONECTIVIDADE",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListGatewayConnectivity record={record} id={id} />
        ),
      },
    },
    online: {
      id: "online",
      label: "ONLINE",
      type: "CHECK",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
      },
    },
  },
};
