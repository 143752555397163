import { Container, Item, Title } from "./styles";

import { SidebarProps } from "./types";

export const Sidebar = ({ selected }: SidebarProps) => {
  return (
    <Container visible={true}>
      <Title>Gateways</Title>
      <Item to="/summary/gateways">Tabelão</Item>
      <Item to="/monitorator/gateway-health-check">Saúde</Item>
      <Item to="/monitorator/gateways-status">Histórico</Item>
      <Item to="/monitorator/health-status-problems">Análise de Falhas</Item>
      <Title>Endpoints</Title>
      <Item to="/summary/endpoints">Tabelão</Item>
      <Item to="/monitorator/sensors-health">Saúde</Item>
      <Item to="/monitorator/sensors-status">Histórico</Item>

      <Title>Monitorator</Title>
      <Item to="/">Geral</Item>
      {/* <Item to="/monitorator/bolts">Bolts</Item> */}
      {/* <Item to="/monitorator/sensors">Sensores</Item> */}
      <Item to="/monitorator/collects">Coletas</Item>
      <Item to="/monitorator/growth">Crescimento</Item>
      <Item to="/monitorator/disconnected-positions">Pontos desconectados</Item>
      <Item to="/monitorator/offline-bolts">Bolts offline</Item>
      <Item to="/monitorator/facility-status">Status da unidade</Item>
      <Item to="/monitorator/occurrences">Ocorrências</Item>

      {/* <Item to="/monitorator/board-alarms?status=open">Alarme sensores</Item> */}
      <Title>Infra</Title>
      <Item to="/infra/users">Usuários</Item>
      <Item to="/infra/diagnostics">Diagnósticos</Item>
      <Item to="/infra/asset-types">Tipos de equipamentos</Item>
      <Item to="/infra/bearings">Rolamentos</Item>
      {/* <Item to="#">Placas</Item> */}
      <Title>Web</Title>
      <Item to="/web/users">Usuários</Item>
      <Item to="/web/users-facilities">Usuários Unidades</Item>
      <Item to="/web/assets">Ativos</Item>
      <Item to="/web/positions">Pontos</Item>
      <Item to="/web/announcements">Anúncios</Item>
      <Item to="/web/occurrence-exclusion-reasons">
        Motivos exclusão Ocorrências
      </Item>
      {/* <Item to="#">Usuários Representante</Item> */}
      {/* <Item to="#">Empresas</Item> */}
      <Title>Configurator</Title>
      <Item to="/configurator/board-signals">Sinais dos sensores</Item>
      <Item to="/configurator/gateway-schedules">Horarios dos sensores</Item>
      <Item to="/configurator/gateways">Bolts</Item>
      <Item to="/configurator/gateway-firmwares">Bolt Firmwares</Item>
      <Title>AI</Title>
      <Item to="/ai/results">Resultados</Item>
      <Title>Qualidade</Title>
      <Item to="/quality/inspections">Inspeções de Qualidade</Item>
    </Container>
  );
};
