import { Badge } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  width: 100%;
  width: calc(100vw - 300px);
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 5px;
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ColumnHeader = styled.div`
  /* margin: 8px 0 4px 0; */
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H4Bold};

  /* width: 100px; */

  & > span {
    display: flex;
    align-items: center;
  }

  & > div:first-child {
    display: flex;
    align-items: flex-end;
    gap: 6px;
  }
`;

export const TableContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background} !important;
    width: 15px;
    border-radius: 100vw;
    margin-block: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
  //FIREFOX
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 24px;
    max-width: 84%;
  }

  .table-responsive {
    width: 100%;
    overflow-x: unset;
  }
`;

export const StatusContainer = styled(Badge)`
  margin-left: 5px;
  padding: 5px;

  &:first-child {
    margin-left: 0;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 10px;
  align-items: center;
`;

export const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  margin-right: 5px;

  span {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  & > div:nth-child(1) {
    ${(props) => props.theme.fonts.primary.H4Bold};
  }
  & > div:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  & > div:nth-child(3) {
    ${(props) => props.theme.fonts.primary.H4Bold};
  }

  & > div:nth-child(4) {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  animation: slide-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ClickableTableValue = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const HeadersContainer = styled.div`
  z-index: 1;
`;

export const PaginateContaienr = styled.footer`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const ButtonFilter = styled.button`
  margin-left: 10px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background: ${(props) => props.theme.backgroundColors.background3};
  border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  transition: 0.4s ease-in-out;

  svg {
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  :hover {
    background: ${(props) => props.theme.colors.iconsSecondary};

    svg {
      color: ${(props) => props.theme.backgroundColors.background3};
    }
  }
`;
