import { Container } from "../styles";
import { ListProps } from "../types";

export const ListDate = ({ record, id }: ListProps) => {
  let date = record[id];

  try {
    if (date) {
      date = new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "medium",
      }).format(new Date(date));
    }
  } catch (error) {}

  return <Container>{date}</Container>;
};
