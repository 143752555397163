import { useMemo, useReducer } from "react";
import { OrderByContainers } from "./styles";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";

interface IOrderButton {
  orderState: { field: string; order: string };
  handleOrder: (columnName: string) => void;
  columnName: string;
}

export const OrderButton = ({
  orderState,
  handleOrder,
  columnName,
}: IOrderButton) => {
  return (
    <OrderByContainers selected={orderState.field === columnName}>
      <button onClick={() => handleOrder(columnName)}>
        {orderState.order === "ASCENDING" ? (
          <FaLongArrowAltUp />
        ) : (
          <FaLongArrowAltDown />
        )}
      </button>
    </OrderByContainers>
  );
};
