import { useEffect, useRef, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { Container, FooterContainer } from "./style";
import { Input } from "../../../../components";
import { Loading } from "../../../../components";
import { CSVLink } from "react-csv";
import useApi from "../../../../hooks/useApi";

interface UserResponse {
  email: string;
  name: string;
  companyName: string;
  facilityName: string;
  status: string;
  profile: string;
  document: string;
}

type OptionKeys = "internos" | "representantes" | "clientes";

export function ExportUserCSV() {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | string[]>(
    "todos"
  );
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<UserResponse[]>([]);

  const csvRef = useRef<any>(null);

  const { request } = useApi({ path: "" });

  const handleShow = () => {
    setShow((state) => !state);
  };

  const options = [
    { value: "todos", label: "Todos" },
    { value: "internos", label: "Internos" },
    { value: "representantes", label: "Representantes" },
    { value: "clientes", label: "Clientes" },
  ];

  const handleDownload = () => {
    setLoading(true);

    const optionsMapping: Record<OptionKeys, string[]> = {
      internos: ["MESA_MASTER", "EXECUTIVO_MASTER", "MESA_ANALISTA"],
      representantes: ["REPRESENTANTE"],
      clientes: ["CLIENTE_MASTER", "CLIENTE_COMUM"],
    };

    const options = optionsMapping[selectedOption as OptionKeys] || ["all"];

    let csvArray: UserResponse[] = [];

    Promise.all(
      options.map((option: string) =>
        request({
          pathParameters: `web/users?${
            selectedOption === "todos" ? "limit=10&page=1" : `profile=${option}`
          }`,
          method: "get",
        })
      )
    )
      .then((responses) => {
        responses.forEach((response) => {
          let data = response.data.map((item: UserResponse) => {
            return {
              EMAIL: item.email,
              NOME: item.name,
              EMPRESA: item.companyName,
              UNIDADE: item.facilityName,
              PERFIL: item.profile,
              STATUS: item.status,
              // CNPJ: item.document || "",
            };
          });

          csvArray.push(...data);
        });

        setCsvData(csvArray);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!loading && csvData.length > 0) {
      csvRef.current.link.click();
    }
  }, [csvData.length, loading]);

  return (
    <>
      <Button onClick={handleShow}>Exportar CSV</Button>
      <Modal handleClose={handleShow} show={show} changeFotter>
        <Container>
          <div>
            <strong>Exportar dados</strong>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <form>
              <Input
                type="SELECT"
                options={options}
                onChange={(event) => {
                  setSelectedOption(event.target.value);
                }}
                id="id"
                label="Selecione um grupo de usuários"
                value={selectedOption}
              />
            </form>
          )}

          <CSVLink
            separator=";"
            style={{ display: "none" }}
            ref={csvRef}
            data={csvData}
            filename={`Usuários Retina - ${selectedOption}.csv`}
          />
          <FooterContainer>
            <Button onClick={handleShow} disabled={loading}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={handleDownload}
              disabled={loading}
            >
              Baixar
            </Button>
          </FooterContainer>
        </Container>
      </Modal>
    </>
  );
}
