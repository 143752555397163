import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TooltipBootStrap from "react-bootstrap/Tooltip";

type TooltipProps = {
  tooltip: string;
  position: any;
  children: JSX.Element;
  disabled?: boolean;
};

export const Tooltip = ({
  tooltip,
  position,
  children,
  disabled,
}: TooltipProps) => {
  return (
    <>
      {!disabled ? (
        <OverlayTrigger
          key={tooltip}
          placement={position}
          overlay={
            <TooltipBootStrap
              id={`tooltip-${tooltip}}`}
              style={{ zIndex: 10000 }}
            >
              {tooltip}
            </TooltipBootStrap>
          }
        >
          {children}
        </OverlayTrigger>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
