import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "../Button";
import { Input } from "../Input";

import {
  ContainerFilter,
  ContainerActions,
  ContainerInputs,
  Title,
  RoundedButton,
  FilterHeader,
} from "./styles";
import { FilterProps } from "./types";
import { BiChevronRight } from "react-icons/bi";

export const Filter = ({
  show,
  resource,
  initialValues,
  handleClose,
  handleFilter,
}: FilterProps) => {
  const [, setSearchParams] = useSearchParams();

  const [values, setValues] = useState<any>(initialValues || {});

  const filters = useMemo(
    () => [
      ...Object.values(resource.properties).filter(
        (propertie: any) => !propertie.isVisible || propertie.isVisible?.filter
      ),
    ],
    [resource]
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const filteredValues: any = Object.keys(values).reduce((acc, curr) => {
      if (!values[curr]) {
        return acc;
      }
      return {
        ...acc,
        [curr]: values[curr],
      };
    }, {});

    delete filteredValues.page;

    let newSearchParams = new URLSearchParams(filteredValues);
    setSearchParams(newSearchParams);
    handleFilter();
    handleClose();
  };

  const clearFields = () => {
    Object.keys(values).forEach((key) => {
      values[key] = "";
    });
    setValues({ ...values });
  };

  return (
    <ContainerFilter show={show}>
      <FilterHeader>
        {show && (
          <RoundedButton onClick={handleClose}>
            <BiChevronRight />
          </RoundedButton>
        )}
        <Title>Filtro</Title>
      </FilterHeader>
      <ContainerInputs>
        {filters.map((filter: any) => (
          <Input
            key={filter.id}
            {...filter}
            value={values[filter.id]}
            values={values}
            setValues={setValues}
            onChange={(e: any) => {
              setValues((prevState: any) => ({
                ...prevState,
                [filter.id]: e.target.value,
              }));
            }}
            isFilter
          />
        ))}
        <ContainerActions>
          <Button type="button" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={() => {
              setSearchParams();
              handleFilter();
              clearFields();

              handleClose();
            }}
          >
            Limpar
          </Button>
          <Button type="submit" onClick={handleSubmit} variant="primary">
            Filtrar
          </Button>
        </ContainerActions>
      </ContainerInputs>
    </ContainerFilter>
  );
};
