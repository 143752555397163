import { getProfile } from "../../../utils/getProfile";
import { PropertyContainer, PropertyLabel, PropertyValue } from "../styles";
import { ShowProps } from "../types";

export const ShowProfile = ({ record, label, id }: ShowProps) => {
  const profile = getProfile(record[id as string]);

  return (
    <PropertyContainer key={id}>
      <PropertyLabel>{label}</PropertyLabel>
      <PropertyValue>{profile}</PropertyValue>
    </PropertyContainer>
  );
};
