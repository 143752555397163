import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { userSelect } from "../store/slices/userSlice";

type RouteProps = {
  children: JSX.Element;
  isGuest?: boolean;
  isPrivate?: boolean;
  isNewPassword?: boolean;
};

export const Route = ({
  children,
  isGuest,
  isPrivate,
  isNewPassword,
}: RouteProps) => {
  const user = useAppSelector(userSelect);
  const location = useLocation();

  if (isGuest && user.id !== "") {
    return <Navigate to="/" />;
  }

  if (isPrivate && user.id === "") {
    return <Navigate to={`/login?from=${location.pathname}`} />;
  }

  if (isNewPassword && user.loginNewPassword) {
    return <Navigate to={`/new-password?from=${location.pathname}`} />;
  }

  return children;
};
