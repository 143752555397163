import React, { useEffect, useState } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { Loading } from "../../../../components";

import { Modal } from "../../../../components/Modal";
import { success } from "../../../../components/Toast";
import useApi from "../../../../hooks/useApi";
import { RecordProps } from "../../../../types/default";

import { Container, Button, CollectContainer } from "./styles";

export const ModalCollect = ({ id, record }: RecordProps) => {
  const [show, setShow] = useState(false);
  const [collect, setCollect] = useState(null);

  const [value, copy] = useCopyToClipboard();

  const { request, processing } = useApi({ path: "/monitorator/collects" });

  useEffect(() => {
    request({
      method: "get",
      queryStringParameters: {
        sk: record.sk,
      },
      pathParameters: `${id}`,
    }).then((response) => setCollect(response));
  }, []);

  return (
    <>
      <Button onClick={() => setShow(true)}>Ver Coleta</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        handleConfirm={() => {
          copy(JSON.stringify(collect));
          success("Coleta copiada com sucesso");
        }}
        confirmLabel="Copiar"
        size="lg"
      >
        <Container>
          <span>{`Coleta (${id})`}</span>
          {processing ? (
            <Loading />
          ) : (
            <CollectContainer>
              {JSON.stringify(collect, null, 2)}
            </CollectContainer>
          )}
        </Container>
      </Modal>
    </>
  );
};
