import { MdOpenInNew } from "react-icons/md";
import { ColumnHeader, TableValue } from "../../List/styles";
import { ReactTable } from "../../../components/Table/ReactTable";
import { GatewayProblemsTableContainer } from "./styles";

export default function GatewaysProblemsTable({ list }: any) {
  return (
    <GatewayProblemsTableContainer>
      <ReactTable
        responsive
        data={list || []}
        columns={[
          {
            id: "gatewayId",
            accessor: "gatewayId",
            Header: () => (
              <ColumnHeader>
                <span>GATEWAY ID</span>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return (
                <TableValue>
                  {value}{" "}
                  <MdOpenInNew
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RETINA_INFRA_URL}/summary/gateways/${value}/history`,
                        "_blank"
                      )
                    }
                  />
                </TableValue>
              );
            },
          },
          {
            id: "problem",
            accessor: "problem",
            Header: () => (
              <ColumnHeader>
                <span>PROBLEMA</span>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return <TableValue>{value}</TableValue>;
            },
          },
          {
            id: "companyName",
            accessor: "companyName",
            Header: () => (
              <ColumnHeader>
                <span>EMPRESA</span>
              </ColumnHeader>
            ),
            Cell: ({ value }) => {
              return <TableValue>{value}</TableValue>;
            },
          },

          {
            id: "facilityName",
            accessor: "facilityName",
            Header: () => (
              <ColumnHeader>
                <span>UNIDADE</span>
              </ColumnHeader>
            ),
            Cell: ({ value, row: { original } }: any) => {
              return (
                <TableValue>
                  {value}{" "}
                  <MdOpenInNew
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${original.companyId}/facilities/${original.facilityId}/assets?sidetab=bolt`,
                        "_blank"
                      )
                    }
                  />
                </TableValue>
              );
            },
          },
        ]}
      />
    </GatewayProblemsTableContainer>
  );
}
