export const STATUS_CONFIG: any = {
  NORMAL: {
    value: "NORMAL",
    color: "#008000",
    label: "Normal",
    status: 1,
  },
  ALERT: {
    value: "NORMAL",
    color: "#E6B400",
    label: "Alerta",
    status: 1,
  },
  RISK: {
    value: "NORMAL",
    color: "#FF0000",
    label: "Risco",
    status: 1,
  },
};

export const DEAD_ALIVE_CONFIG: any = {
  Total: {
    color: "",
  },
  Mortos: {
    color: "#FF0000",
  },

  Reviveram: {
    color: "#008000",
  },
};

export const STATUS_PROBLEMS_CONFIG: any = {
  NEW_GATEWAY: {
    value: "NORMAL",
    color: "#DAD6CA",
    label: "Novo Gateway",
    status: 1,
  },
  DISCONNECTED: {
    value: "NORMAL",
    color: "#1BB0CE",
    label: "Desconectado",
    status: 1,
  },
  NO_ENERGY_RISK: {
    value: "NORMAL",
    color: "#4F8699",
    label: "Desenergizado",
    status: 1,
  },
  NO_ENERGY_ALERT: {
    value: "NORMAL",
    color: "#6A5E72",
    label: "Desenergizado Alerta",
    status: 1,
  },
  INSTABLE_CONNECTION: {
    value: "NORMAL",
    color: "#563444",
    label: "Conexão Instável",
    status: 1,
  },
  WEAK_RSSI_RISK: {
    value: "NORMAL",
    color: "#008000",
    label: "RSSI Fraco",
    status: 1,
  },
  WEAK_RSSI_ALERT: {
    value: "NORMAL",
    color: "#BFD3DA",
    label: "RSSI Fraco Alerta",
    status: 1,
  },
  DEAD: {
    value: "NORMAL",
    color: "#BFD3DA",
    label: "Morto",
    status: 1,
  },
};
