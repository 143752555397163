import { useState } from "react";

import { Modal } from "../../components/Modal";

import { ButtonCancel, ContainerConfirmModal } from "./styles";

import { ConfirmModalProps } from "./types";

export const ConfirmModal = ({
  onConfirm,
  text,
  confirmLabel,
  cancelLabel,
  isEsgPanel,
  children,
}: ConfirmModalProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {children ? (
        <div
          onClick={() => {
            setShow(true);
          }}
        >
          {children}
        </div>
      ) : (
        <ButtonCancel
          type="button"
          isEsgPanel={isEsgPanel}
          onClick={() => {
            setShow(true);
          }}
        >
          Cancelar
        </ButtonCancel>
      )}
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        handleConfirm={onConfirm}
        size="sm"
        confirmLabel={confirmLabel || `Confirmar`}
        cancelLabel={cancelLabel || `Cancelar`}
        backgroundColor={isEsgPanel ? "#f6f8ee" : ""}
        borderColor={isEsgPanel ? "#f6f8ee" : ""}
      >
        <ContainerConfirmModal isEsgPanel={isEsgPanel}>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {text}
          </label>
        </ContainerConfirmModal>
      </Modal>
    </>
  );
};
