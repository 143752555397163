// @flow
import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

import logoIbbxOrange from "../../images/ibbxLogoOrange.svg";

export const AppLoading = () => {
  return (
    <ContainerAppLoading>
      <img src={logoIbbxOrange} alt="logo" />
      <div>
        <AppLoadingSpinner animation="border" />
      </div>
    </ContainerAppLoading>
  );
};

interface LoadingProps {
  marginTop?: string;
  height?: string;
}

export const Loading = ({ marginTop, height }: LoadingProps) => {
  return (
    <ContainerLoading marginTop={marginTop} height={height}>
      <Spinner animation="border" />
    </ContainerLoading>
  );
};

const ContainerAppLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  position: relative;

  & > div {
    width: 100%;
    text-align: center;
    position: absolute;
  }

  & > img {
    width: 200px;
    height: 200px;
    padding-bottom: 5px;
  }
`;

const ContainerLoading = styled.div<LoadingProps>`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => props.marginTop || "30px"};
  ${(props) => (props.height ? `height: ${props.height}` : "")};
`;

const AppLoadingSpinner = styled(Spinner)`
  width: 200px;
  height: 200px;
  opacity: 0.5;
`;
