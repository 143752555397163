import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";

import { CSVLink } from "react-csv";

import { Button, PageTemplate } from "../../components";

import useApi from "../../hooks/useApi";

import { Container, Header, InfoContainer } from "./styles";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";
import GatewaysStatusTable from "./GatewaysStatusTable";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import GatewaysProblemsFilter from "../GatewaysProblems/GatewaysProblemsFilter";
import { ChartContainer } from "../GatewaysProblems/styles";
import { PageTitle } from "../List/styles";
import {
  DEAD_ALIVE_CONFIG,
  STATUS_CONFIG,
  STATUS_PROBLEMS_CONFIG,
} from "./types";
import HealthCheckDynamicStatus from "../GatewayHealthCheck/HeathCheckDynamicStatus";
import { PROBLEMS_LABELS } from "../GatewayHealthCheck";
import { DifferenceModal } from "../../modals/DifferenceModal";
import { DeadAliveComment } from "../../modals/DeadAliveComment";
import { Spinner } from "react-bootstrap";

const FIELD_TO_TYPE: any = {
  connectivity: "CONNECTION",
  sdCardType: "SD_CARD",
  powerSupply: "POWER_SUPPLY",
  version: "VERSION",
  percentFreePSRAM: "PSRAM",
  percentFreeHeap: "HEAP_MEMORY",
  gsmRSSI: "GSM_RSSI",
  wifiRSSI: "WIFI_RSSI",
};

const ENABLE_LABELS = true;

const IGNORED_DEFAULT: string[] = [
  "wifiRSSI",
  "sdCardType",
  "powerSupply",
  "version",
  "percentFreePSRAM",
  "percentFreeHeap",
];

const GATEWAY_DYNAMIC_STATUS = [
  { value: "connectivity", label: "Conexão" },
  { value: "sdCardType", label: "Cartão SD" },
  { value: "powerSupply", label: "Alimentação" },
  { value: "version", label: "Versão" },
  { value: "percentFreePSRAM", label: "Memória PSRAM" },
  { value: "percentFreeHeap", label: "Memória Heap" },
  { value: "gsmRSSI", label: "GSM RSSI" },
  { value: "wifiRSSI", label: "WIFI RSSI" },
];

const SERIES_NAME = [
  "Normal",
  "Alerta",
  "Risco",
  "Desconectado",
  "Novo Gateway",
  "Desenergizado",
  "Desenergizado Alerta",
  "Desconectado",
  "Conexão Instável",
  "RSSI Fraco",
  "RSSI Fraco Alerta",
  "Cartão",
  "Morto",
];

const EMPTY_DEAD_ALIVE_COMMENT = { date: "", comment: "", serie: "" };

const DEAD_ALIVE_SERIES_NAMES: any = {
  Mortos: "gatewaysOfflineFromYesterday",
  "Total Mortos": "gatewaysOffline",
  Reviveram: "gatewaysRevived",
  Desativados: "deactivatedGateways",
};
interface ISelectedChart {
  name: "CHART-PROBLEMS" | "CHART-STATUS" | "DEAD-ALIVE";
}

export const GatewaysStatus = () => {
  const { darkMode } = useAppSelector(appSelect);

  const { request, processing } = useApi({
    path: "",
  });

  const csvRef = useRef<any>(null);

  const [data, setData] = useState<any>(null);
  const [list, setList] = useState<any>([]);
  const [filters, setFilters] = useState<any>({});
  const [statusIgnored, setStatusIgnored] = useState<any>(IGNORED_DEFAULT);
  const [selectedSeries, setSelectedSeries] = useState<string[]>(SERIES_NAME);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [commentsModal, setCommentsModal] = useState<{
    show: boolean;
    date: string;
    serie: string;
  }>({ show: false, date: "", serie: "" });
  const [deadAliveComment, setDeadAliveComment] = useState<{
    date: string;
    comment: string;
    serie: string;
  }>(EMPTY_DEAD_ALIVE_COMMENT);

  const [chartType, setChartType] = useState<
    "CHART-PROBLEMS" | "CHART" | "DEAD-ALIVE"
  >("CHART");

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const ignore = useMemo(() => {
    const items = statusIgnored.map((item: string) => FIELD_TO_TYPE[item]);
    if (!items.length) {
      return undefined;
    }
    return items.join(",");
  }, [statusIgnored]);

  const handleClickDeadAliveSerie = useCallback(
    ({ date, serie }: { date: string; serie: string }) => {
      const [day, month, year] = date.split("/");

      const formatedDate = `${year}-${month}-${day}`;

      let epoch = new Date(formatedDate).getTime();

      const hours = new Date(epoch).getHours();

      if (hours !== 0) {
        epoch = epoch + 60 * 60 * 1000 * 3;
      }

      const serieName = DEAD_ALIVE_SERIES_NAMES[serie];

      request({
        method: "get",
        pathParameters: `monitorator/dead-alive-csv/${epoch}/series/${serieName}`,
      }).then((response) => {
        const sorted = response.sort((a: any, b: any) =>
          a?.companyName?.localeCompare(b?.companyName)
        );

        if (sorted.length) {
          setList(sorted);
          return;
        }
        setList(response);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processing]
  );

  function handleCloseCommentsModal() {
    setCommentsModal({ show: false, date: "", serie: "" });
  }

  function handleChangeChartType(
    chart: "CHART-PROBLEMS" | "CHART" | "DEAD-ALIVE"
  ) {
    setChartType(chart);
  }

  const STATUS_LABEL: any = {
    1: "NORMAL",
    2: "ALERTA",
    3: "RISCO",
  };

  function getChartData() {
    if (queryParams.minDate && !queryParams.maxDate) return;

    request({
      method: "get",
      pathParameters: "monitorator/gateways-status",
      queryStringParameters: {
        ...queryParams,
        ...filters,
        ignore,
      },
    }).then((response) => {
      let csvData;
      csvData = response.csv.map((item: any) => {
        return {
          DIA: new Intl.DateTimeFormat("pt-BR", {
            dateStyle: "short",
            timeStyle: "medium",
          }).format(new Date(item.date)),
          STATUS: STATUS_LABEL[+item.status],
          GATEWAY: item.gatewayId,
          EMPRESA: item.companyName,
          UNIDADE: item.facilityName,
          PROBLEMA: PROBLEMS_LABELS[item.flag],
          CONECTADO: item.isConnected,
        };
      });

      setData({
        chart: response.chart,
        csv: csvData,
        table: response.csv,
        chartByProblems: response.chartByProblems || [],
        deadAlive: response.deadAlive || [],
      });
    });
  }

  useEffect(() => {
    if (!processing) {
      getChartData();
    }

    // eslint-disable-next-line
  }, [darkMode, location.search, filters, statusIgnored]);

  const statusValue: any = {
    Normal: 1,
    Alerta: 2,
    Risco: 3,
  };

  function removeDuplicatesByGatewayId(array: any[]) {
    const unique = new Map(array.map((item) => [item.gatewayId, item]));
    return Array.from(unique.values());
  }

  const handleSaveSelectedSeries = useCallback(({ selected }: any) => {
    const filtered = Object.entries(selected)
      .filter(([key, value]) => value)
      .map(([keyMap, valueMap]) => {
        return keyMap;
      });
    setSelectedSeries(filtered);
  }, []);

  const onEvents = useMemo(() => {
    return {
      click: (params: any) => {
        const key = params?.data?.name;
        const date = params?.data?.value?.[0];

        switch (chartType) {
          case "CHART": {
            const removedDuplicateArray = removeDuplicatesByGatewayId(
              data.table
                .filter(
                  (item: any) =>
                    item.date === date && item.status === statusValue[key]
                )
                .map((item: any) => {
                  return { ...item, status: key };
                })
            );

            setList(removedDuplicateArray || []);
            break;
          }

          case "CHART-PROBLEMS": {
            const keyName = Object.entries(STATUS_PROBLEMS_CONFIG).find(
              ([chave, value]: any) => value.label === key
            )?.[0];

            const removedArray = removeDuplicatesByGatewayId(
              data?.table
                .filter(
                  (item: any) => item.date === date && item.flag === keyName
                )
                .map((item: any) => {
                  return { ...item, status: key };
                })
            );

            setList(removedArray || []);
            setDisableButton(false);

            break;
          }

          case "DEAD-ALIVE": {
            const { seriesName: serie, name: date, componentType } = params;

            handleClickDeadAliveSerie({ serie, date });
            ////Código para inserir e ver comentários - Comentado caso seja necessário a volta.
            // if (componentType === "markPoint") {
            //   const comment = params?.name;
            //   const serie = params?.data?.serieName;
            //   const date = params?.data?.xAxis;

            //   setDeadAliveComment({ comment, serie, date });
            //   break;
            // }

            // handleOpenCommentsModal({ date, serie });

            break;
          }
        }
      },
      legendselectchanged: handleSaveSelectedSeries,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartType]);

  const getPercentFontSize = (arrayLength: number) => {
    switch (true) {
      case arrayLength <= 15: {
        return null;
      }
      case arrayLength <= 20: {
        return 8;
      }

      case arrayLength <= 32: {
        return 7;
      }

      case arrayLength >= 33: {
        return 5;
      }
      
      default: {
        return null;
      }
    }
  };

  const series: any[] = useMemo(() => {
    const fontSize = getPercentFontSize(data?.chart?.ALERT?.length);

    return data?.chart
      ? Object.keys(data?.chart || {}).map((item, index) => {
          return {
            name: STATUS_CONFIG[item].label,
            color: STATUS_CONFIG[item].color,
            data: (data.chart[item] || []).map((i: any) => ({
              name: STATUS_CONFIG[item].label,
              value: i,
            })),
            type: "bar",
            stack: "Ad",

            showSymbol: false,
            endLabel: {
              show: true,
              formatter: function (params: any) {
                return `${params.seriesName}: ${params.value[1]}`;
              },
            },
            labelLayout: {
              moveOverlap: "shiftY",
              fontSize,
            },
            emphasis: {
              focus: "series",
            },
            lineStyle: {
              width: 3,
            },
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartType]);

  const seriesByProblems: any[] = useMemo(() => {
    return data?.chartByProblems
      ? Object.keys(data?.chartByProblems || {}).map((item, index) => {
          return {
            name: STATUS_PROBLEMS_CONFIG[item].label,
            color: STATUS_PROBLEMS_CONFIG[item].color,
            data: (data.chartByProblems[item] || []).map((i: any) => ({
              name: STATUS_PROBLEMS_CONFIG[item].label,
              value: i,
            })),
            type: "bar",
            stack: "Ad",
            showSymbol: false,
            endLabel: {
              show: true,
              formatter: function (params: any) {
                return `${params.seriesName}: ${params.value[1]}`;
              },
            },
            labelLayout: {
              moveOverlap: "shiftY",
            },
            emphasis: {
              focus: "series",
            },
            lineStyle: {
              width: 3,
            },
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartType]);

  function handleCloseDeadAliveComment() {
    setDeadAliveComment(EMPTY_DEAD_ALIVE_COMMENT);
  }

  function genFormatter(series: any[]) {
    return (param: any) => {
      if (!ENABLE_LABELS) {
        return "";
      }
      let sum = 0;
      const onlySelectedSerie = series.filter((item: any) =>
        selectedSeries.includes(item.name)
      );

      onlySelectedSerie.forEach((item) => {
        sum += item.data[param.dataIndex].value[1];
      });

      return sum || "";
    };
  }

  function percentFormater(series: any[]) {
    return (param: any) => {
      if (!ENABLE_LABELS) {
        return "";
      }
      let sum = 0;
      series.forEach((item) => {
        sum += item.data[param.dataIndex]?.value?.[1];
      });
      if (!sum || !param.data.value[1]) {
        return "";
      }
      return `${+((param.data.value[1] / sum) * 100).toFixed(2)}%`;
    };
  }

  const echartsOptionsDeadAlive = useMemo(() => {
    const color = darkMode ? "#FFF" : "#000";

    return {
      grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["Forest", "Steppe", "Desert", "Wetland"],
        textStyle: { color },
        right: "right",
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: data?.deadAlive?.dates || [],
          nameTextStyle: {
            color,
          },
          axisLabel: {
            color,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            color,
          },
          nameTextStyle: {
            color,
          },
          max: data?.deadAlive?.series?.[0]?.maxValue + 5 || 30,
        },
      ],
      series: data?.deadAlive?.series?.map(
        (item: {
          name: string;
          data: any[];
          maxValue: number;
          markPoints: any;
        }) => {
          return {
            name: item.name,
            type: "bar",
            color: DEAD_ALIVE_CONFIG[item.name]?.color,
            barGap: 0,
            endLabel: {
              show: true,
              formatter: function (params: any) {
                return `${params.seriesName}: ${params.value[1]}`;
              },
            },
            label: {
              show: ENABLE_LABELS,
              fontSize: 15,
              color: "white",
              position: "inside",
            },
            emphasis: {
              focus: "series",
            },
            data: item.data,
            lineStyle: {
              width: 5,
            },
          };
        }
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const echartsOptions = useMemo(() => {
    if (!data?.chart || !Object.keys(data.chart).length) {
      return null;
    }

    if (chartType === "DEAD-ALIVE") {
      return echartsOptionsDeadAlive;
    }

    let currentSeries = series;
    if (chartType === "CHART-PROBLEMS") {
      currentSeries = seriesByProblems;
    }

    const color = darkMode ? "#FFF" : "#000";

    const fontSize = getPercentFontSize(currentSeries?.[0]?.data?.length);

    return {
      grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
      legend: {
        data: Object.keys(data?.chart || {}).map(
          (item) => STATUS_CONFIG[item].label
        ),
        textStyle: { color },
        right: "right",
      },
      xAxis: {
        type: "time",
        nameTextStyle: {
          color,
        },
        axisLabel: {
          color,
          formatter: function (value: any) {
            const day = new Date(value).getDate();
            const monthNumber = new Date(value).getMonth() + 1;
            const month = monthNumber.toString().padStart(2, "0");
            return `${day}/${month}`;
          },
        },
      },
      yAxis: {
        axisLabel: {
          color,
        },
        nameTextStyle: {
          color,
        },
      },
      darkMode: true,
      series: currentSeries
        .map((item, index) => {
          return Object.assign(item, {
            type: "bar",
            stack: "Ad",
            label: {
              show: ENABLE_LABELS,
              formatter: percentFormater(currentSeries),
              fontSize: 15,
              color: "white",
              position: "inside",
            },
          });
        })
        .concat([
          {
            show: false,
            name: "Total",
            type: "bar",
            stack: "Ad",
            data: currentSeries[0].data.map((item: any) => {
              return {
                name: "Total",
                value: [item.value[0], 0],
              };
            }),
            label: {
              show: ENABLE_LABELS,
              position: "top",
              formatter: genFormatter(currentSeries),
              fontSize,
              color: "white",
            },
            tooltip: {
              show: false,
            },
          },
        ]),
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false,
        },
      },
      animationDuration: 1000,
    };
    // eslint-disable-next-line
  }, [data, darkMode, chartType, selectedSeries]);

  useEffect(() => {
    setList([]);
  }, [chartType]);

  function filterAndShowAllProblemsList() {
    const removedArray = removeDuplicatesByGatewayId(
      data.table
        .filter((itemF: any) => !!itemF.flag)
        .map((item: any) => {
          const problemName = STATUS_PROBLEMS_CONFIG[item.flag]?.label || "";

          return { ...item, status: problemName };
        })
    );

    const sort = removedArray.sort((a: any, b: any) => {
      if (b.flag < a.flag) {
        return 1;
      }
      return -1;
    });
    setDisableButton(true);
    setList(sort || []);
  }

  useEffect(() => {
    if (chartType === "CHART-PROBLEMS") {
      filterAndShowAllProblemsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartType]);

  return (
    <PageTemplate>
      <Container>
        <Header>
          <PageTitle>
            Histórico dos Gateways{" "}
            <Button
              variant={chartType === "CHART" ? "primary" : "secundary"}
              onClick={() => handleChangeChartType("CHART")}
            >
              Por Status
            </Button>
            <Button
              variant={chartType === "CHART-PROBLEMS" ? "primary" : "secundary"}
              onClick={() => handleChangeChartType("CHART-PROBLEMS")}
              disabled={processing}
            >
              Por Problema
            </Button>{" "}
            <Button
              variant={chartType === "DEAD-ALIVE" ? "primary" : "secundary"}
              onClick={() => handleChangeChartType("DEAD-ALIVE")}
              disabled={processing}
            >
              Mortos
            </Button>
            {processing && <Spinner animation={"border"} />}
          </PageTitle>
          <div>
            <CSVLink
              separator=";"
              style={{ display: "none" }}
              ref={csvRef}
              data={data?.csv || []}
              filename={`data.csv`}
              className=""
            ></CSVLink>

            <Button
              variant="primary"
              onClick={() => {
                csvRef.current.link.click();
              }}
            >
              Baixar CSV
            </Button>
          </div>
        </Header>

        {chartType !== "DEAD-ALIVE" && (
          <>
            <HealthCheckDynamicStatus
              ignored={statusIgnored}
              setIgnored={setStatusIgnored}
              items={GATEWAY_DYNAMIC_STATUS}
            />
          </>
        )}
        <GatewaysProblemsFilter
          filters={filters}
          setFilters={setFilters}
          type="STATUS"
          hideGateweayId={chartType === "DEAD-ALIVE"}
          hideFilters={chartType === "DEAD-ALIVE"}
        />
        <ChartContainer>
          {data?.chart && (
            <ReactECharts option={echartsOptions} onEvents={onEvents} />
          )}
        </ChartContainer>
        {chartType === "CHART-PROBLEMS" && (
          <InfoContainer>
            <button
              disabled={disableButton}
              onClick={filterAndShowAllProblemsList}
            >
              Ver todos
            </button>
            {/* <label>{list?.length} registros</label> */}
          </InfoContainer>
        )}
        {!!list?.length && (
          <GatewaysStatusTable
            list={list}
            changeColumnLabel={chartType === "CHART" ? "" : "Problema/Status"}
          />
        )}
        <DifferenceModal
          onClose={handleCloseCommentsModal}
          isOpen={commentsModal.show}
          data={commentsModal}
          refresh={() => {
            getChartData();
            setChartType("DEAD-ALIVE");
          }}
        />
        <DeadAliveComment
          onClose={handleCloseDeadAliveComment}
          isOpen={!!deadAliveComment.comment}
          handleConfirm={handleCloseDeadAliveComment}
          comment={deadAliveComment.comment}
          date={deadAliveComment.date}
          serie={deadAliveComment.serie}
        />
      </Container>
    </PageTemplate>
  );
};
