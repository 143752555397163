import { RecordProps } from "../../../../types/default";
import { ListContainer, ConnectionStatus, SynchronizedStatus } from "./styles";

export const ListSchedule = ({ id, record }: RecordProps) => {
  const { schedule } = record;
  const {
    gatewayId,
    schedulePosition,
    synchronized,
    healthCheckDate,
    rssi,
    gateway,
  } = schedule || {};

  if (!schedule) {
    return (
      <ListContainer>
        <span>Sem agendamento</span>
      </ListContainer>
    );
  }

  let healthCheckDateFormatted;
  try {
    healthCheckDateFormatted = new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "medium",
    }).format(new Date(healthCheckDate));
  } catch (error) {}

  return (
    <ListContainer>
      <div>
        <span>Bolt: </span>
        <span>{`${gatewayId.slice(-6)}`}</span>{" "}
        <ConnectionStatus status={gateway.online}>ONLINE</ConnectionStatus>
      </div>
      <div>
        <span>Sincronizado: </span>
        <SynchronizedStatus status={synchronized}>
          {synchronized ? "Sim" : "Não"}
        </SynchronizedStatus>
      </div>
      <div>
        <span>Posição: </span>
        <span>{schedulePosition}</span>
      </div>
      <div>
        <span>RSSI: </span>
        <span>{rssi?.toFixed(2)}</span>
      </div>
      <div>
        <span>Ultimo sincronizado: </span>
        <span>{healthCheckDateFormatted}</span>
      </div>
    </ListContainer>
  );
};
